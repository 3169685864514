import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import { cityList } from '../../../_mock/cityList';
import JobsService from '../../../redux/api/JobsService';
import { getJobsMethod } from '../../../redux/slice/jobsSlice';
import RichTextEditors from '../../RichText/RichTextEditors';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function UpdateJobsDialog({ openEditForm, handleClickOpenEditForm, jobId }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.job);

  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [jobType, setJobType] = React.useState('Select Job Type');
  const [responsibilities, setResponsibilities] = React.useState(null);
  const [companyName, setCompanyName] = React.useState(null);
  const [city, setCity] = React.useState('Select City');
  const [country, setCountry] = React.useState('Select Country');
  const [skills, setSkills] = React.useState(null);
  const [salary, setSalary] = React.useState(null);
  const [status, setStatus] = React.useState('Select Status');
  const [noJobs, setNoJobs] = React.useState(null);
  const [lastDate, setLastdate] = React.useState(null);

  React.useEffect(() => {
    const result = data.data && data.data.filter((item) => item._id === jobId);
    if (result && result.length !== 0) {
      setTitle(result[0]?.title);
      setDescription(result[0]?.description);
      setJobType(!result[0]?.jobType ? 'Select Job Type' : result[0]?.jobType);
      setResponsibilities(result[0]?.responsibilities);
      setCompanyName(result[0].companyName);
      setCity(!result[0].city ? 'Select City' : result[0]?.city);
      setCountry(!result[0]?.country ? 'Select Country' : result[0]?.country);
      setSkills(result[0]?.skills);
      setSalary(result[0]?.salary);
      setStatus(!result[0]?.status ? 'Select Status' : result[0].status);
      setNoJobs(result[0]?.noOfPositions);
      setLastdate(result[0]?.lastDate);
    }
  }, [jobId]);

  const handleSubmit = async () => {
    if (
      !title ||
      !responsibilities ||
      !companyName ||
      !city ||
      !country ||
      !jobType ||
      !skills ||
      !salary ||
      !status ||
      !noJobs ||
      !lastDate
    ) {
      toast.error('Please fill all the fields');
      return;
    }
    const data = {
      title,
      responsibilities,
      companyName,
      city,
      country,
      jobType,
      skills,
      salary,
      status,
      noOfPositions: noJobs,
      lastDate,
      description,
    };
    await JobsService.update(jobId, data)
      .then((res) => {
        console.warn(res);
        toast.success('Job updated successfully');
        dispatch(getJobsMethod());
        setTitle(null);
        setDescription(null);
        setJobType('Select Job Type');
        setResponsibilities(null);
        setCompanyName(null);
        setCity('Select City');
        setCountry('Select Country');
        setSkills(null);
        setSalary(null);
        setStatus('Select Status');
        setNoJobs(null);
        setLastdate(null);
        handleClickOpenEditForm();
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
        throw error;
      });
  };

  return (
    <div>
      <Dialog fullScreen open={openEditForm} onClose={handleClickOpenEditForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenEditForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Job
            </Typography>
            {/* <Button autoFocus variant="outlined" sx={{ color: 'white', borderColor: 'white' }} onClick={handleSubmit}>
              Edit
            </Button> */}
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Edit Job</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Job Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Job Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Company Name
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Company Name"
              variant="outlined"
              fullWidth
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Job Type
            </Typography>
            <FormControl fullWidth>
              <Select value={jobType} onChange={(e) => setJobType(e.target.value)} sx={{ textAlign: 'left' }}>
                <MenuItem value="Select Job Type" disabled>
                  Select Job Type
                </MenuItem>
                <MenuItem value="Full Time">Full Time</MenuItem>
                <MenuItem value="Part Time">Part Time</MenuItem>
                <MenuItem value="Hybrid">Hybrid</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Skills
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Skills"
              variant="outlined"
              fullWidth
              value={skills}
              onChange={(e) => setSkills(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Salary
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Salary"
              variant="outlined"
              fullWidth
              type='number'
              value={salary}
              onChange={(e) => setSalary(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              No. of Jobs
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="No. of Jobs"
              variant="outlined"
              fullWidth
              type='number'
              value={noJobs}
              onChange={(e) => setNoJobs(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Last Date
            </Typography>
            <TextField
              id="outlined-basic"
              type="date"
              variant="outlined"
              fullWidth
              value={lastDate}
              onChange={(e) => setLastdate(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              City
            </Typography>
            <FormControl fullWidth>
              <Select value={city} onChange={(e) => setCity(e.target.value)} sx={{ textAlign: 'left' }}>
                <MenuItem value="Select City" disabled>
                  Select City
                </MenuItem>
                {cityList.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Country
            </Typography>
            <FormControl fullWidth>
              <Select value={country} onChange={(e) => setCountry(e.target.value)} sx={{ textAlign: 'left' }}>
                <MenuItem value="Select Country" disabled>
                  Select Country
                </MenuItem>
                <MenuItem value="Pakistan">Pakistan</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Status
            </Typography>
            <FormControl fullWidth>
              <Select value={status} onChange={(e) => setStatus(e.target.value)} sx={{ textAlign: 'left' }}>
                <MenuItem value="Select Status" disabled>
                  Select Status
                </MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Draft">Draft</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Job Responsibilities
            </Typography>
            <RichTextEditors placeholder="Job Responsibilities" body={responsibilities} setBody={setResponsibilities} />
            {/* <TextField
              id="outlined-basic"
              placeholder="Job Responsibilities"
              multiline
              rows={5}
              variant="outlined"
              fullWidth
              value={responsibilities}
              onChange={(e) => setResponsibilities(e.target.value)}
              required
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Job Description
            </Typography>
            <RichTextEditors placeholder="Job Description" body={description} setBody={setDescription} />
            {/* <TextField
              id="outlined-basic"
              placeholder="Job Description"
              multiline
              rows={5}
              variant="outlined"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={() => {
                handleSubmit();
                // handleClickOpenEditForm();
              }}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
