import { callAPi } from './http-common';

const prevGet = () => callAPi.get('/getVendorWalletHistory');
const get = () => callAPi.get('/getPaymentHistory');

const PaymentHistoryService = {
  get,
  prevGet,
};

export default PaymentHistoryService;
