import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ReviewServices from '../api/ReviewServices';

const initialState = {
  loading: false,
  data: [],
  error: null,
  success: false,
};

export const getReview = createAsyncThunk(`/getReview`, async () => {
  try {
    const { data } = await ReviewServices.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteReview = createAsyncThunk(`/deleteReview`, async (id) => {
  try {
    const res = await ReviewServices.deleteReview(id);
    return res.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

export const multipleDeleteReviews = createAsyncThunk(`/multipleDelete`, async (data) => {
  try {
    const res = await ReviewServices.deleteMultiple(data);
    return res.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {},
  extraReducers: {
    [getReview.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getReview.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getReview.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [deleteReview.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteReview.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
    },
    [deleteReview.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    [multipleDeleteReviews.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [multipleDeleteReviews.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
    },
    [multipleDeleteReviews.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default reviewSlice.reducer;
