import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { Grid, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import RichTextEditors from '../../RichText/RichTextEditors';
import { getCustomParentCategoryMethod } from '../../../redux/slice/customParentCategorySlice';
import CustomParentCategoryService from '../../../redux/api/CustomParentCategoryService';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function AddCustomParentCategoryDialog({ openAddForm, handleClickOpenAddForm }) {
  const dispatch = useDispatch();
  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [file, setFile] = React.useState(null);

  const handleSubmit = async () => {
    if (title === null || description === null || file === null) {
      toast.error('Please fill all fields');
      return;
    }
    const data = {
      title,
      description,
      customParentCategoryImage: file,
    };
    await CustomParentCategoryService.add(data)
      .then((res) => {
        toast.success(res?.data?.message);
        setTitle(null);
        setDescription(null);
        setFile(null);
        dispatch(getCustomParentCategoryMethod());
        handleClickOpenAddForm();
      })
      .catch((err) => {
        if (!err?.response?.data) {
          toast.error(err.message);
        } else {
          toast.error(err.response.data.message);
        }
      });
  };
  return (
    <div>
      <Dialog fullScreen open={openAddForm} onClose={handleClickOpenAddForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenAddForm} aria-label="close">
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Custom Parent Category
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Add Custom Parent Category</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Title"
              variant="outlined"
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Description
            </Typography>
            <RichTextEditors placeholder="Description" setBody={setDescription} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Upload Category Image
            </Typography>
            <br />
            <input
              accept=".png, .jpg, .jpeg"
              type="file"
              id="select-image"
              onChange={(e) => setFile(e.target.files[0])}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
