import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { deleteAccount, getUserDetails, logout } from '../../redux/slice/userSlice';

const ProfileNavBar = () => {
  const cachedData = useSelector((state) => state.clientuser.userInfo);
  const userInfo = React.useMemo(() => cachedData, [cachedData]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [titlePage, setTitlePage] = useState('Profile');
  // console.log(userInfo?.first_name)

  // automatically authenticate user if token is found

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      const res = await dispatch(deleteAccount());
      if (res.payload.success) {
        setOpen(false);
        dispatch(logout());

        navigate('/');
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    dispatch(getUserDetails());
  }, []);
  useEffect(() => {
    setTitlePage(
      window.location.pathname.split('/')[1].charAt(0).toUpperCase() + window.location.pathname.split('/')[1].slice(1)
    );
  }, [window.location.href]);
  return (
    <Box>
      <Helmet>
        <title>{titlePage} | PakPrint Wishes </title>
      </Helmet>
      <Typography className="profile-bar-name">Hello,{userInfo ? `${userInfo?.first_name}` : ''}</Typography>
      <ul className="profile-list">
        <li className="profile-list-item">
          <Link to="/profile">My Profile</Link>
        </li>
        <li className="profile-list-item">
          <Link to="/order-history">Order History</Link>
        </li>
        <li className="profile-list-item">
          <Link to="/wishlist">My Wishlist</Link>
        </li>
        <li className="profile-list-item">
          <button
            className="profile-navbar-btn"
            style={{ color: 'red' }}
            onClick={() => {
              dispatch(logout());
              navigate('/login');
            }}
          >
            Logout
          </button>
        </li>
        <li className="profile-list-item">
          <button style={{ color: 'red' }} className="profile-navbar-btn" onClick={handleClickOpen}>
            Delete Account
          </button>
        </li>
      </ul>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Account Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are You sure you want to delete your account
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button variant="contained" onClick={handleDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfileNavBar;
