import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Button, TextField, InputAdornment, IconButton } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CryptoJS from 'crypto-js';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { useFormik } from 'formik';
import { userSignUpSchema } from '../../schemas';
import { createUser, socialLogin } from '../../redux/slice/userSlice';
import Iconify from '../../../components/iconify/Iconify';

const SignUpPage = () => {
  const disptach = useDispatch();
  const navigate = useNavigate();

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneno, setPhoneno] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: '141583905035-b3s65qca6n62q6kc4d235mp59rjs0sru.apps.googleusercontent.com',
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  const submitForm = async () => {
    const formdata = new FormData();
    formdata.append('first_name', fname);
    formdata.append('last_name', lname);
    formdata.append('email', email);
    formdata.append('phone', phoneno);
    formdata.append('country', country);
    formdata.append('state', state);
    formdata.append('city', city);
    formdata.append('address', address);
    formdata.append('password', CryptoJS.AES.encrypt(password, 'ppw-153').toString());
    formdata.append('confirmpassword',CryptoJS.AES.encrypt(cpassword, 'ppw-153').toString());

    try {
      const res = await disptach(createUser(formdata));
      if (res.payload) {
        navigate('/login');
      }
    } catch (error) {
      console.log(error);
    }

    // disptach(registerUser(formdata))
    //   // .unwrap()
    //   .then(() => {
    //     toast.success('User registered successfully.', {
    //       position: 'bottom-left',
    //     });
    //     navigate('/login');
    //   })
    //   .catch((error) => {
    //     console.log('error', error);
    //     if (error.response && error.response.data.message) {
    //       console.log(error.response.data.message);
    //       toast.error(error.response.data.message, { position: 'bottom-left' });
    //     } else {
    //       toast.error(error.message, { position: 'bottom-left' });
    //     }
    //   });
  };
  // const onSubmitFacebook = (firstName, LastName, emails, userID) => {
  //   const formdata = new FormData();
  //   formdata.append('first_name', firstName);
  //   formdata.append('last_name', LastName);
  //   formdata.append('email', emails);
  //   formdata.append('password', userID);
  //   formdata.append('confirmpassword', userID);

  //   disptach(registerUser(formdata))
  //     .unwrap()
  //     .then(() => {
  //       toast.success('User registered successfully.', {
  //         position: 'bottom-left',
  //       });
  //       navigate('/login');
  //     })
  //     .catch((error) => {
  //       if (error.response && error.response.data.message) {
  //         toast.error(error.response.data.message, { position: 'bottom-left' });
  //       } else {
  //         toast.error(error.message, { position: 'bottom-left' });
  //       }
  //     });
  // };

  const { errors, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
      phoneno: '',
      country: '',
      state: '',
      city: '',
      address: '',
      password: '',
      cpassword: '',
    },
    validationSchema: userSignUpSchema,
    onSubmit: (values) => {
      submitForm();
    },
  });

  // console.log(errors)

  return (
    <>
      <Helmet>
        <title>Sign up | PakPrint Wishes </title>
      </Helmet>
      <Grid className="top-container" container>
        <Typography className="login-title">Sign up</Typography>
        <Grid item className="login-sub-container">
          <Grid item className="login-form-container" xs={10} sm={10} md={4} lg={4} xl={4}>
            <form onSubmit={handleSubmit}>
              <Box className="login-form">
                <TextField
                  placeholder="First Name"
                  type="text"
                  fullWidth
                  // onChange={(e) => setFname(e.target.value)}
                  id="fname"
                  name="fname"
                  value={fname}
                  onChange={(e) => {
                    setFieldValue('fname', e.target.value);
                    setFname(e.target.value);
                  }}
                  onBlur={handleBlur}
                  error={errors.fname && touched.fname}
                  helperText={errors.fname && touched.fname ? errors.fname : null}
                />
                <br />
                <TextField
                  type="text"
                  placeholder="Last Name"
                  fullWidth
                  // onChange={(e) => setLname(e.target.value)}
                  id="lname"
                  name="lname"
                  value={lname}
                  onChange={(e) => {
                    setFieldValue('lname', e.target.value);
                    setLname(e.target.value);
                  }}
                  onBlur={handleBlur}
                  error={errors.lname && touched.lname}
                  helperText={errors.lname && touched.lname ? errors.lname : null}
                />
                <br />
                <TextField
                  type="email"
                  placeholder="Email"
                  fullWidth
                  // onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setFieldValue('email', e.target.value);
                    setEmail(e.target.value);
                  }}
                  onBlur={handleBlur}
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email ? errors.email : null}
                />
                <br />
                <TextField
                  type="number"
                  placeholder="Phone"
                  fullWidth
                  // onChange={(e) => setPhoneno(e.target.value)}id="fname"
                  name="phoneno"
                  value={phoneno}
                  onChange={(e) => {
                    setFieldValue('phoneno', e.target.value);
                    setPhoneno(e.target.value);
                  }}
                  onBlur={handleBlur}
                  error={errors.phoneno && touched.phoneno}
                  helperText={errors.phoneno && touched.phoneno ? errors.phoneno : null}
                />
                <br />
                <TextField
                  type="text"
                  placeholder="Country"
                  fullWidth
                  // onChange={(e) => setCountry(e.target.value)}
                  id="country"
                  name="country"
                  value={country}
                  onChange={(e) => {
                    setFieldValue('country', e.target.value);
                    setCountry(e.target.value);
                  }}
                  onBlur={handleBlur}
                  error={errors.country && touched.country}
                  helperText={errors.country && touched.country ? errors.country : null}
                />
                <br />
                <TextField
                  type="text"
                  placeholder="State"
                  fullWidth
                  id="state"
                  name="state"
                  value={state}
                  onChange={(e) => {
                    setFieldValue('state', e.target.value);
                    setState(e.target.value);
                  }}
                  onBlur={handleBlur}
                  error={errors.state && touched.state}
                  helperText={errors.state && touched.state ? errors.state : null}
                />
                <br />
                <TextField
                  type="text"
                  placeholder="City"
                  fullWidth
                  // onChange={(e) => setCity(e.target.value)}
                  id="city"
                  name="city"
                  value={city}
                  onChange={(e) => {
                    setFieldValue('city', e.target.value);
                    setCity(e.target.value);
                  }}
                  onBlur={handleBlur}
                  error={errors.city && touched.city}
                  helperText={errors.city && touched.city ? errors.city : null}
                />
                <br />
                <TextField
                  type="text"
                  placeholder="Address"
                  fullWidth
                  multiline
                  rows={2}
                  id="address"
                  name="address"
                  value={address}
                  onChange={(e) => {
                    setFieldValue('address', e.target.value);
                    setAddress(e.target.value);
                  }}
                  onBlur={handleBlur}
                  error={errors.address && touched.address}
                  helperText={errors.address && touched.address ? errors.address : null}
                />
                <br />
                <TextField
                  placeholder="Password"
                  fullWidth
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setFieldValue('password', e.target.value);
                    setPassword(e.target.value);
                  }}
                  onBlur={handleBlur}
                  error={errors.password && touched.password}
                  helperText={errors.password && touched.password ? errors.password : null}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <br />
                <TextField
                  placeholder="Re-enter Password"
                  fullWidth
                  // onChange={(e) => setCPassword(e.target.value)}
                  id="cpassword"
                  name="cpassword"
                  value={cpassword}
                  onChange={(e) => {
                    setFieldValue('cpassword', e.target.value);
                    setCPassword(e.target.value);
                  }}
                  onBlur={handleBlur}
                  error={errors.cpassword && touched.cpassword}
                  helperText={errors.cpassword && touched.cpassword ? errors.cpassword : null}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <br />
                {/* <span className="login-forgot-link-span"></span> */}
                <Button
                  style={{ marginTop: '150px !important' }}
                  variant="contained"
                  className="login-btn"
                  // onClick={onSubmit}
                  type="submit"
                >
                  Sign Up
                </Button>
              </Box>
            </form>

            <span>
              <Typography className="login-signup-text">
                Already have an account? &nbsp;
                <span>
                  <Link to="/login" className="login-signup-link">
                    Login
                  </Link>
                </span>
              </Typography>
            </span>
            <span className="login-other-connect-span">
              <Typography className="login-signup-text login-other-text">or connect with</Typography>
            </span>
            <span className="login-google-span">
              <GoogleLogin
                onSuccess={(res) => {
                  disptach(socialLogin({ token: res?.tokenId }));
                  navigate('/login');
                }}
                onFailure={(err) => {
                  console.log(err);
                }}
                // cookiePolicy={"single_host_origin"}
                // isSignedIn={true}
              />
              {/* <LoginSocialFacebook
                id="fb-root"
                appId="1330694440822436"
                onResolve={(res) => {
                  onSubmitFacebook(res.data.first_name, res.data.last_name, res.data.email, res.data.userID);
                }}
                onReject={(err) => {
                  toast.error(err.message, {
                    position: 'bottom-left',
                  });
                }}
              >
                <Button
                  size="small"
                  startIcon={<img className="login-google" src={p2} alt="" />}
                  sx={{
                    fontSize: '13px',
                    marginLeft: '5px',
                    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;',
                    color: 'gray',
                    textTransform: 'capitalize',
                  }}
                >
                  Login with Facebook
                </Button>
              </LoginSocialFacebook> */}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SignUpPage;
