import { callAPi } from './http-common';

const getAllUser = () => callAPi.get('/getAllVendors');

const getAllVendorApprovedOrders = (id) => callAPi.get(`/getApprovedOrders/${id}`);
const vendorRole = (id, data) => callAPi.patch(`/vendorStatus/${id}`, data);
const cratePaymentToVendor = (id, data) => callAPi.post(`/createPayment/${id}`, data);

const deletes = (id) => callAPi.delete(`/vendor/deleteUser/${id}`);
const deleteMultiple = (data) => callAPi.post(`/vendor/deleteMultipleUsers`, { ids: data });
const getVendorbyId = (id) => callAPi.get(`/getVendorById/${id}`);
const getVendorProductbyId = (id) => callAPi.get(`/getProductByVendorId/${id}`);

const AllVendorServices = {
  getAllUser,
  vendorRole,
  deletes,
  deleteMultiple,
  cratePaymentToVendor,
  getAllVendorApprovedOrders,
  getVendorbyId,
  getVendorProductbyId
};

export default AllVendorServices;
