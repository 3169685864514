import { callAPi } from './http-common';

const deletes = (id) => {
  return callAPi.delete(`/deleteMessage/${id}`);
};
const deleteMultiple = (data) => {
  return callAPi.post(`/deleteMessageMultiple`, { ids: data });
};
const get = () => {
  return callAPi.get(`/getAllMessages`);
};
const ContactUsService = {
  deletes,
  get,
  deleteMultiple,
};

export default ContactUsService;
