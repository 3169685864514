import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NavBarCard } from '../Components/Components';
import { getUserDetails } from '../redux/slice/userSlice';
import logoImage from '../Assets/logo.png';
import SearchDialog from '../Components/SearchDialog';
import ProfilePopover from '../Components/ProfilePopover';

const drawerWidth = '70%';

const NavBar = (props) => {
  const { window, isTop, setIsTop } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const { cartItems } = useSelector((state) => state.cart);
  const { userInfo, userToken } = useSelector((state) => state.clientuser);
  const dispatch = useDispatch();
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  React.useEffect(() => {
    if (userToken) {
      dispatch(getUserDetails());
    }
  }, [userToken, dispatch]);

  const handleSearch = () => {
    setOpenSearch(!openSearch);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box component={Link} to="/">
        <picture>
          <img src={logoImage} alt="Logo Images" className="navbar-logo-img1" />
        </picture>
      </Box>
      <Divider />
      <List>
        <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/art-print-&-stationery">
          <ListItemText>ART PRINT & STATIONERY </ListItemText>
        </ListItemButton>
        <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/custom-design">
          <ListItemText>CUSTOM DESIGN</ListItemText>
        </ListItemButton>
        <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/dashboard">
          <ListItemText>VENDOR</ListItemText>
        </ListItemButton>
        <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/services">
          <ListItemText>SERVICES</ListItemText>
        </ListItemButton>
        {!userInfo || Object.keys(userInfo).length === 0 ? (
          <ListItemButton sx={{ textAlign: 'center' }} component={Link} to="/login">
            <ListItemText>LOGIN</ListItemText>
          </ListItemButton>
        ) : null}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-around' }} className="main-container">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: '100%',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className="menu-icon"
          onClick={handleDrawerToggle}
          sx={{ display: { lg: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box className="navbar-icon" sx={{ display: { lg: 'none' }, padding: '10px' }} component={Link} to="/">
          <img src={logoImage} alt="Logo Imag" className="navbar-logo-img" />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box className="navbar-icon" sx={{ display: { lg: 'none' } }} component={Link} to="/cart">
            <Typography className="navbar-cart-number">
              {Number(cartItems?.products.length) + Number(cartItems?.customproducts.length)}
            </Typography>
            <span className="material-icons cart-icon" color="black">
              <ShoppingCartIcon color="black" />
            </span>
          </Box>
          {userInfo && Object.keys(userInfo).length > 0 ? <ProfilePopover /> : null}
        </Box>
      </Box>
      <Box
        className="navbar-container navbar-box"
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'none',
            lg: 'block',
            zIndex: 199999,
          },
        }}
      >
        <NavBarCard>
          <Grid container className="nav-list" p={1}>
            <Grid item md={1} lg={1} xl={1}>
              <Box
                sx={{ display: { xs: 'none', sm: 'block' } }}
                component={Link}
                to="/"
                onClick={() => setIsTop(!isTop)}
              >
                <img src={logoImage} alt="Logo Imag" className="navbar-logo-img" />
              </Box>
            </Grid>
            <Grid item md={8} lg={8} xl={8} sx={{ display: { xs: 'none', sm: 'block' } }}>
              <ul className="nav-bar-item">
                <li className="nav-link">
                  <Link
                    style={{
                      color: location.pathname === '/art-print-&-stationery' ? '#4E9F0B' : '',
                      textDecoration: location.pathname === '/art-print-&-stationery' ? 'underline' : '',
                    }}
                    to="/art-print-&-stationery"
                    onClick={() => setIsTop(!isTop)}
                  >
                    ART PRINT & STATIONERY
                  </Link>
                </li>
                <li className="nav-link">
                  <Link
                    style={{
                      color: location.pathname === '/get-quote' ? '#4E9F0B' : '',
                      textDecoration: location.pathname === '/get-quote' ? 'underline' : '',
                    }}
                    to="/custom-design"
                    onClick={() => setIsTop(!isTop)}
                  >
                    CUSTOM DESIGN
                  </Link>
                </li>
                <li className="nav-link">
                  <Link
                    style={{
                      color: location.pathname === '/dashboard' ? '#4E9F0B' : '',
                      textDecoration: location.pathname === '/dashboard' ? 'underline' : '',
                    }}
                    to="/dashboard"
                    onClick={() => setIsTop(!isTop)}
                  >
                    VENDOR
                  </Link>
                </li>
                <li className="nav-link">
                  <Link
                    style={{
                      color: location.pathname === '/services' ? '#4E9F0B' : '',
                      textDecoration: location.pathname === '/services' ? 'underline' : '',
                    }}
                    to="/services"
                    onClick={() => setIsTop(!isTop)}
                  >
                    SERVICES
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item md={2} lg={2} xl={2} className="icon-com">
              {!userInfo || Object.keys(userInfo).length === 0 ? (
                <div className="nav-link">
                  <Link to="/login" style={{ fontWeight: 'bold' }}>
                    LOGIN
                  </Link>
                </div>
              ) : null}
              <IconButton aria-label="Search" onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
              <Box className="navbar-icon" sx={{ marginLeft: '15px' }} component={Link} to="/cart">
                <Typography className="navbar-cart-number">
                  {Number(cartItems?.products.length) + Number(cartItems?.customproducts.length)}
                </Typography>
                <span className="material-icons">
                  <ShoppingCartIcon />
                </span>
              </Box>
              {userInfo && Object.keys(userInfo).length > 0 ? <ProfilePopover /> : null}
            </Grid>
          </Grid>
        </NavBarCard>
        <SearchDialog openSearch={openSearch} handleSearch={handleSearch} />
      </Box>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {
              xs: 'block',
              sm: 'block',
              md: 'block',
              lg: 'none',
              xl: 'none',
            },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};
NavBar.propTypes = {
  window: PropTypes.func,
};
export default NavBar;
