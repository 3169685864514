import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// components
import { useEffect, useState } from 'react';
import FooterPage from '../../client/Pages/FooterPage';
import '../../jobs/jobsutils/jobstyle.css';
import JobNavbar from '../../jobs/components/navbar';
import { jobsgetUserDetails } from '../../jobs/redux/slice/jobsUserSlice';
import AddExperinceDialog from '../../jobs/components/Dialog/AddExperinceDialog';

export default function JobsLayout() {
  const data = useSelector((state) => state.jobsUser?.userInfo);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('job-token-ppw');
  const handleClose = () => {
    if (data !== undefined || data !== null || Object.keys(data).length > 0) {
      if (data?.experience !== undefined || data?.experience !== null || data?.experience.length > 1) {
        setOpen(false);
      }
    }
  };
  const [isTop, setIsTop] = useState(false);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, [pathname, isTop]);

  useEffect(() => {
    dispatch(jobsgetUserDetails());
  }, [dispatch]);

  useEffect(() => {
    if (data !== null && data !== undefined && Object.keys(data).length > 0) {
      if (!data?.experience || Object.keys(data?.experience).length === 0 || Object.keys(data?.experience).length === 1) {
        setOpen(true);
      }
    }
  }, [dispatch, data]);

  useEffect(() => {
    if (token === undefined || token === null) {
      navigate('/jobs/login');
    }
  }, [navigate, token]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <JobNavbar />
      </Grid>
      <Grid item xs={12}>
        <Outlet />
      </Grid>
      <Grid item xs={12} sx={{ backgroundColor: 'white' }}>
        <FooterPage setIsTop={setIsTop} isTop={isTop} />
      </Grid>
      <AddExperinceDialog open={open} handleClose={handleClose} />
    </Grid>
  );
}
