import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FreelanceJobsService from '../api/FreelanceJobsService';

const initialState = {
  loading: false,
  data: [],
  error: null,
  success: false,
};

export const getfreelanceClientJobs = createAsyncThunk(`freelanceClientJobs/getfreelanceClientJobs`, async () => {
  try {
    const { data } = await FreelanceJobsService.getAllFinJob();
    return data.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
});

const freelanceClientJobsSlice = createSlice({
  name: 'freelanceClientJobs',
  initialState,
  reducers: {},
  extraReducers: {
    // get user details
    [getfreelanceClientJobs.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getfreelanceClientJobs.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getfreelanceClientJobs.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default freelanceClientJobsSlice.reducer;
