import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/createParentCategory', data);
};
const deletes = (id) => {
  return callAPi.delete(`/deleteParentCategory/${id}`);
};
const deleteMultiple = (data) => {
  return callAPi.post(`/deleteManyParentCategory`, { ParentCategoryId: data });
};
const get = () => {
  return callAPi.get('/getParentCategory');
};
const update = (id, data) => {
  return callAPi.post(`/UpdateParentCategory/${id}`, data);
};
const publicGet = () => {
  return callAPi.get(`/user/getParentCategory`);
};
const status = (id) => {
  return callAPi.post(`/admin/verifyCategory`,{categoryId:id});
};
const ParentCategoryServices = {
  add,
  deletes,
  get,
  update,
  deleteMultiple,
  status,
  publicGet
};

export default ParentCategoryServices;
