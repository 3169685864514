import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
  cartItems: localStorage.getItem('cartItems')
    ? JSON.parse(localStorage.getItem('cartItems'))
    : { products: [], customproducts: [] },
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  cartTotalShip: 0,
  customQuantity: 0,
  customTotal: 0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart(state, action) {
      const existingIndex = state?.cartItems?.products?.findIndex((item) => item._id === action.payload.products._id);
      if (existingIndex >= 0) {
        state.cartItems.products[existingIndex] = {
          ...state.cartItems?.products[existingIndex],
          cartQuantity: state.cartItems?.products[existingIndex].cartQuantity + action.payload.cartQty,
        };
        // toast.info("Increased product quantity", {
        //   position: "bottom-left",
        // });
      } else {
        const tempProductItem = {
          ...action.payload.products,
          cartQuantity: action.payload.cartQty,
        };
        state?.cartItems?.products?.push(tempProductItem);
        toast.success('Product added to cart', {
          position: 'bottom-left',
        });
      }
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    customProductAddToCart(state, action) {
      const tempProductItem = {
        ...action.payload.customproducts,
        cartQuantity: action.payload.cartQty,
      };
      state?.cartItems?.customproducts?.push(tempProductItem);
      toast.success('Custom Product added to cart', {
        position: 'bottom-left',
      });
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    decreaseCart(state, action) {
      const itemIndex = state.cartItems.products.findIndex((item) => item._id === action.payload._id);

      if (state.cartItems.products[itemIndex].cartQuantity > 1) {
        state.cartItems.products[itemIndex].cartQuantity -= 1;

        // toast.info("Decreased product quantity", {
        //   position: "bottom-left",
        // });
      } else if (state.cartItems.products[itemIndex].cartQuantity === 1) {
        const nextCartItems = state.cartItems.products.filter((item) => item._id !== action.payload._id);

        state.cartItems.products = nextCartItems;

        toast.error('Product removed from cart', {
          position: 'bottom-left',
        });
      }

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    cartInputQuantity(state, action) {
      const existingIndex = state.cartItems.products.findIndex((item) => item._id === action.payload.products._id);
      if (existingIndex >= 0) {
        state.cartItems.products[existingIndex] = {
          ...state.cartItems.products[existingIndex],
          cartQuantity: action.payload.cartQty,
        };
        // toast.info("Increased product quantity", {
        //   position: "bottom-left",
        // });
      } else {
        const tempProductItem = {
          ...action.payload.products,
          cartQuantity: action.payload.cartQty,
        };
        state.cartItems.products.push(tempProductItem);
        toast.success('Product added to cart', {
          position: 'bottom-left',
        });
      }
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    removeFromCart(state, action) {
      state.cartItems.products.map((cartItem) => {
        if (cartItem._id === action.payload._id) {
          const nextCartItems = state.cartItems.products.filter((item) => item._id !== cartItem._id);

          state.cartItems.products = nextCartItems;

          toast.error('Product removed from cart', {
            position: 'bottom-left',
          });
        }
        localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
        return state;
      });
    },
    removeCustomProductFromCart(state, action) {
      if (action.payload !== -1) {
        state.cartItems.customproducts.splice(action.payload, 1);

        toast.error('Custom Product removed from cart', {
          position: 'bottom-left',
        });

        localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      }

      return state;
    },
    getTotals(state, action) {
      const productsArray = JSON.parse(JSON.stringify(state?.cartItems?.products || []));
      const customProductsArray = JSON.parse(JSON.stringify(state?.cartItems?.customproducts || []));
      const { total, quantity } = productsArray.reduce(
        (cartTotal, cartItem) => {
          const products = cartItem;
          if (products) {
            const { cartQuantity } = products;
            const itemTotal = Number(products?.new_price) * Number(cartQuantity);

            cartTotal.total += itemTotal;
            cartTotal.quantity += Number(cartQuantity);
          }
          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );

      const { customProductTotal, customProductQuantity } = customProductsArray.reduce(
        (cartTotal, cartItem) => {
          const products = cartItem;
          if (products) {
            const { cartQuantity } = products;
            const itemTotal = Number(products?.totalPrice) * Number(cartQuantity);

            cartTotal.customProductTotal += itemTotal;
            cartTotal.customProductQuantity += Number(cartQuantity);
          }
          return cartTotal;
        },
        {
          customProductTotal: 0,
          customProductQuantity: 0,
        }
      );

      const roundedTotal = parseFloat(total.toFixed(2));
      const roundedCustomTotal = parseFloat(customProductTotal.toFixed(2));
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = roundedTotal;
      state.customQuantity = customProductQuantity;
      state.customTotal = roundedCustomTotal;
    },

    clearCart(state, action) {
      state.cartItems = { products: [], customproducts: [] };
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      toast.error('Cart cleared', { position: 'bottom-left' });
    },
  },
});

export const {
  addToCart,
  customProductAddToCart,
  decreaseCart,
  removeFromCart,
  removeCustomProductFromCart,
  getTotals,
  clearCart,
  cartInputQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;
