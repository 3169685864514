import { callAPi } from './http-common';

const login = (data) => {
  return callAPi.post('/verdor/login', data);
};
const register = (data) => {
  return callAPi.post('/verdor/register', data);
};

const vendorregister = (data) => {
  return callAPi.post('/addVendor', data);
};
const getUser = () => callAPi.get('/getProfile');
const update = (data) => callAPi.patch(`/vendor/updateProfile`, data);
const otp = (data) => callAPi.post('/verdor/verifyOTP', data);
const forgetPassword = (data) => callAPi.patch('/verdor/forgotPassword', data);
const otpForgetPassword = (data) => {
  return callAPi.post('/verdor/verifyForgotPasswordOtp', data);
};
const resetPassword = (data) => {
  return callAPi.patch('/verdor/resetPassword', data);
};
const becomeVendor = (data) => {
  return callAPi.post('/vendor/becomeVendor', data);
};
const socialLogin = (data) => {
  return callAPi.post('/socialLogin', data);
};
const resendOTP = (data) => {
  return callAPi.post('/vendor/resendOTP', data);
};
const UserServices = {
  login,
  register,
  getUser,
  update,
  otp,
  forgetPassword,
  otpForgetPassword,
  resetPassword,
  becomeVendor,
  socialLogin,
  resendOTP,
  vendorregister,
};

export default UserServices;
