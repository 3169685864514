import React, { useEffect } from 'react';
import { Typography, Grid, Card, ListItemButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useDispatch, useSelector } from 'react-redux';
import { BlueButton } from '../../Components/Components';
import ProfileNavBar from './ProfileNavBar';
import { fetchALL } from '../../redux/slice/wishlistSlice';
import { addToCart } from '../../redux/slice/cartSlice';
import { deleteFavourite } from '../../redux/slice/favouriteSlice';
import { imageBaseUrl } from '../../redux/api/http-common';
import ProgressBar from '../../../components/progress/ProgressBar';

const MyWishListCard = () => {
  const dispatch = useDispatch();
  const cachedData = useSelector((state) => state.wishlist);
  const data = React.useMemo(() => cachedData, [cachedData]);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(fetchALL());
  }, []);
  // Add to cart
  const handleAddToCart = (product) => {
    const products = { products: product, cartQty: 1 };
    dispatch(addToCart(products));
    // navigate("/cart");
  };
  const removeFavourite = async (id) => {
    const payload = {
      productId: id,
      status: false,
    };
    const result = await dispatch(deleteFavourite(payload));
    if (result.payload.status === 200) {
      dispatch(fetchALL());
    }
  };
  return (
    <Grid container className="profile-container" spacing={2}>
      <Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
        <ProfileNavBar />
      </Grid>
      <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
        <Typography className="prfile-title">My Wishlist</Typography>
        <Grid container spacing={2} className="profile-content-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {data?.loading && <ProgressBar />}
            <Grid container spacing={2}>
              {!data.data && data.data.length === 0
                ? null
                : data.data?.map((res) => (
                    <Grid xs={12} sm={6} md={12} lg={12} xl={12} item key={res?._id}>
                      <Card className="wish-list-card">
                        <Grid container p={4}>
                          <Grid xs={12} sm={12} md={2} lg={2} xl={2} item key={res?._id}>
                            <img className="wishlist-prod-img" src={imageBaseUrl + res.images[0]} alt={res?._id} />
                          </Grid>
                          <Grid
                            className="wishlist-prod-grid-item1 wishlist-prod-grid-item2"
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            item
                          >
                            <Typography className="wishlist-card-prod-title">{res?.product_title}</Typography>
                            <ListItemButton
                              onClick={() => {
                                removeFavourite(res?._id);
                              }}
                              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                            >
                              <DeleteIcon style={{ color: 'rgba(0, 0, 0, 0.2)' }} />
                            </ListItemButton>
                          </Grid>
                          <Grid className="wishlist-prod-grid-item2" xs={12} sm={12} md={4} lg={4} xl={4} item>
                            <Typography className="wishlist-card-prod-price">Rs. {res.new_price.toFixed(2)}</Typography>
                            <Typography className="wishlist-card-prod-discount">
                              <del style={{ color: 'gray' }}>Rs {res?.old_price}</del>&nbsp;
                              <span style={{ color: 'red' }}>{res?.discount}% Off</span>
                            </Typography>
                            <Typography className="co-header-text-font6" sx={{ color: 'red !important' }}>
                              Note: Ship Fee Calculate on the base of Distance and Weight.
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            sm={10}
                            md={2}
                            lg={2}
                            xl={2}
                            className="wishlist-cart-btn-container wishlist-prod-grid-item2"
                          >
                            <BlueButton className="wishlist-cart-btn" onClick={() => handleAddToCart(res)}>
                              <AddShoppingCartIcon />
                            </BlueButton>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MyWishListCard;
