import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListToolbar } from '../sections/@dashboard/user';
// mock
import OrderProductListHead from '../sections/@dashboard/user/OrderProductListHead';
import { getpaymentHistoryByIDMethod } from '../redux/slice/paymentHistoryByIDSlice';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'TID', label: 'Transaction ID', alignRight: false },
  { id: 'currentBalance', label: 'Current Balance', alignRight: false },
  { id: 'remainingBalance', label: 'Remaining Balance', alignRight: false },
  { id: 'total', label: 'Total Balance', alignRight: false },
  { id: 'payAmount', label: 'Pay Balance', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.TID.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PaymentHistoryByIDPage() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const vendorData = location?.state?.vendorData;

  const { id } = useParams();

  const data = useSelector((state) => state.paymentHistoryByID);

  useEffect(() => {
    dispatch(getpaymentHistoryByIDMethod(id));
  }, [dispatch, id]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    data.data !== null && data.data !== undefined ? data.data : [],
    getComparator(order, orderBy),
    filterName
  );

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Payments History | PakPrint Wishes </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Payments History
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="uit:print" />}
            onClick={() => {
              navigate(`/dashboard/payments/history/print/${id}`, { state: { vendorData, dataObj: data?.data } });
            }}
          >
            Print
          </Button>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="product-order-box"
          p={3}
          mb={5}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h6" gutterBottom>
                Vendor Information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="subtitle1">Name</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="subtitle2">
                {vendorData?.first_name} {vendorData?.last_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="subtitle1">Email</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="subtitle2">{vendorData?.email}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="subtitle1">Phone</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="subtitle2">{vendorData?.phone}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="subtitle1">CNIC</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="subtitle2">{vendorData?.cnic}</Typography>
            </Grid>
          </Grid>
        </Stack>

        <Card>
          <UserListToolbar numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrderProductListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id } = row;
                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell component="th" scope="row">
                          <Typography variant="subtitle2" noWrap>
                            {row?.TID}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{row?.paymentRecieved.toFixed(2)}</TableCell>
                        <TableCell align="left">{row?.paymentPending.toFixed(2)}</TableCell>
                        <TableCell align="left">{row?.paymentTotal.toFixed(2)}</TableCell>
                        <TableCell align="left">{row?.payPrice.toFixed(2)}</TableCell>
                        <TableCell align="left">
                          {!row.createdAt
                            ? 'N/A'
                            : new Date(row.createdAt).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: '2-digit',
                              })}
                        </TableCell>

                        <TableCell align="left">
                          <Button
                            variant="contained"
                            onClick={() =>
                              navigate('/dashboard/payments/history/byTransaction', {
                                state: { data: row?.orderId, TID: row.TID },
                              })
                            }
                          >
                            view more
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
