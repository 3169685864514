import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Box, Grid, ListItemButton, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveSlider } from '../../redux/slice/slider';
import { GreenButton } from '../../Components/Components';
import { imageBaseUrl } from '../../redux/api/http-common';
import ProgressBar from '../../../components/progress/ProgressBar';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const HomeSlider = () => {
  const dispatch = useDispatch();
  const cachedData = useSelector((state) => state.clientslider);
  const data = React.useMemo(() => cachedData, [cachedData]);
  React.useEffect(() => {
    // initFetch();
    dispatch(retrieveSlider());
  }, [dispatch]);

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = data?.data?.length || 0;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    // sx={{ backgroundImage:`url(${backgroundImg})`,backgroundSize:'100% 100%', height: "auto" }}
    <Box sx={{ width: '100%' }}>
      {data?.loading && <ProgressBar />}
      {!data.data || data.data.length === 0 ? null : (
        <Grid container className="slider-container">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Paper
              sx={{
                height: '100%',
                backgroundColor: '#000',
                paddingY: '10px',
                paddingTop: { xs: '10px', sm: '10px', md: '10px', lg: '80px' },
                borderRadius: '0px',
              }}
            >
              <Grid container sx={{ height: '100%' }} justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                  <ListItemButton
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    className="left-arrow-btn"
                    aria-label="Back Button"
                  >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  </ListItemButton>
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{ height: '100%' }}>
                  <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                  >
                    {data.data
                      ? data.data?.map((res, index) => (
                          <Grid
                            container
                            justifyContent={'center'}
                            p={1}
                            spacing={2}
                            key={index}
                            tabindex="0"
                            aria-hidden="true"
                          >
                            <Grid item xs={12} sm={12}>
                              <Grid container>
                                <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                                  <Typography className="first-slider-text">{res.title}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <h1 className="second-slider-text">{res.subTitle}</h1>
                                </Grid>
                                <Grid item xs={10}>
                                  <Typography variant="body" className="slider-paragraph">
                                    {res.sliderDescription}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} mt={5}>
                              {res.url !== null ? (
                                <GreenButton
                                  aria-label="Shop Button"
                                  className="slider-btn"
                                  aria-hidden="false"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = res?.url;
                                  }}
                                >
                                  SHOP NOW
                                </GreenButton>
                              ) : null}
                            </Grid>
                          </Grid>
                        ))
                      : ''}
                  </AutoPlaySwipeableViews>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Paper
              sx={{
                backgroundImage: `url("${
                  !data.data || data.data.length === 0 || !data.data[activeStep]?.sliderImage
                    ? ''
                    : imageBaseUrl + data.data[activeStep]?.sliderImage
                }")`,
                backgroundSize: '100% 100%',
                paddingY: '10px',
                paddingTop: { xs: '10px', sm: '10px', md: '10px', lg: '80px' },
                borderRadius: '0px',
              }}
              className="slider-child-container"
            >
              <Grid container sx={{ height: '100%' }} justifyContent={'center'} alignItems={'center'}>
                {/* <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {data
                  ? data?.map((res, index) => (
                      <Box className="slider-card" key={index}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={{ textAlign: "center", height: "100%" }}
                          >
                            <figure>
                              <img
                                src={imageBaseUrl + res.sliderImage}
                                alt={res.title}
                                width={"75%"}
                              />
                            </figure>
                          </Grid>
                        </Grid>
                      </Box>
                    ))
                  : ""}
              </AutoPlaySwipeableViews>
            </Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
                >
                  <ListItemButton
                    className="right-arrow-btn"
                    size="small"
                    aria-label="Next Button"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  </ListItemButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default HomeSlider;
