import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/createCustomDesign', data);
};
const deletes = (id) => {
  return callAPi.patch(`/deleteCustomDesign/${id}`);
};
const get = () => {
  return callAPi.get('/authApiGetAllCustomDesigns');
};
const publicGetAll = () => {
  return callAPi.get('/publicApiGetAllCustomDesigns');
};
const getSideBar = () => {
  return callAPi.get('/getCustomCategoriesNavigationBar');
};
const getbyParentId = (id) => {
  return callAPi.get(`/publicApiGetCustomDesignsByCustomParentId/${id}`);
};
const getbyChildId = (id) => {
  return callAPi.get(`/publicApiGetCustomDesignsByCustomChildId/${id}`);
};
const getbyId = (id) => {
  return callAPi.get(`/publicApiGetCustomDesignsByProductId/${id}`);
};
const getbyAuthId = (id) => {
  return callAPi.get(`/authApiGetCustomDesignById/${id}`);
};
const update = (id, data) => {
  return callAPi.patch(`/updateCustomDesign/${id}`, data);
};
const status = (id) => {
  return callAPi.patch(`/changeCustomDesignStatus/${id}`);
};
const CustomProductServices = {
  add,
  deletes,
  get,
  update,
  status,
  getbyId,
  publicGetAll,
  getbyParentId,
  getbyChildId,
  getSideBar,
  getbyAuthId
};

export default CustomProductServices;
