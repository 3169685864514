import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { FormHelperText, Grid, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FreelanceJobService from '../../../redux/api/FreelanceJobService';
import RichTextEditors from '../../RichText/RichTextEditors';
import { getFreelanceJobMethod } from '../../../redux/slice/freelanceJobSlice';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const initialValues = {
  jobTitle: '',
  experienceLevel: '',
  priceBeforeAssignJob: '',
};

export default function UpdateFreelanceJobDialog({ openEditForm, handleClickOpenEditForm, jobId }) {
  const [body, setBody] = React.useState(null);
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState(initialValues);
  const [images, setImages] = React.useState([]);
  const [errors, setErrors] = React.useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validations()) {
        await FreelanceJobService.update(jobId, { ...formData, jobDescription: body, jobAttachments: images })
          .then((response) => {
            toast.success(response.data.message);
            dispatch(getFreelanceJobMethod());
            handleClickOpenEditForm();
          })
          .catch((error) => {
            if (!error.response.data.message) {
              toast.error(error.message, { position: 'top-right' });
            } else {
              toast.error(error.response.data.message, { position: 'top-right' });
            }
          });
      }
    } catch (error) {
      if (!error.response.data.message) {
        toast.error(error.message, { position: 'top-right' });
      } else {
        toast.error(error.response.data.message, { position: 'top-right' });
      }
    }
  };

  const validations = (fieldValue = formData) => {
    const temp = { ...errors };
    if ('jobTitle' in fieldValue) temp.jobTitle = fieldValue.jobTitle ? '' : 'This field requires';
    if ('experienceLevel' in fieldValue) temp.experienceLevel = fieldValue.experienceLevel ? '' : 'This field requires';
    if ('priceBeforeAssignJob' in fieldValue)
      temp.priceBeforeAssignJob = fieldValue.priceBeforeAssignJob ? '' : 'This field requires';
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validations({ [name]: value });
  };

  const handleImagesUpload = (e) => {
    const { files } = e.target;
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes
    const maxFiles = 5;
    const selectedFiles = [];
    let errorMessage = '';

    // Validate the number of selected files
    if (files.length > maxFiles) {
      errorMessage = `You can select up to ${maxFiles} images.`;
    } else {
      // Validate each selected file
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        // Validate file type
        if (!file.type.startsWith('image/')) {
          errorMessage = 'Please select only image files.';
          break;
        }
        // Validate file size
        if (file.size > maxFileSize) {
          errorMessage = `File "${file.name}" exceeds the maximum size limit of 5MB.`;
          break;
        }
        // Add the file to the array
        selectedFiles.push(file);
      }
    }

    // If there's no error, update the state
    if (errorMessage === '') {
      setImages((prev) => ({ ...prev, images: selectedFiles }));
    } else {
      // Display error message or handle as needed
      toast.error(errorMessage);
      // Clear the file input if needed
      e.target.value = null;
    }
  };

  const getAllUser = async () => {
    await FreelanceJobService.getbyId(jobId)
      .then((res) => {
        if (res.data && res.data.data) {
          const items = res?.data?.data;
          setFormData({
            jobTitle: items?.jobTitle || '',
            experienceLevel: items?.experienceLevel || '',
            priceBeforeAssignJob: items?.priceBeforeAssignJob || '',
          });
          setBody(items?.jobDescription || '');
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  React.useEffect(() => {
    getAllUser();
  }, [jobId]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [jobId]);

  return (
    <div>
      <Dialog fullScreen open={openEditForm} onClose={handleClickOpenEditForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenEditForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Freelance Job
            </Typography>
            {/* <Button
              autoFocus
              variant="outlined"
              sx={{ color: 'white', borderColor: 'white' }}
              onClick={() => {
                handleSubmit();
                handleClickOpenEditForm();
              }}
            >
              Edit
            </Button> */}
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5"> Edit Freelance Job</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Title"
              variant="outlined"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
              error={errors.jobTitle}
              fullWidth
            />
            {errors.jobTitle && <FormHelperText error={!!errors}>{errors.jobTitle}</FormHelperText>}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Price
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Price"
              variant="outlined"
              name="priceBeforeAssignJob"
              value={formData.priceBeforeAssignJob}
              onChange={handleChange}
              error={errors.priceBeforeAssignJob}
              type="number"
              min={0}
              fullWidth
            />
            {errors.priceBeforeAssignJob && (
              <FormHelperText error={!!errors}>{errors.priceBeforeAssignJob}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Experience Level
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              name="experienceLevel"
              value={formData.experienceLevel}
              onChange={handleChange}
              error={errors.experienceLevel}
            >
              <MenuItem value={''} disabled>
                Select Experience Level
              </MenuItem>
              <MenuItem value={'Basic'}>Basic</MenuItem>
              <MenuItem value={'Intermediate'}>Intermediate</MenuItem>
              <MenuItem value={'Expert'}>Expert</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Description
            </Typography>
            <RichTextEditors body={body} placeholder="Enter Description" setBody={setBody} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Upload Image
            </Typography>
            <br />
            <input
              type="file"
              multiple
              accept=".png, .jpg, .jpeg"
              placeholder="Choose an image"
              name="image"
              onChange={handleImagesUpload}
            />
            {errors.jobAttachments && <FormHelperText error={!!errors}>{errors.jobAttachments}</FormHelperText>}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
