import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ServicesDataService from "../api/ServiceApiServices";

const initialState = {
  data: [],
  status: 'idle', 
  error: null
}

export const retrieveSerives = createAsyncThunk(
  "services-getall",
  async () => {
    try{
      const res = await ServicesDataService.getAll();
    return res.data;
    }catch(err){
      console.log(err.message);
      throw err;
    }
  }
);


const serviceSlice = createSlice({
  name: "services",
  initialState,
  extraReducers: {
    [retrieveSerives.fulfilled]: (state, action) => {
      return action.payload;
    }
  },
});

export const selectAllData = (state) => state.services.data;
export const getStatusMessage = (state) => state.services.status;
export const getErrorMessage = (state) => state.services.error;

export default serviceSlice.reducer;