import http from "./http-common";

const getAll = () => {
  return http.get("/user/getParentCategory");
};
const getById = (id) => {
  return http.get(`/user/getallSubCategoryByParentId/${id}`);
};
const getParentProductById = (id) => {
  return http.get(`/user/getAllProductsByParentCategory/${id}`);
};
const getCategoryDetailById = (id) => {
  return http.get(`/user/getCategoryDetailsById/${id}`);
};
const getChildProductById = (id) => {
  return http.get(`/user/getAllProductsBySubCategory/${id}`);
};
const CategoryServices = {
  getAll,
  getById,
  getParentProductById,
  getChildProductById,
  getCategoryDetailById
};

export default CategoryServices;