import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  FormControl,
  Select,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import {
  deleteSelectedPermanentOrderMethod,
  getDeletedOrderMethod,
  getOrderCancelledMethod,
  getOrderCompleteMethod,
  getOrderConfirmMethod,
  getOrderDeliverMethod,
  getOrderMethod,
  getOrderPendingMethod,
} from '../redux/slice/orderSlice';
import OrderService from '../redux/api/OrderService';
import DeliveryChargesDialog from '../components/Dialog/DeliveryChargesDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'first_name', label: ' Customer First Name', alignRight: false },
  { id: 'last_name', label: ' Customer Last Name', alignRight: false },
  { id: 'email', label: 'Customer Email', alignRight: false },
  { id: 'phone', label: 'Customer Phone', alignRight: false },
  { id: 'payment_method', label: 'Payment Method', alignRight: false },
  { id: 'sub_total', label: 'Total Price', alignRight: false },
  { id: 'total_shipping_fee', label: 'Total Shiping Fee', alignRight: false },
  { id: 'all_totals', label: 'Grand Total', alignRight: false },
  { id: 'delivery_address', label: 'Delivery Address', alignRight: false },
  { id: 'createdAt', label: 'Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'ChangeStatus', label: 'Change Status', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.user_id.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function OrderPages() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(null);

  const [orderId, setOrderId] = useState(null);

  const [orderItem, setOrderItem] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [requestName, setRequestName] = useState('All');

  const [changeStatusMenu, setChangeStatusMenu] = useState(false);

  const [deliveryDiloag, setDeliveryDiloag] = useState(false);

  const handleDelivery = () => {
    setDeliveryDiloag(!deliveryDiloag);
  };

  const [selectStatus, setSelectStatus] = useState('Change Status');

  const [orderStatus, setorderStatus] = useState(null);

  const data = useSelector((state) => state.order);
  
  useEffect(() => {
    if (requestName === 'All') {
      dispatch(getOrderMethod());
    } else if (requestName === 'Pending') {
      dispatch(getOrderPendingMethod());
    } else if (requestName === 'Confirm') {
      dispatch(getOrderConfirmMethod());
    } else if (requestName === 'Completed') {
      dispatch(getOrderCompleteMethod());
    } else if (requestName === 'Cancelled') {
      dispatch(getOrderCancelledMethod());
    } else if (requestName === 'Deliver') {
      dispatch(getOrderDeliverMethod());
    } else {
      dispatch(getDeletedOrderMethod());
    }
  }, [requestName,dispatch]);

  const handleOpenMenu = (event, row) => {
    setOrderId(row._id);
    setOrderItem(row);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.data !== null && data.data !== undefined && data.data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleOpenChangeStatusMenu = (event, id) => {
    setChangeStatusMenu(event.currentTarget);
    setOrderId(id);
  };
  const handleCloseChangeStatusMenu = () => {
    setChangeStatusMenu(false);
  };

  const filteredUsers = applySortFilter(
    data.data !== null && data.data !== undefined ? data.data : [],
    getComparator(order, orderBy),
    filterName
  );

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const isNotFound = !filteredUsers.length && !!filterName;
  
  const handleSelectDeleteMethod = async () => {
    if (requestName === 'Deleted') {
      dispatch(deleteSelectedPermanentOrderMethod(selected));
      setSelected([]);
    } else {
      await OrderService.deleteSoftMultiple(selected)
        .then((res) => {
          console.warn('--------', res);
          toast.success(res?.data?.message);
          if (requestName === 'All') {
            dispatch(getOrderMethod());
          } else if (requestName === 'Pending') {
            dispatch(getOrderPendingMethod());
          } else if (requestName === 'Confirm') {
            dispatch(getOrderConfirmMethod());
          } else if (requestName === 'Completed') {
            dispatch(getOrderCompleteMethod());
          } else if (requestName === 'Cancelled') {
            dispatch(getOrderCancelledMethod());
          } else if (requestName === 'Deliver') {
            dispatch(getOrderDeliverMethod());
          }
          setSelected([]);
        })
        .catch((error) => {
          if (!error.response.data.message) {
            toast.error(error.message, { position: 'top-right' });
          } else {
            toast.error(error.response.data.message, { position: 'top-right' });
          }
        });
      setSelected([]);
    }
  };
  const handleDeleteMethod = async () => {
    if (requestName === 'Deleted') {
      await OrderService.premanentDelete(orderId)
        .then((res) => {
          console.warn('--------', res);
          toast.success('Order deleted successfully');
          dispatch(getDeletedOrderMethod());
          setOrderId(null);
          setOpen(false);
        })
        .catch((error) => {
          if (!error.response.data.message) {
            toast.error(error.message, { position: 'top-right' });
          } else {
            toast.error(error.response.data.message, { position: 'top-right' });
          }
        });
    } else {
      await OrderService.softDelete(orderId)
        .then((res) => {
          console.warn('--------', res);
          toast.success(res?.data?.message);
          if (requestName === 'All') {
            dispatch(getOrderMethod());
          } else if (requestName === 'Pending') {
            dispatch(getOrderPendingMethod());
          } else if (requestName === 'Confirm') {
            dispatch(getOrderConfirmMethod());
          } else if (requestName === 'Completed') {
            dispatch(getOrderCompleteMethod());
          } else if (requestName === 'Cancelled') {
            dispatch(getOrderCancelledMethod());
          } else if (requestName === 'Deliver') {
            dispatch(getOrderDeliverMethod());
          }
          setOrderId(null);
          setOpen(false);
        })
        .catch((error) => {
          if (!error.response.data.message) {
            toast.error(error.message, { position: 'top-right' });
          } else {
            toast.error(error.response.data.message, { position: 'top-right' });
          }
        });
    }
  };

  const handeleRestore = async () => {
    await OrderService.restore(orderId)
      .then((res) => {
        console.warn('--------', res);
        toast.success(res?.data?.message);
        if (requestName === 'All') {
          dispatch(getOrderMethod());
        } else if (requestName === 'Pending') {
          dispatch(getOrderPendingMethod());
        } else if (requestName === 'Confirm') {
          dispatch(getOrderConfirmMethod());
        } else if (requestName === 'Completed') {
          dispatch(getOrderCompleteMethod());
        } else if (requestName === 'Cancelled') {
          dispatch(getOrderCancelledMethod());
        } else if (requestName === 'Deliver') {
          dispatch(getOrderDeliverMethod());
        }
        setOrderId(null);
        setSelectStatus('Change Status');
        setOpen(false);
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };

  const handleChangeStatus = async (e) => {
    if (e.target.value === 'Confirm') {
      setorderStatus(e.target.value);
      handleDelivery();
      return;
    }
    const data = {
      status: e.target.value,
    };
    await OrderService.updateStatus(orderId, data)
      .then((res) => {
        console.warn('--------', res);
        toast.success(res?.data?.message);
        if (requestName === 'All') {
          dispatch(getOrderMethod());
        } else if (requestName === 'Pending') {
          dispatch(getOrderPendingMethod());
        } else if (requestName === 'Confirm') {
          dispatch(getOrderConfirmMethod());
        } else if (requestName === 'Completed') {
          dispatch(getOrderCompleteMethod());
        } else if (requestName === 'Cancelled') {
          dispatch(getOrderCancelledMethod());
        } else if (requestName === 'Deliver') {
          dispatch(getOrderDeliverMethod());
        }
        handleCloseChangeStatusMenu();
        setOrderId(null);
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
        throw error;
      });
  };

  const handleChangeShippingFeeStatus = async (shippFee) => {
    const data = {
      status: orderStatus,
      total_shipping_fee: Number(shippFee),
    };
    await OrderService.updateStatus(orderId, data)
      .then((res) => {
        console.warn('--------', res);
        toast.success(res?.data?.message);
        if (requestName === 'All') {
          dispatch(getOrderMethod());
        } else if (requestName === 'Pending') {
          dispatch(getOrderPendingMethod());
        } else if (requestName === 'Confirm') {
          dispatch(getOrderConfirmMethod());
        } else if (requestName === 'Completed') {
          dispatch(getOrderCompleteMethod());
        } else if (requestName === 'Cancelled') {
          dispatch(getOrderCancelledMethod());
        } else if (requestName === 'Deliver') {
          dispatch(getOrderDeliverMethod());
        }
        handleCloseChangeStatusMenu();
        handleDelivery();
        setorderStatus(null);
        setOrderId(null);
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
        throw error;
      });
  };

  return (
    <>
      <Helmet>
        <title> Orders | PakPrint Wishes </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {requestName} Orders
          </Typography>
          <Box>
            <Button
              variant="contained"
              sx={{ margin: '5px' }}
              onClick={() => {
                setRequestName('All');
                dispatch(getOrderMethod());
              }}
            >
              All Orders
            </Button>
            <Button
              variant="contained"
              sx={{ margin: '5px' }}
              onClick={() => {
                setRequestName('Pending');
                dispatch(getOrderPendingMethod());
              }}
            >
              Pending Orders
            </Button>
            <Button
              variant="contained"
              sx={{ margin: '5px' }}
              onClick={() => {
                setRequestName('Confirm');
                dispatch(getOrderConfirmMethod());
              }}
            >
              Confirm Orders
            </Button>
            <Button
              variant="contained"
              sx={{ margin: '5px' }}
              onClick={() => {
                setRequestName('Deliver');
                dispatch(getOrderDeliverMethod());
              }}
            >
              Deliver Orders
            </Button>
            <Button
              variant="contained"
              sx={{ margin: '5px' }}
              onClick={() => {
                setRequestName('Completed');
                dispatch(getOrderCompleteMethod());
              }}
            >
              Complete Orders
            </Button>
            <Button
              variant="contained"
              sx={{ margin: '5px' }}
              onClick={() => {
                setRequestName('Cancelled');
                dispatch(getOrderCancelledMethod());
              }}
            >
              Cancelled Orders
            </Button>
            <Button
              sx={{ margin: '5px' }}
              variant="contained"
              onClick={() => {
                setRequestName('Deleted');
                dispatch(getDeletedOrderMethod());
              }}
            >
              Deleted Orders
            </Button>
          </Box>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleSelectDeleteMethod={handleSelectDeleteMethod}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredUsers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id } = row;
                    const selectedUser = selected.indexOf(_id) !== -1;

                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {row?.user_id?.first_name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{row?.user_id?.last_name}</TableCell>
                        <TableCell align="left">{row?.user_id?.email}</TableCell>
                        <TableCell align="left">{row?.user_id?.phone}</TableCell>
                        <TableCell align="left">
                          {row?.payment_method !== null && row?.payment_method !== undefined
                            ? row?.payment_method
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="left">{row?.sub_total.toFixed(2)}</TableCell>
                        <TableCell align="left">{row?.total_shipping_fee}</TableCell>
                        <TableCell align="left">{row?.all_totals.toFixed(2)}</TableCell>
                        <TableCell align="left">{row?.delivery_address}</TableCell>
                        <TableCell align="left">{new Date(row?.createdAt).toISOString().substring(0, 10)}</TableCell>
                        <TableCell align="left">{row?.status}</TableCell>
                        <TableCell align="left">
                          <Button
                            disabled={requestName === 'Deleted'}
                            onClick={(e) => {
                              handleOpenChangeStatusMenu(e, _id);
                            }}
                          >
                            Change Status
                          </Button>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              handleOpenMenu(e, row);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <DeliveryChargesDialog
        open={deliveryDiloag}
        handleClose={handleDelivery}
        handleChangeShippingFeeStatus={handleChangeShippingFeeStatus}
      />
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {requestName !== 'Deleted' ? (
          <Link
            style={{ color: 'black', textDecoration: 'none' }}
            to={`/dashboard/orders/order-products/${orderId}`}
            state={{ item: orderItem }}
          >
            <MenuItem>
              <Iconify icon={'ic:outline-remove-red-eye'} sx={{ mr: 2 }} />
              View
            </MenuItem>
          </Link>
        ) : null}
        {requestName === 'Deleted' ? (
          <MenuItem onClick={handeleRestore}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Restore
          </MenuItem>
        ) : null}
        <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteMethod}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <Popover
        open={Boolean(changeStatusMenu)}
        anchorEl={changeStatusMenu}
        onClose={handleCloseChangeStatusMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <FormControl fullWidth>
          <Select value={selectStatus} onChange={handleChangeStatus} sx={{ textAlign: 'left' }}>
            <MenuItem value="Change Status" disabled>
              Change Status
            </MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Confirm">Confirm</MenuItem>
            <MenuItem value="Deliver">Deliver</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="Cancelled">Cancelled</MenuItem>
          </Select>
        </FormControl>
      </Popover>
    </>
  );
}
