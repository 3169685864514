import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import GalleryService from '../api/GalleryService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getGalleryMethod = createAsyncThunk(`/get-gallery`, async (id) => {
  try {
    const { data } = await GalleryService.get(id);
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

export const getGalleryFolderMethod = createAsyncThunk(`/get-galleryfolder`, async (id) => {
  try {
    const { data } = await GalleryService.getFolder(id);
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const gallerySlice = createSlice({
  name: 'gallerySlice',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getGalleryMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getGalleryMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getGalleryMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get folder
    [getGalleryFolderMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getGalleryFolderMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getGalleryFolderMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default gallerySlice.reducer;
