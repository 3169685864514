import http from '../api/http-common';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const fetchALL = createAsyncThunk('wishlist/getAPI', async () => {
  const response = await http.get(`/getAllFavtProducts`);
  return response.data.data;
});

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchALL.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchALL.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchALL.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default wishlistSlice.reducer;
