import http from "./http-common";

const get = (id) => {
  return http.get(`/user/getSameProducts/${id}`);
};

const SameStoreServices = {
  get,
};

export default SameStoreServices;
