import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomSubCategoryService from '../api/CustomSubCategoryService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getCustomSubCategoryMethod = createAsyncThunk(`/get-customSubCategorySlice`, async () => {
  try {
    const { data } = await CustomSubCategoryService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const customSubCategorySlice = createSlice({
  name: 'customSubCategorySlice',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getCustomSubCategoryMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomSubCategoryMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomSubCategoryMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default customSubCategorySlice.reducer;
