import { callAPi } from './http-common';

const UpdateMultiple = () => {
    return callAPi.post(`/markAllAsRead`);
};
const get = () => {
    return callAPi.get('/getNotification');
};
const update = (id) => {
    return callAPi.post(`/markOneAsRead/${id}`);
};
const NotificationService = {
    get,
    update,
    UpdateMultiple,
};

export default NotificationService;