import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CareersServices from "../api/CareersServices";

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

export const retrieveSerives = createAsyncThunk("career-get", async () => {
  try {
    const res = await CareersServices.getAll();
    return res.data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
});
export const retrieveByIdSerives = createAsyncThunk("career-getbyid", async (id) => {
  try {
    const res = await CareersServices.get(id);
    return res.data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
});
export const retrieveByTitleSerives = createAsyncThunk(
  "career-searchby-city",
  async (city) => {
    try {
      const res = await CareersServices.getToSearch(city);
      return res.data;
    } catch (err) {
      console.log(err.message);
      throw err;
    }
  }
);

const careersSlice = createSlice({
  name: "career",
  initialState,
  extraReducers: {
    [retrieveSerives.fulfilled]: (state, action) => {
      return action.payload;
    },
    [retrieveByIdSerives.fulfilled]: (state, action) => {
      return action.payload;
    },
    // [retrieveByTitleSerives.fulfilled]: (state, action) => {
    //   state.data=action.payload;
    // },
    
  },
});

export const selectAllData = (state) => state.services.data;
export const getStatusMessage = (state) => state.services.status;
export const getErrorMessage = (state) => state.services.error;

export default careersSlice.reducer;
