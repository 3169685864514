import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useDispatch, useSelector } from 'react-redux';
import facebook from '../Assets/icons/facebook.png';
import instagram from '../Assets/icons/instagram.png';
import linkedin from '../Assets/icons/linkedin.png';
import pinterest from '../Assets/icons/pinterest.png';
import youtube from '../Assets/icons/youtube.png';
import apples from '../Assets/icons/apple-store-button 1.png';
import androids from '../Assets/icons/google play 1.png';
import { retrieveSerives } from '../redux/slice/serviceSlice';

const FooterPage = (props) => {
  const { isTop, setIsTop } = props;

  const dispatch = useDispatch();
  const cachedData = useSelector((state) => state.services.data);
  const data = React.useMemo(() => cachedData, [cachedData]);
  const location = useLocation();

  const initFetch = React.useCallback(() => {
    dispatch(retrieveSerives());
  }, [dispatch]);

  React.useEffect(() => {
    initFetch();
  }, [initFetch]);
  return (
    <Grid
      container
      className={`${location.pathname === '/contact' ? 'footer-main-container-contact' : 'footer-main-container'}`}
      spacing={2}
      p={2}
      justifyContent={'center'}
    >
      <Grid style={{ paddingTop: 'none' }} item xs={12} sm={12} md={12} lg={3} xl={3}>
        <Typography sx={{ lineHeight: '1' }} variant="h4">
          PakPrint Wishes
        </Typography>
        <Typography width={'80%'} variant="body2" pt={2}>
          <b>PakPrintwishes.com </b>
          is the first website for ultimate vendor and user base printing company, bringing you top-notch Online
          Printing services all across Pakistan. We have excellent quality and print material and Machine & Accessories.
          We provide services of all types, styles, shapes, and colors, custom boxes of all shapes and quality. We also
          give discounts on big orders.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} xl={2} p={1}>
        <ul className="footer-bar-item">
          <li style={{ paddingBottom: '10px' }}>
            <b>Quick Links</b>
          </li>
          <li className="footer-link">
            <Link to="/" onClick={() => setIsTop(!isTop)}>
              Home
            </Link>
          </li>
          <li className="footer-link">
            <Link to="/about-us" onClick={() => setIsTop(!isTop)}>
              About Us
            </Link>
          </li>
          <li className="footer-link">
            <Link to="/contact" onClick={() => setIsTop(!isTop)}>
              Contact Us
            </Link>
          </li>
          <li className="footer-link">
            <Link to="/category" onClick={() => setIsTop(!isTop)}>
              Category
            </Link>
          </li>
          <li className="footer-link">
            <Link to="/blogs" onClick={() => setIsTop(!isTop)}>
              Blog
            </Link>
          </li>
          <li className="footer-link">
            <Link to="/art-print-&-stationery" onClick={() => setIsTop(!isTop)}>
              Art Print & Stationery
            </Link>
          </li>
          <li className="footer-link">
            <Link to="/printing-accessories" onClick={() => setIsTop(!isTop)}>
              Machine & Accessories
            </Link>
          </li>
          <li className="footer-link">
            <Link to="/careers" onClick={() => setIsTop(!isTop)}>
              Careers
            </Link>
          </li>
          <li className="footer-link">
            <Link to="/terms&condition" onClick={() => setIsTop(!isTop)}>
              Term & Condition
            </Link>
          </li>
          <li className="footer-link">
            <Link to="/policy" onClick={() => setIsTop(!isTop)}>
              Privacy Policy
            </Link>
          </li>
          <li className="footer-link">
            <Link to="/delete-account" onClick={() => setIsTop(!isTop)}>
              Delete Account Policy
            </Link>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2} xl={2} p={1}>
        <ul className="footer-bar-item">
          <li style={{ paddingBottom: '10px' }}>
            <b>Our Trending Services</b>
          </li>
          {data?.slice(0, 5).map((res) => (
            <li className="footer-link" key={res._id}>
              <Link to="/services">{res.heading}</Link>
            </li>
          ))}
        </ul>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={3} p={1}>
        <ul className="footer-bar-item">
          <li style={{ paddingBottom: '10px' }}>
            <b>Download App</b>
          </li>
          {/* <li>
            <img src={printwishesapp} alt="app1" />
          </li>
          <li>Download App</li> */}
          <li>
            <Box className="app-icons">
              <a
                href="https://play.google.com/store/apps/details?id=com.printwishes&pli=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img width={'100%'} height={'50px'} src={androids} alt="app1" />
              </a>
              <a
                href="https://apps.apple.com/us/app/pakprint-wishes/id6473772578"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img width={'100%'} height={'50px'} src={apples} alt="app2" />
              </a>
            </Box>
          </li>
          <li>
            <Box mt={2}>
              <Typography variant="subtitle2">Follow Us</Typography>
              <Box className="social-icon">
                <a
                  href="https://www.facebook.com/pakprintwishes.pk/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon-item"
                >
                  <img src={facebook} alt="facebook" style={{ width: '30px' }} />
                </a>
                <a
                  href="https://www.instagram.com/pakprintwishes/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon-item"
                >
                  <img src={instagram} alt="instagram" style={{ width: '30px' }} />
                </a>
                <a
                  href="https://www.linkedin.com/company/90792249/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon-item"
                >
                  <img
                    src={linkedin}
                    alt="linkedin"
                    style={{ width: '30px', backgroundColor: 'white', borderRadius: '5px' }}
                  />
                </a>
                <a
                  href="https://www.pinterest.com/infopakprintwishes/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon-item"
                >
                  <img src={pinterest} alt="pinterest" style={{ width: '30px' }} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCbPX9qErevRoJAwB9mqZ5Vg"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon-item"
                >
                  <img src={youtube} alt="youtube" style={{ width: '30px' }} />
                </a>
              </Box>
            </Box>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} m={2}>
        <Grid
          container
          sx={{
            backgroundColor: 'white',
            color: 'black',
            padding: '1%',
            textAlign: 'center',
          }}
        >
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Typography variant="subtitle2">
              Copyright @ <strong>PakPrintwishes</strong>. All Rights Reserved.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid
              container
              onClick={() => {
                window.open('https://wa.me/+923007605006');
              }}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body2"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <PhoneIcon /> &nbsp;<strong>Enquiry No.:</strong>&nbsp; +92 300 7605006&nbsp;
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  <strong>Landline#</strong>&nbsp;042 35305203
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Typography
              variant="subtitle2"
              onClick={() => {
                window.open('mailto:info.pak.printwishes@gmail.com');
              }}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              <EmailIcon />
              &nbsp; info.pak.printwishes@gmail.com
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FooterPage;
