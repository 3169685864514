import { Box, Grid, Typography, Card, Avatar, CardActionArea } from '@mui/material';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-alice-carousel/lib/alice-carousel.css';
import AliceCarousel from 'react-alice-carousel';
import { fetchCategory } from '../../redux/slice/categorySlice';
import { imageBaseUrl } from '../../redux/api/http-common';
import ProgressBar from '../../../components/progress/ProgressBar';

const responsive = {
  0: { items: 1 },
  568: { items: 3 },
  1024: { items: 4 },
};

const Categories = () => {
  const allCatgory = useSelector((state) => state.category);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);
  return (
    <Grid container spacing={2} className="category-container">
      <Grid item xs={12} p={1} display={'flex'} justifyContent="space-between" alignItems="center">
        <Typography variant="h6" px={1} className="featured-products-header-text">
          Categories
        </Typography>
        <Link to="all-categories" className="featured-products-header-link">
          View All&#x276F;
        </Link>
      </Grid>
      {allCatgory?.loading && <ProgressBar />}
      {allCatgory.data === null || allCatgory.data === undefined || allCatgory.data.length === 0 ? null : (
        <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
          <AliceCarousel
            mouseTracking
            infinite
            autoPlayInterval={1000}
            animationDuration={1500}
            // disableButtonsControls
            autoPlay
            disableDotsControls
            responsive={responsive}
          >
            {allCatgory.data?.map((res) => (
              <Card
                elevation={0}
                key={res?._id}
                className="category-card item"
                data-value={res._id}
                component={Link}
                to={`/category/${res?.name.replace(/\s+/g, '-').toLowerCase()}/${res?._id}`}
                state={{ id: res, name: res.name }}
              >
                <CardActionArea className="category-content-card-item">
                  <Avatar
                    alt="T-Shirt Printing"
                    src={imageBaseUrl + res?.category_image}
                    sx={{ height: '60px', width: '65px' }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      width: '60%',
                      paddingTop: '2px',
                    }}
                  >
                    <Typography gutterBottom component="div" className="category-card-content-title">
                      {res?.name}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </AliceCarousel>
        </Grid>
      )}
    </Grid>
  );
};

export default Categories;
