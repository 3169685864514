import { Grid, Pagination, Typography } from '@mui/material';
import React from 'react';
import CompleteJobCard from '../card/CompleteJobCard';

const ActiveJobs = () => {
  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} py={3} p={1}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Active Jobs</Typography>
          </Grid>
          <Grid item xs={12}>
            <CompleteJobCard />
          </Grid>
          <Grid item xs={12}>
            <CompleteJobCard />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination count={10} variant="outlined" shape="rounded" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActiveJobs;
