import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import bgImg from '../Assets/header_bg/shop_bg.png';
import { fetchCategory } from '../redux/slice/categorySlice';
import CategoryCard from '../Components/CategoryCard';
import WhatsApp from '../Components/WhatsApp';

const CategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [productPerPage] = useState(12);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, [currentPage]);
  
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  //
  const dispatch = useDispatch();
  const cachedData = useSelector((state) => state.category.data.data);
  console.log(cachedData);
  const data = React.useMemo(() => cachedData, [cachedData]);
  const initFetch = React.useCallback(() => {
    dispatch(fetchCategory());
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const totalProducts = data?.length; // Total number of products
  const totalPages = Math.ceil(totalProducts / productPerPage); // Calculate total pages
  const indexOfLastPost = currentPage * productPerPage;
  const indexOfFirstPost = indexOfLastPost - productPerPage;
  const currentPosts = data?.slice(indexOfFirstPost, indexOfLastPost);
  return (
    <Grid pb={6} sx={{ backgroundColor: 'white' }} container className="main-top-container" justifyContent={'center'}>
      <Helmet>
        <title>Category | PakPrint Wishes</title>
        <meta
          name="description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:title" content="Category | PakPrint Wishes" />
        <meta
          property="og:description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:image" content={bgImg} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
        sx={{ backgroundImage: `url(${bgImg})` }}
      >
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9} sx={{ display: 'flex' }}>
            <div className="breadcrumb-text">
              <h1 className="breadcrumb-text__title">Category</h1>
              <ul className="breadcrumb-text__nav">
                <li>
                  <Link to={'/'}>Home</Link>
                </li>
                <li>&nbsp;-&nbsp;</li>
                <li>Category</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        item
        xs={1}
        sm={1}
        md={1}
        lg={1}
        xl={1}
        className="featured-produts-containers"
      ></Grid> */}
      <Grid pt={6} item xs={12} sm={12} md={12} lg={11} xl={11}>
        <Grid container spacing={2} className="featured-products-container">
          {currentPosts?.map((image, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                <CategoryCard image={image} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid py={2} item xs={12} sm={12} md={12} lg={12} xl={12} className="pagination-container">
        <Pagination
          count={totalPages} // Show total pages instead of current page
          page={currentPage} // Set the current page
          renderItem={(item) => (
            <PaginationItem components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />
          )}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </Grid>
      <WhatsApp />
    </Grid>
  );
};

export default CategoryPage;
