import http from "./http-common";

const getAll = () => {
  return http.get("/user/getAllAccessories");
};
const get = (id) => {
  return http.get(`/get-accessories-details/${id}`);
};


const PrintingAccessoriesServices = {
  getAll,
  get
};

export default PrintingAccessoriesServices;