import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Divider, Typography, Button, IconButton, Select, MenuItem, TextField, Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import CustomProductServices from '../../redux/api/CustomProductServices';
import { IMAGE_BASE_URL } from '../../redux/api/http-common';
import ContactUsPlace from '../../components/card/ContactUsPlace';
import { customProductAddToCart } from '../../client/redux/slice/cartSlice';
import CustomProductSEOService from '../../redux/api/CustomProductSEOService';
import GalleryService from '../../redux/api/GalleryService';
import CustomProductInquiryService from '../../redux/api/CustomProductInquiryService';
import ProgressBar from '../../components/progress/ProgressBar';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const initialFormValues = {
  cartQuantity: 1,
  totalPrice: 1,
  stringFields: {},
  customProductId: '',
  customParentCategoryIds: [],
  customChildCategoryIds: [],
  sku: '',
  customFields: {},
  booleanFields: {},
  images: [],
  titles: '',
  prices: '',
  createdBy: '',
};

const CustomProductDetailPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = React.useState(initialFormValues);
  const [fetchProduct, setFetchProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageProduct, setImageProduct] = useState([]);
  const [imgUrls, setImageUrl] = useState(null);
  const [seoData, setSeoData] = useState(null);
  const userInfo = useSelector((state) => state.clientuser.userInfo);
  const handleAddToCart = () => {
    const products = { customproducts: values, cartQty: values?.cartQuantity };
    dispatch(customProductAddToCart(products));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    getDetails();
    getProductSEO();
  }, [id]);

  // Product Detail
  const getDetails = async () => {
    await CustomProductServices.getbyId(id)
      .then((res) => {
        setFetchProduct(!res?.data?.data ? null : res?.data?.data[0]);
        setImageProduct(!res?.data?.data || !res?.data?.data[0]?.images ? [] : res?.data?.data[0]?.images);
        setImageUrl(
          !res?.data?.data || !res?.data?.data[0]?.images || !res?.data?.data[0]?.images[0]
            ? ''
            : res?.data?.data[0]?.images[0]
        );

        const responseData = res?.data?.data[0];
        // Dynamically set string fields
        const stringFields = {};
        if (responseData.stringFields && Object.keys(responseData.stringFields).length > 0) {
          Object.keys(responseData.stringFields).forEach((key) => {
            stringFields[key] = responseData.stringFields[key];
          });
        }

        // Dynamically set customFields
        const customFields = {};
        if (responseData.customFields && Object.keys(responseData.customFields).length > 0) {
          Object.keys(responseData.customFields).forEach((key) => {
            // customFields[key] = responseData.customFields[key];
            if (key === 'textInput' && responseData.customFields[key]?.fields) {
              customFields[key] = {};
              responseData.customFields[key]?.fields.forEach((item) => {
                customFields[key][item?.title] = '';
              });
            } else {
              customFields[key] = '';
            }
          });
        }

        // Dynamically set booleanFields
        const booleanFields = {};
        if (responseData.booleanFields && Object.keys(responseData.booleanFields).length > 0) {
          Object.keys(responseData.booleanFields).forEach((key) => {
            if (key === 'inquiryForm') {
              booleanFields[key] = { fullName: '', emailAddress: '', phoneNo: '', yourQuery: '' };
            } else {
              booleanFields[key] = '';
            }
          });
        }

        // Update state with the dynamically created object
        setValues({
          cartQuantity:
            !responseData?.objectFields?.qty?.status || responseData?.objectFields?.qty?.status === 'false'
              ? 1
              : Number(responseData?.objectFields?.qty?.fields?.startRange),
          totalPrice: !responseData?.prices ? 1 : Number(responseData?.prices),
          stringFields,
          customProductId: responseData._id || '',
          customParentCategoryIds: responseData.customParentCategoryIds || [],
          customChildCategoryIds: responseData.customChildCategoryIds || [],
          sku: responseData.sku || '',
          customFields,
          booleanFields,
          images: responseData.images || [],
          titles: responseData.titles || '',
          prices: responseData.prices || '',
          createdBy: responseData.createdBy || '',
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getProductSEO = async () => {
    await CustomProductSEOService.getbyid(id)
      .then((res) => {
        setSeoData(res?.data?.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectedItem = (type, fieldKey, newItem) => {
    if (type === 'customFields' && values[type][fieldKey] !== '') {
      setValues((prevValues) => ({
        ...prevValues,
        [type]: {
          ...prevValues[type],
          [fieldKey]: '',
        },
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [type]: {
          ...prevValues[type],
          [fieldKey]: newItem,
        },
      }));
    }
    calculatePrice();
  };

  const calculatePrice = () => {
    let sumOfPrice = 0;
    if (values?.customFields && Object.keys(values?.customFields).length > 0) {
      Object.keys(values.customFields).forEach((keyItem) => {
        const itemPrice = Number(values.customFields[keyItem]?.price) || 0;
        sumOfPrice += itemPrice;
      });
    }
    setValues((prevValues) => ({
      ...prevValues,
      totalPrice:
        sumOfPrice === 0 || sumOfPrice === '0'
          ? values?.prices * values.cartQuantity
          : sumOfPrice * values.cartQuantity, // Multiply by cartQuantity
    }));
  };

  useEffect(() => {
    calculatePrice(); // Calculate the price when the component mounts or when cartQuantity changes
  }, [values.customFields, values.cartQuantity]);

  const pathSegments = location?.pathname.split('/').filter((segment) => segment !== ''); // Split and remove empty segments

  const breadcrumbItems = pathSegments
    .slice(0, !id ? pathSegments.length : pathSegments.length - 1)
    .map((segment, index) => (
      <React.Fragment key={index}>
        <li>
          {segment.replace(/-/g, ' ')}
          {(!id ? pathSegments.length - 1 : pathSegments.length - 2) === index ? '' : null}
        </li>
        {index < (!id ? pathSegments.length - 1 : pathSegments.length - 2) && <li>&nbsp;-&nbsp;</li>}{' '}
        {/* Add separator if not the last segment */}
      </React.Fragment>
    ));

  const uploadFileHandler = async (images) => {
    const data = {
      file: images,
    };
    await GalleryService.uploadFiles(data)
      .then((res) => {
        toast.success(res?.data?.message);
        handleSelectedItem('booleanFields', 'files', !res?.data?.data?.file ? '' : res?.data?.data?.file);
      })
      .catch((err) => {
        if (!err?.response?.data) {
          toast.error(err.message);
        } else {
          toast.error(err.response.data.message);
        }
      });
  };

  const inquiryFormSubmit = async () => {
    if (!userInfo || Object.keys(userInfo).length === 0) {
      navigate('/login');
    } else {
      await CustomProductInquiryService.add(values)
        .then((res) => {
          toast.success(res?.data?.message);
          getDetails();
        })
        .catch((err) => {
          if (!err?.response?.data) {
            toast.error(err.message);
          } else {
            toast.error(err.response.data.message);
          }
        });
    }
  };

  const handleQRCodeSubmit = async () => {
    if (!userInfo || Object.keys(userInfo).length === 0) {
      navigate('/login');
    } else {
      toast.success('Comming Soon');
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {!seoData || Object.keys(seoData).length === 0 || !seoData?.webTitle
            ? pathSegments[!id ? pathSegments.length - 1 : pathSegments.length - 2]
                .toLocaleUpperCase()
                .replace(/-/g, ' ')
            : seoData?.webTitle}{' '}
          | PakPrint Wishes
        </title>
        <meta
          name="description"
          content={
            !seoData || Object.keys(seoData).length === 0 || !seoData?.webDescription
              ? 'We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!'
              : seoData?.webDescription
          }
        />
        <meta
          property="og:title"
          content={
            !seoData || Object.keys(seoData).length === 0 || !seoData?.webTitle
              ? pathSegments[!id ? pathSegments.length - 1 : pathSegments.length - 2]
                  .toLocaleUpperCase()
                  .replace(/-/g, ' ')
              : seoData?.webTitle
          }
        />
        <meta
          property="og:description"
          content={
            !seoData || Object.keys(seoData).length === 0 || !seoData?.webDescription
              ? 'We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!'
              : seoData?.webDescription
          }
        />
        <meta property="og:image" content={!imageProduct ? null : IMAGE_BASE_URL + imgUrls} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {loading === true ? null : !fetchProduct ||
        Object.keys(fetchProduct).length === 0 ||
        !fetchProduct?.objectFields ||
        Object.keys(fetchProduct?.objectFields).length === 0 ||
        !fetchProduct?.objectFields?.qty ||
        Object.keys(fetchProduct?.objectFields?.qty).length === 0 ||
        !fetchProduct?.objectFields?.qty?.status ||
        fetchProduct?.objectFields?.qty?.status === 'false' ? null : (
        <Box className="custom-product-ribbon">
          <Box>
            <p className="custon-product-checkbox-text">Price Details:</p>
            <p className="custon-product-checkbox-text">QTY:</p>
            <p className="custon-product-checkbox-status">Total Price:</p>
          </Box>
          <Box>
            <p className="custon-product-checkbox-text">{values?.cartQuantity}</p>
            <p className="custon-product-checkbox-status">PKR: {values?.totalPrice}</p>
          </Box>
        </Box>
      )}
      {loading === true ? (
        <ProgressBar />
      ) : (
        <Grid container className="top-container" justifyContent={'center'} spacing={2}>
          <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
            <ul className="breadcrumb-text-custom-product">
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li>&nbsp;-&nbsp;</li>
              {breadcrumbItems}
            </ul>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
            {!fetchProduct ? null : (
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {!imgUrls ? null : (
                    <img src={IMAGE_BASE_URL + imgUrls} alt="pic" className="custom-prod-image-main" />
                  )}
                  <Divider className="prod-divider" />
                  <Grid container className="prod-image-small-container">
                    {imageProduct !== null || imageProduct !== undefined || imageProduct.length > 0
                      ? imageProduct.map((res, index) => (
                          <Grid item key={index}>
                            <IconButton
                              disableRipple
                              sx={{ padding: '5px !important' }}
                              onClick={() => setImageUrl(res)}
                            >
                              <img
                                src={IMAGE_BASE_URL + res}
                                alt="pic"
                                style={{
                                  width: '100px',
                                  height: '100px',
                                }}
                                className="prod-image-small pointers"
                              />
                            </IconButton>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </Grid>
                {!fetchProduct ||
                Object.keys(fetchProduct).length === 0 ||
                !fetchProduct?.booleanFields ||
                Object.keys(fetchProduct?.booleanFields).length === 0 ||
                !fetchProduct?.booleanFields?.imageCaption ||
                fetchProduct?.booleanFields?.imageCaption === 'false' ? null : (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="subtitle2">
                      Please insert text in boxes below. Our design team will get in touch with the final version before
                      proceeding with your order.
                    </Typography>
                  </Grid>
                )}
                {!fetchProduct ||
                Object.keys(fetchProduct).length === 0 ||
                !fetchProduct?.customFields ||
                Object.keys(fetchProduct?.customFields).length === 0 ||
                !fetchProduct?.customFields?.textInput ||
                Object.keys(fetchProduct?.customFields?.textInput).length === 0 ||
                !fetchProduct?.customFields?.textInput?.status ||
                fetchProduct?.customFields?.textInput?.status === 'false' ||
                !fetchProduct?.customFields?.textInput?.fields ||
                fetchProduct?.customFields?.textInput?.fields.length === 0 ? null : (
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems={'flex-end'}>
                      {fetchProduct?.customFields?.textInput?.fields.map((resItem, index) =>
                        !resItem?.title ? null : (
                          <Grid item xs={12} sm={6} key={index}>
                            <TextField
                              placeholder={resItem?.title}
                              fullWidth
                              value={values?.customFields?.textInput[resItem?.title] || ''}
                              onChange={(e) => {
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  customFields: {
                                    ...prevValues.customFields,
                                    textInput: {
                                      ...prevValues.customFields.textInput,
                                      [resItem?.title]: e.target.value,
                                    },
                                  },
                                }));
                              }}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Grid>
                )}
                {!fetchProduct ||
                Object.keys(fetchProduct).length === 0 ||
                !fetchProduct?.booleanFields ||
                Object.keys(fetchProduct?.booleanFields).length === 0 ||
                !fetchProduct?.booleanFields?.files ||
                fetchProduct?.booleanFields?.files === 'false' ? null : (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button component="label" className="custom-product-detail">
                      Choose a file
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(e) => {
                          uploadFileHandler(e.target.files[0]);
                        }}
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <Grid container px={2} spacing={2}>
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.booleanFields ||
              Object.keys(fetchProduct?.booleanFields).length === 0 ||
              !fetchProduct?.booleanFields?.disclaimer ||
              fetchProduct?.booleanFields?.disclaimer === 'false' ? null : (
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <strong>Disclaimer</strong> : Colours on screen may vary from final print owing to the difference in
                    screen resolutions between devices. The file you’ve chosen is a little heavy, will load in a few
                    seconds.
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography className="custom-product-title">
                  {!fetchProduct || Object.keys(fetchProduct).length === 0 || !fetchProduct.titles
                    ? null
                    : fetchProduct.titles}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  PPW No.:&nbsp;
                  {!fetchProduct || Object.keys(fetchProduct).length === 0 || !fetchProduct.sku
                    ? null
                    : fetchProduct.sku}
                </Typography>
              </Grid>
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.booleanFields ||
              Object.keys(fetchProduct?.booleanFields).length === 0 ||
              !fetchProduct?.booleanFields?.contactUsPlace ||
              fetchProduct?.booleanFields?.contactUsPlace === 'false' ? null : (
                <Grid item xs={12}>
                  <Typography variant="h5" py={1}>
                    Please contact us to place your order
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    <Grid item xs={12}>
                      <ContactUsPlace />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.bagMaterial ||
              Object.keys(fetchProduct?.customFields?.bagMaterial).length === 0 ||
              !fetchProduct?.customFields?.bagMaterial?.status ||
              fetchProduct?.customFields?.bagMaterial?.status === 'false' ||
              !fetchProduct?.customFields?.bagMaterial?.fields ||
              fetchProduct?.customFields?.bagMaterial?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Bag Material
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.bagMaterial?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.bagMaterial?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'bagMaterial', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.bagSize ||
              Object.keys(fetchProduct?.customFields?.bagSize).length === 0 ||
              !fetchProduct?.customFields?.bagSize?.status ||
              fetchProduct?.customFields?.bagSize?.status === 'false' ||
              !fetchProduct?.customFields?.bagSize?.fields ||
              fetchProduct?.customFields?.bagSize?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Bag Size
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.bagSize?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.bagSize?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'bagSize', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.colors ||
              Object.keys(fetchProduct?.customFields?.colors).length === 0 ||
              !fetchProduct?.customFields?.colors?.status ||
              fetchProduct?.customFields?.colors?.status === 'false' ||
              !fetchProduct?.customFields?.colors?.fields ||
              fetchProduct?.customFields?.colors?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Colors
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.colors?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.colors?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'colors', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.colorsofRibbon ||
              Object.keys(fetchProduct?.customFields?.colorsofRibbon).length === 0 ||
              !fetchProduct?.customFields?.colorsofRibbon?.status ||
              fetchProduct?.customFields?.colorsofRibbon?.status === 'false' ||
              !fetchProduct?.customFields?.colorsofRibbon?.fields ||
              fetchProduct?.customFields?.colorsofRibbon?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Colors of Ribbon
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.colorsofRibbon?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.colorsofRibbon?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'colorsofRibbon', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.colorsofTextonRibbon ||
              Object.keys(fetchProduct?.customFields?.colorsofTextonRibbon).length === 0 ||
              !fetchProduct?.customFields?.colorsofTextonRibbon?.status ||
              fetchProduct?.customFields?.colorsofTextonRibbon?.status === 'false' ||
              !fetchProduct?.customFields?.colorsofTextonRibbon?.fields ||
              fetchProduct?.customFields?.colorsofTextonRibbon?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Colors of Text on Ribbon
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.colorsofTextonRibbon?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.colorsofTextonRibbon?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'colorsofTextonRibbon', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}

              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.coverMaterial ||
              Object.keys(fetchProduct?.customFields?.coverMaterial).length === 0 ||
              !fetchProduct?.customFields?.coverMaterial?.status ||
              fetchProduct?.customFields?.coverMaterial?.status === 'false' ||
              !fetchProduct?.customFields?.coverMaterial?.fields ||
              fetchProduct?.customFields?.coverMaterial?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Cover Material
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.coverMaterial?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.coverMaterial?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'coverMaterial', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.cutting ||
              Object.keys(fetchProduct?.customFields?.cutting).length === 0 ||
              !fetchProduct?.customFields?.cutting?.status ||
              fetchProduct?.customFields?.cutting?.status === 'false' ||
              !fetchProduct?.customFields?.cutting?.fields ||
              fetchProduct?.customFields?.cutting?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Cutting
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.cutting?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.cutting?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'cutting', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.foilMinimumQTY ||
              Object.keys(fetchProduct?.customFields?.foilMinimumQTY).length === 0 ||
              !fetchProduct?.customFields?.foilMinimumQTY?.status ||
              fetchProduct?.customFields?.foilMinimumQTY?.status === 'false' ||
              !fetchProduct?.customFields?.foilMinimumQTY?.fields ||
              fetchProduct?.customFields?.foilMinimumQTY?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Foil Minimum 100 QTY
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.foilMinimumQTY?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.foilMinimumQTY?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'foilMinimumQTY', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.formats ||
              Object.keys(fetchProduct?.customFields?.formats).length === 0 ||
              !fetchProduct?.customFields?.formats?.status ||
              fetchProduct?.customFields?.formats?.status === 'false' ||
              !fetchProduct?.customFields?.formats?.fields ||
              fetchProduct?.customFields?.formats?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Formats
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.formats?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.formats?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'formats', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.innerCard ||
              Object.keys(fetchProduct?.customFields?.innerCard).length === 0 ||
              !fetchProduct?.customFields?.innerCard?.status ||
              fetchProduct?.customFields?.innerCard?.status === 'false' ||
              !fetchProduct?.customFields?.innerCard?.fields ||
              fetchProduct?.customFields?.innerCard?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Inner Card
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.innerCard?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.innerCard?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'innerCard', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.ledOption ||
              Object.keys(fetchProduct?.customFields?.ledOption).length === 0 ||
              !fetchProduct?.customFields?.ledOption?.status ||
              fetchProduct?.customFields?.ledOption?.status === 'false' ||
              !fetchProduct?.customFields?.ledOption?.fields ||
              fetchProduct?.customFields?.ledOption?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    LED Option
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.ledOption?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.ledOption?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'ledOption', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.mobileBrand ||
              Object.keys(fetchProduct?.customFields?.mobileBrand).length === 0 ||
              !fetchProduct?.customFields?.mobileBrand?.status ||
              fetchProduct?.customFields?.mobileBrand?.status === 'false' ||
              !fetchProduct?.customFields?.mobileBrand?.fields ||
              fetchProduct?.customFields?.mobileBrand?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Mobile Brand
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.mobileBrand?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.mobileBrand?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'mobileBrand', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.mugColor ||
              Object.keys(fetchProduct?.customFields?.mugColor).length === 0 ||
              !fetchProduct?.customFields?.mugColor?.status ||
              fetchProduct?.customFields?.mugColor?.status === 'false' ||
              !fetchProduct?.customFields?.mugColor?.fields ||
              fetchProduct?.customFields?.mugColor?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Mug Color
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.mugColor?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.mugColor?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'mugColor', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.packaging ||
              Object.keys(fetchProduct?.customFields?.packaging).length === 0 ||
              !fetchProduct?.customFields?.packaging?.status ||
              fetchProduct?.customFields?.packaging?.status === 'false' ||
              !fetchProduct?.customFields?.packaging?.fields ||
              fetchProduct?.customFields?.packaging?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Packaging
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.packaging?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.packaging?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'packaging', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.paper ||
              Object.keys(fetchProduct?.customFields?.paper).length === 0 ||
              !fetchProduct?.customFields?.paper?.status ||
              fetchProduct?.customFields?.paper?.status === 'false' ||
              !fetchProduct?.customFields?.paper?.fields ||
              fetchProduct?.customFields?.paper?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Paper
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.paper?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.paper?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'paper', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.paperFinish ||
              Object.keys(fetchProduct?.customFields?.paperFinish).length === 0 ||
              !fetchProduct?.customFields?.paperFinish?.status ||
              fetchProduct?.customFields?.paperFinish?.status === 'false' ||
              !fetchProduct?.customFields?.paperFinish?.fields ||
              fetchProduct?.customFields?.paperFinish?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Paper Finish
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.paperFinish?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.paperFinish?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'paperFinish', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.paperThickness ||
              Object.keys(fetchProduct?.customFields?.paperThickness).length === 0 ||
              !fetchProduct?.customFields?.paperThickness?.status ||
              fetchProduct?.customFields?.paperThickness?.status === 'false' ||
              !fetchProduct?.customFields?.paperThickness?.fields ||
              fetchProduct?.customFields?.paperThickness?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Paper Thickness
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.paperThickness?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.paperThickness?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'paperThickness', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.paperType ||
              Object.keys(fetchProduct?.customFields?.paperType).length === 0 ||
              !fetchProduct?.customFields?.paperType?.status ||
              fetchProduct?.customFields?.paperType?.status === 'false' ||
              !fetchProduct?.customFields?.paperType?.fields ||
              fetchProduct?.customFields?.paperType?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Paper Type
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.paperType?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.paperType?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'paperType', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.card ||
              Object.keys(fetchProduct?.customFields?.card).length === 0 ||
              !fetchProduct?.customFields?.card?.status ||
              fetchProduct?.customFields?.card?.status === 'false' ||
              !fetchProduct?.customFields?.card?.fields ||
              fetchProduct?.customFields?.card?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Card
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.card?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.card?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'card', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.cardFinish ||
              Object.keys(fetchProduct?.customFields?.cardFinish).length === 0 ||
              !fetchProduct?.customFields?.cardFinish?.status ||
              fetchProduct?.customFields?.cardFinish?.status === 'false' ||
              !fetchProduct?.customFields?.cardFinish?.fields ||
              fetchProduct?.customFields?.cardFinish?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Card Finish
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.cardFinish?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.cardFinish?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'cardFinish', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.cardThickness ||
              Object.keys(fetchProduct?.customFields?.cardThickness).length === 0 ||
              !fetchProduct?.customFields?.cardThickness?.status ||
              fetchProduct?.customFields?.cardThickness?.status === 'false' ||
              !fetchProduct?.customFields?.cardThickness?.fields ||
              fetchProduct?.customFields?.cardThickness?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Card Thickness
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.cardThickness?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.cardThickness?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'cardThickness', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.cardType ||
              Object.keys(fetchProduct?.customFields?.cardType).length === 0 ||
              !fetchProduct?.customFields?.cardType?.status ||
              fetchProduct?.customFields?.cardType?.status === 'false' ||
              !fetchProduct?.customFields?.cardType?.fields ||
              fetchProduct?.customFields?.cardType?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Card Type
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.cardType?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.cardType?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'cardType', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.pasting ||
              Object.keys(fetchProduct?.customFields?.pasting).length === 0 ||
              !fetchProduct?.customFields?.pasting?.status ||
              fetchProduct?.customFields?.pasting?.status === 'false' ||
              !fetchProduct?.customFields?.pasting?.fields ||
              fetchProduct?.customFields?.pasting?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Pasting
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.pasting?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.pasting?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'pasting', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.printing ||
              Object.keys(fetchProduct?.customFields?.printing).length === 0 ||
              !fetchProduct?.customFields?.printing?.status ||
              fetchProduct?.customFields?.printing?.status === 'false' ||
              !fetchProduct?.customFields?.printing?.fields ||
              fetchProduct?.customFields?.printing?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Printing
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.printing?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.printing?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'printing', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.printingFrontBack ||
              Object.keys(fetchProduct?.customFields?.printingFrontBack).length === 0 ||
              !fetchProduct?.customFields?.printingFrontBack?.status ||
              fetchProduct?.customFields?.printingFrontBack?.status === 'false' ||
              !fetchProduct?.customFields?.printingFrontBack?.fields ||
              fetchProduct?.customFields?.printingFrontBack?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Printing Front-Back
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.printingFrontBack?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.printingFrontBack?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'printingFrontBack', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.printingSide ||
              Object.keys(fetchProduct?.customFields?.printingSide).length === 0 ||
              !fetchProduct?.customFields?.printingSide?.status ||
              fetchProduct?.customFields?.printingSide?.status === 'false' ||
              !fetchProduct?.customFields?.printingSide?.fields ||
              fetchProduct?.customFields?.printingSide?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Printing Side
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.printingSide?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.printingSide?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'printingSide', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.sides ||
              Object.keys(fetchProduct?.customFields?.sides).length === 0 ||
              !fetchProduct?.customFields?.sides?.status ||
              fetchProduct?.customFields?.sides?.status === 'false' ||
              !fetchProduct?.customFields?.sides?.fields ||
              fetchProduct?.customFields?.sides?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Sides
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.sides?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.sides?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'sides', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.sizes ||
              Object.keys(fetchProduct?.customFields?.sizes).length === 0 ||
              !fetchProduct?.customFields?.sizes?.status ||
              fetchProduct?.customFields?.sizes?.status === 'false' ||
              !fetchProduct?.customFields?.sizes?.fields ||
              fetchProduct?.customFields?.sizes?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Size
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.sizes?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.sizes?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'sizes', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.stickerType ||
              Object.keys(fetchProduct?.customFields?.stickerType).length === 0 ||
              !fetchProduct?.customFields?.stickerType?.status ||
              fetchProduct?.customFields?.stickerType?.status === 'false' ||
              !fetchProduct?.customFields?.stickerType?.fields ||
              fetchProduct?.customFields?.stickerType?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Sticker Type
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.stickerType?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.stickerType?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'stickerType', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.tShirtColor ||
              Object.keys(fetchProduct?.customFields?.tShirtColor).length === 0 ||
              !fetchProduct?.customFields?.tShirtColor?.status ||
              fetchProduct?.customFields?.tShirtColor?.status === 'false' ||
              !fetchProduct?.customFields?.tShirtColor?.fields ||
              fetchProduct?.customFields?.tShirtColor?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    T-Shirt Colors
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.tShirtColor?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.tShirtColor?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'tShirtColor', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.tShirtCut ||
              Object.keys(fetchProduct?.customFields?.tShirtCut).length === 0 ||
              !fetchProduct?.customFields?.tShirtCut?.status ||
              fetchProduct?.customFields?.tShirtCut?.status === 'false' ||
              !fetchProduct?.customFields?.tShirtCut?.fields ||
              fetchProduct?.customFields?.tShirtCut?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    T-Shirt Cut
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.tShirtCut?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.tShirtCut?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'tShirtCut', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.tShirtSize ||
              Object.keys(fetchProduct?.customFields?.tShirtSize).length === 0 ||
              !fetchProduct?.customFields?.tShirtSize?.status ||
              fetchProduct?.customFields?.tShirtSize?.status === 'false' ||
              !fetchProduct?.customFields?.tShirtSize?.fields ||
              fetchProduct?.customFields?.tShirtSize?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    T-Shirt Size
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.tShirtSize?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.tShirtSize?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'tShirtSize', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.types ||
              Object.keys(fetchProduct?.customFields?.types).length === 0 ||
              !fetchProduct?.customFields?.types?.status ||
              fetchProduct?.customFields?.types?.status === 'false' ||
              !fetchProduct?.customFields?.types?.fields ||
              fetchProduct?.customFields?.types?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Types
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.types?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.types?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'types', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.customFields ||
              Object.keys(fetchProduct?.customFields).length === 0 ||
              !fetchProduct?.customFields?.waxSeal ||
              Object.keys(fetchProduct?.customFields?.waxSeal).length === 0 ||
              !fetchProduct?.customFields?.waxSeal?.status ||
              fetchProduct?.customFields?.waxSeal?.status === 'false' ||
              !fetchProduct?.customFields?.waxSeal?.fields ||
              fetchProduct?.customFields?.waxSeal?.fields.length === 0 ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Wax Seal
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    {fetchProduct?.customFields?.waxSeal?.fields.map((resItem, index) =>
                      !resItem?.title ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <button
                            className={
                              values?.customFields?.waxSeal?.title === resItem?.title
                                ? 'custon-product-border'
                                : 'custon-product-checkbox'
                            }
                            onClick={() => handleSelectedItem('customFields', 'waxSeal', resItem)}
                          >
                            {!resItem?.itemStatus ||
                            resItem?.itemStatus === 'Select Recommand Status' ||
                            resItem?.itemStatus === 'No' ? null : (
                              <p className="custon-product-checkbox-status">Recommended</p>
                            )}
                            <p className="custon-product-checkbox-text">{resItem?.title}</p>
                          </button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.booleanFields ||
              Object.keys(fetchProduct?.booleanFields).length === 0 ||
              !fetchProduct?.booleanFields?.lamination ||
              fetchProduct?.booleanFields?.lamination === 'false' ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Limination
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <button
                        className={
                          values?.booleanFields?.lamination === 'Yes'
                            ? 'custon-product-border'
                            : 'custon-product-checkbox'
                        }
                        onClick={() => {
                          if (values?.booleanFields?.lamination === 'Yes') {
                            handleSelectedItem('booleanFields', 'lamination', '');
                          } else {
                            handleSelectedItem('booleanFields', 'lamination', 'Yes');
                          }
                        }}
                      >
                        <p className="custon-product-checkbox-text">Yes</p>
                      </button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <button
                        className={
                          values?.booleanFields?.lamination === 'No'
                            ? 'custon-product-border'
                            : 'custon-product-checkbox'
                        }
                        onClick={() => {
                          if (values?.booleanFields?.lamination === 'No') {
                            handleSelectedItem('booleanFields', 'lamination', '');
                          } else {
                            handleSelectedItem('booleanFields', 'lamination', 'No');
                          }
                        }}
                      >
                        <p className="custon-product-checkbox-text">No</p>
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.booleanFields ||
              Object.keys(fetchProduct?.booleanFields).length === 0 ||
              !fetchProduct?.booleanFields?.tissueFilling ||
              fetchProduct?.booleanFields?.tissueFilling === 'false' ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Tissue Filling
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <button
                        className={
                          values?.booleanFields?.tissueFilling === 'Yes'
                            ? 'custon-product-border'
                            : 'custon-product-checkbox'
                        }
                        onClick={() => {
                          if (values?.booleanFields?.tissueFilling === 'Yes') {
                            handleSelectedItem('booleanFields', 'tissueFilling', '');
                          } else {
                            handleSelectedItem('booleanFields', 'tissueFilling', 'Yes');
                          }
                        }}
                      >
                        <p className="custon-product-checkbox-text">Yes</p>
                      </button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <button
                        className={
                          values?.booleanFields?.tissueFilling === 'No'
                            ? 'custon-product-border'
                            : 'custon-product-checkbox'
                        }
                        onClick={() => {
                          if (values?.booleanFields?.tissueFilling === 'No') {
                            handleSelectedItem('booleanFields', 'tissueFilling', '');
                          } else {
                            handleSelectedItem('booleanFields', 'tissueFilling', 'No');
                          }
                        }}
                      >
                        <p className="custon-product-checkbox-text">No</p>
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.booleanFields ||
              Object.keys(fetchProduct?.booleanFields).length === 0 ||
              !fetchProduct?.booleanFields?.mobileModel ||
              fetchProduct?.booleanFields?.mobileModel === 'false' ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    Mobile Model
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        placeholder="Enter Mobile Model"
                        fullWidth
                        value={values?.booleanFields?.mobileModel || ''}
                        onChange={(e) => {
                          handleSelectedItem('booleanFields', 'mobileModel', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.objectFields ||
              Object.keys(fetchProduct?.objectFields).length === 0 ||
              !fetchProduct?.objectFields?.qty ||
              Object.keys(fetchProduct?.objectFields?.qty).length === 0 ||
              !fetchProduct?.objectFields?.qty?.status ||
              fetchProduct?.objectFields?.qty?.status === 'false' ? null : (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" py={1}>
                    QTY
                  </Typography>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values?.cartQuantity}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            cartQuantity: e.target.value,
                          });
                        }}
                        fullWidth
                      >
                        {!fetchProduct ||
                        Object.keys(fetchProduct).length === 0 ||
                        !fetchProduct?.objectFields?.qty ||
                        !fetchProduct?.objectFields?.qty?.fields
                          ? null
                          : Array.from(
                              {
                                length:
                                  Math.floor(
                                    (Number(
                                      !fetchProduct?.objectFields?.qty?.fields?.endRange
                                        ? 0
                                        : fetchProduct?.objectFields?.qty?.fields?.endRange
                                    ) -
                                      Number(
                                        !fetchProduct?.objectFields?.qty?.fields?.startRange
                                          ? 0
                                          : fetchProduct?.objectFields?.qty?.fields?.startRange
                                      )) /
                                      Number(
                                        !fetchProduct?.objectFields?.qty?.fields?.difference
                                          ? 0
                                          : fetchProduct?.objectFields?.qty?.fields?.difference
                                      )
                                  ) + 1,
                              },
                              (_, index) => {
                                const qtyItem =
                                  Number(
                                    !fetchProduct?.objectFields?.qty?.fields?.startRange
                                      ? 0
                                      : fetchProduct?.objectFields?.qty?.fields?.startRange
                                  ) +
                                  index *
                                    Number(
                                      !fetchProduct?.objectFields?.qty?.fields?.difference
                                        ? 0
                                        : fetchProduct?.objectFields?.qty?.fields?.difference
                                    );
                                return (
                                  <MenuItem key={qtyItem} value={qtyItem}>
                                    {qtyItem}
                                  </MenuItem>
                                );
                              }
                            )}
                      </Select>
                    </Grid>
                  </Grid>
                  {!fetchProduct ||
                  Object.keys(fetchProduct).length === 0 ||
                  !fetchProduct?.booleanFields ||
                  Object.keys(fetchProduct?.booleanFields).length === 0 ||
                  !fetchProduct?.booleanFields?.qtyCaption ||
                  fetchProduct?.booleanFields?.qtyCaption === 'false' ? null : (
                    <Typography variant="body1">
                      Please order at least {!values?.cartQuantity ? 1 : values?.cartQuantity} quantity to avail
                      attractive discounts
                    </Typography>
                  )}
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.booleanFields ||
              Object.keys(fetchProduct?.booleanFields).length === 0 ||
              !fetchProduct?.booleanFields?.inquiryForm ||
              fetchProduct?.booleanFields?.inquiryForm === 'false' ? null : (
                <Grid item xs={12} md={7}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h5" py={1}>
                        Inquiry Form
                      </Typography>
                      <Typography variant="body2" py={1}>
                        Enter details below
                      </Typography>
                      <Grid container spacing={1} alignItems={'flex-end'}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" py={1}>
                            YOUR NAME
                          </Typography>
                          <TextField
                            placeholder="ENTER your Name"
                            fullWidth
                            value={values?.booleanFields?.inquiryForm?.fullName || ''}
                            onChange={(e) => {
                              setValues((prevValues) => ({
                                ...prevValues,
                                booleanFields: {
                                  ...prevValues.booleanFields,
                                  inquiryForm: {
                                    ...prevValues.booleanFields.inquiryForm,
                                    fullName: e.target.value,
                                  },
                                },
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" py={1}>
                            EMAIL ADDRESS
                          </Typography>
                          <TextField
                            placeholder="ENTER EMAIL ADDRESS"
                            fullWidth
                            type="email"
                            value={values?.booleanFields?.inquiryForm?.emailAddress || ''}
                            onChange={(e) => {
                              setValues((prevValues) => ({
                                ...prevValues,
                                booleanFields: {
                                  ...prevValues.booleanFields,
                                  inquiryForm: {
                                    ...prevValues.booleanFields.inquiryForm,
                                    emailAddress: e.target.value,
                                  },
                                },
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" py={1}>
                            PHONE NUMBER
                          </Typography>
                          <TextField
                            placeholder="ENTER PHONE NUMBER"
                            fullWidth
                            type="number"
                            value={values?.booleanFields?.inquiryForm?.phoneNo || ''}
                            onChange={(e) => {
                              setValues((prevValues) => ({
                                ...prevValues,
                                booleanFields: {
                                  ...prevValues.booleanFields,
                                  inquiryForm: {
                                    ...prevValues.booleanFields.inquiryForm,
                                    phoneNo: e.target.value,
                                  },
                                },
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" py={1}>
                            YOUR QUERY
                          </Typography>
                          <TextField
                            placeholder="ENTER YOUR QUERY"
                            multiline
                            rows={5}
                            fullWidth
                            value={values?.booleanFields?.inquiryForm?.yourQuery || ''}
                            onChange={(e) => {
                              setValues((prevValues) => ({
                                ...prevValues,
                                booleanFields: {
                                  ...prevValues.booleanFields,
                                  inquiryForm: {
                                    ...prevValues.booleanFields.inquiryForm,
                                    yourQuery: e.target.value,
                                  },
                                },
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button className="custom-product-detail" fullWidth onClick={inquiryFormSubmit}>
                            SUBMIT
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.booleanFields ||
              Object.keys(fetchProduct?.stringFields).length === 0 ||
              !fetchProduct?.stringFields?.productDescription ||
              Object.keys(fetchProduct?.stringFields?.productDescription).length === 0 ||
              !fetchProduct?.stringFields?.productDescription?.status ? null : (
                <Grid item xs={12} sm={12} md={11}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={11}>
                      <Typography variant="subtitle2">Product Description</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={11}>
                      <div
                        dangerouslySetInnerHTML={{ __html: fetchProduct?.stringFields?.productDescription?.value }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.booleanFields ||
              Object.keys(fetchProduct?.booleanFields).length === 0 ||
              !fetchProduct?.booleanFields?.addToCartBtn ||
              fetchProduct?.booleanFields?.addToCartBtn === 'false' ? null : (
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    <Grid item xs={6}>
                      <Button
                        className="custom-product-detail"
                        fullWidth
                        onClick={() => {
                          handleAddToCart();
                          getDetails();
                        }}
                      >
                        Add to Cart
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!fetchProduct ||
              Object.keys(fetchProduct).length === 0 ||
              !fetchProduct?.booleanFields ||
              Object.keys(fetchProduct?.booleanFields).length === 0 ||
              !fetchProduct?.booleanFields?.qrCodeButton ||
              fetchProduct?.booleanFields?.qrCodeButton === 'false' ? null : (
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems={'flex-end'}>
                    <Grid item xs={12} md={6}>
                      <Button className="custom-product-detail" onClick={handleQRCodeSubmit} fullWidth>
                        Generate QR Code
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {!fetchProduct ||
          Object.keys(fetchProduct).length === 0 ||
          !fetchProduct?.booleanFields ||
          Object.keys(fetchProduct?.stringFields).length === 0 ||
          !fetchProduct?.stringFields?.description ||
          Object.keys(fetchProduct?.stringFields?.description).length === 0 ||
          !fetchProduct?.stringFields?.description?.status ||
          fetchProduct?.stringFields?.description?.status === 'false' ? null : (
            <Grid item xs={12} sm={12} md={11}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={11}>
                  <Typography variant="subtitle2">Description</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={11}>
                  <div dangerouslySetInnerHTML={{ __html: fetchProduct?.stringFields?.description?.value }} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default CustomProductDetailPage;
