import { callAPi } from './job-http-common';

const login = (data) => callAPi.post('/jobPortal/login', data);
const register = (data) => callAPi.post('/jobPortal/register', data);

const getUser = () => callAPi.get('/jobPortal/localGetProfileById');
const update = (data) => callAPi.patch(`/jobPortal/localUpdateProfile`, data);
const forgetPassword = (data) => callAPi.post('/jobPortal/forgotPassword', data);
const otpForgetPassword = (data) => callAPi.post('/jobPortal/OTPVerfication', data);
const resetPassword = (data) => callAPi.post('/jobPortal/resetPassword', data);
const addExperience = (data) => callAPi.post('/jobPortal/addExperience', data);

const JobUserServices = {
  login,
  register,
  getUser,
  update,
  forgetPassword,
  otpForgetPassword,
  resetPassword,
  addExperience
};

export default JobUserServices;
