import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
// components
import Iconify from '../../../../components/iconify';
import { jobsuserRegister } from '../../../redux/slice/jobsUserSlice';

// ----------------------------------------------------------------------

export default function JobsRegisterForm() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [fName, setFName] = useState(null);
  const [LastName, setLastName] = useState(null);
  const [email, setemail] = useState(null);
  const [Phone, setPhone] = useState(null);
  const [CNIC, setCNIC] = useState(null);
  const [BankAccountName, setBankAccountName] = useState(null);
  const [BankAccountNumber, setBankAccountNumber] = useState('');
  const [password, setpassword] = useState(null);
  const [Address, setAddress] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = async () => {
    if (
      !email ||
      !password ||
      !fName ||
      !LastName ||
      !Phone ||
      !CNIC ||
      !BankAccountName ||
      !BankAccountNumber ||
      !password ||
      !Address
    ) {
      toast.error('Please enter all fields');
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (fName.length < 3) {
      toast.error('Please enter more then 3 Characters');
      return;
    }
    if (LastName.length < 3) {
      toast.error('Please enter more then 3 Characters');
      return;
    }
    if (!emailPattern.test(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }
    if (password.length < 6) {
      toast.error('Please enter password greater than 6 characters');
      return;
    }
    if (CNIC.length < 13) {
      toast.error('Please enter 13 numbers for the cnic.');
      return;
    }
    const numberPattern = /^\d+$/;
    if (!numberPattern.test(CNIC)) {
      toast.error('Please enter a valid number for the CNIC.');
      return;
    }
    if (!numberPattern.test(Phone)) {
      toast.error('Please enter a valid number for the Phone.');
      return;
    }
    if (Phone.length < 11) {
      toast.error('Please enter 11 numbers for the Phone.');
      return;
    }
    if (BankAccountNumber.length < 24) {
      toast.error('Please enter 24 numbers for the Bank Account IBAN Number.');
      return;
    }
    const specialSymbolPattern = /^[a-zA-Z0-9]*$/;
    if (!specialSymbolPattern.test(BankAccountNumber)) {
      toast.error('Please enter a valid bank account IBAN number');
      return;
    }
    const data = {
      first_name: fName,
      last_name: LastName,
      phone: Phone,
      bank_name: BankAccountName,
      bank_account_number: BankAccountNumber,
      cnic: CNIC,
      role:'freelancer',
      address: Address,
      email,
      password,
      confirmpassword: password,
    };
    try {
      const result = await dispatch(jobsuserRegister(data));
      if (result.payload.success === true) {
        toast.success(result.payload?.message)
        navigate('/jobs/login');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField label="First Name" required onChange={(e) => setFName(e.target.value)} />
        <TextField label="Last Name" required onChange={(e) => setLastName(e.target.value)} />

        <TextField name="email" label="Email address" required onChange={(e) => setemail(e.target.value)} />

        <TextField label="Phone" type="number" required onChange={(e) => setPhone(e.target.value)} />
        <TextField label="CNIC" required onChange={(e) => setCNIC(e.target.value)} />
        <TextField label="Bank Account Name" required onChange={(e) => setBankAccountName(e.target.value)} />
        <TextField
          label={`Bank Account IBAN Number without dash (${!BankAccountNumber ? 0 : BankAccountNumber.length})`}
          required
          onChange={(e) => setBankAccountNumber(e.target.value)}
        />
        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setpassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField label="Address" multiline rows={5} required onChange={(e) => setAddress(e.target.value)} />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        className="green-job-btn"
        sx={{ marginTop: '15px' }}
        onClick={handleClick}
      >
        Register
      </LoadingButton>
    </>
  );
}
