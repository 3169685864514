import { callAPi } from './http-common';

const add = (data) => callAPi.post('/freelaningJob/addFreelancingJob', data);
const deletes = (id) => callAPi.post(`/freelaningJob/deleteFreelancingJobById/${id}`);
const get = () => callAPi.get('/freelaningJob/authGetAllFreelancingJobs');
const getbyId = (id) => callAPi.get(`/freelaningJob/getFreelancingJobDetailsById/${id}`);
const update = (id, data) => callAPi.post(`/freelaningJob/updateFreelancingJobById/${id}`, data);
const status = (id) => callAPi.patch(`/changeCustomDesignStatus/${id}`);

const FreelanceJobService = {
  add,
  deletes,
  get,
  update,
  status,
  getbyId,
};

export default FreelanceJobService;
