import React,{ useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

const ThanksPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="content top-container">
      <Helmet>
        <title>Thank you | PakPrint Wishes </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="wrapper-1">
        <div className="wrapper-2">
          <h1>Thank you !</h1>
          <p>Thanks for add Get Quote.</p>
          <p>you should receive a email</p>
          <button
            className="go-home"
            onClick={() => {
              navigate("/");
            }}
          >
            go home
          </button>
        </div>
        <div className="footer-like">
          <p>
            Email not received?
            <Link to="/get-quote">Click here to send again</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThanksPage;
