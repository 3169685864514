import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import CustomLayout from './layouts/custom';
//
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import ParentCategory from './pages/ParentCategory';
import SubCategoryPage from './pages/SubCategoryPage';
import PartnerPage from './pages/PartnerPage';
import ServicesPage from './pages/ServicesPage';
import ContactPage from './pages/ContactPage';
import SliderPage from './pages/SliderPage';
import GetQuoteRequest from './pages/GetQuoteRequest';
import BrandsPage from './pages/BrandsPage';
import VendorPage from './pages/VendorPage';
import JobsPage from './pages/JobsPage';
import ProductsPage from './pages/ProductsPage';
import AppliedJobsPage from './pages/AppliedJobsPage';
import OrderProductsPage from './pages/OrderProductsPage';
import RegisterPage from './pages/RegisterPage';
import ForgetPasswordPage from './pages/ForgetPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import OTPPage from './pages/OTPPage';
import ChatPage from './pages/ChatPage';
import MessagePage from './pages/MessagePage';
import ProfilePage from './pages/ProfilePage';
import PaymentPage from './pages/PaymentPage';
import VendorPaymentHistoryPage from './pages/VendorPaymentHistoryPage';
import PaymentHistoryByIDPage from './pages/PaymentHistoryByIDPage';
import GetQuoteDetails from './components/Dialog/Details/GetQuoteDetails';
import VendorOrderDeatail from './components/Dialog/Details/VendorOrderDeatail';
import GalleyCategoryPage from './pages/GalleyCategoryPage';
import GalleryImagesPage from './pages/GalleryImagesPage';
import PrintOrderPage from './pages/PrintOrderPage';
import ProductDetails from './components/Dialog/Details/ProductDetails';
import HomePage from './client/Pages/HomePage';
import FeaturedProductPage from './client/Pages/FeaturedProductPage';
import ProductDetail from './client/Pages/FeaturedProducts/ProductDetail';
import ProductAccessoriesDetails from './client/Pages/FeaturedProducts/ProductAccessoriesDetails';
import PrintingAccessoriesPage from './client/Pages/PrintingAccessoriesPage';
import NewArrivalsPage from './client/Pages/NewArrivalsPage';
import AllCategoreyPage from './client/Pages/AllCategoreyPage';
import CategoryDetail from './client/Pages/Categories/CategoryDetail';
import ShopPage from './client/Pages/ShopPage';
import AboutUsPage from './client/Pages/AboutUsPage';
import ContactPages from './client/Pages/ContactPages';
import GetQuotePage from './client/Pages/GetQuotePage';
import ServicesPages from './client/Pages/ServicesPages';
import LoginPages from './client/Pages/User/LoginPages';
import SignUpPage from './client/Pages/User/SignUpPage';
import ForgotPasswordPages from './client/Pages/User/ForgotPasswordPages';
import ProfilePages from './client/Pages/ProfilePages/ProfilePages';
import OrderHistoryPage from './client/Pages/ProfilePages/OrderHistoryPage';
import MyWishListCard from './client/Pages/ProfilePages/MyWishListCard';
import CareersPage from './client/Pages/Careers/CareersPage';
import NewPasswordPages from './client/Pages/User/NewPasswordPages';
import ThanksPage from './client/Pages/ThanksPage';
import CareersDetailsPage from './client/Pages/Careers/CareersDetailsPage';
import CheckoutPage from './client/Pages/Products/CheckoutPage';
import AddToCartPage from './client/Pages/Products/AddToCartPage';
import ConfirmOrderPage from './client/Pages/Products/ConfirmOrderPage';
import PaymentMethodPage from './client/Pages/Products/PaymentMethodPage';
import TermAndConditionPage from './client/Pages/TermAndConditionPage';
import PrivacyPolicyPage from './client/Pages/PrivacyPolicyPage';
import DeletePolicy from './client/Pages/DeletePolicy';
import CategoryPage from './client/Pages/CategoryPage';
import ProductSEOPage from './pages/ProductSEOPage';
import ReviewPage from './pages/ReviewPage';
import VendorPayment from './pages/VendorPayment';
import PayementTransactionOreders from './pages/PayementTransactionOreders';
import PaymentHistoryPrint from './pages/PaymentHistoryPrint';
import ParentCategorySEOPage from './pages/ParentCategorySEOPage';
import ChildCategorySEOPage from './pages/ChildCategorySEOPage';
import VendorDetails from './components/Dialog/Details/VendorDetails';
import CustomParentCategoryPage from './pages/CustomParentCategoryPage';
import CustomParentCategorySEOPage from './pages/CustomParentCategorySEOPage';
import CustomSubCategoryPage from './pages/CustomSubCategoryPage';
import CustomSubCategorySEOPage from './pages/CustomSubCategorySEOPage';
import CustomProductSEOPage from './pages/CustomProductSEOPage';
import CustomProductsPage from './pages/CustomProductsPage';
import AllCustomProductPage from './pages/clientpage/AllCustomProductPage';
import CustomParentProductPage from './pages/clientpage/CustomParentProductPage';
import CustomChildProductPage from './pages/clientpage/CustomChildProductPage';
import CustomProductDetailPage from './pages/clientpage/CustomProductDetailPage';
import CustomProductInquireFormPage from './pages/CustomProductInquireFormPage';
import CustomProductDetail from './components/Dialog/Details/CustomProductDetail';
import BlogPostPage from './pages/BlogPostPage';
import CustomProductOrderDetailPage from './pages/CustomProductOrderDetailPage';
import AllBlogPostPage from './pages/clientpage/AllBlogPostPage';
import PublicBlogDetailPage from './pages/clientpage/PublicBlogDetailPage';
import VendorOrderTabs from './pages/VendorOrderTabs';
import OrderTabs from './pages/OrderTabs';
import JobsLayout from './layouts/jobs/JobsLayout';
import JobPortalLayout from './layouts/jobs/JobPortalLayout';
import FindJobsPages from './jobs/pages/FindJobsPages';
import JobsLoginPage from './jobs/pages/JobsLoginPage';
import JobsRegisterPage from './jobs/pages/JobsRegisterPage';
import JobsForgetPasswordPage from './jobs/pages/JobsForgetPasswordPage';
import JobsResetPasswordPage from './jobs/pages/JobsResetPasswordPage';
import FreelanceJobsPage from './jobs/pages/FreelanceJobsPage';
import SavedJobsPages from './jobs/pages/SavedJobsPages';
import ProposalsPage from './jobs/pages/ProposalsPage';
import JobDetailPage from './jobs/pages/JobDetailPage';
import SavedJobDetailPage from './jobs/pages/SavedJobDetailPage';
import CompleteJobsPage from './jobs/pages/CompleteJobsPage';
import JobsPaymentPage from './jobs/pages/JobsPaymentPage';
import FreelanceJobPage from './pages/FreelanceJobPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <SimpleLayout />,
      children: [
        { path: '/', element: <HomePage /> },
        { path: '/featured-products', element: <FeaturedProductPage /> },

        {
          path: '/products/:title/:id',
          element: <ProductDetail />,
        },
        { path: '/product-accessories/:title/:id', element: <ProductAccessoriesDetails /> },
        { path: '/printing-accessories', element: <PrintingAccessoriesPage /> },
        { path: '/new-arrivals', element: <NewArrivalsPage /> },
        { path: '/all-categories', element: <AllCategoreyPage /> },
        { path: '/category/:title/:id', element: <CategoryDetail /> },
        { path: '/art-print-&-stationery', element: <ShopPage /> },
        { path: '/about-us', element: <AboutUsPage /> },
        { path: '/contact', element: <ContactPages /> },
        { path: '/get-quote', element: <GetQuotePage /> },
        { path: '/services', element: <ServicesPages /> },
        { path: '/login', element: <LoginPages /> },
        { path: '/signup', element: <SignUpPage /> },
        { path: '/forgot-password', element: <ForgotPasswordPages /> },
        { path: '/profile', element: <ProfilePages /> },
        { path: '/order-history', element: <OrderHistoryPage /> },
        { path: '/wishlist', element: <MyWishListCard /> },
        { path: '/careers', element: <CareersPage /> },
        { path: '/reset-password', element: <NewPasswordPages /> },
        { path: '/success', element: <ThanksPage /> },
        { path: '/careers/careers-details/:id', element: <CareersDetailsPage /> },
        { path: '/checkout', element: <CheckoutPage /> },
        { path: '/cart', element: <AddToCartPage /> },
        { path: '/confirm-order/:id', element: <ConfirmOrderPage /> },
        { path: '/payment', element: <PaymentMethodPage /> },
        { path: '/terms&condition', element: <TermAndConditionPage /> },
        { path: '/blogs', element: <AllBlogPostPage /> },
        { path: '/blogs/:title/:id', element: <PublicBlogDetailPage /> },
        { path: '/policy', element: <PrivacyPolicyPage /> },
        { path: '/delete-account', element: <DeletePolicy /> },
        { path: '/category', element: <CategoryPage /> },
        { path: '/custom-design/product/:title/:id', element: <CustomProductDetailPage /> },
        { path: '/custom-design/all/product/:title/:id', element: <CustomProductDetailPage /> },
        { path: '/custom-design/:parentTitle/product/:title/:id', element: <CustomProductDetailPage /> },
        { path: '/custom-design/:parentTitle/:childTitle/product/:title/:id', element: <CustomProductDetailPage /> },
        {
          path: '/custom-design',
          element: <CustomLayout />,
          children: [
            { path: '', element: <AllCustomProductPage /> },
            { path: 'all', element: <AllCustomProductPage /> },
            { path: ':title/:id', element: <CustomParentProductPage /> },
            { path: ':parentTitle/:title/:id', element: <CustomChildProductPage /> },
          ],
        },
      ],
    },
    {
      path: '/jobs',
      element: <JobsLayout />,
      children: [
        {
          path: '',
          element: <JobPortalLayout />,
          children: [
            { path: 'find-jobs', element: <FindJobsPages /> },
            { path: 'find-jobs/:title/:id', element: <FreelanceJobsPage /> },
            { path: 'saved-jobs', element: <SavedJobsPages /> },
            { path: 'saved-jobs/:title/:id', element: <SavedJobDetailPage /> },
            { path: 'detail/:title/:id', element: <JobDetailPage /> },
            { path: 'proposals', element: <ProposalsPage /> },
          ],
        },
        { path: 'user-jobs', element: <CompleteJobsPage /> },
        { path: 'job-payment', element: <JobsPaymentPage /> },
        { path: 'login', element: <JobsLoginPage /> },
        { path: 'register', element: <JobsRegisterPage /> },
        { path: 'forget-password', element: <JobsForgetPasswordPage /> },
        { path: 'reset-password', element: <JobsResetPasswordPage /> },
      ],
    },
    { path: '404', element: <Page404 /> },
    { path: '*', element: <Navigate to="/404" /> },
    { path: '/admin-forget-password', element: <ForgetPasswordPage /> },
    { path: '/admin-reset-password', element: <ResetPasswordPage /> },
    {
      path: '/register',
      element: <RegisterPage />,
    },
    { path: 'verify-otp', element: <OTPPage /> },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'review', element: <ReviewPage /> },
        { path: 'parent-category', element: <ParentCategory /> },
        { path: 'sub-category', element: <SubCategoryPage /> },
        { path: 'partner', element: <PartnerPage /> },
        { path: 'services', element: <ServicesPage /> },
        { path: 'contact', element: <ContactPage /> },
        { path: 'slider', element: <SliderPage /> },
        { path: 'get-quote', element: <GetQuoteRequest /> },
        { path: 'get-quote-details/:id', element: <GetQuoteDetails /> },
        { path: 'brands', element: <BrandsPage /> },
        { path: 'blog-post', element: <BlogPostPage /> },
        { path: 'vendors', element: <VendorPage /> },
        { path: 'vendors/payment', element: <VendorPayment /> },
        { path: 'jobs', element: <JobsPage /> },
        { path: 'jobs/applied-jobs/:id', element: <AppliedJobsPage /> },
        { path: 'orders', element: <OrderTabs /> },
        { path: 'orders/order-products/:id', element: <OrderProductsPage /> },
        { path: 'orders/print-order/:id', element: <PrintOrderPage /> },
        { path: 'custom-product-orders/custom-product-order-products/:id', element: <CustomProductOrderDetailPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'product-seo-page', element: <ProductSEOPage /> },
        { path: 'products/product-detail/:id', element: <ProductDetails /> },
        { path: 'vendor-orders', element: <VendorOrderTabs /> },
        { path: 'vendor-order-details/:id', element: <VendorOrderDeatail /> },
        { path: 'chat', element: <ChatPage /> },
        { path: 'chat/messages/:id', element: <MessagePage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'payments', element: <PaymentPage /> },
        { path: 'payments-history', element: <VendorPaymentHistoryPage /> },
        { path: 'payments/history/:id', element: <PaymentHistoryByIDPage /> },
        { path: 'payments/history/print/:id', element: <PaymentHistoryPrint /> },
        { path: 'payments/history/byTransaction', element: <PayementTransactionOreders /> },
        { path: 'gallery', element: <GalleyCategoryPage /> },
        { path: 'gallery-images/:id', element: <GalleryImagesPage /> },
        { path: 'parent-category-seo', element: <ParentCategorySEOPage /> },
        { path: 'sub-category-seo', element: <ChildCategorySEOPage /> },
        { path: 'vendor-detail/:id', element: <VendorDetails /> },
        { path: 'custom-parent-category', element: <CustomParentCategoryPage /> },
        { path: 'custom-parent-category-seo', element: <CustomParentCategorySEOPage /> },
        { path: 'custom-sub-category', element: <CustomSubCategoryPage /> },
        { path: 'custom-sub-category-seo', element: <CustomSubCategorySEOPage /> },
        { path: 'custom-product-seo', element: <CustomProductSEOPage /> },
        { path: 'custom-product', element: <CustomProductsPage /> },
        { path: 'custom-product/custom-product-detail/:id', element: <CustomProductDetail /> },
        { path: 'custom-design-inquire-form', element: <CustomProductInquireFormPage /> },
        { path: 'freelance-jobs', element: <FreelanceJobPage /> },
      ],
    },
    {
      path: 'admin-login',
      element: <LoginPage />,
    },

    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
