import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ChatService from '../api/ChatService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getChatOrderMethod = createAsyncThunk(`/get-all-chat`, async () => {
  try {
    const { data } = await ChatService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deletChatById = createAsyncThunk(`/delete-chat`, async (data) => {
  try {
    const res = await ChatService.deletChatById(data);
    return res.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const multipledeletChat = createAsyncThunk(`/multipledeletChat`, async (data) => {
  try {
    const res = await ChatService.multipledeletChat(data);
    return res.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const chatSlice = createSlice({
  name: 'chatSlice',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getChatOrderMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getChatOrderMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getChatOrderMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    [deletChatById.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deletChatById.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
    },
    [deletChatById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [multipledeletChat.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [multipledeletChat.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
    },
    [multipledeletChat.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default chatSlice.reducer;
