import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import OrderService from '../api/OrderService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getOrderMethod = createAsyncThunk(`/get-all-order`, async () => {
  try {
    const { data } = await OrderService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getOrderDeliverMethod = createAsyncThunk(`/get-all-getDeliver`, async () => {
  try {
    const { data } = await OrderService.getDeliver();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getOrderPendingMethod = createAsyncThunk(`/get-all-getPending`, async () => {
  try {
    const { data } = await OrderService.getPending();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getOrderConfirmMethod = createAsyncThunk(`/get-all-getConfirm`, async () => {
  try {
    const { data } = await OrderService.getConfirm();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getOrderCompleteMethod = createAsyncThunk(`/get-all-getComplete`, async () => {
  try {
    const { data } = await OrderService.getComplete();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getOrderCancelledMethod = createAsyncThunk(`/get-all-getConcelled`, async () => {
  try {
    const { data } = await OrderService.getConcelled();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getDeletedOrderMethod = createAsyncThunk(`/get-deleted-order`, async (id) => {
  try {
    const { data } = await OrderService.getAllDeleted(id);
    console.warn(data);
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteSelectedPermanentOrderMethod = createAsyncThunk(`/delete-multiple-order`, async (id) => {
  try {
    const { data } = await OrderService.deletePermanentMultiple(id);
    console.warn(data);
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getOrderMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getOrderMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getOrderMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get Pending
    [getOrderPendingMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getOrderPendingMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getOrderPendingMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get deliver
    [getOrderDeliverMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getOrderDeliverMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getOrderDeliverMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get confirm
    [getOrderConfirmMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getOrderConfirmMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getOrderConfirmMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get complete
    [getOrderCompleteMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getOrderCompleteMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getOrderCompleteMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get cancelled
    [getOrderCancelledMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getOrderCancelledMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getOrderCancelledMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get deleted
    [getDeletedOrderMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getDeletedOrderMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getDeletedOrderMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete permanent Multiple
    [deleteSelectedPermanentOrderMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedPermanentOrderMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedPermanentOrderMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default orderSlice.reducer;
