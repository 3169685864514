import http from '../../client/redux/api/http-common';
import { callAPi } from './http-common';

const add = (data) => {
  return http.post('/createInquiryForm', data);
};
const deletes = (id) => {
  return callAPi.patch(`/deleteInquiryForm/${id}`);
};
const get = () => {
  return callAPi.get('/getAllInquiryForms');
};
const getbyId = (id) => {
  return callAPi.get(`/getCustomParentCategoryById/${id}`);
};
const status = (id) => {
  return callAPi.patch(`/changeInquiryFormStatus/${id}`);
};
const CustomProductInquiryService = {
  add,
  deletes,
  get,
  getbyId,
  status,
};

export default CustomProductInquiryService;
