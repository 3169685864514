import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
// components
import Scrollbar from '../components/scrollbar';
// sections
import { UserListToolbar } from '../sections/@dashboard/user';
// mock
import OrderProductListHead from '../sections/@dashboard/user/OrderProductListHead';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'last_name', label: 'Last Name', alignRight: false },
  { id: 'orderId', label: 'Order Id', alignRight: false },
  { id: 'payment_method', label: 'Payment Method', alignRight: false },
  { id: 'delivery_address', label: 'Delivery Address', alignRight: false },
  { id: 'total_shipping_fee', label: 'Total Shipping Fee', alignRight: false },
  { id: 'sub_total', label: 'Sub total', alignRight: false },
  { id: 'all_totals', label: 'All Totals', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.TID.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PayementTransactionOreders() {
  const location = useLocation();
  const { data } = location.state;

  //   const data = useSelector((state) => state.paymentHistoryByID);

  //   useEffect(() => {
  //     dispatch(getpaymentHistoryByIDMethod(id));
  //   }, [dispatch, id]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    data !== null && data !== undefined ? data : [],
    getComparator(order, orderBy),
    filterName
  );


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers?.length - page * rowsPerPage);

  const isNotFound = !filteredUsers?.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Payments History | PakPrint Wishes </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Orders Details By This Transaction ({location?.state?.TID})
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrderProductListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id } = row;

                    return (
                      <TableRow hover key={_id}>
                        <TableCell component="th" scope="row">
                          <Stack paddingX={2} direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {!row?.order?.name ? 'N/A' : row?.order?.name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{!row?.order?.orderId ? 'N/A' : row?.order?.orderId}</TableCell>

                        <TableCell align="left">
                          {!row?.order?.payment_method ? 'N/A' : row?.order?.payment_method}
                        </TableCell>
                        <TableCell align="left">
                          {!row?.order?.delivery_address ? 'N/A' : row?.order?.delivery_address}
                        </TableCell>
                        <TableCell align="left">
                          {!row?.order?.total_shipping_fee ? 'N/A' : row?.order?.total_shipping_fee}
                        </TableCell>
                        <TableCell align="left">{!row?.order?.sub_total ? 'N/A' : row?.order?.sub_total}</TableCell>
                        <TableCell align="left">{!row?.order?.all_totals ? 'N/A' : row?.order?.all_totals}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
