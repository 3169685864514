import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
//
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { retrieveFeaturedProductServices } from '../../redux/slice/featuredProductSlice';
//
import ProductCard from '../../Components/ProductCard';
import ProgressBar from '../../../components/progress/ProgressBar';

const FeaturedProducts = () => {
  const dispatch = useDispatch();

  const cachedData = useSelector((state) => state.featuredProduct);
  const data = React.useMemo(() => cachedData, [cachedData]);
  const initFetch = React.useCallback(() => {
    dispatch(retrieveFeaturedProductServices());
    // console.log(data)
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  return (
    <Box>
      {data?.loading && <ProgressBar />}
      {data.data === null || data.data === undefined || data.data.length === 0 ? null : (
        <Grid container spacing={2} className="featured-products-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="featured-products-header-container">
            <Typography variant="h1" className="featured-products-header-text">
              Featured Products
            </Typography>
            <Link to={'featured-products'} className="featured-products-header-link">
              View All&#x276F;
            </Link>
          </Grid>
          {Array.isArray(data.data)
            ? data.data.slice(0, 4).map((image) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={image?._id}>
                  <ProductCard image={image} />
                </Grid>
              ))
            : null}
        </Grid>
      )}
    </Box>
  );
};
export default FeaturedProducts;
