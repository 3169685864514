import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import AllVendorServices from '../api/AllVendorServices';

const initialState = {
  loading: false,
  data: null,
  vendorApprovedOrders: [],
  error: null,
  success: false,
};

export const getAllVendorDetails = createAsyncThunk(`/all-Vendor-details`, async () => {
  try {
    const { data } = await AllVendorServices.getAllUser();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

export const getVendorAllApprovedOrders = createAsyncThunk(`/getVendorAllApprovedOrders`, async (id) => {
  try {
    const { data } = await AllVendorServices.getAllVendorApprovedOrders(id);
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteVendorMethod = createAsyncThunk(`/delete-Vendor`, async (id) => {
  try {
    const { data } = await AllVendorServices.deletes(id);
    return data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
export const deleteSelectedVendorMethod = createAsyncThunk(`/delete-selected-Vendor`, async (id) => {
  try {
    const { data } = await AllVendorServices.deleteMultiple(id);
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

const allVendorSlice = createSlice({
  name: 'allVendor',
  initialState,
  reducers: {},
  extraReducers: {
    // get all user details
    [getAllVendorDetails.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllVendorDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getAllVendorDetails.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    [getVendorAllApprovedOrders.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getVendorAllApprovedOrders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.vendorApprovedOrders = payload;
    },
    [getVendorAllApprovedOrders.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete
    [deleteVendorMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteVendorMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = state.data.filter((item) => item._id !== payload.id);
    },
    [deleteVendorMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete Multiple
    [deleteSelectedVendorMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedVendorMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedVendorMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default allVendorSlice.reducer;
