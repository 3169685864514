// component
import { imageMissing } from '../../../redux/api/http-common';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`${imageMissing}/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('dashboard-ic'),
  },
  {
    title: 'Chat',
    path: '/dashboard/chat',
    icon: icon('chat-message-ic'),
  },
  {
    title: 'User',
    path: '/dashboard/user',
    icon: icon('users-ic'),
  },
  {
    title: 'Vendors',
    path: '/dashboard/vendors',
    icon: icon('users-ic'),
  },
  {
    title: 'Products',
    path: '/dashboard/products',
    icon: icon('product-ic'),
  },
  {
    title: 'Products SEO',
    path: '/dashboard/product-seo-page',
    icon: icon('product-ic'),
  },
  {
    title: 'Custom Products',
    path: '/dashboard/custom-product',
    icon: icon('product-ic'),
  },
  {
    title: 'Custom Products SEO',
    path: '/dashboard/custom-product-seo',
    icon: icon('product-ic'),
  },
  {
    title: 'Orders',
    path: '/dashboard/orders',
    icon: icon('cart-ic'),
  },
  // {
  //   title: 'Custom Product Orders',
  //   path: '/dashboard/custom-product-orders',
  //   icon: icon('cart-ic'),
  // },
  {
    title: 'Vendor Orders',
    path: '/dashboard/vendor-orders',
    icon: icon('cart-ic'),
  },
  {
    title: 'Payments',
    path: '/dashboard/payments',
    icon: icon('payment-ic'),
  },
  {
    title: 'Jobs',
    path: '/dashboard/jobs',
    icon: icon('job-family-ic'),
  },
  {
    title: 'Freelance Jobs',
    path: '/dashboard/freelance-jobs',
    icon: icon('job-family-ic'),
  },
  {
    title: 'Custom Design Inquire Form',
    path: '/dashboard/custom-design-inquire-form',
    icon: icon('quote-ic'),
  },
  {
    title: 'Brands',
    path: '/dashboard/brands',
    icon: icon('trade-ic'),
  },
  {
    title: 'Parent Category',
    path: '/dashboard/parent-category',
    icon: icon('category-ic'),
  },
  {
    title: 'Parent Category SEO',
    path: '/dashboard/parent-category-seo',
    icon: icon('category-ic'),
  },
  {
    title: 'Sub Category',
    path: '/dashboard/sub-category',
    icon: icon('category-ic'),
  },
  {
    title: 'Sub Category SEO',
    path: '/dashboard/sub-category-seo',
    icon: icon('category-ic'),
  },
  {
    title: 'Custom Parent Category',
    path: '/dashboard/custom-parent-category',
    icon: icon('category-ic'),
  },
  {
    title: 'Custom Parent Category SEO',
    path: '/dashboard/custom-parent-category-seo',
    icon: icon('category-ic'),
  },
  {
    title: 'Custom Sub Category',
    path: '/dashboard/custom-sub-category',
    icon: icon('category-ic'),
  },
  {
    title: 'Custom Sub Category SEO',
    path: '/dashboard/custom-sub-category-seo',
    icon: icon('category-ic'),
  },
  {
    title: 'Partner',
    path: '/dashboard/partner',
    icon: icon('partner-relationship-ic'),
  },
  {
    title: 'Slider',
    path: '/dashboard/slider',
    icon: icon('slider-ic'),
  },
  {
    title: 'Services',
    path: '/dashboard/services',
    icon: icon('service-svgrepo-ic'),
  },
  {
    title: 'Gallery',
    path: '/dashboard/gallery',
    icon: icon('gallery_ic'),
  },
  {
    title: 'Contact',
    path: '/dashboard/contact',
    icon: icon('slider-ic'),
  },
  {
    title: 'blog',
    path: '/dashboard/blog-post',
    icon: icon('blog-ic'),
  },
  {
    title: 'review',
    path: '/dashboard/review',
    icon: icon('review-ic'),
  },
];

export const navVendorConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('dashboard-ic'),
  },
  // {
  //   title: 'Chats',
  //   path: '/dashboard/chat',
  //   icon: icon('chat-message-ic'),
  // },
  {
    title: 'Products',
    path: '/dashboard/products',
    icon: icon('product-ic'),
  },
  {
    title: 'Vendor Orders',
    path: '/dashboard/vendor-orders',
    icon: icon('cart-ic'),
  },
  {
    title: 'Payments History',
    path: '/dashboard/payments-history',
    icon: icon('payment-ic'),
  },
  {
    title: 'Jobs',
    path: '/dashboard/jobs',
    icon: icon('job-family-ic'),
  },
  {
    title: 'Parent Category',
    path: '/dashboard/parent-category',
    icon: icon('category-ic'),
  },
  {
    title: 'Sub Category',
    path: '/dashboard/sub-category',
    icon: icon('category-ic'),
  },
  {
    title: 'Gallery',
    path: '/dashboard/gallery',
    icon: icon('gallery_ic'),
  },
];

export default navConfig;
