import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SendIcon from '@mui/icons-material/Send';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
// import { imageBaseUrl } from '../../redux/api/http-common';
import { chatBaseUrl } from '../../redux/api/http-common';
import ChatServices from '../../redux/api/ChatServices';
import { RoundedInputField } from '../../Components/Components';

const ChatDialog = ({ productid, handleChat, openChat }) => {
  const [datas, setData] = useState([]);

  // console.log("-----------------------data", datas);
  const socket = useRef();
  const clientuser = useSelector((s) => s.clientuser?.userInfo);
  const [arrivalMessage, setArrivalMessage] = useState(null);

  const [message, setMessage] = useState('');
  const divRef = useRef(null);

  // setTimeout(() => {
  //   getData(productid);
  // }, 5000);
  useEffect(() => {
    socket.current = io(`${chatBaseUrl}/`);
    socket.current.on('getMessage', (data) => {
      setArrivalMessage({
        sender: data.senderId,
        message: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);
  useEffect(() => {
    if (clientuser?._id) {
      socket.current.emit('addUser', clientuser?._id);
      socket.current.on('getUsers', (users) => {
        // console.log('users====>', users);
        // setOnlineUsers(user.followings.filter((f) => users.some((u) => u.userId === f)));
      });
    }
  }, [clientuser?._id]);

  useEffect(() => {
    getData(productid);
  }, [productid]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    arrivalMessage && setData((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, clientuser?._id]);

  const getData = async (productid) => {
    if (productid !== undefined) {
      await ChatServices.get(productid)
        .then((res) => {
          setData(res.data.data && res.data.data.length !== 0 && res.data.data);
          divRef.current.scrollIntoView({ behavior: 'smooth' });
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };

  const handleSubmitMessage = async (e) => {
    e.preventDefault();
    if (!message) {
      toast.error('please enter a message');
      return;
    }

    socket.current.emit('sendMessage', {
      senderId: clientuser?._id,
      isClient: true,
      text: message,
    });

    const data = {
      productId: productid,
      message,
    };
    await ChatServices.create(data)
      .then((res) => {
        if (res.data.success === true) {
          setMessage('');
          getData(productid);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  // const handleSubmitMessage = async (e) => {
  //   e.preventDefault();
  //   const data = {
  //     productId: productid,
  //     message,
  //   };
  //   await ChatServices.create(data)
  //     .then((res) => {
  //       if (res.data.success === true) {
  //         setMessage('');
  //         getData(productid);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error.response.data.message, {
  //         position: 'bottom-left',
  //       });
  //     });
  // };
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: '#58a30a',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          color: 'white',
        }}
      >
        <Box className="row-space-around-flex" sx={{ alignItems: 'center' }} mx={3} py={2}>
          <AccountCircleIcon className="chat-header-icon" />
          <Typography variant="body">Welcome to PakPrintWishes</Typography>
          <CloseOutlinedIcon className="chat-header-icon1" onClick={handleChat} sx={{ cursor: 'pointer' }} />
        </Box>
      </Grid>
      <Grid item sm={12} sx={{ textAlign: 'center', height: '300px', overflowY: 'scroll' }}>
        {/* <Typography variant="caption">27-03-2022</Typography> */}
        {datas.length === 0 ? (
          <Box className="chat-container">
            <Typography variant="body2">Empty</Typography>
          </Box>
        ) : (
          <Grid container spacing={1} p={1}>
            {datas &&
              datas.map((result) =>
                result.adminId !== null ? (
                  <Grid item xs={12} key={result._id}>
                    <Grid container alignItems={'center'}>
                      <Grid item xs={4} md={2}>
                        <AccountCircleIcon className="chat-header-icon chat-content1-icon" />
                      </Grid>
                      <Grid item xs={8} md={10} className="chat-content-text1">
                        <Typography variant="body2">{result.message}</Typography>
                        <Typography variant="caption">{moment(result.createdAt).fromNow(true)}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12} key={result._id}>
                    <Grid container alignItems={'center'}>
                      <Grid item xs={8} md={10} className="chat-content-text2">
                        <Typography variant="body2">{result.message}</Typography>
                        <Typography variant="caption">{moment(result.createdAt).fromNow(true)}</Typography>
                      </Grid>
                      <Grid item xs={4} md={2}>
                        <AccountCircleIcon className="chat-header-icon" />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )}
            <div ref={divRef} />
          </Grid>
        )}
      </Grid>
      <Grid item sm={12} className="chat-input">
        <Divider />
        <Box className="row-simple-container-item">
          <RoundedInputField
            inputProps={{ style: { fontSize: 10 } }}
            sx={{ width: '80%', margin: '2%' }}
            placeholder="Please write your and press the send button... "
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmitMessage(e);
              }
            }}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
          <IconButton onClick={handleSubmitMessage}>
            <SendIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChatDialog;
