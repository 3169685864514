import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import GetQuoteServices from '../../../redux/api/GetQuoteServices';
import { IMAGE_BASE_URL } from '../../../redux/api/http-common';
import ProgressBar from '../../progress/ProgressBar';

const GetQuoteDetails = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    getDetail();
  }, [id]);
  const getDetail = async () => {
    await GetQuoteServices.getbyid(id)
      .then((res) => {
        setData(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };
  return (
    <Box sx={{ width: '100%' }}>
      {loading === true ? (
        <ProgressBar />
      ) : (
        <Grid
          container
          sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '20px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;',
          }}
        >
          <Grid item xs={12} py={2}>
            <Typography variant="h5">Get Quote Details</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Customer Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.name ? 'N/A' : data?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Customer Email</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.email ? 'N/A' : data?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Customer Phone</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.phone ? 'N/A' : data?.phone}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Box Type</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.boxType ? 'N/A' : data?.boxType}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Width</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.width ? 'N/A' : data?.width}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Height</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.height ? 'N/A' : data?.height}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Length</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.length ? 'N/A' : data?.length}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Type Card</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.typeCard ? 'N/A' : data?.typeCard}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Printing Type</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.printingType ? 'N/A' : data?.printingType}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Color Options</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.colorOption ? 'N/A' : data?.colorOption}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Quantity 1</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.quantity_1 ? 'N/A' : data?.quantity_1}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Quantity 2</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.quantity_2 ? 'N/A' : data?.quantity_2}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Quantity 3</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.quantity_3 ? 'N/A' : data?.quantity_3}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Rate</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.rate ? 'N/A' : data?.rate}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Comments</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.comment ? 'N/A' : data?.comment}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Status</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.status ? 'N/A' : data?.status}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            {!data || !data?.attachFile ? (
              'N/A'
            ) : (
              <img src={`${IMAGE_BASE_URL}${data?.attachFile}`} alt={data?.name} height={'200px'} />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default GetQuoteDetails;
