import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import CustomProductSEOService from '../../../redux/api/CustomProductSEOService';
import { getCustomProductSEOMethod } from '../../../redux/slice/customProductSEOSlice';
import { getCustomProductMethod } from '../../../redux/slice/customProductSlice';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function UpdateCustomProductSEODialog({ openAddForm, handleClickOpenAddForm, jobId }) {
  const dispatch = useDispatch();
  const productData = useSelector((state) => state.customProduct.data);
  const data = useSelector((state) => state.customProductSEO);
  const [title, setTitle] = React.useState(null);
  const [productId, setProductId] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const handleSubmit = async () => {
    if (title === null || description === null) {
      toast.error('Please enter all required fields');
      return;
    }
    const data = {
      webTitle: title,
      webDescription: description,
      customDesignId: productId?.value,
    };
    await CustomProductSEOService.update(jobId, data)
      .then((res) => {
        toast.success(res?.data?.message);
        setTitle(null);
        setDescription(null);
        setProductId(null);
        dispatch(getCustomProductSEOMethod());
        handleClickOpenAddForm();
      })
      .catch((err) => {
        if (!err?.response?.data) {
          toast.error(err.message);
        } else {
          toast.error(err.response.data.message);
        }
      });
  };
  React.useEffect(() => {
    dispatch(getCustomProductMethod());
  }, [dispatch]);
  React.useEffect(() => {
    const result = data.data && data.data.filter((item) => item._id === jobId);
    if (result && result.length !== 0) {
      setTitle(result[0]?.webTitle);
      setProductId({
        value: result[0]?.customDesignId?._id,
        label: result[0]?.customDesignId?.titles,
      });
      setDescription(result[0]?.webDescription);
    }
  }, [jobId]);
  return (
    <div>
      <Dialog fullScreen open={openAddForm} onClose={handleClickOpenAddForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenAddForm} aria-label="close">
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Custom Product SEO
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Edit Custom Product SEO</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Select Custom Product
            </Typography>
            <Autocomplete
              value={productId}
              onChange={(event, newValue) => {
                setProductId(newValue);
              }}
              id="controllable-states-demo"
              options={
                productData &&
                productData.length > 0 &&
                productData.map((product) => ({
                  value: product._id,
                  label: product.titles,
                }))
              }
              isOptionEqualToValue={(option, value) => option.value === value}
              fullWidth
              renderInput={(params) => <TextField {...params} placeholder="Select Custom Product" />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Description
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={() => {
                handleSubmit();
                // handleClickOpenEditForm();
              }}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
