import React, { useRef, useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { toast } from 'react-toastify';
import { Alert, Button, Card, CardContent, CardHeader, Checkbox, Grid, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../iconify/Iconify';
import { getGalleryMethod } from '../../redux/slice/gallerySlice';
import { IMAGE_BASE_URL } from '../../redux/api/http-common';
import GalleryService from '../../redux/api/GalleryService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '100%', sm: '50%', md: '50%', lg: '50%', xl: '50%' },
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  height: { xs: '200px', sm: '400px', md: '400px', lg: '500px', xl: '500px' },
};
const GalleyModel = ({ openModel, handleModel, selectImageNumber = 1, folderId = null, setFile, file }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.gallery.data);
  const inputFile = useRef(null);
  const selectFiles = (event) => {
    const selectedFiles = event.target.files;
    const imagesFile = [];
    if (selectedFiles.length > selectImageNumber) {
      toast.error(`You can only upload a maximum of ${selectImageNumber} images`);
      return;
    }
    for (let i = 0; i < selectedFiles.length; i += 1) {
      imagesFile.push(URL.createObjectURL(selectedFiles[i]));
    }
    handleImage(selectedFiles);
  };

  const handleImage = async (image) => {
    await GalleryService.add({ folderId, image })
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getGalleryMethod(folderId));
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };

  useEffect(() => {
    if (folderId !== null || folderId !== undefined) {
      dispatch(getGalleryMethod(folderId));
    }
  }, [dispatch, folderId]);

  // handle select images
  const handleCheckboxChange = (id) => {
    if (file.includes(id)) {
      setFile(file.filter((selectedId) => selectedId !== id));
    } else if (file.length < selectImageNumber) {
      setFile([...file, id]);
    }
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModel}
        onClose={handleModel}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModel}>
          <Box sx={style}>
            <Card sx={{ borderRadius: '0px', height: '100%', width: '100%', objectFit: '100%' }}>
              <CardHeader
                title="Choose Images"
                action={
                  <IconButton aria-label="settings" onClick={handleModel}>
                    <Iconify icon="ic:baseline-close" />
                  </IconButton>
                }
              />
              <CardContent sx={{ overflowY: 'scroll', height: '90%', objectFit: '100%', width: '100%' }}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Button
                      variant="contained"
                      startIcon={<Iconify icon="solar:gallery-bold" />}
                      sx={{ marginX: '10px' }}
                    >
                      Gallery
                    </Button> */}
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      multiple={selectImageNumber > 1}
                      id="file"
                      ref={inputFile}
                      onChange={selectFiles}
                      style={{ display: 'none' }}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => {
                        inputFile.current.click();
                      }}
                      startIcon={<Iconify icon="uil:image-upload" />}
                      sx={{ marginX: '10px' }}
                    >
                      Upload
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (file.length > 0) {
                          handleModel();
                        } else if (file.length === 0) {
                          toast.error('Please select a image');
                        }
                      }}
                      startIcon={<Iconify icon="gala:select" />}
                      sx={{ marginX: '10px' }}
                    >
                      Selected
                    </Button>
                  </Grid>
                  {!folderId ? (
                    <Grid item xs={12} pt={3}>
                      <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                        Please select main category
                      </Typography>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} pt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Alert severity="info">{`Only select ${selectImageNumber} Images`}</Alert>
                      </Grid>
                      {!data || data.length === 0 ? (
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                            Images not found
                          </Typography>
                        </Grid>
                      ) : (
                        data.map((item, i) => (
                          <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                            <Box
                              sx={{
                                height: '150px',
                                borderRadius: '5px',
                                boxShadow:
                                  ' rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;',
                              }}
                              className="gallery-image-container"
                            >
                              <img
                                src={`${IMAGE_BASE_URL}${item?.imgUrl}`}
                                alt={item?._id}
                                className="gallery-image"
                                style={{ height: '100%', width: '100%', objectFit: '100%', borderRadius: '5px' }}
                              />
                              <Box className="gallery-checkbox-container">
                                <Checkbox
                                  inputProps={{ 'aria-label': 'controlled' }}
                                  checked={file.includes(item.imgUrl)}
                                  onChange={() => handleCheckboxChange(item.imgUrl)}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        ))
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default GalleyModel;
