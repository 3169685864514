import { callAPi } from './http-common';

const get = () => {
  return callAPi.get('/getAllCommentsRecord');
};
const deletChatById = (data) => {
  return callAPi.post('/deleteCommentById', data);
};
const multipledeletChat = (data) => {
  return callAPi.post('/deleteComments', data);
};
const ChatService = {
  get,
  deletChatById,
  multipledeletChat,
};

export default ChatService;
