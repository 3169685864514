import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/createCustomParentSeoCategory', data);
};
const get = () => {
  return callAPi.get('/getAllCustomParentSeoCategories');
};
const getbyid = (id) => {
  return callAPi.get(`/getCustomParentSeoCategoryById/${id}`);
};
const update = (id, data) => {
  return callAPi.patch(`/updateCustomParentSeoCategoryById/${id}`, data);
};
const deletes = (id) => {
  return callAPi.patch(`/deleteCustomParentSeoCategoryById/${id}`);
};
const CustomParentCategorySEOService = {
  add,
  get,
  update,
  deletes,
  getbyid,
};

export default CustomParentCategorySEOService;
