import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addSubCategoryMethod } from '../../../redux/slice/subCategorySlice';
import RichTextEditors from '../../RichText/RichTextEditors';
import GalleyModel from '../../Gallery/GalleyModel';
import Iconify from '../../iconify/Iconify';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function AddSubCategoryDialog({ openAddForm, handleClickOpenAddForm }) {
  const [folderId, setFolderId] = React.useState(null);
  // Gallery Model
  const [openModel, setOpenModel] = React.useState(false);
  const handleModel = () => {
    setOpenModel(!openModel);
  };
  const dispatch = useDispatch();
  const parentCategoryData = useSelector((state) => state.parentCategory);
  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [parentCategory, setParentCategory] = React.useState('Select Parent Category');
  const [file, setFile] = React.useState([]);

  const handleSubmit = () => {
    if (
      title === null ||
      description === null ||
      parentCategory === 'Select Parent Category' ||
      file === null ||
      file.length === 0
    ) {
      toast.error('Please fill all the fields');
      return;
    }
    const data = {
      name: title,
      parent_id: parentCategory,
      category_description: description,
      category_image: file[0],
    };
    dispatch(addSubCategoryMethod(data));
    setFile([]);
    setParentCategory('Select Parent Category');
    handleClickOpenAddForm();
  };
  return (
    <div>
      <Dialog fullScreen open={openAddForm} onClose={handleClickOpenAddForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenAddForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Sub Category
            </Typography>
            {/* <Button autoFocus sx={{ color: 'white', borderColor: 'white' }} onClick={handleSubmit}>
              Save
            </Button> */}
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Add Sub Category</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Sub Category Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Sub Category Title"
              variant="outlined"
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Parent Category
            </Typography>
            <FormControl fullWidth>
              <Select
                value={parentCategory}
                onChange={(e) => {
                  setParentCategory(e.target.value);
                  setFolderId(e.target.value);
                }}
                sx={{ textAlign: 'left' }}
              >
                <MenuItem value="Select Parent Category" disabled>
                  Select Parent Category
                </MenuItem>
                {parentCategoryData.data !== null &&
                  parentCategoryData.data !== undefined &&
                  parentCategoryData.data.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Sub Category Description
            </Typography>
            <RichTextEditors placeholder="Sub Category Description" setBody={setDescription} />
            {/* <TextField
              id="outlined-basic"
              placeholder="Sub Category Description"
              multiline
              rows={5}
              variant="outlined"
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
              required
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Upload Image
            </Typography>
            <br />
            {/* <input
              accept="image/*"
              type="file"
              id="select-image"
              onChange={(e) => setFile(e.target.files[0])}
              required
            /> */}
            <Button
              variant="contained"
              size="small"
              startIcon={<Iconify icon="solar:gallery-bold" />}
              onClick={() => {
                if (!folderId) {
                  toast.error('Please select main category');
                  window.scrollTo(0, 0);
                  return;
                }
                handleModel();
              }}
            >
              Choose Image
            </Button>
          </Grid>
          <GalleyModel
            openModel={openModel}
            handleModel={handleModel}
            folderId={folderId}
            setFile={setFile}
            file={file}
          />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={() => {
                handleSubmit();
                // handleClickOpenAddForm();
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
