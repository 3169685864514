import http from "./http-common";

const getAll = () => {
  return http.get("/user/getAllServices");
};

// };

const ServiceApiServices = {
  getAll
};

export default ServiceApiServices;