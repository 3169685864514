import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ReviewServices from '../api/ReviewServices';

const initialState = [];

export const createReview = createAsyncThunk('review-create', async (data) => {
  const res = await ReviewServices.create(data);
  return res.data;
});

export const getReview = createAsyncThunk('review/id-get', async (id) => {
  const res = await ReviewServices.get(id);
  return res.data;
});

export const updateReview = createAsyncThunk('review/id-get', async (datas) => {
  const { id, data } = datas;
  const res = await ReviewServices.updateReview(id, data);
  return res.data;
});

const reviewSlice = createSlice({
  name: 'review',
  initialState,
  extraReducers: {
    [createReview.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [getReview.fulfilled]: (state, action) => action.payload,
    [updateReview.fulfilled]: (state, action) => action.payload,
  },
});

export const { contactAdded } = reviewSlice.actions;
export default reviewSlice.reducer;
