import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { Grid, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import CustomParentCategoryService from '../../../redux/api/CustomParentCategoryService';
import RichTextEditors from '../../RichText/RichTextEditors';
import { getCustomParentCategoryMethod } from '../../../redux/slice/customParentCategorySlice';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function UpdateCustomParentCategoryDialog({ openEditForm, handleClickOpenEditForm, parentCategoryId }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.customParentCategory);
  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [file, setFile] = React.useState(null);
  React.useEffect(() => {
    const result = data?.data && data?.data.filter((item) => item?._id === parentCategoryId);
    if (result && result.length !== 0) {
      setTitle(result[0]?.title);
      setDescription(result[0]?.description);
    }
  }, [parentCategoryId]);

  const handleSubmit = async () => {
    if (!title || !description) {
      toast.error('Please fill all fields');
      return;
    }
    const data = {
      title,
      description,
      customParentCategoryImage: file,
    };
    await CustomParentCategoryService.update(parentCategoryId, data)
      .then(() => {
        toast.success('Custom Parent Category Updated Successfully');
        dispatch(getCustomParentCategoryMethod());
        handleClickOpenEditForm();
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };
  return (
    <div>
      <Dialog fullScreen open={openEditForm} onClose={handleClickOpenEditForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenEditForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Custom Parent Category
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Edit Custom Parent Category</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Description
            </Typography>
            <RichTextEditors placeholder="Description" body={description} setBody={setDescription} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Upload Category Image
            </Typography>
            <br />
            <input
              accept=".png, .jpg, .jpeg"
              type="file"
              id="select-image"
              onChange={(e) => setFile(e.target.files[0])}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
