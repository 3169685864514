import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const ProposalCard = () => {
  return (
    <Grid
      container
      spacing={2}
      className="job-card"
      component={Link}
      alignItems={'center'}
      to={`/jobs/detail/${'kurioje buvo ir Lorem Ipsum versija'.replace(/\s+/g, '-').toLowerCase()}/1`}
    >
      <Grid item xs={12} sm={8} md={10}>
        <Typography variant="subtitle1">kurioje buvo ir Lorem Ipsum versija.</Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <Typography variant="subtitle1">Received Jan 20,2023</Typography>
        <Typography variant="body2" sx={{ color: 'rgba(147, 147, 147, 1)' }}>
          1 day ago
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProposalCard;
