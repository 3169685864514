import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/createCustomChildCategory', data);
};
const deletes = (id) => {
  return callAPi.patch(`/deleteCustomChildCategoryById/${id}`);
};
const get = () => {
  return callAPi.get('/getAllCustomChildCategories');
};
const update = (id, data) => {
  return callAPi.patch(`/updateCustomChildCategoryById/${id}`, data);
};
const status = (id) => {
  return callAPi.patch(`/changeCustomChildCategoryStatus/${id}`);
};
const CustomSubCategoryService = {
  add,
  deletes,
  get,
  update,
  status,
};

export default CustomSubCategoryService;
