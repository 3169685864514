import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function DeliveryChargesDialog({ open, handleClose, handleChangeShippingFeeStatus }) {
  const [value, setValue] = React.useState(null);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Set Shipping Fee</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Shipping Fee"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type="number"
          fullWidth
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={() => handleChangeShippingFeeStatus(value)} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
