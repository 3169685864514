import { Grid, Typography, ListItemIcon, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet-async';
import { BlueButton, CareerListItemButton } from '../../Components/Components';
import CareersServices from '../../redux/api/CareersServices';
import { applyCarrerSchema } from '../../schemas';
import bgImg from '../../Assets/header_bg/careers.png';

const CareersDetailsPage = (props) => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [career, setCareer] = useState([]);
  const [fname, setFname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneno, setPhoneno] = useState('');
  const [position, setPostion] = useState('');
  const [file, setFile] = useState(null);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  const getDetails = (id) => {
    CareersServices.get(id).then((res) => {
      setCareer(res.data.data);
    });
  };
  useEffect(() => {
    getDetails(id);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append('jobId', id);
    formData.append('fullName', fname);
    formData.append('email', email);
    formData.append('phone', phoneno);
    formData.append('applyPosition', position);
    formData.append('file', file);
    try {
      const res = await CareersServices.create(formData);
      if (res.data) {
        toast.success(res.data.message, {
          position: 'bottom-left',
        });
      }
    } catch (error) {
      if (!error.response.data.message) {
        toast.error(error.message, { position: 'top-right' });
      } else {
        toast.error(error.response.data.message, { position: 'top-right' });
      }
    }
  };
  const { values, errors, handleChange, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: {
      fname: '',
      email: '',
      phoneno: '',
      position: '',
      file: null,
    },
    validationSchema: applyCarrerSchema,
    onSubmit: (values) => {
      onSubmit();
      setEmail('');
      setFname('');
      setPostion('');
      setPhoneno('');
      values.email = '';
      values.fname = '';
      values.position = '';
      values.phoneno = '';
      handleClose();
    },
  });
  return (
    <Grid container className="top-container centered">
      <Helmet>
        <title>Career Detail | PakPrint Wishes</title>
        <meta
          name="description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:title" content="Career Detail | PakPrint Wishes" />
        <meta
          property="og:description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:image" content={bgImg} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
        sx={{ backgroundImage: `url(${bgImg})` }}
      >
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9} sx={{ display: 'flex' }}>
            <div className="breadcrumb-text">
              <h2 className="breadcrumb-text__title">Careers</h2>
              <ul className="breadcrumb-text__nav">
                <li>
                  <Link to={'/'}>Home</Link>
                </li>
                <li>&nbsp;-&nbsp;</li>
                <li>Careers</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
        {career ? (
          <Grid container>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10} pt={5} sx={{ textAlign: 'left' }}>
              <Typography className="careers-details-job-title">{career.title}</Typography>
              <Typography className="careers-details-job-date" pt={1}>
                {career.city}
                {', '}
                {career.country}
              </Typography>
              <Typography className="careers-details-job-date">{career.lastDate}</Typography>
              <Typography className="careers-job-date" pt={1}>
                {career.jobType}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pt={5} sx={{ textAlign: 'left' }}>
              <Typography className="careers-job-desc-details">We are looking for a {career.title}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pt={5} sx={{ textAlign: 'left' }}>
              <Typography className="careers-job-descr">Skills:</Typography>
              <Typography className="careers-job-date" pt={1}>
                {career.skills}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pt={5} sx={{ textAlign: 'left' }}>
              <Typography className="careers-job-descr">Job Discription:</Typography>
              <div dangerouslySetInnerHTML={{ __html: career.description }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pt={5} sx={{ textAlign: 'left' }}>
              <Typography className="careers-job-descr">Responsibilities:</Typography>
              <div dangerouslySetInnerHTML={{ __html: career.responsibilities }} />
              <br />
              <br />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CareerListItemButton className="career-btn" onClick={handleClickOpen} sx={{ float: 'left' }}>
                <ListItemText>Apply Now</ListItemText>
                <ListItemIcon>
                  <ArrowRightAltIcon sx={{ color: 'white', marginLeft: '15px' }} />
                </ListItemIcon>
              </CareerListItemButton>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: '25px', color: '#173470', marginTop: '10px' }}>Apply Now</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Your Name"
            type="text"
            fullWidth
            variant="outlined"
            id="fname"
            name="fname"
            value={fname}
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldValue('fname', e.target.value);
              setFname(e.target.value);
            }}
            error={errors.fname && touched.fname}
            helperText={errors.fname && touched.fname ? errors.fname : null}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            id="email"
            name="email"
            value={email}
            onChange={(e) => {
              setFieldValue('email', e.target.value);
              setEmail(e.target.value);
            }}
            onBlur={handleBlur}
            error={errors.email && touched.email}
            helperText={errors.email && touched.email ? errors.email : null}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Phone Number"
            type="Number"
            fullWidth
            variant="outlined"
            id="phoneno"
            name="phoneno"
            value={phoneno}
            onChange={(e) => {
              setFieldValue('phoneno', e.target.value);
              setPhoneno(e.target.value);
            }}
            onBlur={handleBlur}
            error={errors.phoneno && touched.phoneno}
            helperText={errors.phoneno && touched.phoneno ? errors.phoneno : null}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Postion Applied For"
            type="text"
            fullWidth
            variant="outlined"
            id="position"
            name="position"
            value={position}
            onChange={(e) => {
              setFieldValue('position', e.target.value);
              setPostion(e.target.value);
            }}
            onBlur={handleBlur}
            error={errors.position && touched.position}
            helperText={errors.position && touched.position ? errors.position : null}
          />
          <TextField
            type="file"
            variant="outlined"
            autoFocus
            margin="dense"
            fullWidth
            id="file"
            name="file"
            onChange={(e) => {
              setFieldValue('file', e.target.files[0]);
              setFile(e.target.files[0]);
            }}
            onBlur={handleBlur}
            error={errors.file && touched.file}
            helperText={errors.file && touched.file ? errors.file : null}
          />
          <BlueButton sx={{ marginTop: '10px' }} onClick={handleSubmit}>
            Submit
          </BlueButton>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default CareersDetailsPage;
