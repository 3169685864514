import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import VendorOrderServices from '../../../redux/api/VendorOrderServices';
import ProductCard from '../../card/ProductCard';

const VendorOrderDeatail = () => {
  const [data, setData] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    getDetail();
  }, [id]);
  const getDetail = async () => {
    await VendorOrderServices.getbyid(id)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <Grid
      container
      sx={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '20px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;',
      }}
    >
      <Grid item xs={12} py={2}>
        <Typography variant="h5">Vendor Order Detail</Typography>
      </Grid>
      <Grid item xs={12} sm={6} pt={1}>
        <Typography variant="subtitle1">Vendor Name</Typography>
      </Grid>
      <Grid item xs={12} sm={6} pt={1}>
        <Typography variant="subtitle1" sx={{ color: 'gray' }}>
          {!data || !data?.vendor || Object.keys(data?.vendor).length === 0 || !data?.vendor?.first_name
            ? 'N/A'
            : `${data?.vendor?.first_name} ${data?.vendor?.last_name}`}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} pt={1}>
        <Typography variant="subtitle1">Vendor Email</Typography>
      </Grid>
      <Grid item xs={12} sm={6} pt={1}>
        <Typography variant="subtitle1" sx={{ color: 'gray' }}>
          {!data || !data?.vendor || Object.keys(data?.vendor).length === 0 || !data?.vendor?.email
            ? 'N/A'
            : data?.vendor?.email}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} pt={1}>
        <Typography variant="subtitle1">Status</Typography>
      </Grid>
      <Grid item xs={12} sm={6} pt={1}>
        <Typography variant="subtitle1" sx={{ color: 'gray' }}>
          {!data || !data?.status ? 'N/A' : data?.status}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} pt={2}>
        <Typography variant="subtitle1">Products</Typography>
      </Grid>
      <Grid item xs={12} p={1}>
        <Grid container spacing={2}>
          {!data || !data?.products || data?.products.length === 0
            ? null
            : data.products.map((image, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                  <ProductCard image={image} />
                </Grid>
              ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VendorOrderDeatail;
