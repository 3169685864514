import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/addRateToQuote', data);
};
const softDelete = (id) => {
  return callAPi.delete(`/softDeleteQuote/${id}`);
};
const premanentDelete = (id) => {
  return callAPi.delete(`/permenantDelete/${id}`);
};
const restore = (id) => {
  return callAPi.patch(`/restoreQuote/${id}`);
};
const deletePermanentMultiple = (data) => {
  return callAPi.post(`/permanantDeleteQuoteMultiple`, { ids: data });
};
const deleteSoftMultiple = (data, status) => {
  const results = {
    ids: data,
    status,
  };
  return callAPi.post(`/softDeleteQuoteMultiple`, results);
};
const get = () => {
  return callAPi.get('/getQuote');
};
const getbyid = (id) => {
  return callAPi.get(`/getQuote/${id}`);
};
const getPending = () => {
  return callAPi.get('/getQuotePending');
};
const getConfirm = () => {
  return callAPi.get('/getQuoteConfirm');
};
const getDeliver = () => {
  return callAPi.get('/getQuoteDeliver');
};
const getAllDeleted = () => {
  return callAPi.get('/getAllSoftDeleted');
};
const update = (data) => {
  return callAPi.post('/addRateToQuote', data);
};
const updateStatus = (data) => {
  return callAPi.patch(`/updateStatus`, data);
};
const GetQuoteServices = {
  add,
  softDelete,
  get,
  update,
  deletePermanentMultiple,
  deleteSoftMultiple,
  getAllDeleted,
  restore,
  premanentDelete,
  getPending,
  getConfirm,
  getDeliver,
  updateStatus,
  getbyid
};

export default GetQuoteServices;
