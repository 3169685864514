export const cityList = [
  'Ahmedpur East',
  'Attock',
  'Bhawalnagar',
  'Bahawalpur',
  'Bhakkar',
  'Chakwal',
  'Chichawatni',
  'Chiniot',
  'Dera Ghazi Khan',
  'Faisalabad',
  'Gujranwala',
  'Gujrat',
  'Hafizabad',
  'Islamabad',
  'Jhang',
  'Jhelum',
  'Kala Bagh',
  'Kallar Kahar',
  'Kasur',
  'Khanewal',
  'Khushab',
  'Lahore',
  'Layyah',
  'Lodhran',
  'Mandi Bahauddin',
  'Mianwali',
  'Minchinabad',
  'Multan',
  'Murree',
  'Muzaffargarh',
  'Nankana Sahib',
  'Narowal',
  'Okara',
  'Pakpatan',
  'Rahimyar Khan',
  'Rajanpur',
  'Rawalpindi',
  'Sahiwal',
  'Sargodha',
  'Shakargarh',
  'Sheikhupura',
  'Sialkot',
  'Toba Tek Singh',
  'Uch Sharif',
  'Vehari',
  'Badin',
  'Dadu',
  'Ghotki',
  'Hyderabad',
  'Jacobabad',
  'Jamshoro',
  'Karachi',
  'Kashmore',
  'Khairpur',
  'Bagh',
  'Bhimber',
  'Hattian Bala',
  'Haveli',
  'Kotli',
  'Mirpur',
  'Muzzafarabad',
  'Neelum Valley',
  'Rawalkot',
  'Sudhanoti',
  'Awaran',
  'Barkhan',
  'Chagai',
  'Chaman',
  'Dera Bugti',
  'Gawadar',
  'Harnai',
  'Jafarabad',
  'Kacchi',
  'Kalat',
  'Kech',
  'Kharan',
  'Khuzdar',
  'Killa Abdullah',
  'Kila Saifullah',
  'Kohlu',
  'Lasbela',
  'Lehri',
  'Loralai',
  'Mastung',
  'Nasirabad',
  'Nushki',
  'Panjgur',
  'Pishi Valley',
  'Quetta',
  'Sherani',
  'Sibi',
  'Sohbatpur',
  'Washuk',
  'Zhob',
  'Ziarat',
  'Astore',
  'Chilas',
  'Darel',
  'Diamer',
  'Ghanche',
  'GHizer',
  'Gilgit',
  'Gojal Upper Hunza',
  'Gupis Yasin',
  'Kharmang',
  'Rondu',
  'Shigar',
  'Skardu',
  'Tangir',
  'Abbottabad',
  'Bajaur Agency',
  'Bannu',
  'Battagram',
  'Buner',
  'Charsadda',
  'Chitral',
  'Dera Ismail Khan',
  'Dir',
  'Hangu',
  'Haripur',
  'Karak',
  'Khyber Agency',
  'Kohat',
  'Kohistan',
  'Kurram Agency',
  'Lakki Marwat',
  'Malakand',
  'Mansehra',
  'Mardan',
  'Muhmad Agency',
  'North Waziristan Agency',
  'Nowshera',
  'Peshawar',
  'Shangal',
  'South Waziristan Agency',
  'Swabi',
  'Swat',
  'Tank',
  'Torghar',
  'Larkana',
  'Matiari',
  'Qambar Shahdakot',
  'Sanghar',
  'Shaheed Benazirabad',
  'Shikarpur',
  'Sujawal',
  'Sukkur',
  'Tando Allahyar',
  'Tando Muhammad Khan',
  'Tharparkar',
  'Thatta',
  'Umerkot',
];
