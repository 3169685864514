import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/createBrand', data);
};
const deletes = (id) => {
  return callAPi.delete(`/deleteBrand/${id}`);
};
const get = () => {
  return callAPi.get('/getAllBrands');
};
// const getById = (id) => {
//     return callAPi.get(`/getAllBrands/${id}`);
//   };
const deleteMultiple = (data) => {
  return callAPi.post(`/deleteManyBrand`, { ids: data });
};
const update = (id, data) => {
  return callAPi.post(`/updateBrand/${id}`, data);
};
const BrandServices = {
  add,
  deletes,
  get,
  update,
  deleteMultiple,
//   getById
};

export default BrandServices;
