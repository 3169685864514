import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GetQuoteServices from "../api/GetQuoteServices";

const initialState = [];

export const createGetQuote = createAsyncThunk("add-quote", async (data) => {
  const res = await GetQuoteServices.create(data);
  return res.data;
});

const getQuoteSlice = createSlice({
  name: "quote",
  initialState,
  extraReducers: {
    [createGetQuote.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
  },
});

export const { getQuoteAdded } = getQuoteSlice.actions;
export default getQuoteSlice.reducer;
