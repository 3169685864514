import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slice/userSlice';
import brandSlice from './slice/brandSlice';
import parentCategorySlice from './slice/parentCategorySlice';
import allUserSlice from './slice/allUserSlice';
import allVendorSlice from './slice/allVendorSlice';
import subCategorySlice from './slice/subCategorySlice';
import productSlice from './slice/productSlice';
import sliderSlice from './slice/sliderSlice';
import allServicesSlice from './slice/allServicesSlice';
import partnerSlice from './slice/partnerSlice';
import jobsSlice from './slice/jobsSlice';
import appliedJobsSlice from './slice/appliedJobsSlice';
import getQuoteSlice from './slice/getQuoteSlice';
import contactUsSlice from './slice/contactUsSlice';
import orderSlice from './slice/orderSlice';
import publicVendorSlice from './slice/publicVendorSlice';
import vendorOrderSlice from './slice/vendorOrderSlice';
import chatSlice from './slice/chatSlice';
import messageSlice from './slice/messageSlice';
import paymentSlice from './slice/paymentSlice';
import paymentHistorySlice from './slice/paymentHistorySlice';
import paymentHistoryByIDSlice from './slice/paymentHistoryByIDSlice';
import notificationSlice from './slice/notificationSlice';
import gallerySlice from './slice/gallerySlice';
import { clientReducer } from '../client/redux/store';
import { getTotals } from '../client/redux/slice/cartSlice';
import publicParentCatgroySlice from './api/publicParentCatgroySlice';
import publicSubCategorySlice from './slice/publicSubCategorySlice';
import productSEOSlice from './slice/productSEOSlice';
import reviewSlice from './slice/reviewSlice';
import childCategorySEOSlice from './slice/childCategorySEOSlice';
import parentCategorySEOSlice from './slice/parentCategorySEOSlice';
import customSubCategorySlice from './slice/customSubCategorySlice';
import customSubCategorySEOSlice from './slice/customSubCategorySEOSlice';
import customParentCategorySlice from './slice/customParentCategorySlice';
import customParentCategorySEOSlice from './slice/customParentCategorySEOSlice';
import customProductSEOSlice from './slice/customProductSEOSlice';
import customProductSlice from './slice/customProductSlice';
import customLayoutSideBarSlice from './slice/customLayoutSideBarSlice';
import publicCustomProductSlice from './slice/publicCustomProductSlice';
import customProductInquirySlice from './slice/customProductInquirySlice';
import customProductOrdersSlice from './slice/customProductOrdersSlice';
import blogSlice from './slice/blogSlice';
import customVendorOrdersSlice from './slice/customVendorOrdersSlice';
import jobsUserSlice from '../jobs/redux/slice/jobsUserSlice';
import freelanceJobSlice from './slice/freelanceJobSlice';
import freelanceClientJobsSlice from '../jobs/redux/slice/freelanceClientJobsSlice';

const rootReducer = {
  ...clientReducer,
  user: userSlice,
  brand: brandSlice,
  parentCategory: parentCategorySlice,
  allUser: allUserSlice,
  vendors: allVendorSlice,
  subCategory: subCategorySlice,
  product: productSlice,
  slider: sliderSlice,
  service: allServicesSlice,
  partner: partnerSlice,
  job: jobsSlice,
  appliedJob: appliedJobsSlice,
  quote: getQuoteSlice,
  contact: contactUsSlice,
  order: orderSlice,
  publicVendor: publicVendorSlice,
  vendorOrder: vendorOrderSlice,
  chat: chatSlice,
  message: messageSlice,
  payment: paymentSlice,
  paymentHistory: paymentHistorySlice,
  paymentHistoryByID: paymentHistoryByIDSlice,
  notification: notificationSlice,
  gallery: gallerySlice,
  publicParentCatgroy: publicParentCatgroySlice,
  publicSubCategory: publicSubCategorySlice,
  productSEO: productSEOSlice,
  reviews: reviewSlice,
  childCategorySEO: childCategorySEOSlice,
  parentCategorySEO: parentCategorySEOSlice,
  customSubCategory: customSubCategorySlice,
  customSubCategorySEO: customSubCategorySEOSlice,
  customParentCategory: customParentCategorySlice,
  customParentCategorySEO: customParentCategorySEOSlice,
  customProductSEO: customProductSEOSlice,
  customProduct: customProductSlice,
  customLayoutSideBar: customLayoutSideBarSlice,
  publicCustomProduct: publicCustomProductSlice,
  customProductInquiry: customProductInquirySlice,
  customProductOrders: customProductOrdersSlice,
  blog: blogSlice,
  customVendorOrders: customVendorOrdersSlice,
  jobsUser: jobsUserSlice,
  freelanceJobadmin: freelanceJobSlice,
  freelanceClientJobs: freelanceClientJobsSlice,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
store.dispatch(getTotals());
