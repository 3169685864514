import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import RequestPayment from '../components/Payments/RequestPayment';
import ReceivedPayment from '../components/Payments/ReceivedPayment';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const JobsPaymentPage = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} p={1}>
      <Grid item xs={12} md={10}>
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid item xs={12} md={6}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="#000"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab
                label="Request Payment"
                {...a11yProps(0)}
                sx={{ fontSize: '14px', padding: '5px', minHeight: '0px' }}
              />
              <Tab
                label="Received Payment"
                {...a11yProps(1)}
                sx={{ fontSize: '14px', padding: '5px', minHeight: '0px' }}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <RequestPayment />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <ReceivedPayment />
              </TabPanel>
            </SwipeableViews>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobsPaymentPage;
