import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SliderServices from '../../../redux/api/SliderServices';
import { getSliderMethod } from '../../../redux/slice/sliderSlice';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function UpdateSliderDialog({ openEditForm, handleClickOpenEditForm, sliderId }) {
  const dispatch = useDispatch();
  const parentCategoryData = useSelector((state) => state.parentCategory);
  const data = useSelector((state) => state.slider);

  const [title, setTitle] = React.useState(null);
  const [subTitle, setSubTitle] = React.useState(null);
  const [productUrl, setProductUrl] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [parentCategory, setParentCategory] = React.useState('Select Parent Category');
  const [file, setFile] = React.useState(null);

  React.useEffect(() => {
    const result = data?.data && data?.data?.filter((item) => item?._id === sliderId);
    if (result && result?.length !== 0) {
      setTitle(result[0]?.title);
      setSubTitle(result[0]?.subTitle);
      setDescription(result[0]?.sliderDescription);
      setProductUrl(result[0]?.url);
      setParentCategory(!result[0]?.parent_Id?._id ? 'Select Parent Category' : result[0]?.parent_Id?._id);
    }
  }, [sliderId]);

  const handleSubmit = async () => {
    if (!title || !subTitle || !productUrl || !description || parentCategory === 'Select Parent Category') {
      toast.error('Please fill all the fields');
      return;
    }
    const data = {
      title,
      sliderDescription: description,
      subTitle,
      url: productUrl,
      parent_Id: parentCategory,
      sliderImage: file,
    };
    await SliderServices.update(sliderId, data)
      .then((res) => {
        console.warn(res);
        toast.success('Slider Updated Successfully');
        dispatch(getSliderMethod());
        handleClickOpenEditForm();
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
        throw error;
      });
  };

  return (
    <div>
      <Dialog fullScreen open={openEditForm} onClose={handleClickOpenEditForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenEditForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Slider
            </Typography>
            {/* <Button autoFocus variant='outlined' sx={{color:'white',borderColor:'white'}} onClick={handleSubmit}>
              Edit
            </Button> */}
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Edit Slider</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Slider Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Slider Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Slider Sub Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Slider Sub Title"
              variant="outlined"
              value={subTitle}
              onChange={(e) => setSubTitle(e.target.value)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Parent Category"
            </Typography>
            <FormControl fullWidth>
              <Select
                value={parentCategory}
                onChange={(e) => setParentCategory(e.target.value)}
                sx={{ textAlign: 'left' }}
              >
                <MenuItem value="Select Parent Category" disabled>
                  Select Parent Category
                </MenuItem>
                {parentCategoryData.data !== null &&
                  parentCategoryData.data !== undefined &&
                  parentCategoryData.data.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Slider Product URL
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Slider Product URL"
              variant="outlined"
              value={productUrl}
              onChange={(e) => setProductUrl(e.target.value)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Slider Product Description
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Slider Product Description"
              multiline
              rows={5}
              variant="outlined"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Upload Image
            </Typography>
            <br />
            <input
              accept=".png, .jpg, .jpeg"
              type="file"
              id="select-image"
              onChange={(e) => setFile(e.target.files[0])}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={() => {
                handleSubmit();
                // handleClickOpenEditForm();
              }}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
