import http from "./http-common";

const create = (data) => {
  return http.patch("/forgotPassword", data);
};
const sendOtp = (data) => {
  return http.post("/verifyForgotPasswordOtp", data);
};
const reset = (data) => {
  return http.patch("/resetPassword", data);
};

const ForgetPasswordService = {
  create,
  sendOtp,
  reset,
};

export default ForgetPasswordService;
