import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CategoryServices from "../api/CategoryServices";

const initialState = {
  data: [],
};
export const fetchSubCategory = createAsyncThunk("cars/getAPI", async (id) => {
  const response = await CategoryServices.getById(id);
  return response.data;
});
const subCategorySlice = createSlice({
  name: "order",
  initialState,
  extraReducers: {
    [fetchSubCategory.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

export default subCategorySlice.reducer;
