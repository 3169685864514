import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Button,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListToolbar } from '../sections/@dashboard/user';
// mock
import OrderProductListHead from '../sections/@dashboard/user/OrderProductListHead';
import { getPaymentMethod } from '../redux/slice/paymentSlice';
import AddPaymentDialog from '../components/Dialog/AddFormDialog/AddPaymentDialog';
// import UpdatePaymentDialog from '../components/Dialog/UpdateFormDialog/UpdatePaymentDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'first_name', label: 'Vendor Name', alignRight: false },
  { id: 'email', label: 'Vendor Email', alignRight: false },
  { id: 'cnic', label: 'Vendor CNIC', alignRight: false },
  { id: 'phone', label: 'Vendor Phone', alignRight: false },
  { id: 'address', label: 'Vendor Address', alignRight: false },
  { id: 'bank_name', label: 'Vendor Bank Name', alignRight: false },
  { id: 'bank_account_number', label: 'Vendor Bank Number', alignRight: false },
  { id: 'currentBalance', label: 'Current Balance', alignRight: false },
  { id: 'remainingBalance', label: 'Remaining Balance', alignRight: false },
  { id: 'total', label: 'Total Balance', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => {
      const fullName = `${_user.vendor?.first_name} ${_user.vendor?.last_name}`.toLowerCase();
      return fullName.indexOf(query.toLowerCase()) !== -1;
    });
  }

  return stabilizedThis.map((el) => el[0]);
}

export default function PaymentPage() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const data = useSelector((state) => state.payment);
  const [isPayment, setIsPayment] = useState('All');

  useEffect(() => {
    dispatch(getPaymentMethod(isPayment));
  }, [dispatch, isPayment]);

  const [open, setOpen] = useState(null);

  const [paymentId, setpaymentId] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openAddForm, setOpenAddForm] = useState(false);

  const [vendorData, setVendorData] = useState(false);

  // const [openEditForm, setOpenEditForm] = useState(false);

  const handleClickOpenAddForm = () => {
    setOpenAddForm(!openAddForm);
  };

  // const handleClickOpenEditForm = () => {
  //     setOpenEditForm(!openEditForm);
  //     setOpen(false);
  // };

  const handleOpenMenu = (event, row, id) => {
    setpaymentId(id);
    setVendorData(row);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    data.data !== null && data.data !== undefined ? data.data : [],
    getComparator(order, orderBy),
    filterName
  );

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Payments | PakPrint Wishes </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {isPayment} Payments
          </Typography>
          <Box display={'flex'} gap={4}>
            <Button
              variant="contained"
              gutterBottom
              onClick={() => {
                setIsPayment('All');
              }}
            >
              All Payments
            </Button>
            <Button
              variant="contained"
              gutterBottom
              onClick={() => {
                setIsPayment('User');
              }}
            >
              User Payments
            </Button>
            <Button
              variant="contained"
              gutterBottom
              onClick={() => {
                setIsPayment('Vendor');
              }}
            >
              Vendor Payments
            </Button>
          </Box>
        </Stack>

        <Card>
          <UserListToolbar numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrderProductListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id } = row;
                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell component="th" scope="row">
                          <Typography variant="subtitle2" noWrap>
                            {row.vendorId === null
                              ? ''
                              : row.vendorId?.first_name === null || row.vendorId?.first_name === undefined
                              ? null
                              : row.vendorId?.first_name}{' '}
                            {row.vendorId === null
                              ? ''
                              : row.vendorId?.last_name === null || row.vendorId?.last_name === undefined
                              ? null
                              : row.vendorId?.last_name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{row.vendorId === null ? '' : row.vendorId?.email}</TableCell>
                        <TableCell align="left">{row.vendorId === null ? '' : row.vendorId?.cnic}</TableCell>
                        <TableCell align="left">{row.vendorId === null ? '' : row.vendorId?.phone}</TableCell>
                        <TableCell align="left">{row.vendorId === null ? '' : row.vendorId?.address}</TableCell>
                        <TableCell align="left">{row.vendorId === null ? '' : row.vendorId?.bank_name}</TableCell>
                        <TableCell align="left">
                          {row.vendorId === null ? '' : row.vendorId.bank_account_number}
                        </TableCell>
                        <TableCell align="left">{row?.paymentRecieved.toFixed(2)}</TableCell>
                        <TableCell align="left">{row?.paymentPending.toFixed(2)}</TableCell>
                        <TableCell align="left">{row?.paymentTotal.toFixed(2)}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => handleOpenMenu(e, row.vendorId, row?.vendorId?._id)}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleClickOpenAddForm}>
          <Iconify icon={'eva:plus-fill'} sx={{ mr: 2 }} />
          Add
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(`/dashboard/payments/history/${paymentId}`, { state: { vendorData } });
          }}
        >
          <Iconify icon={'ic:outline-remove-red-eye'} sx={{ mr: 2 }} />
          View
        </MenuItem>
      </Popover>
      <AddPaymentDialog
        openAddForm={openAddForm}
        handleClickOpenAddForm={handleClickOpenAddForm}
        paymentId={paymentId}
      />
      {/* <UpdatePaymentDialog
                openEditForm={openEditForm}
                paymentId={paymentId}
                handleClickOpenEditForm={handleClickOpenEditForm}
            /> */}
    </>
  );
}
