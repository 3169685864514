import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomProductServices from '../api/CustomProductServices';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getCustomLayoutSideBarMethod = createAsyncThunk(`/get-customLayoutSideBar`, async () => {
  try {
    const { data } = await CustomProductServices.getSideBar();
    return data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const customLayoutSideBarSlice = createSlice({
  name: 'customLayoutSideBar',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getCustomLayoutSideBarMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomLayoutSideBarMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomLayoutSideBarMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default customLayoutSideBarSlice.reducer;
