import { callAPi } from './http-common';
import http from '../../client/redux/api/http-common';

const add = (data) => {
  return callAPi.post('/add/image/to/gallery', data);
};
const uploadFiles = (data) => {
  return http.post('/uploadCustomDesignFile', data);
};
const deletes = (id) => {
  return callAPi.delete(`/deleteParentCategory/${id}`);
};
const get = (id) => {
  return callAPi.get(`/getImag/${id}`);
};
const getFolder = (id) => {
  return callAPi.get(`/folder/getParentCategory?userType=${id}`);
};

const deleteGallaryImage = (id) => {
  return callAPi.delete(`/deleteImage/${id}`);
};

const GalleryService = {
  add,
  deletes,
  get,
  getFolder,
  deleteGallaryImage,
  uploadFiles
};

export default GalleryService;
