import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ContactUsService from '../api/ContactUsService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getContactUsMethod = createAsyncThunk(`/get-contactUs`, async () => {
  try {
    const { data } = await ContactUsService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteContactUsMethod = createAsyncThunk(`/delete-contactUs`, async (id) => {
  try {
    const { data } = await ContactUsService.deleteMultiple(id);
    console.warn(data);
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getContactUsMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getContactUsMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getContactUsMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete Multiple
    [deleteContactUsMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteContactUsMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteContactUsMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default contactUsSlice.reducer;
