import { callAPi } from './http-common';

const get = (id) => {
    return callAPi.get(`/getWalletHistoryByWallet/${id}`);
};
const PaymentHistoryByIDService = {
    get,
};

export default PaymentHistoryByIDService;
