import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import bgImg from "../Assets/header_bg/terms.png";
import WhatsApp from "../Components/WhatsApp";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid container className="centered top-container">
      <Helmet>
        <title>Our Privacy Policy | PakPrint Wishes </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <WhatsApp />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
        sx={{ backgroundImage: `url(${bgImg})` }}
      >
        <Grid container justifyContent={"center"}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={9}
            xl={9}
            sx={{ display: "flex" }}
          >
            <div className="breadcrumb-text">
              <h1 className="breadcrumb-text__title">Privacy Policy</h1>
              <ul className="breadcrumb-text__nav">
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li>&nbsp;-&nbsp;</li>
                <li>Privacy Policy</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="featured-products-header-title">
              TERMS AND CONDITIONS
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-list">
                <Link className="breadcrumb-list" to="/">
                  Home
                </Link>
                <Link className="breadcrumb-list">TERMS AND CONDITIONS</Link>
              </Breadcrumbs>
            </div>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="service-header-container"
      >
        <Typography
          className="service-header-text"
          // sx={{ textTransform: "uppercase" }}
        >
          Our Privacy Policy
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="service-paragraph-container centered"
      >
        <Typography className="service-paragraph-text">
          We welcome you to our Online Print Store. Please review these terms
          and conditions carefully, as they contain important information about
          your rights and obligations. By using the Site, you agree to be bound
          by these Terms and Conditions, which also cover all orders made via or
          in connection with the Site. You should not use this Website if you do
          not want to be bound by these terms and conditions.
        </Typography>
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10} pt={5}>
        <Grid container pb={5}>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              What Information Do We Collect?
            </Typography>
            <Typography className="service-paragraph-text2">
              We could gather the following data: Name, Contact information,
              including email address, Demographic information like postcode
              preferences, interests and preferences, and Other details related
              to surveys or offers for customers.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              How do we use your information?
            </Typography>
            <Typography className="service-paragraph-text2">
              We can use the data we gather about you when you sign up or
              purchase goods, participate in an event or contest and respond to
              surveys or marketing messages, visit the site, or utilize specific
              other features of the website and allow us to provide the kind of
              products and content that you are interested in. We will use this
              information to better assist you when it comes to responding to
              queries regarding customer service. To quickly process your
              transactions and manage promotions, contests, surveys, contests,
              or other features on the site, we also use this information.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Questions and feedback
            </Typography>
            <Typography className="service-paragraph-text2">
              We welcome you questions and feedback. Any questions or concerns
              about this website's privacy policy should be brought to our
              attention by emailing and providing us with information about your
              concern.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Limitation of Liability
            </Typography>
            <Typography className="service-paragraph-text2">
              We will not be liable for any delay or failure caused by causes
              beyond our control.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">Repayment</Typography>
            <Typography className="service-paragraph-text2">
              As part of our promotional offers and updates through websites,
              SMSs, social media, or emails, we may collect personal data such
              as your name, email address, telephone number, and postal address.
              When processing custom orders, we also gather payment details -
              credit card info being one such method of collecting 50% upfront
              deposits - to process these advance payments efficiently.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Choice /Opt-out
            </Typography>
            <Typography className="service-paragraph-text2">
              There are many size, designs and materials are for products. We
              give variety to our customers. You can selects already designed
              products and can customize them too.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Your Consent?
            </Typography>
            <Typography className="service-paragraph-text2">
              When you purchase something from our website or submit an inquiry
              containing personal or business details to receive a quotation or
              pay by any method for an order or arrange delivery, you agree to
              us obtaining data, storing, and utilizing the information to
              benefit you or to enhance our services and products. In doing
              this, you also agree to us disclosing your identity as our
              customer and use your company's name and logo. Packaging when
              advertising the products or services we offer unless stated
              otherwise.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Customer Data
            </Typography>
            <Typography className="service-paragraph-text2">
              We protect our customer data as you agree to our privacy policy
              and terms and conditions in oder to use our website.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Printing Error Resolution
            </Typography>
            <Typography className="service-paragraph-text2">
              We use your information and feedback to accurately match the
              design to the printed product and ensure it exceeds your
              expectations. Your approval and input are essential in this
              process. Once we receive your order, we kindly request that it is
              reviewed and approved within 48 hours so we may start printing
              immediately and ensure timely delivery of our services. Minor
              color discrepancies may exist between your digital proof (CMYK)
              and printed product (RGB), but rest assured, we work tirelessly to
              achieve optimal printing results by upholding high color accuracy
              standards.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Refund Policy
            </Typography>
            <Typography className="service-paragraph-text2">
              Due to the nature of custom printing products and the other
              products we provide, we require you to notify us via email, or
              phone no within business days after receiving your package if you
              discover the custom made purchased item delivered insufficiently
              or does not meet the specifications. We will not offer a credit or
              refund to you in case of customized products purcahse. However, we
              can reprint your original order if we make a mistake or discover
              it is defective.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Faulty Goods
            </Typography>
            <Typography className="service-paragraph-text2">
              The determination of the defect lies in the decision by our staff.
              Suppose you wish to receive a reprint of your original order. In
              that case, you must send us digital images of the weakness in the
              product or return at your expense the entire amount of your
              initial order within seven business days from the date of your
              initial delivery. The charges associated with expedited order
              processing are not refundable.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Payment Methods
            </Typography>
            <Typography className="service-paragraph-text2">
              All prices and information on our website are presented in RS
              (rupees) and U.S. Dollars (USD). Because of the custom nature of
              print products as well as other offerings we provide, We cannot
              begin working on your requests for printing and other products
              until complete payment, including the total amount of charges,
              taxes as well as handling, and shipping fees, are made through a
              credit card or by the person who visits our website using a valid
              payment method.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Order Placing
            </Typography>
            <Typography className="service-paragraph-text2">
              To ensure the processing, we require customers and users on our
              Site to accept the proof (whether an electronic file or hard
              copies) of the printing product and other products they request.
              <br />
              After customers approve the evidence, the print jobs are sent to
              the printer. After a print job is supported by the customer and
              shipped to press or through our website, no changes can be made to
              the art documents, specifications, or the printing turnaround
              time.
              <br />
              Customers can also cancel orders after the 24-hour time
              limitation. This cancellation is subject to an additional charge
              to pay for work performed by our prepress department and the
              financial cost associated with cancellation. We can't guarantee
              that an order will be canceled after 24 hours.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Delivery and Risk of Loss
            </Typography>
            <Typography className="service-paragraph-text2">
              We ship through courier services and within the Lahore we
              guarantee the delivery of product 100 percent with the insurance
              covergae. But for the orders outside from Lhaore can be shipped
              through courier services and those customers will be charged for
              insurance charges.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Satisfaction Guarantee
            </Typography>
            <Typography className="service-paragraph-text2">
              We always ensure that our customers should be satisfied. We
              deliver our best. And address any isuues and concerts of
              customers.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Personal Information
            </Typography>
            <Typography className="service-paragraph-text2">
              We collect personal information of uers. We protect the personal
              information of users of our website. However we keep record of
              visitors data. But we don’t share sensitiive information of our
              website users to third parties.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={10} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Contact us :
            </Typography>
            <Typography className="service-paragraph-text2">
              You can contact us through email or phone number and order now.
            </Typography>
            <Typography className="service-paragraph-text2">
              <b>Email:</b>{" "}
              <a href="mailto:info.pak.printwishes@gmail.com">
                info.pak.printwishes@gmail.com
              </a>
            </Typography>
            <Typography className="service-paragraph-text2">
              <b>Phone No:</b> 0300-7605006
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicyPage;
