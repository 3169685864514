import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
// sections
import { AppWidgetSummary } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const data = useSelector((state) => state.user.userInfo);

  const userData = useSelector((state) => state.allUser);

  const vendorData = useSelector((state) => state.vendors);

  const productData = useSelector((state) => state.product);

  const orderData = useSelector((state) => state.order);

  return (
    <>
      <Helmet>
        <title> Dashboard | PakPrint Wishes </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
        <Grid container spacing={3}>
          {data && data.role === 'admin' ? (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                      title="Total Users"
                      total={userData && userData.data ? userData.data.length : 0}
                      icon={'mdi:user-group'}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                      title="Total Vendors"
                      total={vendorData && vendorData.data ? vendorData.data.length : 0}
                      color="info"
                      icon={'mdi:user-group'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                      title="Total Products"
                      total={productData && productData.data ? productData.data.length : 0}
                      color="success"
                      icon={'fluent-mdl2:product'}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                      title="Total Order"
                      total={orderData && orderData.data ? orderData.data.length : 0}
                      color="warning"
                      icon={'material-symbols:shopping-cart-outline'}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Typography variant="h4" sx={{ m: 5 }}>
                Payments
              </Typography>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <AppWidgetSummary
                      title="My Earnings"
                      total={data && data.paymentPending ? data.paymentPending : 0}
                      icon={'mdi-wallet'}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <AppWidgetSummary
                      title="Total Vendors Pending Payment"
                      total={data && data?.totalPaymentPending ? data.totalPaymentPending : 0}
                      color="info"
                      icon={'mdi-wallet'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <AppWidgetSummary
                      title="Total Vendors Recieved Payment"
                      total={data && data?.totalPaymentRecieved ? data.totalPaymentRecieved : 0}
                      color="success"
                      icon={'mdi-wallet'}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                      title="Total Order"
                      total={orderData && orderData.data ? orderData.data.length : 0}
                      color="warning"
                      icon={'mdi-wallet'}
                    />
                  </Grid> */}
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Stack width={'100%'} spacing={4}>
                <Grid item xs={12} sm={6} md={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                      <AppWidgetSummary
                        title="Total Products"
                        total={productData && productData.data ? productData.data.length : 0}
                        color="success"
                        icon={'fluent-mdl2:product'}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <AppWidgetSummary
                        title="Total Order"
                        total={orderData && orderData.data ? orderData.data.length : 0}
                        color="warning"
                        icon={'material-symbols:shopping-cart-outline'}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Typography variant="h4" sx={{ m: 5 }}>
                  Payments
                </Typography>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                      <AppWidgetSummary
                        title="My Pending Amounts"
                        total={data && data?.paymentPending ? data.paymentPending : 0}
                        icon={'mdi-wallet'}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <AppWidgetSummary
                        title="My Received Amounts"
                        total={data && data.paymentRecieved ? data.paymentRecieved : 0}
                        color="info"
                        icon={'mdi-wallet'}
                      />
                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                      title="Total Order"
                      total={orderData && orderData.data ? orderData.data.length : 0}
                      color="warning"
                      icon={'mdi-wallet'}
                    />
                  </Grid> */}
                  </Grid>
                </Grid>
              </Stack>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
