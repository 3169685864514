import { callAPi } from './http-common';

const deletes = (id) => {
  return callAPi.delete(`/deleteApplication/${id}`);
};
const deleteMultiple = (data) => {
  return callAPi.post(`/deleteMultipleApplications`, { ids: data });
};
const get = (id) => {
  return callAPi.get(`/applicationForJob/${id}`);
};
const AppliedJobsServices = {
  deletes,
  get,
  deleteMultiple,
};

export default AppliedJobsServices;
