import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Button, InputAdornment, IconButton, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import CryptoJS from 'crypto-js';
import ForgetPasswordService from '../../redux/api/ForgetPasswordService';
import Iconify from '../../../components/iconify/Iconify';

const NewPasswordPages = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState(null);
  const [confirmPassword, setconfirmPassword] = useState(null);
  const [disableComponent, setDisableComponent] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [disableEmailComponent, setDisableEmailComponent] = useState(false);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  const handleClick = async () => {
    const data = {
      email: location.state.email,
      forgotPasswordOtp: otp,
    };
    await ForgetPasswordService.sendOtp(data)
      .then(() => {
        setDisableComponent(false);
        setDisableEmailComponent(true);
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };
  const handleResetPasswordClick = async () => {
    if (password === confirmPassword) {
      const data = {
        email: location.state.email,
        password: CryptoJS.AES.encrypt(password, 'ppw-153').toString(),
        confirmPassword: CryptoJS.AES.encrypt(confirmPassword, 'ppw-153').toString(),
      };
      await ForgetPasswordService.reset(data)
        .then(() => {
          setDisableComponent(true);
          setDisableEmailComponent(true);
          navigate('/login', { replace: true });
        })
        .catch((error) => {
          if (!error.response.data.message) {
            toast.error(error.message, { position: 'top-right' });
          } else {
            toast.error(error.response.data.message, { position: 'top-right' });
          }
        });
    } else {
      toast.error('Confirm Password not match', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Verify OTP & Reset Password | PakPrint Wishes </title>
      </Helmet>
      <Grid className="top-container" container>
        <Typography className="login-title">Verify OTP & Reset Password</Typography>
        <span className="forgot-subtitle-text-span">
          <></>
        </span>
        <Grid item style={{ padding: '0' }} className="login-sub-container">
          <Grid item className="login-form-container" xs={10} sm={10} md={4} lg={4} xl={4}>
            <Box className="login-form">
              <MuiOtpInput
                required
                value={otp}
                length={6}
                onChange={(e) => setOtp(e)}
                TextFieldsProps={{ disabled: disableEmailComponent }}
              />

              {/* <span className="login-forgot-link-span"></span> */}
              <Button
                style={{ marginTop: '20px' }}
                variant="contained"
                className="login-btn"
                disabled={disableEmailComponent}
                onClick={handleClick}
              >
                Verify Otp
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item style={{ padding: '0' }} className="login-sub-container">
          <Grid item className="login-form-container" xs={10} sm={10} md={4} lg={4} xl={4}>
            <Box className="login-form">
              <TextField
                placeholder="Password"
                fullWidth
                disabled={disableComponent}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              <TextField
                placeholder="Re-type password"
                fullWidth
                disabled={disableComponent}
                onChange={(e) => setconfirmPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              {/* <span className="login-forgot-link-span"></span> */}
              <Button
                style={{ marginTop: '150px !important' }}
                variant="contained"
                className="login-btn"
                // disabled={disableComponent}
                onClick={handleResetPasswordClick}
              >
                Reset Password
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NewPasswordPages;
