import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { IMAGE_BASE_URL } from '../../redux/api/http-common';

const VendorProductCard = ({ image }) => (
    <Card className="featured-card">
      <CardActionArea
        onClick={() => {
          const url =
            image?.product_type === 'Product'
              ? `/dashboard/products/product-detail/${image?._id}`
              : `/dashboard/products/product-detail/${image?._id}`;
          window.open(url, '_blank');
        }}
        sx={{ height: '90%' }}
      >
        <CardMedia
          component="img"
          height="250px"
          width="100%"
          sx={{ objectFit: '100%' }}
          image={IMAGE_BASE_URL + image?.images[0]}
          alt={image?.product_title}
        />
        <CardContent sx={{ padding: '10px' }}>
          <Typography gutterBottom component="div" className="card-content-title">
            {image?.product_title}
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="div"
            sx={{
              color: '#58a30a',
              fontWeight: 200,
              fontFamily: 'Poppins',
            }}
          >
            RS. {image?.new_price.toFixed(2)}
          </Typography>
          {!image?.discount ? null : (
            <Typography
              gutterBottom
              variant="body"
              component="div"
              sx={{
                fontWeight: 'bold',
                fontFamily: 'Poppins',
                color: 'rgba(0, 0, 0, 0.5);',
              }}
            >
              <del>RS.{image?.old_price}</del>
              <span style={{ color: 'red' }}> {image?.discount}%off</span>
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );

export default VendorProductCard;
