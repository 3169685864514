import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import http from '../api/http-common';

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null;

const initialState = {
  loading: false,
  userInfo: null,
  userToken,
  error: null,
  success: false,
};
export const userLogin = createAsyncThunk(`login`, async (datas, { rejectWithValue }) => {
  try {
    const { data } = await http.post(`/login`, datas);
    // store user's token in local storage
    localStorage.setItem('userToken', data.token);
    localStorage.setItem('user', data.data.id);
    toast.success('User Login Successfully');
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      toast.error(error.response.data.message, { position: 'bottom-left' });
      throw error;
    } else {
      toast.error(error.message, { position: 'bottom-left' });
      throw error;
    }
  }
});

export const createUser = createAsyncThunk(`login`, async (datas, { rejectWithValue }) => {
  try {
    const { data } = await http.post(`/user/register`, datas);
    toast.success('User Registered Successfully');
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      toast.error(error.response.data.message, { position: 'bottom-left' });
      throw error;
    } else {
      toast.error(error.message, { position: 'bottom-left' });
      throw error;
    }
  }
});

export const deleteAccount = createAsyncThunk(`delete`, async (datas, { rejectWithValue }) => {
  try {
    const { data } = await http.delete(`/user/deleteAccount`);
    // store user's token in local storage
    toast.success('User Delete Successfully');
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      toast.error(error.response.data.message, { position: 'bottom-left' });
      throw error;
    } else {
      toast.error(error.message, { position: 'bottom-left' });
      throw error;
    }
  }
});

export const socialLogin = createAsyncThunk(`socialLogin`, async (datas, { rejectWithValue }) => {
  try {
    const { data } = await http.post(`/socialLogin`, datas);
    // store user's token in local storage
    localStorage.setItem('userToken', data.token);
    localStorage.setItem('user', data.data.id);
    toast.success('User Login Successfully');
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      toast.error(error.response.data.message, { position: 'bottom-left' });
      throw error;
    } else {
      toast.error(error.message, { position: 'bottom-left' });
      throw error;
    }
  }
});

export const getUserDetails = createAsyncThunk(`/getProfile`, async (arg, { getState, rejectWithValue }) => {
  try {
    const { data } = await http.get(`/getProfile`);
    return data.data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      // toast.error(error.response.data.message, { position: 'bottom-left' });
      throw error;
    } else {
      // toast.error(error.message, { position: 'bottom-left' });
      throw error;
    }
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('userToken'); // delete token from storage
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    },
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.userToken = payload.userToken;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    [deleteAccount.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteAccount.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.userToken = payload.userToken;
    },
    [deleteAccount.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // socialLogin user
    [socialLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [socialLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.userToken = payload.userToken;
    },
    [socialLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get user details
    [getUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [getUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
    },
    [getUserDetails.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [createUser.pending]: (state) => {
      state.loading = true;
    },
    [createUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [createUser.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
