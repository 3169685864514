import * as React from 'react';
import moment from 'moment';

import { imageBaseUrl } from '../../client/redux/api/http-common';

export default function BlogCard({ item }) {
  console.log(item);
  return (
    <div className="card-blog">
      <img src={`${imageBaseUrl}${item?.image}`} alt={item && item?.title} className="card-blog__img" />
      <span className="card-blog__footer">
        <h3 style={{ padding: 0, margin: 0 }}>{item && item?.title}</h3>
        <div>
          <span style={{marginRight:'10px'}}>
            <strong>Written by</strong>{' '}
            {item && item?.createdBy && `${item?.createdBy?.first_name} ${item?.createdBy?.last_name}`}
          </span>
          <span style={{marginRight:'10px'}}>
            <strong>Published :</strong> {item && moment(item?.createdAt).format('LL')}
          </span>
          <span>
            <strong>Last Updated on</strong> {item && moment(item?.updatedAt).format('LL')}
          </span>
        </div>
      </span>
    </div>
  );
}
