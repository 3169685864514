import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Box, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import CryptoJS from 'crypto-js';
// components
import CustomPopup from '../../../client/Components/CustomPopup';
import Iconify from '../../../components/iconify';
import { userLogin } from '../../../redux/slice/userSlice';
import UpdateVendorDialog from '../../../components/Dialog/UpdateVendorDialog';
import BecomeVendorPermission from '../../../components/Dialog/BecomeVendorPermission';
import LoginIcon from '../../../client/Assets/loginIcons.png';
import RejectedIcon from '../../../client/Assets/reject 1.png';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openVendorForm, setOpenVendorForm] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const emailAddress = 'info.pak.printwishes@gmail.com'; // Replace with the recipient's email address
  const subject = 'Your Subject Here'; // Replace with the desired email subject
  const body = 'Email body text goes here.'; // Replace with the desired email body text

  // Construct the mailto link
  const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickOpenAddForm = () => {
    setOpenVendorForm(false);
    setOpenAddForm(!openAddForm);
  };

  const handleClickOpenVendorForm = () => {
    setOpenVendorForm(!openVendorForm);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error('Please enter email and password.');
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+\s*$/;
    if (!emailPattern.test(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }
    if (password.length < 6) {
      toast.error('Please enter password greater than 6 characters');
      return;
    }
    try {
      const data = {
        email,
        password: CryptoJS.AES.encrypt(password, 'ppw-153').toString(),
      };
      dispatch(userLogin(data)).then((res) => {
        if (res.payload.success === true) {
          if (res.payload.data.OTPStatus === 'requested') {
            navigate('/verify-otp', { replace: true, state: { emailID: email } });
            return;
          }
          if (res.payload.data.isVendorVerified === 'pending') {
            setIsPending(true);
            return;
          }
          if (res.payload.data.isVendorVerified === 'rejected') {
            setIsRejected(true);
            return;
          }
          if (res.payload.data.isVendorVerified === 'block') {
            setIsBlock(true);
            return;
          }
          if (res.payload.data.role === 'user') {
            localStorage.setItem('access_token', res.payload.access_token);
            handleClickOpenVendorForm();
            return;
          }
          if (
            (res.payload.data.role === 'vendor' && res.payload.data.verified === true) ||
            (res.payload.data.role === 'admin' && res.payload.data.verified === true)
          ) {
            localStorage.setItem('access_token', res.payload.access_token);

            navigate('/dashboard', { replace: true });
          }
        }
        if (res.payload.data.role === 'vendor' && res.payload.data.verified === false) {
          toast.error(res.payload.message);
          navigate('/verify-otp', { replace: true, state: { emailID: email } });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField id="email" name="email" label="Email address" onChange={(e) => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" /> */}
        <Link
          style={{ cursor: 'pointer', color: '#70AD47', textDecoration: 'none' }}
          className="test33"
          to="/admin-forget-password"
        >
          Forget Password
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
      <CustomPopup open={isPending} setOpen={setIsPending}>
        <Box
          p={4}
          sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 2 }}
        >
          <img width={'120px'} height={'120px'} src={LoginIcon} alt="" />
          <Stack spacing={1} alignItems={'center'}>
            <Typography fontWeight={'bold'} fontSize={'22px'}>
              Request Successfully Submitted{' '}
            </Typography>
            <Typography textAlign={'center'} fontSize={'18px'}>
              You will receive a approval through <br /> admin with in 48 hours.
            </Typography>
          </Stack>
          <Button sx={{ color: '#58A30A', fontWeight: 400 }} onClick={() => setIsPending(false)}>
            Ok
          </Button>
        </Box>
      </CustomPopup>
      <CustomPopup open={isRejected} setOpen={setIsRejected}>
        <Box
          p={4}
          sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 2 }}
        >
          <img width={'120px'} height={'120px'} src={RejectedIcon} alt="" />
          <Stack spacing={1} alignItems={'center'}>
            <Typography fontWeight={'bold'} fontSize={'22px'}>
              Your Account Has Been Rejected
            </Typography>
            <Typography textAlign={'center'} fontSize={'18px'}>
              Your account has been rejected, if you want to <br /> find a reason, click here below👇
            </Typography>
          </Stack>
          <Button
            sx={{ borderRadius: '20PX', fontWeight: 400 }}
            variant="contained"
            onClick={() => setIsRejected(false)}
          >
            <a
              href={mailtoLink}
              target="_blank"
              style={{
                textDecoration: 'none',
                color: 'white',
              }}
              rel="noreferrer"
            >
              {' '}
              Contact Us
            </a>
          </Button>
        </Box>
      </CustomPopup>
      <CustomPopup open={isBlock} setOpen={setIsBlock}>
        <Box>
          <Typography p={4}>
            Your Vendor Status is block for some reason from Admin side. If u have find reason contact us on
            info.pak.printwishes@gmail.com.
          </Typography>
        </Box>
      </CustomPopup>
      <BecomeVendorPermission
        openVendorForm={openVendorForm}
        handleClickOpenVendorForm={handleClickOpenVendorForm}
        handleClickOpenAddForm={handleClickOpenAddForm}
      />
      <UpdateVendorDialog openAddForm={openAddForm} handleClickOpenAddForm={handleClickOpenAddForm} emailID={email} />
    </>
  );
}
