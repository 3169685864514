import { callAPi } from './http-common';

const getAllUser = () => {
  return callAPi.get('/getAllUsers');
};
const userRole = (id, data) => {
  return callAPi.patch(`/blockUnblockUser/${id}`, data);
};
const deletes = (id) => {
  return callAPi.delete(`/user/deleteUser/${id}`);
};
const deleteMultiple = (data) => {
  return callAPi.post(`/user/deleteMultipleUsers`, { ids: data });
};
const AllUserServices = {
  getAllUser,
  userRole,
  deletes,
  deleteMultiple,
};

export default AllUserServices;
