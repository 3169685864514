import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/payAmount', data);
};
const get = (data) => {
  return callAPi.get(`/getAllVendorsWalletDetails?userType=${data}`);
};
const update = (id, data) => {
  return callAPi.post(`/UpdateParentCategory/${id}`, data);
};
const PaymentServices = {
  add,
  get,
  update,
};

export default PaymentServices;
