import { Box, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { BlueButton, BootstrapDialog } from '../../Components/Components';
import ProfileNavBar from './ProfileNavBar';
import { getUserDetails } from '../../redux/slice/userSlice';
import UserUpdateService from '../../redux/api/UserUpdateService';

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const ProfilePages = () => {
  const [open, setOpen] = React.useState(false);
  const cachedData = useSelector((state) => state.clientuser.userInfo);
  const userInfo = React.useMemo(() => cachedData, [cachedData]);
  const [fname, setFname] = useState(userInfo && userInfo.first_name !== null ? userInfo.first_name : '');
  const [lname, setlname] = useState(userInfo && userInfo.last_name !== null ? userInfo.last_name : '');
  const [phone, setPhone] = useState(userInfo && userInfo.phone !== null ? userInfo.phone : '');
  const [address, setAddress] = useState(userInfo && userInfo.address !== null ? userInfo.address : '');
  const [city, setCity] = useState(userInfo && userInfo.city !== null ? userInfo.city : '');
  const [country, setCountry] = useState(userInfo && userInfo?.country !== null ? userInfo?.country : '');
  const [state, setState] = useState(userInfo && userInfo.state !== null ? userInfo.state : '');
  const dispatch = useDispatch();
  // console.log(userInfo?.first_name)
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  // automatically authenticate user if token is found
  useEffect(() => {
    dispatch(getUserDetails());
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = () => {
    const formdata = new FormData();
    // formdata.append('id',userInfo?.id)
    formdata.append('first_name', fname);
    formdata.append('last_name', lname);
    formdata.append('phone', phone);
    formdata.append('address', address);
    formdata.append('city', city);
    formdata.append('country', country);
    formdata.append('state', state);
    UserUpdateService.update(formdata)
      .then((response) => {
        toast.success(response.data.message, {
          position: 'bottom-left',
        });
        dispatch(getUserDetails());
        handleClose();
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };
  return (
    <Grid container className="profile-container" spacing={2}>
      <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
        <ProfileNavBar />
      </Grid>
      <Grid item xs={10} sm={4} md={4} lg={4} xl={4}>
        <Typography className="prfile-title">My Profile</Typography>
        <Grid container spacing={2} className="profile-content-container ">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="address-content" ml={2}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box className="address-profile-user-container">
                  <Typography className="get-quote-input-label2">
                    {userInfo?.first_name ? userInfo?.first_name : ''}&nbsp;
                    {userInfo?.last_name ? userInfo?.last_name : ''}
                  </Typography>
                  <Typography className="add-phone" onClick={handleClickOpen}>
                    Edit
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="get-quote-input-label">{userInfo?.phone}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="get-quote-input-label" sx={{ width: '60%' }}>
                  {userInfo?.address}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="get-quote-input-label" sx={{ width: '60%' }}>
                  {userInfo?.city}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="get-quote-input-label" sx={{ width: '60%' }}>
                  {userInfo?.country}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="get-quote-input-label" sx={{ width: '60%' }}>
                  {userInfo?.state}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Edit Address
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className="get-quote-input-label">First Name</Typography>
                <TextField
                  size="small"
                  defaultValue={userInfo?.first_name}
                  className="get-quote-input"
                  fullWidth
                  onChange={(e) => setFname(e.target.value)}
                />
                <br />
                <br />
                <Typography className="get-quote-input-label">Email</Typography>
                <TextField
                  size="small"
                  defaultValue={userInfo?.email || ''}
                  className="get-quote-input"
                  fullWidth
                  disabled
                />
                <br />
                <br />
                <Typography className="get-quote-input-label">Last Name</Typography>
                <TextField
                  size="small"
                  defaultValue={userInfo?.last_name}
                  className="get-quote-input"
                  fullWidth
                  onChange={(e) => setlname(e.target.value)}
                />
                <br />
                <br />
                <Typography className="get-quote-input-label">Mobile Number</Typography>
                <TextField
                  type={'number'}
                  size="small"
                  defaultValue={userInfo?.phone}
                  className="get-quote-input"
                  fullWidth
                  onChange={(e) => setPhone(e.target.value)}
                />
                <br />
                <br />
                <Typography className="get-quote-input-label">City</Typography>
                <TextField
                  size="small"
                  defaultValue={userInfo?.city}
                  className="get-quote-input"
                  fullWidth
                  onChange={(e) => setCity(e.target.value)}
                />
                <br />
                <br />
                <Typography className="get-quote-input-label">Country</Typography>
                <TextField
                  size="small"
                  defaultValue={userInfo?.country}
                  className="get-quote-input"
                  fullWidth
                  onChange={(e) => setCountry(e.target.value)}
                />
                <br />
                <br />
                <Typography className="get-quote-input-label">State</Typography>
                <TextField
                  size="small"
                  defaultValue={userInfo?.state}
                  className="get-quote-input"
                  fullWidth
                  onChange={(e) => setState(e.target.value)}
                />
                <br />
                <br />
                <Typography className="get-quote-input-label">Address</Typography>
                <TextField
                  size="small"
                  defaultValue={userInfo?.address}
                  className="get-quote-input"
                  multiline
                  rows={2}
                  fullWidth
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <BlueButton className="order-history-dialog-btn" onClick={onSubmit}>
                  Save
                </BlueButton>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Box>
    </Grid>
  );
};

export default ProfilePages;
