import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import bgImg from '../../client/Assets/header_bg/about_bg.png';
import WhatsApp from '../../client/Components/WhatsApp';
import SideBar from './sidebar';
import CustomParentCategorySEOService from '../../redux/api/CustomParentCategorySEOService';
import CustomSubCategorySEOService from '../../redux/api/CustomSubCategorySEOService';
import { IMAGE_BASE_URL } from '../../redux/api/http-common';

const CustomLayout = () => {
  const location = useLocation();
  const { id } = useParams();
  const history = useParams();
  const [seoData, setSeoData] = useState(null);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  const pathSegments = location?.pathname.split('/').filter((segment) => segment !== ''); // Split and remove empty segments

  const breadcrumbItems = pathSegments
    .slice(0, !id ? pathSegments.length : pathSegments.length - 1)
    .map((segment, index) => (
      <React.Fragment key={index}>
        <li>
          {segment.replace(/-/g, ' ')}
          {(!id ? pathSegments.length - 1 : pathSegments.length - 2) === index ? ' CATEGORY' : null}
        </li>
        {index < (!id ? pathSegments.length - 1 : pathSegments.length - 2) && <li>&nbsp;-&nbsp;</li>}{' '}
        {/* Add separator if not the last segment */}
      </React.Fragment>
    ));
  useEffect(() => {
    if (history && Object.keys(history).length > 0 && !history?.parentTitle) {
      getParentSEO();
    } else if (history && Object.keys(history).length > 0) {
      getSubSEO();
    }
  }, [id]);

  const getParentSEO = async () => {
    await CustomParentCategorySEOService.getbyid(id)
      .then((res) => {
        setSeoData(res?.data?.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubSEO = async () => {
    await CustomSubCategorySEOService.getbyid(id)
      .then((res) => {
        setSeoData(res?.data?.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Grid container className="centered top-container">
      <Helmet>
        <title>
          {!seoData || Object.keys(seoData).length === 0 || !seoData?.webTitle
            ? pathSegments[!id ? pathSegments.length - 1 : pathSegments.length - 2]
                .toLocaleUpperCase()
                .replace(/-/g, ' ')
            : seoData?.webTitle}{' '}
          | PakPrint Wishes
        </title>
        <meta
          name="description"
          content={
            !seoData || Object.keys(seoData).length === 0 || !seoData?.webDescription
              ? 'We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!'
              : seoData?.webDescription
          }
        />
        <meta
          property="og:title"
          content={`${
            !seoData || Object.keys(seoData).length === 0 || !seoData?.webTitle
              ? pathSegments[!id ? pathSegments.length - 1 : pathSegments.length - 2]
                  .toLocaleUpperCase()
                  .replace(/-/g, ' ')
              : seoData?.webTitle
          } CATEGORY | PakPrint Wishes`}
        />
        <meta
          property="og:description"
          content={
            !seoData || Object.keys(seoData).length === 0 || !seoData?.webDescription
              ? 'We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!'
              : seoData?.webDescription
          }
        />
        <meta
          property="og:image"
          content={
            !seoData ||
            Object.keys(seoData).length === 0 ||
            !seoData?.customParentCategoryId ||
            Object.keys(seoData?.customParentCategoryId).length === 0 ||
            !seoData?.customParentCategoryId?.customParentCategoryImage
              ? !seoData?.customChildCategoryId ||
                Object.keys(seoData?.customChildCategoryId).length === 0 ||
                !seoData?.customChildCategoryId?.customChildCategoryImage
                ? bgImg
                : IMAGE_BASE_URL + seoData?.customChildCategoryId?.customChildCategoryImage
              : IMAGE_BASE_URL + seoData?.customParentCategoryId?.customParentCategoryImage
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
        sx={{ backgroundImage: `url(${bgImg})` }}
      >
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9} sx={{ display: 'flex' }}>
            <div className="breadcrumb-text">
              <h1 className="breadcrumb-text__title">
                {pathSegments[!id ? pathSegments.length - 1 : pathSegments.length - 2].replace(/-/g, ' ')} CATEGORY
              </h1>
              <ul className="breadcrumb-text__nav">
                <li>
                  <Link to={'/'}>Home</Link>
                </li>
                <li>&nbsp;-&nbsp;</li>
                {breadcrumbItems}
                {/* <li>&nbsp;-&nbsp;</li>
                <li>Custom</li>
                <li>&nbsp;-&nbsp;</li>
                <li>Product</li> */}
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid py={4} item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Grid container p={1}>
          <Grid item xs={1} sm={1} md={4} lg={3}>
            <SideBar />
          </Grid>
          <Grid item xs={11} sm={11} md={8} lg={9}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
      <WhatsApp />
    </Grid>
  );
};

export default CustomLayout;
