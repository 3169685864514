import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const SavedJobCard = () => {
  return (
    <Grid
      container
      spacing={2}
      className="job-card"
      component={Link}
      to={`/jobs/saved-jobs/${'kurioje buvo ir Lorem Ipsum versija'.replace(/\s+/g, '-').toLowerCase()}/1`}
    >
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="subtitle1">kurioje buvo ir Lorem Ipsum versija.</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="body2">4 hours ago</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="body2">Proposals-2</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <Typography variant="h6">PKR 334</Typography>
      </Grid>
    </Grid>
  );
};

export default SavedJobCard;
