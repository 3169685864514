import http from "./http-common";

const create = (data) => {
  return http.post("/addComment", data);
};
const get = (productId) => {
  return http.get(`/getComments/${productId}`);
};

const ChatServices = {
  create,
  get,
};

export default ChatServices;
