import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { imageBaseUrl } from '../redux/api/http-common';
import { GreenButton } from './Components';

export default function ServiceDialog({ open = false, handleClose, serviceDetail }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{!serviceDetail?.heading ? '' : serviceDetail?.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div
                dangerouslySetInnerHTML={{ __html: !serviceDetail?.description ? '' : serviceDetail?.description }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                src={imageBaseUrl + serviceDetail?.image}
                alt={!serviceDetail?.heading ? '' : serviceDetail?.heading}
                width={'100%'}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GreenButton
          size="small"
          onClick={() => {
            navigate('/custom-design');
            handleClose();
          }}
          autoFocus
        >
          Custom Product
        </GreenButton>
        <GreenButton
          size="small"
          onClick={() => {
            navigate('/art-print-&-stationery');
            handleClose();
          }}
        >
          Shop
        </GreenButton>
      </DialogActions>
    </Dialog>
  );
}
