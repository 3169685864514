import { callAPi } from './http-common';

const softDelete = (id) => {
  return callAPi.patch(`/softDeleteCustomProductOrder/${id}`);
};
const premanentDelete = (id) => {
  return callAPi.patch(`/deletePermanentlyCustomProductOrder/${id}`);
};
const restore = (id) => {
  return callAPi.patch(`/restoreSoftDeleteCustomProductOrder/${id}`);
};
const get = () => {
  return callAPi.get('/getAllCustomProductOrders');
};
const getPending = () => {
  return callAPi.get('/getAllPendingCustomProductOrders');
};
const getDeliver = () => {
  return callAPi.get('/getAllDeliverCustomProductOrders');
};
const getConfirm = () => {
  return callAPi.get('/getAllConfirmCustomProductOrders');
};
const getComplete = () => {
  return callAPi.get('/getAllCompletedCustomProductOrders');
};
const getConcelled = () => {
  return callAPi.get('/getAllCancelledCustomProductOrders');
};
const getAllDeleted = () => {
  return callAPi.get('/getAllDeletedCustomProductOrders');
};
const assignCompete = (data) => {
  return callAPi.patch('/assignCompleteCustomProductOrderToAsingleVendor', data);
};
const assignOneItem = (data) => {
  return callAPi.patch('/assignSingleCustomProductOrderToAsingleVendor', data);
};
const updateStatus = (id, data) => {
  return callAPi.patch(`/changeCustomProductOrderStatus/${id}`, data);
};
const getbyId = (id) => {
  return callAPi.get(`/getCustomProductOrderById/${id}`);
};
const CustomProductOrdersService = {
  softDelete,
  get,
  getAllDeleted,
  restore,
  premanentDelete,
  assignCompete,
  assignOneItem,
  updateStatus,
  getPending,
  getDeliver,
  getConfirm,
  getComplete,
  getConcelled,
  getbyId,
};

export default CustomProductOrdersService;
