import http from './http-common';

const getbyid = (id) => {
  return http.get(`/seo/get/${id}`);
};
const ClientProductSEOService = {
  getbyid,
};

export default ClientProductSEOService;
