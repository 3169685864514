import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/createBlog', data);
};
const get = () => {
  return callAPi.get('/getAllBlogs');
};
const getPublic = () => {
  return callAPi.get('/getAllBlogsPublic');
};
const getSlider= () => {
  return callAPi.get('/getBlogSlider');
};
const getbyidPublic = (id) => {
  return callAPi.get(`/getBlogByIdPublic/${id}`);
};
const getbyid = (id) => {
  return callAPi.get(`/getBlogById/${id}`);
};
const deleteItem = (id) => {
  return callAPi.patch(`/deleteBlogById/${id}`);
};
const update = (id, data) => {
  return callAPi.patch(`/updateBlogById/${id}`, data);
};
const status = (id) => {
  return callAPi.patch(`/updateBlogStatusById/${id}`);
};
const BlogService = {
  add,
  get,
  getbyid,
  deleteItem,
  update,
  status,
  getPublic,
  getbyidPublic,
  getSlider
};

export default BlogService;
