import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { imageBaseUrl } from '../../client/redux/api/http-common';

const BlogListCard = ({ item }) => {
  return (
    <Card sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}>
      <CardActionArea
        component={Link}
        to={`/blogs/${item.title.replace(/\s+/g, '-').toLowerCase()}/${item?._id}`}
        state={{ id: item._id }}
        sx={{ height: '90%' }}
      >
        <CardMedia
          component="img"
          height="250px"
          width="100%"
          sx={{ objectFit: '100%',borderRadius:'10px' }}
          image={`${imageBaseUrl}${item?.image}`}
          alt={item && item?.title}
        />
        <CardContent sx={{ padding: '10px' }}>
          <Typography gutterBottom className="card-content-title">
            {item && item?.title}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography
              gutterBottom
              variant="subtitle1"
              sx={{
                color: '#58a30a',
                fontWeight: 200,
                fontFamily: 'Poppins',
              }}
            >
              {item && moment(item?.createdAt).format('LL')}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BlogListCard;
