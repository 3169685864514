import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { Box, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import easypaisaimg from '../../Assets/paymentIcon/image 27.png';
import jazzcashimg from '../../Assets/paymentIcon/image 25.png';
import cashondeliverimg from '../../Assets/paymentIcon/image 29.png';
import { BlueButton } from '../../Components/Components';
import http from '../../redux/api/http-common';

const Tab = styled(TabUnstyled)`
  color: black;
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px 6px;
  border: none;
  height: 100%;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #f8f8f8;
    color: black;
  }

  &:focus {
    color: #fff;
    outline: 1px solid gray;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: black;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)(
  ({ theme }) => `
  width: 100%
  background: white;
  `
);

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `
);

const PaymentMethodPage = () => {
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [disabledBtn, setDisabledBtn] = useState(false);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('orderitem'));
    if (items) {
      setItem(items);
    }
  }, []);

  const updateSubmit = async () => {
    setDisabledBtn(true);
    const payload = {
      orderId: !item || !item?.products || item?.products.length === 0 ? null : item?._id,
      customOrderId:
        !item?.customProduct || item?.customProduct.length === 0
          ? null
          : !item?.products || item?.products.length === 0
          ? item?._id
          : item?.customOrderId,
      payment_method: 'Cash on Delivery',
    };
    await http
      .post(`/choosePaymentMethod`, payload)
      .then((res) => {
        if (res.data.success === true) {
          const responseItem = res?.data?.data;
          toast.success('Your Order is Confirm Successfully', { position: 'bottom-left' });
          localStorage.removeItem('orderitem');
          navigate(
            `/confirm-order/${
              !responseItem?.customProduct || responseItem?.customProduct.length === 0
                ? responseItem?.orderId
                : responseItem?.customOrderCode
            }`
          );
        }
      })
      .catch((error) => {
        setDisabledBtn(false);
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };
  return (
    <Grid container className="top-container row-space-around-flex">
      <Helmet>
        <title>Payment | PakPrint Wishes </title>
      </Helmet>
      <Grid item xs={10} sm={10} md={6} lg={6} xl={6} pt={2}>
        <TabsUnstyled defaultValue={0} style={{ width: '100%' }}>
          <TabsList>
            <Tab style={{ margin: 0 }}>
              <Box className="pm-col-container">
                <img src={cashondeliverimg} alt="Cash on Delivery" className="pm-tab-img" />
                <Typography className="pm-tab-text">Cash on Delivery</Typography>
              </Box>
            </Tab>
            <Tab>
              <Box className="pm-col-container">
                <img src={jazzcashimg} alt="Jazz Cash" className="pm-tab-img" />
                <Typography className="pm-tab-text">Jazz Cash</Typography>
              </Box>
            </Tab>
            <Tab>
              <Box className="pm-col-container">
                <img src={easypaisaimg} alt="EasyPaisa" className="pm-tab-img" />
                <Typography className="pm-tab-text">EasyPaisa</Typography>
              </Box>
            </Tab>
          </TabsList>
          <TabPanel value={0}>
            <Typography className="pm-tab-content-text" pb={3} pt={2}>
              You can pay in cash to our courier when you receive the goods at your doorstep.
            </Typography>
            <BlueButton disabled={disabledBtn} onClick={updateSubmit}>
              Confirm Order
            </BlueButton>
          </TabPanel>
          <TabPanel value={1}>
            <Typography className="pm-tab-content-text" pb={3} pt={2}>
              Comming Soon
            </Typography>
          </TabPanel>
          <TabPanel value={2}>
            <Typography className="pm-tab-content-text" pb={3} pt={2}>
              Comming Soon
            </Typography>
          </TabPanel>
        </TabsUnstyled>
      </Grid>
      <Grid item xs={10} sm={10} md={3} lg={3} xl={3} pt={2}>
        <Typography>Order Summary</Typography>
        <Box className="row-space-between-container" py={2}>
          <Typography className="co-header-text-font6">Total Items</Typography>
          <Typography className="co-header-text-font2">
            {(!item?.products || item?.products.length === 0 ? 0 : item?.products.length) +
              (!item?.customProduct || item?.customProduct.length === 0 ? 0 : item?.customProduct.length)}
          </Typography>
        </Box>
        <Box className="row-space-between-container" py={2}>
          <Typography className="co-header-text-font6" sx={{ color: 'red !important' }}>
            Note: Ship Fee Calculate on the base of Distance and Weight.
          </Typography>
          {/* <Typography className="co-header-text-font6">Total Shaping Fee</Typography>
          <Typography className="co-header-text-font2">{item?.total_shipping_fee}</Typography> */}
        </Box>
        <Box className="row-space-between-container" py={2}>
          <Typography className="co-header-text-font6">Total:</Typography>
          <Typography className="co-header-text-font2" sx={{ color: '#58A30A' }}>
            Rs.{' '}
            {(
              (!item?.sub_total ? 0 : Number(item?.sub_total)) + (!item?.customTotal ? 0 : Number(item?.customTotal))
            ).toFixed(2)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PaymentMethodPage;
