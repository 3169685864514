import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import PartnerService from '../api/PartnerService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const addPartnerMethod = createAsyncThunk(`/add-partner`, async (datas) => {
  try {
    const { data } = await PartnerService.add(datas);
    console.warn(data);
    toast.success("Partner added successfully");
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
export const getPartnerMethod = createAsyncThunk(`/get-partner`, async () => {
  try {
    const { data } = await PartnerService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteSelectedPartnerMethod = createAsyncThunk(`/delete-partner`, async (id) => {
  try {
    const { data } = await PartnerService.deleteMultiple(id);
    toast.success("Partner deleted successfully");
    console.warn(data);
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {},
  extraReducers: {
    // add
    [addPartnerMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addPartnerMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = [...state.data, payload];
    },
    [addPartnerMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get
    [getPartnerMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPartnerMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getPartnerMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete Multiple
    [deleteSelectedPartnerMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedPartnerMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedPartnerMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default partnerSlice.reducer;
