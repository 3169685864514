import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IMAGE_BASE_URL } from '../../redux/api/http-common';

const CustomProductCard = ({ image, path }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        navigate(`${path}/${image.titles.replace(/\s+/g, '-').toLowerCase()}/${image._id}`);
      }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          borderRadius: '0px',
        }}
      >
        <Button
          className="custom-product-card"
          sx={{
            cursor: 'none',
            zIndex: '19999',
            position: 'absolute',
            alignSelf: 'flex-start',
            marginTop: '10px',
            marginLeft: '10px',
          }}
        >
          Custom
        </Button>
        <CardActionArea
          sx={{
            height: '90%',
            width: 'auto',
          }}
        >
          <CardMedia
            component="img"
            height="250px"
            width="auto"
            image={
              !image?.images || image?.images.length === 0
                ? '/assets/Rectangle 16934.png'
                : `${IMAGE_BASE_URL}${image?.images[0]}`
            }
            alt={!image?.prices ? 'N/A' : image?.prices}
          />
        </CardActionArea>
      </Card>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <Typography gutterBottom variant="subtitle1">
          Starting Price PKR {!image?.prices ? '0' : image?.prices}
        </Typography>
        <Typography
          gutterBottom
          variant="caption"
          sx={{
            color: 'gray',
            fontWeight: 200,
            fontFamily: 'Poppins',
          }}
        >
          Finishing Option are Available
        </Typography>
      </CardContent>
    </Box>
  );
};
export default CustomProductCard;
