import { Grid, Typography } from '@mui/material';
import React from 'react';
import SavedJobCard from '../components/card/SavedJobCard';

const SavedJobsPages = () => {
  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} py={3} p={1}>
      <Grid item xs={12} md={10}>
        <Grid container spacing={3}>
          <Grid item xs={12} py={1} mt={2}>
            <Typography variant="h6">Saved Jobs(12)</Typography>
          </Grid>
          <Grid item xs={12}>
            <SavedJobCard />
          </Grid>
          <Grid item xs={12}>
            <SavedJobCard />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SavedJobsPages;
