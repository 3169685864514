import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BlueButton } from './Components';
import { addToCart } from '../redux/slice/cartSlice';
import { imageBaseUrl } from '../redux/api/http-common';

const AccessoriesProductCard = ({ image }) => {
  const dispatch = useDispatch();
  // Add to cart
  const handleAddToCart = (product) => {
    const products = { products: product, cartQty: 1 };
    dispatch(addToCart(products));
  };
  return (
    <Card className="featured-card">
      <CardActionArea
        component={Link}
        to={`/product-accessories/${image.product_title.replace(/\s+/g, '-').toLowerCase()}/${image?._id}`}
        state={{ id: image._id }}
        sx={{ height: '90%' }}
      >
        <CardMedia
          component="img"
          height="250px"
          width="100%"
          sx={{ objectFit: '100%' }}
          image={imageBaseUrl + image?.images[0]}
          alt={image.product_title}
        />
        <CardContent sx={{ padding: '10px' }}>
          <Typography gutterBottom component="div" className="card-content-title">
            {!image.product_title ? null : image.product_title.slice(0, 25)}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography
              gutterBottom
              variant="subtitle1"
              component="div"
              sx={{
                color: '#58a30a',
                fontWeight: 200,
                fontFamily: 'Poppins',
              }}
            >
              RS. {image.new_price.toFixed(2)}
            </Typography>
            {!image?.discount ? null : (
              <Typography
                gutterBottom
                variant="body"
                component="div"
                sx={{
                  fontWeight: 'bold',
                  fontFamily: 'Poppins',
                  color: 'rgba(0, 0, 0, 0.5);',
                  paddingLeft: '5px',
                }}
              >
                <del>RS.{image.old_price}</del>
                <span style={{ color: 'red' }}> {image?.discount.toFixed(2)}%off</span>
              </Typography>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
      <BlueButton className="card-btn" onClick={() => handleAddToCart(image)}>
        Add to Cart
      </BlueButton>
    </Card>
  );
};

export default AccessoriesProductCard;
