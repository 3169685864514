import { Box, Grid, ListItemButton, Pagination, PaginationItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Helmet } from 'react-helmet-async';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { retrieveCategoryChildProduct, retrieveCategoryParentProduct } from '../../redux/slice/categoryProductSlice';
import { fetchSubCategory } from '../../redux/slice/subCategorySlice';
import ProductCard from '../../Components/ProductCard';
import { imageBaseUrl } from '../../redux/api/http-common';
import CategoryServices from '../../redux/api/CategoryServices';
import bgImg from '../../Assets/header_bg/shop_bg.png';
import ParentCategorySEO from '../../../redux/api/ParentCategorySEO';
import ChildCategorySEO from '../../../redux/api/ChildCategorySEO';
import ProgressBar from '../../../components/progress/ProgressBar';

const CategoryDetail = () => {
  const { id } = useParams();
  // const location = useLocation();
  // const idCategoryRes = location?.state?.id;
  // const id = idCategoryRes._id;
  // const name = location.state.name;
  const cachedDatas = useSelector((state) => state.clientsubcategory.data);
  const subdata = React.useMemo(() => cachedDatas, [cachedDatas]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSubCategory(id));
    window.scrollTo(0, 0);
  }, [dispatch, id]);

  // Product Section
  const [currentPage, setCurrentPage] = useState(1);
  const [productPerPage] = useState(12);
  const [subcategoryRes, setSubcategoryRes] = useState(null);
  const [subcategorySEO, setSubcategorySEO] = useState(null);
  // console.log(subcategoryRes)
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  //
  const cachedData = useSelector((state) => state.categoryproduct);
  const data = React.useMemo(() => cachedData, [cachedData]);
  const initFetch = React.useCallback(() => {
    dispatch(retrieveCategoryParentProduct(id));
  }, [dispatch, id]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const totalProducts = data.data?.length; // Total number of products
  const totalPages = Math.ceil(totalProducts / productPerPage); // Calculate total pages
  const indexOfLastPost = currentPage * productPerPage;
  const indexOfFirstPost = indexOfLastPost - productPerPage;
  const currentPosts = !data.data || data.data.length === 0 ? [] : data.data?.slice(indexOfFirstPost, indexOfLastPost);

  const getCategoryDetails = async () => {
    await CategoryServices.getCategoryDetailById(id)
      .then((category) => {
        // console.log(category.data.data);
        setSubcategoryRes(category.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getCategorySEODetails = async () => {
    await ParentCategorySEO.getbyid(id)
      .then((category) => {
        // console.log(category.data.data);
        setSubcategorySEO(category.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getCategoryDetails();
    getCategorySEODetails();
  }, [id]);

  const getSubCategorySEODetails = async (ids) => {
    await ChildCategorySEO.getbyid(ids)
      .then((category) => {
        // console.log(category.data.data);
        setSubcategorySEO(category.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  return (
    <Box sx={{ width: '100%' }}>
      {data?.loading === true ? (
        <ProgressBar />
      ) : (
        <Grid container className="centered top-container">
          <Helmet>
            <title>
              {!subcategorySEO?.web_title ? 'Category Product & Detail | PakPrint Wishes' : subcategorySEO?.web_title}
            </title>
            <meta
              name="description"
              content={
                !subcategorySEO?.web_description
                  ? 'We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!'
                  : subcategorySEO?.web_description
              }
            />
            <meta
              property="og:title"
              content={
                !subcategorySEO?.web_title ? 'Category Product & Detail | PakPrint Wishes' : subcategorySEO?.web_title
              }
            />
            <meta
              property="og:description"
              content={
                !subcategorySEO?.web_description
                  ? 'We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!'
                  : subcategorySEO?.web_description
              }
            />
            <meta
              property="og:image"
              content={!subcategoryRes?.category_image ? null : imageBaseUrl + subcategoryRes?.category_image}
            />
            <meta property="og:type" content="website" />
            <meta property="og:image:width" content="100%" />
            <meta property="og:url" content={window.location.href} />
            <link rel="canonical" href={window.location.href} />
          </Helmet>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="featured-products-header"
            sx={{ backgroundImage: `url(${bgImg})` }}
          >
            <Grid container justifyContent={'center'}>
              <Grid item xs={12} sm={12} md={12} lg={9} xl={9} sx={{ display: 'flex' }}>
                <div className="breadcrumb-text">
                  <h2 className="breadcrumb-text__title">Category</h2>
                  <ul className="breadcrumb-text__nav">
                    <li>
                      <Link to={'/'}>Home</Link>
                    </li>
                    <li>&nbsp;-&nbsp;</li>
                    <li>{subcategoryRes?.name}</li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="featured-products-header-title">
              {name}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-list">
                <Link className="breadcrumb-list" to="/">
                  Home
                </Link>
                <Typography className="breadcrumb-list">{name}</Typography>
              </Breadcrumbs>
            </div>
          </Grid>
        </Grid>
      </Grid> */}
          {subcategoryRes === undefined ? null : (
            <Grid item xs={12} sm={12} md={12} lg={11} xl={11} p={2} mt={1}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
                >
                  <img
                    src={imageBaseUrl + subcategoryRes?.category_image}
                    width={'auto'}
                    height={'400px'}
                    alt={subcategoryRes?.name}
                    // style={{ height: "500px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <h1 className="category-detail-title">{subcategoryRes?.name}</h1>
                  <Typography variant="body1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: subcategoryRes?.category_description,
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container className="row-justify-center-flex" justifyContent={'center'}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
              <Grid container pt={3} justifyContent={'center'}>
                <Grid item xs={11} sm={11} md={12}>
                  <Typography variant="h4">Category</Typography>
                </Grid>
                {subdata
                  ? subdata.map((res) => (
                      <Grid item xs={10} sm={10} md={12} lg={12} xl={12} pt={1} key={res.id}>
                        <ListItemButton
                          onClick={() => {
                            dispatch(retrieveCategoryChildProduct(res._id));
                            setSubcategoryRes(res);
                            getSubCategorySEODetails(res._id);
                          }}
                        >
                          <Typography className="atc-header-text-font2 ">{res.name}</Typography>
                        </ListItemButton>
                      </Grid>
                    ))
                  : ''}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              // className="featured-produts-containers"
            >
              <Typography variant="h4" p={2}>
                Buy Now
              </Typography>
              <Grid container spacing={2} className="featured-products-container">
                {currentPosts?.length > 0 ? (
                  currentPosts?.map((image, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index} sx={{ textAlign: 'left' }}>
                      <ProductCard image={image} />
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <p>Product not found</p>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="pagination-container">
            {currentPosts?.length > 0 ? (
              <Pagination
                count={totalPages} // Show total pages instead of current page
                page={currentPage} // Set the current page
                renderItem={(item) => (
                  <PaginationItem components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />
                )}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            ) : null}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CategoryDetail;
