import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/addProduct', data);
};
const deletes = (id) => {
  return callAPi.patch(`/softDeleteProduct/${id}`);
};
const premanentDelete = (id) => {
  return callAPi.delete(`/permanentDeleteProducts/${id}`);
};
const restore = (id) => {
  return callAPi.patch(`/restoreProduct/${id}`);
};
const deleteMultiple = (data) => {
  return callAPi.post(`/multiplePermanentDeleteProducts`, { ids: data });
};
const deleteProductMultiple = (data) => {
  return callAPi.post(`/product/multipleSoftDeleteProduct`, { ids: data });
};
const deleteAccessoriesMultiple = (data) => {
  return callAPi.post(`/accessories/multipleSoftDeleteProduct`, { ids: data });
};
const get = () => {
  return callAPi.get('/vendor/getAllProducts');
};
const getAccessories = () => {
  return callAPi.get('/vendor/getAllAccessories');
};
const getAllDeleted = () => {
  return callAPi.get('/getAllDeletedProducts');
};
const getbyId = (id) => {
  return callAPi.get(`/user/getProduct/${id}`);
};
const update = (id, data) => {
  return callAPi.post(`/updateProduct/${id}`, data);
};
const status = (id) => {
  return callAPi.post(`/admin/verifyProduct`, { productId: id });
};
const ProductServices = {
  add,
  deletes,
  get,
  update,
  deleteMultiple,
  getAccessories,
  getAllDeleted,
  restore,
  premanentDelete,
  deleteProductMultiple,
  deleteAccessoriesMultiple,
  status,
  getbyId
};

export default ProductServices;
