import http from "./http-common";

const getAll = () => {
  return http.get("/getAllNewArrival");
};

const NewArrivalsServices = {
  getAll,
};

export default NewArrivalsServices;
