import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import BuyNow from './HomePage/BuyNow';
import Categories from './HomePage/Categories';
import ClientsCompanies from './HomePage/ClientsCompanies';
import FeaturedProducts from './HomePage/FeaturedProducts';
import HomeSlider from './HomePage/HomeSlider';
import NewArrivals from './HomePage/NewArrivals';
import PrintingAccessories from './HomePage/PrintingAccessories';
import USP from './HomePage/USP';
import FAQ from './HomePage/FAQ';
import WhatsApp from '../Components/WhatsApp';
import CustomDesign from '../../pages/clientpage/CustomDesign';

const HomePage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Grid container spacing={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Helmet>
          <title>Best Online Printing Company in Pakistan | Pak Print Wishes</title>
          <meta
            name="description"
            content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
          />
          <meta property="og:title" content="Best Online Printing Company in Pakistan | Pak Print Wishes" />
          <meta
            property="og:description"
            content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
          />
          <meta property="og:image" content="https://ahrefs.com/blog/wp-content/uploads/2020/01/fb-open-graph-1.jpg" />
          <meta property="og:type" content="website" />
          <meta property="og:image:width" content="100%" />
          <meta property="og:url" content={window.location.href} />

          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
          <HomeSlider />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={11}
          xl={11}
          sx={{ marginTop: { xs: '220px', sm: '200px', md: '-40px', lg: '0px' } }}
        >
          <USP />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={11} xl={11}>
          <FeaturedProducts />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={11} xl={11}>
          <NewArrivals />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={11} xl={11}>
          <CustomDesign />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={11} xl={11}>
          <Categories />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={11} xl={11}>
          <BuyNow />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={11} xl={11}>
          <PrintingAccessories />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={10} xl={10}>
          <ClientsCompanies />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={11} xl={11}>
          <FAQ />
        </Grid>
        <WhatsApp />
      </Grid>
      {/* <AlertDialogBox open={isPopUp} handleClose={handleClose} /> */}
    </>
  );
};
export default HomePage;
