import { Helmet } from 'react-helmet-async';
import { filter, toLower, includes } from 'lodash';
import { useEffect, useRef, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import Label from '../components/label';
import { IMAGE_BASE_URL } from '../redux/api/http-common';
import AddProductDialog from '../components/Dialog/AddFormDialog/AddProductDialog';
import UpdateProductDialog from '../components/Dialog/UpdateFormDialog/UpdateProductDialog';
import {
  deleteSelectedAccessoriesMethod,
  deleteSelectedPermanentMethod,
  deleteSelectedProductMethod,
  getAccessoriesProductMethod,
  getDeltedProductMethod,
  getProductMethod,
} from '../redux/slice/productSlice';
import ProductServices from '../redux/api/ProductServices';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: ' Product Name', alignRight: false },
  { id: 'productCode', label: ' Product Code', alignRight: false },
  { id: 'product_brand_id.name', label: 'Brand Name', alignRight: false },
  { id: 'product_type', label: 'Product Type', alignRight: false },
  { id: 'product_category_id.name', label: 'Main Category', alignRight: false },
  { id: 'product_child_category_id.name', label: 'Sub Category', alignRight: false },
  { id: 'accessories_condition', label: 'Condition', alignRight: false },
  { id: 'accessories_type', label: 'Type', alignRight: false },
  { id: 'accessories_Location', label: 'Location', alignRight: false },
  { id: 'product_advance', label: 'Advance', alignRight: false },
  { id: 'delivered_days', label: 'Delivery Day', alignRight: false },
  { id: 'minQuantity', label: 'Min. Quantity', alignRight: false },
  // { id: 'shiping_fee', label: 'Shipping Fee', alignRight: false },
  { id: 'old_price', label: 'Total Price', alignRight: false },
  { id: 'discount', label: 'Discount', alignRight: false },
  { id: 'new_price', label: 'New Price', alignRight: false },
  { id: 'featured_product', label: 'Featured Product', alignRight: false },
  { id: 'product_new_arrival', label: 'New Arrival', alignRight: false },
  { id: 'buynow', label: 'Buy Now', alignRight: false },
  { id: 'product_description', label: 'Descriptions', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'images', label: 'Image', alignRight: false },
  { id: 'restore', label: 'Restore', alignRight: false },
  { id: '' },
];
const TABLE_PRODUCT_HEAD = [
  { id: 'product_title', label: ' Product Name', alignRight: false },
  { id: 'productCode', label: ' Product Code', alignRight: false },
  { id: 'product_brand_id.name', label: 'Brand Name', alignRight: false },
  { id: 'product_type', label: 'Product Type', alignRight: false },
  { id: 'product_category_id.name', label: 'Main Category', alignRight: false },
  { id: 'product_child_category_id.name', label: 'Sub Category', alignRight: false },
  { id: 'product_advance', label: 'Advance', alignRight: false },
  { id: 'delivered_days', label: 'Delivery Day', alignRight: false },
  { id: 'minQuantity', label: 'Min. Quantity', alignRight: false },
  // { id: 'shiping_fee', label: 'Shipping Fee', alignRight: false },
  { id: 'old_price', label: 'Total Price', alignRight: false },
  { id: 'discount', label: 'Discount', alignRight: false },
  { id: 'new_price', label: 'New Price', alignRight: false },
  { id: 'featured_product', label: 'Featured Product', alignRight: false },
  { id: 'product_new_arrival', label: 'New Arrival', alignRight: false },
  { id: 'buynow', label: 'New Arrival', alignRight: false },
  { id: 'product_description', label: 'Descriptions', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'images', label: 'Image', alignRight: false },
  { id: '' },
];

const TABLE_ACCESSORIES_HEAD = [
  { id: 'product_title', label: ' Product Name', alignRight: false },
  { id: 'productCode', label: ' Product Code', alignRight: false },
  { id: 'product_brand_id.name', label: 'Brand Name', alignRight: false },
  { id: 'product_type', label: 'Product Type', alignRight: false },
  { id: 'product_category_id.name', label: 'Main Category', alignRight: false },
  { id: 'product_child_category_id.name', label: 'Sub Category', alignRight: false },
  { id: 'accessories_condition', label: 'Condition', alignRight: false },
  { id: 'accessories_type', label: 'Type', alignRight: false },
  { id: 'accessories_Location', label: 'Location', alignRight: false },
  // { id: 'shiping_fee', label: 'Shipping Fee', alignRight: false },
  { id: 'old_price', label: 'Total Price', alignRight: false },
  { id: 'discount', label: 'Discount', alignRight: false },
  { id: 'new_price', label: 'New Price', alignRight: false },
  { id: 'product_description', label: 'Descriptions', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'images', label: 'Image', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function ProductsPage() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [productId, setProductId] = useState(null);

  const [productItem, setProductItem] = useState(null);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [requestName, setRequestName] = useState('All');

  const [openAddForm, setOpenAddForm] = useState(false);

  const [openEditForm, setOpenEditForm] = useState(false);

  const data = useSelector((state) => state.product);

  const userData = useSelector((state) => state.user.userInfo);

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = Array.isArray(array) && array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => {
        const lowerQuery = toLower(query);
        return (
          includes(toLower(!_user?.productCode ? '' : _user?.productCode), lowerQuery) ||
          includes(toLower(!_user?.product_title ? '' : _user?.product_title), lowerQuery) ||
          // includes(toLower(!_user?.product_brand_id?.name ? '' : _user?.product_brand_id?.name), lowerQuery) ||
          // includes(toLower(!_user?.product_category_id.name ? '' : _user?.product_category_id.name), lowerQuery) ||
          // includes(toLower(!_user?.product_type ? '' : _user?.product_type), lowerQuery) ||
          // includes(
          //   toLower(!_user?.product_child_category_id?.name ? '' : _user?.product_child_category_id?.name),
          //   lowerQuery
          // ) ||
          includes(toLower(!_user?.product_advance ? '' : _user?.product_advance), lowerQuery) ||
          includes(toLower(!_user?.product_model ? '' : _user?.product_model), lowerQuery) ||
          includes(toLower(!_user?.size ? '' : _user?.size), lowerQuery) ||
          includes(toLower(!_user?.connectivity_technologies ? '' : _user?.connectivity_technologies), lowerQuery) ||
          includes(toLower(!_user?.printing_technologies ? '' : _user?.printing_technologies), lowerQuery) ||
          includes(toLower(!_user?.printer_color ? '' : _user?.printer_color), lowerQuery) ||
          includes(toLower(!_user?.print_media ? '' : _user?.print_media), lowerQuery) ||
          includes(toLower(!_user?.scanner_type ? '' : _user?.scanner_type), lowerQuery) ||
          includes(toLower(!_user?.compatible_devices ? '' : _user?.compatible_devices), lowerQuery) ||
          includes(toLower(!_user?.delivered_days ? '' : _user?.delivered_days), lowerQuery) ||
          includes(toLower(!_user?.old_price ? '' : _user?.old_price), lowerQuery) ||
          includes(toLower(!_user?.discount ? '' : _user?.discount), lowerQuery) ||
          includes(toLower(!_user?.new_price ? '' : _user?.new_price), lowerQuery)
        );
      });
    }
    return stabilizedThis.map((el) => el[0]);
  }

  useEffect(() => {
    if (requestName === 'All') {
      dispatch(getProductMethod());
    } else if (requestName === 'All Accessories') {
      dispatch(getAccessoriesProductMethod());
    } else {
      dispatch(getDeltedProductMethod());
    }
  }, [requestName,dispatch]);

  const handleClickOpenAddForm = () => {
    setOpenAddForm(!openAddForm);
    handleCloseMenu();
  };

  const handleClickOpenEditForm = () => {
    setOpenEditForm(!openEditForm);
    handleCloseMenu();
  };

  const handleOpenMenu = (event, item) => {
    setProductId(item._id);
    setProductItem(item);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.data !== null && data.data !== undefined && data.data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    data.data !== null && data.data !== undefined ? data.data : [],
    getComparator(order, orderBy),
    filterName
  );

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleSelectDeleteMethod = () => {
    if (requestName === 'All') {
      dispatch(deleteSelectedProductMethod(selected));
      setSelected([]);
    } else if (requestName === 'All Accessories') {
      dispatch(deleteSelectedAccessoriesMethod(selected));
      setSelected([]);
    } else {
      dispatch(deleteSelectedPermanentMethod(selected));
      setSelected([]);
    }
  };
  const handeleRestore = async (id) => {
    await ProductServices.restore(id)
      .then((res) => {
        console.warn('--------', res);
        toast.success(res?.data?.message);
        dispatch(getDeltedProductMethod());
        setProductId(null);
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };

  const dialogRef = useRef(null);

  // Function to scroll to the top of the dialog content
  const scrollToTop = () => {
    if (dialogRef.current) {
      dialogRef.current.scrollTo(0, 0);
    }
  };

  // Use the useEffect hook to scroll to the top when the component is mounted
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Helmet>
        <title> Products | PakPrint Wishes </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {requestName} Products
          </Typography>
          <Box>
            <Button sx={{ margin: '5px' }} variant="contained" onClick={handleClickOpenAddForm}>
              Add Product
            </Button>
            <Button
              variant="contained"
              sx={{ margin: '5px' }}
              onClick={() => {
                dispatch(getProductMethod());
                setRequestName('All');
              }}
            >
              All Products
            </Button>
            <Button
              sx={{ margin: '5px' }}
              variant="contained"
              onClick={() => {
                dispatch(getAccessoriesProductMethod());
                setRequestName('All Accessories');
              }}
            >
              All Accessories Products
            </Button>
            <Button
              sx={{ margin: '5px' }}
              variant="contained"
              onClick={() => {
                dispatch(getDeltedProductMethod());
                setRequestName('All Deleted');
              }}
            >
              All Deleted
            </Button>
          </Box>
        </Stack>
        {/* <Alert severity="info">Product Serach to Product Code</Alert> */}
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleSelectDeleteMethod={handleSelectDeleteMethod}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={
                    requestName === 'All'
                      ? TABLE_PRODUCT_HEAD
                      : requestName === 'All Accessories'
                      ? TABLE_ACCESSORIES_HEAD
                      : TABLE_HEAD
                  }
                  rowCount={filteredUsers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id } = row;
                    const selectedUser = selected.indexOf(_id) !== -1;
                    return requestName === 'All' ? (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {row.product_title ? row.product_title : 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            navigator.clipboard.writeText(row.productCode ? row.productCode : 'N/A');
                            toast.success('Copied Product Code');
                          }}
                        >
                          {row.productCode ? row.productCode : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {!row.product_brand_id || !row.product_brand_id.name ? 'N/A' : row.product_brand_id.name}
                        </TableCell>
                        <TableCell align="left">{row.product_type}</TableCell>
                        <TableCell align="left">
                          {!row.product_category_id || !row.product_category_id.name
                            ? 'N/A'
                            : row.product_category_id.name}
                        </TableCell>
                        <TableCell align="left">
                          {!row.product_child_category_id || !row.product_child_category_id.name
                            ? 'N/A'
                            : row.product_child_category_id.name}
                        </TableCell>
                        <TableCell align="left">{row.product_advance ? row.product_advance : 'N/A'}</TableCell>
                        <TableCell align="left">{row.delivered_days ? row.delivered_days : 'N/A'}</TableCell>
                        <TableCell align="left">{!row.minQuantity ? '0' : row.minQuantity}</TableCell>
                        {/* <TableCell align="left">{row.shiping_fee ? row.shiping_fee : 'N/A'}</TableCell> */}
                        <TableCell align="left">{row.old_price ? row.old_price : 'N/A'}</TableCell>
                        <TableCell align="left">{row.discount ? row.discount : 'N/A'}</TableCell>
                        <TableCell align="left">{row.new_price ? row.new_price.toFixed(2) : 'N/A'}</TableCell>
                        <TableCell align="left">
                          <Label color={(row.featured_product === true && 'success') || 'error'}>
                            {row.featured_product === true ? 'YES' : 'NO'}
                          </Label>
                        </TableCell>

                        <TableCell align="left">
                          <Label color={(row.product_new_arrival === true && 'success') || 'error'}>
                            {row.product_new_arrival === true ? 'YES' : 'NO'}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          <Label color={(row.buynow === true && 'success') || 'error'}>
                            {row.buynow === true ? 'YES' : 'NO'}
                          </Label>
                        </TableCell>
                        <TableCell align="left" sx={{fontSize:'10px'}}>
                          {row.product_description ? (
                            <div dangerouslySetInnerHTML={{ __html: row.product_description.slice(0, 100) }} />
                          ) : (
                            'N/A'
                          )}
                          {row.product_description ? '...' : ''}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {row?.status && row?.status === true ? 'Approved' : 'Disapproved'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <img src={IMAGE_BASE_URL + row.images[0]} alt={row.product_title} style={{ width: '100px' }} />
                        </TableCell>
                        <TableCell align="right" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <IconButton
                            size="normal"
                            onClick={() => {
                              navigate(`/dashboard/products/product-detail/${_id}`);
                            }}
                          >
                            <Iconify icon="carbon:view" />
                          </IconButton>
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              handleOpenMenu(e, row);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ) : requestName === 'All Accessories' ? (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {row.product_title ? row.product_title : 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            navigator.clipboard.writeText(row.productCode ? row.productCode : 'N/A');
                            toast.success('Copied Product Code');
                          }}
                        >
                          {row.productCode ? row.productCode : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {!row.product_brand_id || !row.product_brand_id.name ? 'N/A' : row.product_brand_id.name}
                        </TableCell>
                        <TableCell align="left">{row.product_type ? row.product_type : 'N/A'}</TableCell>
                        <TableCell align="left">
                          {!row.product_category_id || !row.product_category_id.name
                            ? 'N/A'
                            : row.product_category_id.name}
                        </TableCell>
                        <TableCell align="left">
                          {!row.product_child_category_id || !row.product_child_category_id.name
                            ? 'N/A'
                            : row.product_child_category_id.name}
                        </TableCell>
                        
                        <TableCell align="left">{row.accessories_condition ? row.accessories_condition : 'N/A'}</TableCell>
                        <TableCell align="left">{row?.accessories_type ? row?.accessories_type : 'N/A'}</TableCell>
                        <TableCell align="left">
                          {row?.accessories_Location ? row?.accessories_Location : 'N/A'}
                        </TableCell>
                        {/* <TableCell align="left">{row.shiping_fee ? row.shiping_fee : 'N/A'}</TableCell> */}
                        <TableCell align="left">{row.old_price ? row.old_price : 'N/A'}</TableCell>
                        <TableCell align="left">{row.discount ? row.discount : 'N/A'}</TableCell>
                        <TableCell align="left">{row.new_price ? row.new_price.toFixed(2) : 'N/A'}</TableCell>
                        <TableCell align="left" sx={{fontSize:'10px'}}>
                          {row.product_description ? (
                            <div dangerouslySetInnerHTML={{ __html: row.product_description.slice(0, 100) }} />
                          ) : (
                            'N/A'
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {row?.status && row?.status === true ? 'Approved' : 'Disapproved'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <img src={IMAGE_BASE_URL + row.images[0]} alt={row.product_title} style={{ width: '100px' }} />
                        </TableCell>
                        <TableCell align="right" sx={{ display: 'flex', flexDirection: 'row', justifyContent:"center",alignItems: 'center' }}>
                          <IconButton
                            size="normal"
                            onClick={() => {
                              navigate(`/dashboard/products/product-detail/${_id}`);
                            }}
                          >
                            <Iconify icon="carbon:view" />
                          </IconButton>
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              handleOpenMenu(e, row);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {row.product_title ? row.product_title : 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            navigator.clipboard.writeText(row.productCode ? row.productCode : 'N/A');
                            toast.success('Copied Product Code');
                          }}
                        >
                          {row.productCode ? row.productCode : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {!row.product_brand_id || !row.product_brand_id.name ? 'N/A' : row.product_brand_id.name}
                        </TableCell>
                        <TableCell align="left">{row.product_type ? row.product_type : 'N/A'}</TableCell>
                        <TableCell align="left">
                          {!row.product_category_id || !row.product_category_id.name
                            ? 'N/A'
                            : row.product_category_id.name}
                        </TableCell>
                        <TableCell align="left">
                          {!row.product_child_category_id || !row.product_child_category_id.name
                            ? 'N/A'
                            : row.product_child_category_id.name}
                        </TableCell>
                        <TableCell align="left">{row.accessories_condition ? row.accessories_condition : 'N/A'}</TableCell>
                        <TableCell align="left">{row?.accessories_type ? row?.accessories_type : 'N/A'}</TableCell>
                        <TableCell align="left">
                          {row?.accessories_Location ? row?.accessories_Location : 'N/A'}
                        </TableCell>
                        <TableCell align="left">{row.product_advance ? row.product_advance : 'N/A'}</TableCell>
                        <TableCell align="left">{row.delivered_days ? row.delivered_days : 'N/A'}</TableCell>
                        <TableCell align="left">{!row.minQuantity ? '0' : row.minQuantity}</TableCell>
                        {/* <TableCell align="left">{row.shiping_fee ? row.shiping_fee : 'N/A'}</TableCell> */}
                        <TableCell align="left">{row.old_price ? row.old_price : 'N/A'}</TableCell>
                        <TableCell align="left">{row.discount ? row.discount : 'N/A'}</TableCell>
                        <TableCell align="left">{row.new_price ? row.new_price.toFixed(2) : 'N/A'}</TableCell>
                        <TableCell align="left">
                          <Label color={(row.featured_product === true && 'success') || 'error'}>
                            {row.featured_product === true ? 'YES' : 'NO'}
                          </Label>
                        </TableCell>

                        <TableCell align="left">
                          <Label color={(row.product_new_arrival === true && 'success') || 'error'}>
                            {row.product_new_arrival === true ? 'YES' : 'NO'}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          <Label color={(row.buynow === true && 'success') || 'error'}>
                            {row.buynow === true ? 'YES' : 'NO'}
                          </Label>
                        </TableCell>
                        <TableCell align="left" sx={{fontSize:'10px'}}>
                          {row.product_description ? (
                            <div dangerouslySetInnerHTML={{ __html: row.product_description.slice(0, 100) }} />
                          ) : (
                            'N/A'
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {row?.status && row?.status === true ? 'Approved' : 'Disapproved'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <img src={IMAGE_BASE_URL + row.images[0]} alt={row.product_title} style={{ width: '100px' }} />
                        </TableCell>
                        <TableCell align="center" sx={{ display: 'flex', flexDirection: 'row',justifyContent:"center", alignItems: 'center' }}>
                          <IconButton
                            size="normal"
                            onClick={() => {
                              navigate(`/dashboard/products/product-detail/${_id}`);
                            }}
                          >
                            <Iconify icon="carbon:view" />
                          </IconButton>
                          <IconButton
                            size="normal"
                            onClick={() => {
                              handeleRestore(_id);
                              setSelected([]);
                            }}
                          >
                            <Iconify icon="eva:trash-2-fill" />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              handleOpenMenu(e, row);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {!userData || Object.keys(userData).length === 0 || userData?.role !== 'admin' ? null : (
          <MenuItem
            sx={{ color: 'error.main' }}
            onClick={async () => {
              await ProductServices.status(productId)
                .then(() => {
                  if (productItem?.status === false) {
                    toast.success('Product Approved successfully');
                  } else {
                    toast.success('Product Disapproved successfully');
                  }
                  dispatch(getProductMethod());
                  setProductId(null);
                  setOpen(false);
                  setSelected([]);
                })
                .catch((error) => {
                  if (!error.response.data.message) {
                    toast.error(error.message, { position: 'top-right' });
                  } else {
                    toast.error(error.response.data.message, { position: 'top-right' });
                  }
                });
            }}
          >
            <Iconify
              icon={
                'streamline:interface-id-iris-scan-check-identification-retina-security-approved-success-iris-scan-eye-login'
              }
              sx={{ mr: 2 }}
            />
            {productItem?.status === false ? 'Approved' : 'Disapproved'}
          </MenuItem>
        )}
        {requestName === 'All Deleted' ? null : (
          <MenuItem onClick={handleClickOpenEditForm}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
        )}
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={async () => {
            if (requestName === 'All') {
              await ProductServices.deletes(productId)
                .then((res) => {
                  console.warn('--------', res);
                  toast.success('Product deleted successfully');
                  dispatch(getProductMethod());
                  setProductId(null);
                  setOpen(false);
                  setSelected([]);
                })
                .catch((error) => {
                  if (!error.response.data.message) {
                    toast.error(error.message, { position: 'top-right' });
                  } else {
                    toast.error(error.response.data.message, { position: 'top-right' });
                  }
                });
            } else if (requestName === 'All Accessories') {
              await ProductServices.deletes(productId)
                .then((res) => {
                  console.warn('--------', res);
                  toast.success('Product deleted successfully');
                  dispatch(getAccessoriesProductMethod());
                  setProductId(null);
                  setOpen(false);
                  setSelected([]);
                })
                .catch((error) => {
                  if (!error.response.data.message) {
                    toast.error(error.message, { position: 'top-right' });
                  } else {
                    toast.error(error.response.data.message, { position: 'top-right' });
                  }
                });
            } else {
              await ProductServices.premanentDelete(productId)
                .then((res) => {
                  console.warn('--------', res);
                  toast.success('Product deleted successfully');
                  dispatch(getDeltedProductMethod());
                  setProductId(null);
                  setOpen(false);
                  setSelected([]);
                })
                .catch((error) => {
                  if (!error.response.data.message) {
                    toast.error(error.message, { position: 'top-right' });
                  } else {
                    toast.error(error.response.data.message, { position: 'top-right' });
                  }
                });
            }
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      {openAddForm && (
        <AddProductDialog
          openAddForm={openAddForm}
          setRequestName={setRequestName}
          handleClickOpenAddForm={handleClickOpenAddForm}
        />
      )}

      <UpdateProductDialog
        openEditForm={openEditForm}
        handleClickOpenEditForm={handleClickOpenEditForm}
        productItem={productItem}
      />
    </>
  );
}
