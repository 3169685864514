import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CategoryServices from '../api/CategoryServices';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const retrieveCategoryParentProduct = createAsyncThunk('getParentProductById', async (id) => {
  try {
    const res = await CategoryServices.getParentProductById(id);
    return res.data.data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
});
export const retrieveCategoryChildProduct = createAsyncThunk('get-categoryProductSlice', async (id) => {
  try {
    const res = await CategoryServices.getChildProductById(id);
    return res.data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
});

const categoryProductSlice = createSlice({
  name: 'categoryProductSlice',
  initialState,
  extraReducers: {
    [retrieveCategoryParentProduct.pending]: (state) => {
      state.loading = true;
    },
    [retrieveCategoryParentProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [retrieveCategoryParentProduct.rejected]: (state) => {
      state.loading = false;
    },
    [retrieveCategoryChildProduct.pending]: (state) => {
      state.loading = true;
    },
    [retrieveCategoryChildProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [retrieveCategoryChildProduct.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default categoryProductSlice.reducer;
