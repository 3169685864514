import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { retrievePrintingAccessoriesServices } from '../../redux/slice/printingAccessoriesSlice';
import AccessoriesProductCard from '../../Components/AccessoriesProductCard';
import ProgressBar from '../../../components/progress/ProgressBar';

const imagePerRow = 4;

const PrintingAccessories = () => {
  const dispatch = useDispatch();
  const cachedData = useSelector((state) => state.printingAccessories);
  const data = React.useMemo(() => cachedData, [cachedData]);
  const initFetch = React.useCallback(() => {
    dispatch(retrievePrintingAccessoriesServices());
  }, [dispatch]);

  React.useEffect(() => {
    initFetch();
  }, [initFetch]);

  return (
    <Box>
      {data?.loading && <ProgressBar />}
      {data.data === null || data.data === undefined || data.data.length === 0 ? null : (
        <Grid container spacing={2} className="featured-products-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="featured-products-header-container">
            <Typography variant="h6" className="featured-products-header-text">
              Machine & Accessories
            </Typography>
            <Link to="printing-accessories" className="featured-products-header-link">
              View All&#x276F;
            </Link>
          </Grid>
          {data.data?.slice(0, imagePerRow)?.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
              <AccessoriesProductCard image={image} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default PrintingAccessories;
