import { callAPi } from './http-common';

const get = (data) => {
    return callAPi.post('/getCommentsByUserandProduct', data);
};
const add = (data) => {
    return callAPi.post('/replyComment', data);
};
const MessaggeService = {
    get,
    add
};

export default MessaggeService;
