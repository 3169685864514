import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FeaturedProductServices from '../api/FeaturedProductServices';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const retrieveFeaturedProductServices = createAsyncThunk('featured', async () => {
  try {
    const res = await FeaturedProductServices.getAll();
    // console.log(res)
    return res.data.data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
});
export const findFeaturedProductServicesById = createAsyncThunk('featured/findbyid', async ({ id }) => {
  try {
    const res = await FeaturedProductServices.get(id);
    return res.data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
});

const featuredProductSlice = createSlice({
  name: 'featured',
  initialState,
  extraReducers: {
    [retrieveFeaturedProductServices.pending]: (state) => {
      state.loading = true;
    },
    [retrieveFeaturedProductServices.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [retrieveFeaturedProductServices.rejected]: (state) => {
      state.loading = false;
    },
    [findFeaturedProductServicesById.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

export const selectAllData = (state) => state.featuredProducts.data;
export const getStatusMessage = (state) => state.featuredProducts.status;
export const getErrorMessage = (state) => state.featuredProducts.error;

export default featuredProductSlice.reducer;
