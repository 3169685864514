import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import AllUserServices from '../api/AllUserServices';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getAllUserDetails = createAsyncThunk(`/all-user-details`, async () => {
  try {
    const { data } = await AllUserServices.getAllUser();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteUserMethod = createAsyncThunk(`/delete-user`, async (id) => {
  try {
    const { data } = await AllUserServices.deletes(id);
    return data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
export const deleteSelectedMethod = createAsyncThunk(`/delete-selected-user`, async (id) => {
  try {
    const { data } = await AllUserServices.deleteMultiple(id);
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

const allUserSlice = createSlice({
  name: 'allUser',
  initialState,
  reducers: {},
  extraReducers: {
    // get all user details
    [getAllUserDetails.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getAllUserDetails.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete
    [deleteUserMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteUserMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = state.data.filter((item) => item._id !== payload.id);
    },
    [deleteUserMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete Multiple
    [deleteSelectedMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default allUserSlice.reducer;
