import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import SubCategoryService from '../api/SubCategoryService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const addSubCategoryMethod = createAsyncThunk(`/add-subCategory`, async (datas) => {
  try {
    const { data } = await SubCategoryService.add(datas);
    console.warn(data);
    toast.success("Sub Category added successfully");
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
export const getSubCategoryMethod = createAsyncThunk(`/get-subCategory`, async () => {
  try {
    const { data } = await SubCategoryService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteSelectedSubCategoryMethod = createAsyncThunk(`/delete-subCategory`, async (id) => {
  try {
    const { data } = await SubCategoryService.deleteMultiple(id);
    console.warn(data);
    toast.success("Sub Category deleted successfully");
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

const subCategorySlice = createSlice({
  name: 'subCategory',
  initialState,
  reducers: {},
  extraReducers: {
    // add
    [addSubCategoryMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addSubCategoryMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = [...state.data, payload];
    },
    [addSubCategoryMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get
    [getSubCategoryMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSubCategoryMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getSubCategoryMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete Multiple
    [deleteSelectedSubCategoryMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedSubCategoryMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedSubCategoryMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default subCategorySlice.reducer;
