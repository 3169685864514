import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ParentCategoryServices from '../api/ParentCategoryServices';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const addParentCategoryMethod = createAsyncThunk(`/add-parentCategory`, async (datas) => {
  try {
    const { data } = await ParentCategoryServices.add(datas);
    console.warn(data);
    toast.success('Parent category added successfully');
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
export const getParentCategoryMethod = createAsyncThunk(`/get-parentCategory`, async () => {
  try {
    const { data } = await ParentCategoryServices.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteSelectedParentCategoryMethod = createAsyncThunk(`/delete-parentCategory`, async (id) => {
  try {
    const { data } = await ParentCategoryServices.deleteMultiple(id);
    console.warn(data);
    toast.success('Parent category deleted successfully');
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

const parentCategorySlice = createSlice({
  name: 'parentCategory',
  initialState,
  reducers: {},
  extraReducers: {
    // add
    [addParentCategoryMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addParentCategoryMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = [...state.data, payload];
    },
    [addParentCategoryMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get
    [getParentCategoryMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getParentCategoryMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getParentCategoryMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete Multiple
    [deleteSelectedParentCategoryMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedParentCategoryMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedParentCategoryMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default parentCategorySlice.reducer;
