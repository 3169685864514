import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Checkbox,
  IconButton,
  Popover,
  MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// components
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { deletChatById, getChatOrderMethod, multipledeletChat } from '../redux/slice/chatSlice';
import { IMAGE_BASE_URL } from '../redux/api/http-common';
import Iconify from '../components/iconify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'first_name', label: 'Customer Name', alignRight: false },
  { id: 'product_title', label: 'Product Name', alignRight: false },
  { id: 'images', label: 'Image', alignRight: false },
  { id: 'chat', label: 'Chat', alignRight: false },

  { id: 'action', label: 'Action', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.userId.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ChatPage() {
  const dispatch = useDispatch();

  const user = useSelector((s) => s.user?.userInfo);
  console.log('user', user);

  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const data = useSelector((state) => state.chat);
  const [userId, setUserId] = useState(null);
  const [productId, setProductId] = useState(null);

  const [open, setOpen] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    dispatch(getChatOrderMethod());
  }, [dispatch, refetch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenMenu = (event, userId, productId) => {
    setUserId(userId);
    setProductId(productId);
    setOpen(event.currentTarget);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds =
        data.data !== null && data.data !== undefined
          ? data.data.map((n) => ({ _id: n._id, userId: n.userId._id, productId: n.productId._id }))
          : [];
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleClick = (event, _id, userId, productId) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { _id, userId, productId });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    data.data !== null && data.data !== undefined ? data.data : [],
    getComparator(order, orderBy),
    filterName
  );

  const handleSelectDeleteMethod = async () => {
    try {
      const res = await dispatch(multipledeletChat({ criteria: selected }));
      if (res.payload.success) {
        setSelected([]);
        setRefetch(!refetch);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteChat = async () => {
    try {
      const res = await dispatch(deletChatById({ usersId: userId, productId }));
      if (res.payload.success) {
        setUserId(null);
        setOpen(false);
        setRefetch(!refetch);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Chat | PakPrint Wishes </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            All Chats
          </Typography>
        </Stack>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleSelectDeleteMethod={handleSelectDeleteMethod}
          />{' '}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredUsers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {/* <OrderProductListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                /> */}
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id } = row;
                    const selectedUser = selected.some((item) => item._id === _id);

                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedUser}
                            onChange={(event) => handleClick(event, _id, row?.userId?._id, row?.productId?._id)}
                          />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              <Typography variant="subtitle2" noWrap>
                                {row?.userId && row?.userId?.first_name} {row?.userId && row?.userId?.last_name}
                              </Typography>{' '}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{row?.productId && row?.productId?.product_title}</TableCell>
                        <TableCell align="left">
                          <img
                            src={IMAGE_BASE_URL + row?.productId?.images[0]}
                            alt={row.productId.product_title}
                            style={{ width: '100px' }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            onClick={() => {
                              navigate(`/dashboard/chat/messages/${row.userId._id}?product_id=${row.productId._id}`);
                            }}
                          >
                            Messages
                          </Button>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              handleOpenMenu(e, row.userId._id, row.productId._id);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            handleDeleteChat();
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
