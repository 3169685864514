import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import GetQuoteServices from '../api/GetQuoteServices';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getQuoteMethod = createAsyncThunk(`/get-all-quote`, async () => {
  try {
    const { data } = await GetQuoteServices.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getPendingQuoteMethod = createAsyncThunk(`/get-pending-quote`, async () => {
  try {
    const { data } = await GetQuoteServices.getPending();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getConfirmQuoteMethod = createAsyncThunk(`/get-confirm-quote`, async () => {
  try {
    const { data } = await GetQuoteServices.getConfirm();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getDeliverQuoteMethod = createAsyncThunk(`/get-deliver-quote`, async (id) => {
  try {
    const { data } = await GetQuoteServices.getDeliver(id);
    console.warn(data);
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getDeletedQuoteMethod = createAsyncThunk(`/get-deleted-quote`, async (id) => {
  try {
    const { data } = await GetQuoteServices.getAllDeleted(id);
    console.warn(data);
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteSelectedPermanentMethod = createAsyncThunk(`/delete-multiple-quote`, async (id) => {
  try {
    const { data } = await GetQuoteServices.deletePermanentMultiple(id);
    console.warn(data);
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});


const getQuoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getQuoteMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getQuoteMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getQuoteMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get pending
    [getPendingQuoteMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPendingQuoteMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getPendingQuoteMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get confirm
    [getConfirmQuoteMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getConfirmQuoteMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getConfirmQuoteMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get deliver
    [getDeliverQuoteMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getDeliverQuoteMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getDeliverQuoteMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get deleted
    [getDeletedQuoteMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getDeletedQuoteMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getDeletedQuoteMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete permanent Multiple
    [deleteSelectedPermanentMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedPermanentMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedPermanentMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default getQuoteSlice.reducer;
