import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/addPartner', data);
};
const deletes = (id) => {
  return callAPi.delete(`/deletePartner/${id}`);
};
const deleteMultiple = (data) => {
  return callAPi.post(`/deleteMultiplePartners`, { ids: data });
};
const get = () => {
  return callAPi.get('/getAllPartner');
};
const update = (id, data) => {
  return callAPi.patch(`/updatePartners/${id}`, data);
};
const PartnerService = {
  add,
  deletes,
  get,
  update,
  deleteMultiple,
};

export default PartnerService;
