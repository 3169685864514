import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomProductSEOService from '../api/CustomProductSEOService';

const initialState = {
  loading: false,
  data: [],
  error: null,
  success: false,
};

export const getCustomProductSEOMethod = createAsyncThunk(`/get-customProductSEO`, async () => {
  try {
    const { data } = await CustomProductSEOService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const customProductSEOSlice = createSlice({
  name: 'customProductSEO',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getCustomProductSEOMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomProductSEOMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomProductSEOMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default customProductSEOSlice.reducer;
