import { callAPi } from './http-common';

const add = (data) => callAPi.post('/addSlider', data);
const deletes = (id) => callAPi.delete(`/deleteSlider/${id}`);
const deleteMultiple = (data) => callAPi.post(`/multipleDeleteSliders`, { ids: data });
const get = () => callAPi.get('/getSlider');
const update = (id, data) => callAPi.patch(`/updateSlider/${id}`, data);

const SliderServices = {
  add,
  deletes,
  get,
  update,
  deleteMultiple,
};

export default SliderServices;
