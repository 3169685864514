import { callAPi } from './job-http-common';

const getAllFinJob = () => callAPi.get('/freelaningJob/getAllFreelancingJobs');
const getbyIdFindJob = (id) => callAPi.get(`/freelaningJob/getFreelancingJobDetailsById/${id}`);

const FreelanceJobsService = {
  getAllFinJob,
  getbyIdFindJob,
};

export default FreelanceJobsService;
