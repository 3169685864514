import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import RichTextEditors from '../../components/RichText/RichTextEditors';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const JobDetailPage = () => {
  const containerRef = useRef(null);
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  useEffect(() => containerRef?.current?.scrollIntoView({ block: 'end', behavior: 'smooth' }), [window.location.href]);
  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} py={3} p={1}>
      <Grid item xs={12} md={10}>
        <Grid container ref={containerRef} px={1} spacing={2}>
          <Grid item xs={12}>
            <Grid container className="job-title-card" alignItems={'center'}>
              <Grid item xs={12} md={8}>
                <Typography variant="subtitle1">kurioje buvo ir Lorem Ipsum versija.</Typography>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox {...label} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />}
                    label="Save Job"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
                <Typography variant="h6">PKR 334</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} className="job-title-card" alignItems={'center'}>
              <Grid item xs={12}>
                <Typography variant="h6">Description</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ color: 'rgba(147, 147, 147, 1)' }}>
                  Experience Level: <span style={{ color: '#000' }}>Expert</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                I need an commercial web expert
                <br />
                To enhance , optimise and SEO an existing website .<br />
                Build in payment points and allow secure email messages.
                <br />
                Ideally to assist design of campaigns .Long term role for correct person.
                <br />
                Our company also champions females roles and advancement in ports .
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Attachments (3)</Typography>
              </Grid>
              <Grid item xs={12}>
                <IconButton>
                  <PictureAsPdfIcon fontSize="large" />
                </IconButton>
                <IconButton>
                  <ImageIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} className="job-title-card" alignItems={'center'}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Client History</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <AccountCircleOutlinedIcon sx={{ width: '50px', height: '50px' }} />
                <Typography variant="subtitle1">Prajwal Jaswal</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobDetailPage;
