import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { jobsforgetPasswordMethod } from '../../../redux/slice/jobsUserSlice';

// ----------------------------------------------------------------------

export default function JobsForgetPasswordForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);

  const handleClick = async () => {
    if (!email) {
      toast.error('Please enter email.');
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }
    const data = {
      email,
    };
    const result = await dispatch(jobsforgetPasswordMethod(data));
    if (result.payload.success === true) {
      toast.success(result.payload.message);
      navigate('/jobs/reset-password', { replace: true, state: { emailID: email } });
    }
  };

  return (
    <>
      <Stack spacing={2}>
        <TextField name="email" label="Email address" onChange={(e) => setEmail(e.target.value)} />
      </Stack>

      <LoadingButton
        sx={{ marginTop: '15px' }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        className="green-job-btn"
        onClick={handleClick}
      >
        Send
      </LoadingButton>
    </>
  );
}
