import { Box, Button, ListItemButton, styled, InputBase, Dialog, TextField } from '@mui/material';

export const NavBarCard = styled(Box)(() => ({
  clipPath: 'polygon(0 0, 100% 0, 98% 100%, 1.5% 100%)',
  backgroundColor: 'white',
}));
export const BlueButton = styled(Button)(() => ({
  backgroundColor: '#173470',
  fontFamily: 'Poppins',
  color: 'white',
  borderRadius: '50px',
  padding: '10px',
  paddingLeft: '50px',
  paddingRight: '50px',
  '&:hover': {
    color: '#173470',
    backgroundColor: 'transparent',
    borderColor: '#173470',
    border: '1px solid #173470',
  },
}));
export const GreenButton = styled(Button)(() => ({
  backgroundColor: '#4E9F0B',
  color: 'white',
  fontFamily: 'Poppins',
  borderRadius: '50px',
  padding: '10px',
  paddingLeft: '30px',
  paddingRight: '30px',
  '&:hover': {
    color: '#4E9F0B',
    border: '1px solid #4E9F0B',
  },
}));
export const GreenOutlineButton = styled(Button)(() => ({
  border: '1px solid #4E9F0B',
  borderRadius: '0px',
  color: '#4E9F0B',
  padding: '10px',
  paddingLeft: '30px',
  paddingRight: '30px',
}));
export const CareerListItemButton = styled(ListItemButton)(() => ({
  backgroundColor: '#173470',
  color: 'white',
  borderRadius: '50px',
  padding: '5px',
  paddingLeft: '20px',
  paddingRight: '1px',
}));
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const InputStyles = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    // borderRadius: 5,
    border: '2px solid black',
    padding: '11px',
    margin: '10px 0px',
  },
  '& .Mui-focused': {
    border: '2px solid black',
  },
  '&:hover .MuiInputBase-input': {
    border: '2px solid green',
  },
  '& 	.MuiInputBase-adornedStart': {
    border: '2px slolid red',
  },
}));

export const PaymentInput = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    border: '2px solid black',
    padding: '11px',
    margin: '4px 0px',
  },
  '& 	.MuiInputBase-root': {
    width: '100% !important',
  },
}));

export const RoundedInputField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '50px',
    },
  },
}));
