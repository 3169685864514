import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import MessaggeService from '../api/MessaggeService';

const initialState = {
    loading: false,
    data: null,
    error: null,
    success: false,
};

export const addMessageMethod = createAsyncThunk(`/add-message`, async (datas) => {
    try {
        const { data } = await MessaggeService.add(datas);
        console.warn(data);
        return data.data;
    } catch (error) {
        if (!error.response.data.message) {
            toast.error(error.message, { position: 'top-right' });
          } else {
            toast.error(error.response.data.message, { position: 'top-right' });
          }
        throw error;
    }
});
export const getMessageMethod = createAsyncThunk(`/get-message`, async (body) => {
    try {
        const { data } = await MessaggeService.get(body);
        return data.data;
    } catch (error) {
        console.error('-------', error.message);
        throw error;
    }
});

const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {},
    extraReducers: {
        // add
        [addMessageMethod.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [addMessageMethod.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.success = true;
            state.data = [...state.data, payload];
        },
        [addMessageMethod.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        // get
        [getMessageMethod.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [getMessageMethod.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.success = true;
            state.data = payload;
        },
        [getMessageMethod.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export default messageSlice.reducer;
