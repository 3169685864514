import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import http from "../api/http-common";

// export const fetchALLCars = createAsyncThunk("cars/getAPI", async () => {
//   const response = await axios.get("http://localhost:4000/cars");
//   return response.data;
// });

export const addFavourite = createAsyncThunk(
  "favourite/createAPI",
  async (payloads) => {
    const response = await http.post(`/addToProduct`, payloads);
    toast.success(response.data.message, {
      position: "bottom-left",
    });
    return response.data;
  }
);

export const deleteFavourite = createAsyncThunk(
  "favourite/deleteAPI",
  async (payloads) => {
    const response = await http.post(`/addToProduct`, payloads);
    toast.success(response.data.message, {
      position: "bottom-left",
    });
    return response;
  }
);

const initialState = {
  data: [],
  loading: "idle",
};

const favouriteSlice = createSlice({
  name: "favourite",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(fetchALLCars.pending, (state, action) => {
    //   state.loading = "pending";
    // });
    // builder.addCase(fetchALLCars.fulfilled, (state, action) => {
    //   state.loading = "idle";
    //   state.carsData = action.payload;
    // });
    builder.addCase(addFavourite.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(addFavourite.fulfilled, (state, action) => {
      state.loading = "success";
      state.data.unshift(action.payload);
    });
    builder.addCase(deleteFavourite.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(deleteFavourite.fulfilled, (state, action) => {
      state.loading = "idle";
      state.data = state.data.filter((_) => _.id !== action.payload);
    });
  },
});

export default favouriteSlice.reducer;
