import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AddIcon from '@mui/icons-material/Add';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import logoImage from '../../../client/Assets/logo.png';
import { getCustomLayoutSideBarMethod } from '../../../redux/slice/customLayoutSideBarSlice';

const drawerWidth = '70%';

const SideBar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.customLayoutSideBar.data);

  React.useEffect(() => {
    dispatch(getCustomLayoutSideBarMethod());
  }, [dispatch]);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderChildCategories = (parentCategory) => {
    const childCategories = data.customChildCategories.filter(
      (childCategory) => childCategory.customParentCategoryIds[0]._id === parentCategory._id
    );

    return (
      <Accordion key={parentCategory._id}>
        <AccordionSummary
          sx={{ margin: '0px !important', minHeight: '0px !important' }}
          expandIcon={childCategories.length === 0 ? null : <AddIcon />}
          aria-controls={`panel-${parentCategory._id}-content`}
          id={`panel-${parentCategory._id}-header`}
          className='remove-accordian-margin'
          onClick={() => {
            if (childCategories.length === 0) {
              navigate(
                `/custom-design/${parentCategory.title.replace(/\s+/g, '-').toLowerCase()}/${parentCategory._id}`
              );
            }
          }}
        >
          <Typography variant="body2">{parentCategory.title}</Typography>
        </AccordionSummary>
        {!childCategories || childCategories.length === 0 ? null : (
          <AccordionDetails sx={{padding:'0px 16px 10px !important'}}>
            {childCategories.map((childCategory) => (
              <Typography
                key={childCategory._id}
                variant="body2"
                py={1}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(
                    `/custom-design/${parentCategory.title.replace(/\s+/g, '-').toLowerCase()}/${childCategory.title
                      .replace(/\s+/g, '-')
                      .toLowerCase()}/${childCategory._id}`
                  );
                }}
              >
                {childCategory.title}
              </Typography>
            ))}
          </AccordionDetails>
        )}
      </Accordion>
    );
  };

  const renderParentCategories = () => !data
      ? null
      : data.customParentCategories.map((parentCategory) => (
          <div key={parentCategory._id}>
            {renderChildCategories(parentCategory)}
            <Divider />
          </div>
        ));

  const drawer = (
    <Box>
      <Box component={Link} to="/">
        <picture>
          <img src={logoImage} alt="Logo Images" className="navbar-logo-img1" />
        </picture>
      </Box>
      <Divider />
      <Accordion>
        <AccordionSummary
          sx={{ margin: '0px !important', minHeight: '0px !important' }}
          expandIcon={null}
          aria-controls="panel1-content"
          id="panel1-header"
          className='remove-accordian-margin'
          onClick={() => navigate('/custom-design/all')}
        >
          <Typography variant="body2">All</Typography>
        </AccordionSummary>
      </Accordion>
      <Divider />
      {renderParentCategories()}
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
      <Box
        sx={{
          display: { xs: 'flex', sm: 'flex', md: 'none' },
          flexDirection: 'row',
          flex: '100%',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className="menu-icon"
          onClick={handleDrawerToggle}
          sx={{ display: { lg: 'none' }, backgroundColor: '#173470', color: 'white' }}
        >
          <KeyboardDoubleArrowRightIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            width: '100%',
            // zIndex: 199999,
          },
        }}
      >
        <Grid container>
          <Grid item xs={12} sx={{ backgroundColor: '#D9D9D9', width: '100%' }} mx={4} px={2} py={1}>
            <Typography variant="subtitle2">CATEGORIES</Typography>
          </Grid>
          <Grid item xs={12} sx={{ width: '100%' }} mx={4}>
            <Grid container >
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    sx={{ margin: '0px !important', minHeight: '0px !important' }}
                    expandIcon={null}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className='remove-accordian-margin'
                    onClick={() => navigate('/custom-design/all')}
                  >
                    <Typography variant="body2">All</Typography>
                  </AccordionSummary>
                </Accordion>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {renderParentCategories()}
                {/* <Accordion>
                  <AccordionSummary
                    sx={{ margin: '0px !important', minHeight: '0px !important' }}
                    expandIcon={<AddIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant="body2">Accordion 1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" py={1}>
                      Accordion 1
                    </Typography>
                    <Typography variant="body2" py={1}>
                      Accordion 1
                    </Typography>
                    <Typography variant="body2" py={1}>
                      Accordion 1
                    </Typography>
                    <Typography variant="body2" py={1}>
                      Accordion 1
                    </Typography>
                    <Typography variant="body2" py={1}>
                      Accordion 1
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Divider /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {
              xs: 'block',
              sm: 'block',
              md: 'block',
              lg: 'none',
              xl: 'none',
            },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};
SideBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
export default SideBar;
