import http from "./http-common";

const create = (data) => {
  return http.post("/addGetQuote", data);
};

const GetQuoteServices = {
  create,
};

export default GetQuoteServices;
