import CategoryServices from '../api/CategoryServices';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const initialState = {
  data: [],
  loading: false,
  error: null,
};
export const fetchCategory = createAsyncThunk('category-getAll', async () => {
  const response = await CategoryServices.getAll();
  return response.data.data;
});
const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchCategory.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const getLoading = (state) => state.category.loading;

export default categorySlice.reducer;
