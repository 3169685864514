import axios from 'axios';
import { API_URL } from '../../../redux/api/http-common';

export const imageMissing = '';

export const callAPi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type':
      'multipart/form-data; boundary=AaB03x' +
      '--AaB03x' +
      'Content-Disposition: file' +
      'Content-Type: png' +
      'Content-Transfer-Encoding: binary' +
      '...data... ' +
      '--AaB03x--',
    Accept: 'application/json',
    type: 'formData',
    'X-Robots-Tag': 'noindex, nofollow',
  },
});

callAPi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('job-token-ppw');
    if (token && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
