import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import BlogService from '../../redux/api/BlogService';
import BlogCard from '../../components/card/BlogCard';
import BlogListCard from '../../components/card/BlogListCard';
import ProgressBar from '../../components/progress/ProgressBar';

// Function to shuffle an array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const PublicBlogDetailPage = () => {
  const { id } = useParams();
  const [blogDetail, setBlogDetail] = useState(null);
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [randomBlogData, setRandomBlogData] = useState([]);
  const getData = async () => {
    await BlogService.getPublic()
      .then((item) => {
        setBlogData(item.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const getDetail = async () => {
    await BlogService.getbyidPublic(id)
      .then((item) => {
        setBlogDetail(item?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  useEffect(() => {
    getDetail();
  }, [id]);

  // Assuming blogData is a prop or state variable
  useEffect(() => {
    if (blogData && blogData.length > 0) {
      const shuffledData = shuffleArray([...blogData]);
      setRandomBlogData(shuffledData.slice(0, 3));
    }
  }, [blogData]);

  return (
    <Grid container className="centered">
      {loading === true ? (
        <ProgressBar />
      ) : (
        <Grid py={6} item xs={12} sm={12} md={10} lg={10} xl={10}>
          <Grid container py={3} spacing={2} justifyContent={'center'} px={2}>
            <Grid item xs={12}>
              <BlogCard item={blogDetail} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{!blogDetail?.introduction ? null : blogDetail?.introduction}</Typography>
            </Grid>
            <Grid item xs={12}>
              {!blogDetail?.description ? null : <div dangerouslySetInnerHTML={{ __html: blogDetail?.description }} />}
            </Grid>
          </Grid>
          <Grid container spacing={2} py={2} justifyContent={'center'} px={1}>
            <Grid item xs={12} py={2}>
              <Typography variant="h5" sx={{ color: '#B205FF', fontWeight: 'bold' }}>
                Recent Post
              </Typography>
            </Grid>
            {randomBlogData.slice(0, 8).map((item, i) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                <BlogListCard item={item} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default PublicBlogDetailPage;
