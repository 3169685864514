import http from "./http-common";

const getAll = () => {
  return http.get("/getAllFeature");
};
const get = id => {
  return http.get(`/user/getProduct/${id}`);
};



const FeaturedProductServices = {
  getAll,
  get
};

export default FeaturedProductServices;