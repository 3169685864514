import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: 'I Want To Modify The Style Of My Custom Box That I Have Ordered, Can I Do That?',
    answer:
      'Absolutely! We understand that sometimes you may want to modify the style of your custom box after placing an order. At our company, we prioritize customer satisfaction and flexibility. Simply reach out to our customer support team with your modification request, and we will do.',
  },
  {
    question: 'Do I retain ownership of my submitted artwork?',
    answer:
      'Absolutely! You maintain full ownership rights of your provided artwork; we only use it for designing purposes upon your consent.',
  },
  {
    question: 'How secure are my personal details on this website?',
    answer:
      'Your personal details are highly secure on our website. We use advanced encryption technology to protect your information.',
  },
  {
    question: 'How do I stay updated on new trends in printing?',
    answer:
      'Follow social media profiles or blogs on printing and packing boxes to keep up with printing trends. Online printing marketplace newsletters and local galleries might also provide industry trends.',
  },
  {
    question: 'How long does it usually take to dispatch an order?',
    answer:
      " Once your order is dispatched, we'll provide you with a tracking number so you can easily follow its journey to your doorstep. we aim to dispatch your order within 24-48 hours of receiving it.",
  },
  {
    question: 'Are your products made from recycled materials?',
    answer: 'Yes, our products are created using a combination of recycled materials.',
  },
  {
    question: 'Can I use these boxes for e-commerce purposes?',
    answer:
      'Definitely! Our versatile boxes can be utilized for e-commerce purposes as well. You can showcase product information, highlight special offers, or create attractive call-to-action buttons within these boxes.',
  },
  {
    question: 'What forms of payment do you accept?',
    answer: 'We accept various forms of payment including credit cards (Visa, jazzcash, debit cards,), and COD.',
  },
];
const FAQ = () => {
  const [expandedPanel, setExpandedPanel] = useState(null);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };
  return (
    <Grid container p={2} alignItems={'flex-start'}>
      <Grid item xs={12} sm={4}>
        <Typography variant="h6" className="featured-products-header-text">
          FAQs
        </Typography>
        <Typography variant="body2">
          Everything you need to know about the product and order. Can't find answer you're looking? Please{' '}
          <span>Chat to our friendly team on Product Detail</span>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expandedPanel === `panel${index + 1}`}
            onChange={handleAccordionChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

export default FAQ;
