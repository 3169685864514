// ----------------------------------------------------------------------

import { imageMissing } from "../redux/api/http-common";

const account = {
  displayName: 'Jaydon Frankie',
  email: 'demo@minimals.cc',
  // photoURL: '/assets/images/avatars/avatar_default.jpg',
  photoURL: `${imageMissing}/assets/images/avatars/avatar_default.jpg`,
};

export default account;
