import { Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { BlueButton } from '../Components/Components';
import img1 from '../Assets/image 30.png';
import ClientsCompanies from './HomePage/ClientsCompanies';
import headerimg from '../Assets/Group 92.png';
//
import { createGetQuote } from '../redux/slice/getQuoteSlice';
import { getQuoteSchema } from '../schemas';
import bgImg from '../Assets/header_bg/quote_bg.png';
import WhatsApp from '../Components/WhatsApp';
//
const GetQuotePage = () => {
  //
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [type, setType] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [length, setLength] = useState('');
  const [typeCard, setTypeCard] = useState('');
  const [printingType, setPrintingType] = useState('');
  const [colorOption, setColorOption] = useState('');
  const [quantity1, setQuantity1] = useState('');
  const [quantity2, setQuantity2] = useState('');
  const [quantity3, setQuantity3] = useState('');
  const [comments, setComments] = useState('');
  const [attachFile, setAttachFile] = useState(null);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const saveGetQuote = () => {
    const data = {
      name,
      email,
      phone,
      company_name: companyName,
      boxType: type,
      width,
      height,
      length,
      typeCard,
      printingType,
      colorOption,
      quantity_1: quantity1,
      quantity_2: quantity2,
      quantity_3: quantity3,
      comment: comments,
      file: attachFile,
    };
    dispatch(createGetQuote(data))
      .unwrap()
      .then((data) => {
        if (data.success === true) {
          toast.success('Get Quote Successfully Submit', {
            position: 'bottom-left',
          });
          document.getElementById('myForm').reset();
          navigate('/success');
        }
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };

  const { values, errors, handleChange, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      companyName: '',
      type: '',
      width: '',
      height: '',
      length: '',
      typeCard: '',
      printingType: '',
      colorOption: '',
      quantity1: '',
      quantity2: '',
      quantity3: '',
      comments: '',
      attachFile: null,
    },
    validationSchema: getQuoteSchema,
    onSubmit: (values) => {
      saveGetQuote();
      setName('');
      setEmail('');
      setPhone('');
      setCompanyName('');
      setType('');
      setWidth('');
      setHeight('');
      setLength('');
      setTypeCard('');
      setPrintingType('');
      setColorOption('');
      setQuantity1('');
      setQuantity2('');
      setQuantity3('');
      setComments('');
      setAttachFile(null);
      values.name = '';
      values.email = '';
      values.phone = '';
      values.companyName = '';
      values.type = '';
      values.width = '';
      values.height = '';
      values.length = '';
      values.typeCard = '';
      values.printingType = '';
      values.colorOption = '';
      values.quantity1 = '';
      values.quantity2 = '';
      values.quantity3 = '';
      values.comments = '';
      values.attachFile = null;
    },
  });
  return (
    <Grid container className="centered top-container">
      <Helmet>
        <title>Get Quote | PakPrint Wishes</title>
        <meta
          name="description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:title" content="Get Quote | PakPrint Wishes" />
        <meta
          property="og:description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:image" content={bgImg} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <WhatsApp />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
        sx={{ backgroundImage: `url(${bgImg})` }}
      >
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9} sx={{ display: 'flex' }}>
            <div className="breadcrumb-text">
              <h1 className="breadcrumb-text__title">Get Quote</h1>
              <ul className="breadcrumb-text__nav">
                <li>
                  <Link to={'/'}>Home</Link>
                </li>
                <li>&nbsp;-&nbsp;</li>
                <li>Get Quote</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="get-quote-head-container">
        {/* <Typography className="get-quote-text-blur1">CUSTOM</Typography>
        <Typography className="get-quote-text-blur2">DESIGN'S</Typography> */}
        {/* className="get-quote-form-container" */}
        <Grid container className="centered">
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10} className="get-quote-form">
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pb={5}>
                <img src={headerimg} alt="headerimage" style={{ width: '100%' }} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <form onSubmit={handleSubmit} id="myForm">
                  <Grid container spacing={4} p={4}>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <Typography className="get-quote-input-label">Your Name*</Typography>
                      <TextField
                        size="small"
                        placeholder="Your Name*"
                        className="get-quote-input"
                        id="name"
                        inputProps={{ style: { fontSize: '14px' } }}
                        name="name"
                        value={name}
                        onChange={(e) => {
                          setFieldValue('name', e.target.value);
                          setName(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.name && touched.name}
                        helperText={errors.name && touched.name ? errors.name : null}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <Typography className="get-quote-input-label">Your Email*</Typography>
                      <TextField
                        size="small"
                        type="email"
                        inputProps={{ style: { fontSize: '14px' } }}
                        placeholder="Your Email*"
                        className="get-quote-input"
                        value={email}
                        id="email"
                        name="email"
                        onChange={(e) => {
                          setFieldValue('email', e.target.value);
                          setEmail(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.email && touched.email}
                        helperText={errors.email && touched.email ? errors.email : null}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <Typography className="get-quote-input-label">Your Phone*</Typography>
                      <TextField
                        size="small"
                        type="number"
                        placeholder="Your Phone*"
                        id="phone"
                        InputProps={{ inputProps: { min: 0 },style: { fontSize: '14px' } }}
                        name="phone"
                        value={phone}
                        onChange={(e) => {
                          setFieldValue('phone', e.target.value);
                          setPhone(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.phone && touched.phone}
                        helperText={errors.phone && touched.phone ? errors.phone : null}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <Typography className="get-quote-input-label">Company Name</Typography>
                      <TextField
                        size="small"
                        type="text"
                        placeholder="Company Name"
                        className="get-quote-input"
                        inputProps={{ style: { fontSize: '14px' } }}
                        id="companyName"
                        name="companyName"
                        value={companyName}
                        onChange={(e) => {
                          setFieldValue('companyName', e.target.value);
                          setCompanyName(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.companyName && touched.companyName}
                        helperText={errors.companyName && touched.companyName ? errors.companyName : null}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <Typography className="get-quote-input-label">Box Type</Typography>
                      <TextField
                        type="text"
                        size="small"
                        placeholder="Box Type"
                        className="get-quote-input"
                        fullWidth
                        id="type"
                        name="type"
                        inputProps={{ style: { fontSize: '14px' } }}
                        value={type}
                        onChange={(e) => {
                          setFieldValue('type', e.target.value);
                          setType(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.type && touched.type}
                        helperText={errors.type && touched.type ? errors.type : null}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <Typography className="get-quote-input-label">Size</Typography>
                      <TextField
                        type="number"
                        size="small"
                        InputProps={{ inputProps: { min: 0 },style: { fontSize: '14px' } }}
                        placeholder="Width"
                        className="get-quote-input"
                        id="width"
                        name="width"
                        value={width}
                        onChange={(e) => {
                          setFieldValue('width', e.target.value);
                          setWidth(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.width && touched.width}
                        helperText={errors.width && touched.width ? errors.width : null}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <Typography className="get-quote-input-label">Size</Typography>
                      <TextField
                        InputProps={{ inputProps: { min: 0 },style: { fontSize: '14px' } }}
                        type="number"
                        size="small"
                        placeholder="Height"
                        className="get-quote-input"
                        id="height"
                        name="height"
                        value={height}
                        onChange={(e) => {
                          setFieldValue('height', e.target.value);
                          setHeight(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.height && touched.height}
                        helperText={errors.height && touched.height ? errors.height : null}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <Typography className="get-quote-input-label">Size</Typography>
                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 },style: { fontSize: '14px' } }}
                        size="small"
                        placeholder="Length"
                        className="get-quote-input"
                        id="length"
                        name="length"
                        value={length}
                        onChange={(e) => {
                          setFieldValue('length', e.target.value);
                          setLength(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.length && touched.length}
                        helperText={errors.length && touched.length ? errors.length : null}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Typography className="get-quote-input-label">Type of Cards</Typography>
                      <TextField
                        type="text"
                        size="small"
                        placeholder="Type of Cards"
                        className="get-quote-input"
                        fullWidth
                        id="typeCard"
                        name="typeCard"
                        value={typeCard}
                        inputProps={{ style: { fontSize: '14px' } }}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('typeCard', e.target.value);
                          setTypeCard(e.target.value);
                        }}
                        error={errors.typeCard && touched.typeCard}
                        helperText={errors.typeCard && touched.typeCard ? errors.typeCard : null}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Typography className="get-quote-input-label">Printing Type</Typography>
                      <TextField
                        size="small"
                        placeholder="Printing Type"
                        className="get-quote-input"
                        id="printingType"
                        name="printingType"
                        value={printingType}
                        inputProps={{ style: { fontSize: '14px' } }}
                        onChange={(e) => {
                          setFieldValue('printingType', e.target.value);
                          setPrintingType(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.printingType && touched.printingType}
                        helperText={errors.printingType && touched.printingType ? errors.printingType : null}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Typography className="get-quote-input-label">Color Options</Typography>
                      <TextField
                        type="text"
                        size="small"
                        placeholder="Color Options"
                        className="get-quote-input"
                        fullWidth
                        id="colorOption"
                        inputProps={{ style: { fontSize: '14px' } }}
                        name="colorOption"
                        value={colorOption}
                        onChange={(e) => {
                          setFieldValue('colorOption', e.target.value);
                          setColorOption(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.colorOption && touched.colorOption}
                        helperText={errors.colorOption && touched.colorOption ? errors.colorOption : null}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Typography className="get-quote-input-label">Quantity*</Typography>
                      <TextField
                        type="number"
                        size="small"
                        InputProps={{ inputProps: { min: 0 },style: { fontSize: '14px' } }}
                        placeholder="Qty1*"
                        className="get-quote-input"
                        id="quantity1"
                        name="quantity1"
                        value={quantity1}
                        onChange={(e) => {
                          setFieldValue('quantity1', e.target.value);
                          setQuantity1(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.quantity1 && touched.quantity1}
                        helperText={errors.quantity1 && touched.quantity1 ? errors.quantity1 : null}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Typography className="get-quote-input-label1">.</Typography>
                      <TextField
                        type="number"
                        size="small"
                        placeholder="Qty2*"
                        InputProps={{ inputProps: { min: 0 },style: { fontSize: '14px' } }}
                        className="get-quote-input"
                        id="quantity2"
                        name="quantity2"
                        value={quantity2}
                        // value={values.quantity2}
                        // onChange={handleChange}
                        onChange={(e) => {
                          setFieldValue('quantity2', e.target.value);
                          setQuantity2(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.quantity2 && touched.quantity2}
                        helperText={errors.quantity2 && touched.quantity2 ? errors.quantity2 : null}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Typography className="get-quote-input-label1">.</Typography>
                      <TextField
                        type="number"
                        size="small"
                        placeholder="Qty3"
                        id="quantity3"
                        InputProps={{ inputProps: { min: 0 },style: { fontSize: '14px' } }}
                        name="quantity3"
                        value={quantity3}
                        onChange={(e) => {
                          setFieldValue('quantity3', e.target.value);
                          setQuantity3(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.quantity3 && touched.quantity3}
                        helperText={errors.quantity3 && touched.quantity3 ? errors.quantity3 : null}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography className="get-quote-input-label">Addtional Comments</Typography>
                      <TextField
                        type="text"
                        fullWidth
                        placeholder="Addtional Comments*"
                        id="comments"
                        name="comments"
                        inputProps={{ style: { fontSize: '14px' } }}
                        value={comments}
                        onChange={(e) => {
                          setFieldValue('comments', e.target.value);
                          setComments(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.comments && touched.comments}
                        helperText={errors.comments && touched.comments ? errors.comments : null}
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography className="get-quote-input-label">Attach File</Typography>
                      <input
                        type="file"
                        id="attachFile"
                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setAttachFile(e.target.files[0]);
                        }}
                        // error={
                        //   errors.attachFile && touched.attachFile
                        //     ? true
                        //     : false
                        // }
                        // helperText={
                        //   errors.attachFile && touched.attachFile
                        //     ? errors.attachFile
                        //     : null
                        // }
                        // onChange={ImageFileChange}
                        // onChange={(e) => setAttachFile(e.target.files[0])}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <BlueButton type="submit">Send</BlueButton>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5} className="get-quote-content-container">
        <Typography className="get-quote-content-heading">Looking for something outside the Box?</Typography>
        <Typography className="get-quote-content">
          We can turn your custom dream Box into reality. Just share your idea with our experts to serve your
          <b> Custom Printing and Custom Box Packaging</b> needs
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5} p={4} className="centered">
        <img src={img1} alt="image1" style={{ width: '380px', float: 'right' }} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
        <ClientsCompanies />
      </Grid>
    </Grid>
  );
};

export default GetQuotePage;
