import { FloatingWhatsApp } from 'react-floating-whatsapp';
import icons from '../Assets/whatsapp.png';

const WhatsApp = () => {
  return (
    <div className="shadow-3xl">
      <FloatingWhatsApp
        phoneNumber="+923007605006"
        accountName="PakPrint Wishes"
        avatar={icons}
        chatMessage="Hello there! How can I help you?"
      />
    </div>
  );
};

export default WhatsApp;
