import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import bgImg from '../Assets/header_bg/shop_bg.png';
import WhatsApp from '../Components/WhatsApp';
import { fetchCategory } from '../redux/slice/categorySlice';
import { imageBaseUrl } from '../redux/api/http-common';
import ProgressBar from '../../components/progress/ProgressBar';

const AllCategoreyPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [productPerPage] = useState(12);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, [currentPage]);
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const dispatch = useDispatch();

  const cachedData = useSelector((state) => state.category);
  const allCatgory = React.useMemo(() => cachedData, [cachedData]);
  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);

  const totalProducts = allCatgory?.data?.length; // Total number of products
  const totalPages = Math.ceil(totalProducts / productPerPage); // Calculate total pages
  const indexOfLastPost = currentPage * productPerPage;
  const indexOfFirstPost = indexOfLastPost - productPerPage;
  const currentPosts =
    !allCatgory?.data || allCatgory?.data.length === 0
      ? []
      : allCatgory?.data?.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <Grid container className="main-top-container" justifyContent={'center'}>
      <Helmet>
        <title>All Categories | PakPrint Wishes</title>
        <meta
          name="description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:title" content="All Categories | PakPrint Wishes" />
        <meta
          property="og:description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:image" content={bgImg} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <WhatsApp />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
        sx={{ backgroundImage: `url(${bgImg})` }}
      >
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9} sx={{ display: 'flex' }}>
            <div className="breadcrumb-text">
              <h1 className="breadcrumb-text__title">All Categories</h1>
              <ul className="breadcrumb-text__nav">
                <li>
                  <Link to={'/'}>Home</Link>
                </li>
                <li>&nbsp;-&nbsp;</li>
                <li>All Categories</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={11} xl={11} mt={3}>
        {allCatgory?.loading && <ProgressBar />}
        {!allCatgory?.data || allCatgory?.data.length === 0 ? null : (
          <Grid container spacing={2} className="featured-products-container">
            {currentPosts.map((res, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                <Card sx={{ height: 'auto' }}>
                  <CardActionArea
                    key={res?._id}
                    // className="category-card item"
                    data-value={res._id}
                    component={Link}
                    to={`/category/${res?.name.replace(/\s+/g, '-').toLowerCase()}/${res?._id}`}
                    state={{ id: res, name: res.name }}
                    // component={Link}
                    // to={`/products/${image.product_title
                    //   .replace(/\s+/g, "-")
                    //   .toLowerCase()}/${image?._id}`}
                    // state={{ id: image._id }}
                    // sx={{ height: "90%" }}
                  >
                    <CardMedia
                      component="img"
                      height="250px"
                      width="100%"
                      sx={{ objectFit: '100%' }}
                      src={imageBaseUrl + res?.category_image}
                      alt={res?.name}
                    />
                    <CardContent sx={{ padding: '10px' }}>
                      <Typography gutterBottom component="div" className="card-content-title">
                        {res?.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>{' '}
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="pagination-container">
        <Pagination
          count={totalPages} // Show total pages instead of current page
          page={currentPage} // Set the current page
          renderItem={(item) => (
            <PaginationItem components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />
          )}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

export default AllCategoreyPage;
