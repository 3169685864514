import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import img from '../Assets/about/about-img.jpg';
import bgImg from '../Assets/header_bg/about_bg.png';
import WhatsApp from '../Components/WhatsApp';

const AboutUsPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid container className="centered top-container">
      <Helmet>
        <title>About Us | PakPrint Wishes</title>
        <meta
          name="description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:title" content="About Us | PakPrint Wishes" />
        <meta
          property="og:description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:image" content={bgImg} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
        sx={{ backgroundImage: `url(${bgImg})` }}
      >
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9} sx={{ display: 'flex' }}>
            <div className="breadcrumb-text">
              <h1 className="breadcrumb-text__title">About us</h1>
              <ul className="breadcrumb-text__nav">
                <li>
                  <Link to={'/'}>Home</Link>
                </li>
                <li>&nbsp;-&nbsp;</li>
                <li>about</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid py={6} item xs={10} sm={10} md={10} lg={10} xl={10} className="centered">
        <Grid container spacing={2}>
          <Grid
            item
            xs={0}
            md={6}
            lg={6}
            xl={6}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block',
                xl: 'block',
              },
            }}
            className="about-us-image-container"
          >
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'block',
                  lg: 'block',
                  xl: 'block',
                },
              }}
            >
              <img src={img} alt="about us images" className="about-us-image1" />
            </Box>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'block',
                  lg: 'block',
                  xl: 'block',
                },
                marginLeft: '-120px;',
              }}
            >
              <img src={img} alt="about us imag" className="about-us-image2" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography className="about-header">About PakPrint Wishes</Typography>
            <Typography className="about-header-title">Print, Pack, Box - One Stop Shop for Packaging Needs</Typography>
            <br />
            <Typography className="about-header-paragraph">
              Looking to get products of high quality? Say goodbye to the ordinary and welcome the extraordinary
              services from PakPrint Wishes which is a one stop shop providing all your packaging, printing and boxing
              needs solutions under one roof.
              <br />
              <br />
              Utilizing best printing methods, they bring ideas alive - be they brochures and business cards to
              postcards and posters! Every item meets our clients requirements to guarantee excellent results every
              time! At our store, we specialize in offering multiple services tailored specifically towards reaching our
              client goals.
              <br />
              <br />
              Alongwith brochures, business cards, flyers, postcards and business cards. Stickers we offer wide-format
              printing of posters, T-shirts and Customized book printing as part of our printing services. At PakPrint
              Wishes we offer our services for vendors and users. We offer great business opportunities for vendors.
            </Typography>
            <br />
            <Typography className="about-header-paragraph">
              No matter whether you are a small or big business vendor we offer our printing, boxing and packaging
              services for you. You can get registered with us and take all benefits from our services which will help
              you to scale up your business. Few of the benefits are
              <ul>
                <li>Time Saving</li>
                <li>Helpful Support from us </li>
                <li>Cost Effective Solutions</li>
                <li>All Services under one roof (printing, packaging, boxing)</li>
                <li>Less Stress of handling tasks</li>
                <li>Brand Boost</li>
                <li>Professional Assistance</li>
              </ul>
            </Typography>
            <br />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="about-video-container"
            sx={{ textAlign: 'left' }}
          >
            <Typography className="about-header-title">High Quality Services are Our Speciality</Typography>
            <br />
            <Typography className="about-header-paragraph">
              PakPrint stands out as Pakistan's premier online printing store. We take great pride in offering
              high-quality products and services at competitive rates, and our experienced experts work to meet customer
              requirements at fair rates.
              <br />
              <br />
              At Pakprint Wishes for users we offer our professional printing , packaging and boxing services at
              reasonable costs, providing pre designed products as well as on demand customized services at our print
              shop online in Pakistan.
            </Typography>
            <br />
            <Typography className="about-header-paragraph" sx={{ textAlign: 'left' }}>
              We use only top-of-the-line paper stock materials, guaranteeing professional prints every time for users!
              What users will get few of the benefits are listed down!
              <ul>
                <li>Quality Assurance of products</li>
                <li>Reduced Logistics</li>
                <li>Customized Designs Assistance from us</li>
                <li>Pre Designed Availability of Products</li>
                <li>Multiple Materials Options to Choose</li>
                <li>Variety of Products Available</li>
                <li>Tracking and Updates of Order</li>
              </ul>
              If you purchase large volumes at once, discounts may apply!
            </Typography>
            <br />
            <Typography className="about-header-title">Why Partner With PakPrint Wishes?</Typography>
            <br />
            <Typography className="about-header-paragraph">
              Pakprint stands apart as being Pakistan's only online print shop offering every digital printing service
              imaginable - our cutting-edge equipment and experienced staff make us industry leaders, consistently
              producing top-quality prints at highly competitive rates.
              <br />
              <br />
              At Pakprint, our goal is to build long-term relationships with clients, and vendors while consistently
              offering top quality products and services at great price range, meeting client satisfaction in return. We
              take great pride in what we do - let our work speak for itself!
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <iframe
              className="about-video-player"
              src="https://www.youtube.com/embed/z6nlChGS6hk"
              title="Wedding Cards | best wedding cards | Printing Products"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Grid>
        </Grid>
      </Grid>
      <WhatsApp />
    </Grid>
  );
};

export default AboutUsPage;
