import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// @mui
import { Stack, Container, Typography, Button, Grid, List, ListItem, ListItemText, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';

import { toast } from 'react-toastify';
// components
import { imageBaseUrl } from '../client/redux/api/http-common';
import { getMessageMethod, addMessageMethod } from '../redux/slice/messageSlice';

export default function MessagePage() {
  const { id } = useParams();
  const user = useSelector((s) => s.user?.userInfo);
  const socket = useRef();
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [messages, setMessages] = useState([]);

  // start find out the param id afer ?prduct_id=
  const loaction = useLocation();

  const searchParam = new URLSearchParams(loaction.search);

  const productID = searchParam.get('product_id');

  // end find out the param id afer ?prduct_id=

  const chatBottom = useRef(null);

  const [message, setMessage] = useState('');

  const dispatch = useDispatch();

  // setTimeout(() => {
  //   if (id !== undefined && id !== null && productID !== undefined && productID !== null) {
  //     const body = {
  //       productId: productID,
  //       userId: id,
  //     };
  //     dispatch(getMessageMethod(body));
  //   }
  // }, 1000);

  // useEffect(() => {
  //     if (id !== undefined && id !== null && productID !== undefined && productID !== null) {
  //         const body = {
  //             productId: productID,
  //             userId: id,
  //         };
  //         dispatch(getMessageMethod(body));
  //     }
  // }, [dispatch, id]);

  useEffect(() => {
    socket.current = io(`${imageBaseUrl}/`);
    socket.current.on('getMessage', (data) => {

      setArrivalMessage({
        sender: data.senderId,
        message: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, id]);


  useEffect(() => {
    if (user?._id) {
      socket.current.emit('addUser', user?._id);
    }
  }, [user?._id]);

  useEffect(() => {
    if (chatBottom.current) {
      chatBottom.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    const getMessage = async () => {
      const body = {
        productId: productID,
        userId: id,
      };
      try {
        const res = await dispatch(getMessageMethod(body));
        if (res.payload) setMessages(res.payload);
      } catch (error) {
        console.log(error);
      }
    };
    getMessage();
  }, []);

  const handleSUbmit = async (e) => {
    e.preventDefault();

    if (!message) {
      toast.error('please enter a message');
      return;
    }

    const message1 = {
      conversationId: productID,
      sender: id,
      text: message,
    };
    const body1 = {
      productId: productID,
      userId: id,
      message,
    };

    socket.current.emit('sendMessage', {
      senderId: productID,
      receiverId: id,
      text: message,
    });

    try {
      const res = await dispatch(addMessageMethod(body1));
      if (res.payload !== undefined) setMessages([...messages, res.payload]);
      setMessage('');
    } catch (err) {
      console.log(err);
    }
  };

  // const handleSUbmit = (e) => {
  //   e.preventDefault();

  //   if (!message) {
  //     toast.error('please enter a message');
  //     return;
  //   }

  //   const body1 = {
  //     productId: productID,
  //     userId: id,
  //     message,
  //   };

  //   dispatch(addMessageMethod(body1));
  //   setMessage('');
  //   e.target.reset();
  // };
  return (
    <>
      <Helmet>
        <title> Message | PakPrint Wishes </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Message
          </Typography>
        </Stack>
        <Stack className="product-order-box" p={5}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container className="messageContainer">
                <Grid item xs={12} className="message-box">
                  <List className="messagesRow">
                    {messages !== null && messages.length !== 0 ? (
                      messages.map((item) => (
                        <ListItem
                          className="listItem"
                          sx={{
                            width: '100%',
                            textAlign: item?.adminId !== null && item?.adminId ? 'right' : 'left',
                          }}
                        >
                          <ListItemText
                            classes="messageBubble"
                            primary={item.message}
                            secondary={<>{new Date(item.createdAt).toISOString().substring(0, 10)}</>}
                          />
                        </ListItem>
                      ))
                    ) : (
                      <ListItem>No messages available.</ListItem>
                    )}
                    <div ref={chatBottom} />
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={4}>
              <Grid container alignItems="center">
                <Grid item xs={9}>
                  <TextField
                    id="message"
                    label="Message"
                    variant="outlined"
                    margin="dense"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSUbmit(e);
                      }
                    }}
                    value={message}
                    fullWidth
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1} px={1}>
                  <Button type="submit" variant="contained" onClick={handleSUbmit}>
                    Send
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </>
  );
}
