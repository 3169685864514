import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { Grid, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addParentCategoryMethod } from '../../../redux/slice/parentCategorySlice';
import RichTextEditors from '../../RichText/RichTextEditors';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function AddParentCategoryDialog({ openAddForm, handleClickOpenAddForm }) {
  const dispatch = useDispatch();
  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [file, setFile] = React.useState(null);

  const handleSubmit = async () => {
    if (title === null || description === null || file === null) {
      toast.error('Please fill all fields');
      return;
    }
    const data = {
      name: title,
      category_description: description,
      image: file,
    };
    const result = await dispatch(addParentCategoryMethod(data));
    if (result.type === '/add-parentCategory/fulfilled') {
      handleClickOpenAddForm();
    }
  };
  return (
    <div>
      <Dialog fullScreen open={openAddForm} onClose={handleClickOpenAddForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenAddForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Parent Category
            </Typography>
            {/* <Button
              autoFocus
              sx={{ color: 'white', borderColor: 'white' }}
              onClick={() => {
                handleSubmit();
                handleClickOpenAddForm();
              }}
            >
              Save
            </Button> */}
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Add Parent Category</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Parent Category Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Parent Category Title"
              variant="outlined"
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Parent Category Description
            </Typography>
            <RichTextEditors placeholder="Parent Category Description" setBody={setDescription} />
            {/* <TextField
              id="outlined-basic"
              placeholder="Parent Category Description"
              multiline
              rows={5}
              variant="outlined"
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
              required
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Upload Parent Category Image
            </Typography>
            <br />
            <input
              accept=".png, .jpg, .jpeg"
              type="file"
              id="select-image"
              onChange={(e) => setFile(e.target.files[0])}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={() => {
                handleSubmit();
                // handleClickOpenAddForm();
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
