import { callAPi } from './http-common';

const add = (data) => callAPi.post('/addReview', data);
const deleteReview = (id) => callAPi.delete(`/delete-Review/${id}`);
const deleteMultiple = (data) => callAPi.post(`/multipleDeleteReview`, { ids: data });
const get = () => callAPi.get('/getReview');
const update = (id, data) => callAPi.patch(`/updateReview-Review/${id}`, data);

const ReviewServices = {
  add,
  deleteReview,
  get,
  update,
  deleteMultiple,
};

export default ReviewServices;
