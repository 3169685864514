import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import { getUserDetails } from '../../redux/slice/userSlice';
import { getAllUserDetails } from '../../redux/slice/allUserSlice';
import { getBrandMethod } from '../../redux/slice/brandSlice';
import { getParentCategoryMethod } from '../../redux/slice/parentCategorySlice';
import { getAllVendorDetails } from '../../redux/slice/allVendorSlice';
import { getSubCategoryMethod } from '../../redux/slice/subCategorySlice';
import { getProductMethod } from '../../redux/slice/productSlice';
import { getSliderMethod } from '../../redux/slice/sliderSlice';
import { getServicesMethod } from '../../redux/slice/allServicesSlice';
import { getPartnerMethod } from '../../redux/slice/partnerSlice';
import { getJobsMethod } from '../../redux/slice/jobsSlice';
import { getQuoteMethod } from '../../redux/slice/getQuoteSlice';
import { getOrderMethod } from '../../redux/slice/orderSlice';
import { getVendorOrderMethod } from '../../redux/slice/vendorOrderSlice';
import { getChatOrderMethod } from '../../redux/slice/chatSlice';
import { getPaymentMethod } from '../../redux/slice/paymentSlice';
import { getPaymentHistoryMethod } from '../../redux/slice/paymentHistorySlice';
import { getNotificationMethod } from '../../redux/slice/notificationSlice';
import { getProductSEOMethod } from '../../redux/slice/productSEOSlice';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 10,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const data = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('access_token');
  useEffect(() => {
    if (token !== undefined && token !== null) {
      dispatch(getUserDetails());
      dispatch(getBrandMethod());
      dispatch(getParentCategoryMethod());
      dispatch(getSubCategoryMethod());
      dispatch(getProductMethod());
      dispatch(getJobsMethod());
      dispatch(getVendorOrderMethod());
      dispatch(getChatOrderMethod());
      dispatch(getNotificationMethod());
      dispatch(getProductSEOMethod());
    } else {
      console.warn('Token is undefined or null, cannot dispatch action');
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (token !== undefined && token !== null && data !== undefined && data !== null) {
      if (data.role !== 'vendor') {
        dispatch(getAllUserDetails());
        dispatch(getAllVendorDetails());
        dispatch(getSliderMethod());
        dispatch(getServicesMethod());
        dispatch(getPartnerMethod());
        dispatch(getQuoteMethod());
        dispatch(getOrderMethod());
        dispatch(getPaymentMethod());
      } else {
        dispatch(getPaymentHistoryMethod());
      }
    } else {
      console.warn('Token is undefined or null, cannot dispatch action');
    }
  }, [token, dispatch, data]);
  useEffect(() => {
    if (token === undefined || token === null) {
      navigate('/admin-login');
    }
  }, [navigate, token]);

  // useEffect(() => {
  //   if (!data || data?.role === 'user') {
  //     localStorage.removeItem('access_token');
  //     navigate('/admin-login');
  //   }
  // }, [data, navigate, token]);
  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
