import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useParams } from 'react-router-dom';
import RichTextEditors from '../../components/RichText/RichTextEditors';
import FreelanceJobsService from '../redux/api/FreelanceJobsService';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const JobDetailsPageForProposal = () => {
  const { id } = useParams();
  const [age, setAge] = React.useState('');
  const [data, setData] = React.useState(null);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, [data, id]);

  useEffect(() => {
    getDetails();
  }, [id]);

  const getDetails = async () => {
    await FreelanceJobsService.getbyIdFindJob(id)
      .then((res) => {
        console.log(res.data.data);
        setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Box sx={{ width: '100%' }}>
      {!data || Object.keys(data).length === 0 ? null : (
        <Grid container px={1} spacing={2}>
          <Grid item xs={12}>
            <Grid container className="job-title-card" alignItems={'center'}>
              <Grid item xs={12} md={8}>
                <Typography variant="subtitle1">{!data?.jobTitle ? 'N/A' : data?.jobTitle}</Typography>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox {...label} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />}
                    label="Save Job"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
                <Typography variant="h6">PKR {!data?.priceBeforeAssignJob ? 0 : data?.priceBeforeAssignJob}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} className="job-title-card" alignItems={'center'}>
              <Grid item xs={12}>
                <Typography variant="h6">Description</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ color: 'rgba(147, 147, 147, 1)' }}>
                  Experience Level:{' '}
                  <span style={{ color: '#000' }}>{!data?.experienceLevel ? 'N/A' : data?.experienceLevel}</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {!data?.jobDescription ? 'N/A' : <div dangerouslySetInnerHTML={{ __html: data?.jobDescription }} />}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Attachments ({!data?.jobAttachments ? 0 : data?.jobAttachments.length})
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <IconButton>
                  <PictureAsPdfIcon fontSize="large" />
                </IconButton>
                <IconButton>
                  <ImageIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} className="job-title-card" alignItems={'center'}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Client History</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <AccountCircleOutlinedIcon sx={{ width: '50px', height: '50px' }} />
                <Typography variant="subtitle1">Prajwal Jaswal</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Send Proposal</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ backgroundColor: 'rgba(217, 217, 217, 1)' }} p={1}>
              Submit Proposal to Prajwal Jaswal
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={{ textAlign: 'center' }}>
            <Typography variant="h6">PKR 25.00</Typography>
            <Typography variant="subtitle2">Total price of project</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ color: 'rgba(147, 147, 147, 1)' }}>
              -PKR 5.00
            </Typography>
            <Typography variant="subtitle2">Service Fee</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={{ textAlign: 'center' }}>
            <Typography variant="h6">PKR 20.00</Typography>
            <Typography variant="subtitle2">You’ll Receive</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" pb={1}>
              How long will this project take?
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-controlled-open-select-label">Select a duration</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                value={age}
                label="Select a duration"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Select a duration</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Summary</Typography>
            <RichTextEditors />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Attach Work
            </Typography>
            <br />
            <input
              multiple
              type="file"
              accept="image/*"
              onChange={(e) => {
                const files = e.target.files;
                const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes
                const maxFiles = 5;

                const selectedFiles = [];
                let errorMessage = '';

                // Validate the number of selected files
                if (files.length > maxFiles) {
                  errorMessage = `You can select up to ${maxFiles} images.`;
                } else {
                  // Validate each selected file
                  for (let i = 0; i < files.length; i += 1) {
                    const file = files[i];
                    // Validate file type
                    if (!file.type.startsWith('image/')) {
                      errorMessage = 'Please select only image files.';
                      break;
                    }
                    // Validate file size
                    if (file.size > maxFileSize) {
                      errorMessage = `File "${file.name}" exceeds the maximum size limit of 5MB.`;
                      break;
                    }
                    // Add the file to the array
                    selectedFiles.push(file);
                  }
                }

                // If there's no error, update the state
                if (errorMessage === '') {
                  //   setValues((prev) => ({ ...prev, images: selectedFiles }));
                } else {
                  // Display error message or handle as needed
                  alert(errorMessage);
                  // Clear the file input if needed
                  e.target.value = null;
                }
              }}
            />
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Button className="green-job-outline-btn">Discard</Button>
            <Button variant="contained" className="green-job-btn">
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default JobDetailsPageForProposal;
