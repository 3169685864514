import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PaymentHistoryByIDService from '../api/PaymentHistoryByIDService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getpaymentHistoryByIDMethod = createAsyncThunk(`/get-paymentHistoryByID`, async (id) => {
  try {
    const { data } = await PaymentHistoryByIDService.get(id);
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const paymentHistoryByIDSlice = createSlice({
  name: 'paymentHistoryByID',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getpaymentHistoryByIDMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getpaymentHistoryByIDMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getpaymentHistoryByIDMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default paymentHistoryByIDSlice.reducer;
