import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ProductServices from '../api/ProductServices';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getProductMethod = createAsyncThunk(`/get-product`, async () => {
  try {
    const { data } = await ProductServices.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getAccessoriesProductMethod = createAsyncThunk(`/get-accessories-product`, async () => {
  try {
    const { data } = await ProductServices.getAccessories();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getDeltedProductMethod = createAsyncThunk(`/get-deleted-product`, async () => {
  try {
    const { data } = await ProductServices.getAllDeleted();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteSelectedProductMethod = createAsyncThunk(`/soft-delete-multiple-product`, async (id) => {
  try {
    const { data } = await ProductServices.deleteProductMultiple(id);
    console.warn(data);
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
export const deleteSelectedAccessoriesMethod = createAsyncThunk(`/soft-delete-multiple-accessories`, async (id) => {
  try {
    const { data } = await ProductServices.deleteAccessoriesMultiple(id);
    console.warn(data);
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
export const deleteSelectedPermanentMethod = createAsyncThunk(`/delete-multiple-product`, async (id) => {
  try {
    const { data } = await ProductServices.deleteMultiple(id);
    console.warn(data);
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getProductMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProductMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getProductMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get accessories
    [getAccessoriesProductMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAccessoriesProductMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getAccessoriesProductMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get deleted
    [getDeltedProductMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getDeltedProductMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getDeltedProductMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete product Multiple
    [deleteSelectedProductMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedProductMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedProductMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete accessories Multiple
    [deleteSelectedAccessoriesMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedAccessoriesMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedAccessoriesMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete permanent Multiple
    [deleteSelectedPermanentMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedPermanentMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedPermanentMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default productSlice.reducer;
