import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Chip, InputBase, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useSelector } from 'react-redux';
import JobsLogo from '../logo/JobsLogo';
import AccountPopover from '../../../layouts/jobs/AccountPopover';

const drawerWidth = 240;
const navItems = [
  {
    title: 'Find Job',
    path: '/jobs/find-jobs',
    children: ['/jobs/find-jobs', '/jobs/saved-jobs', '/jobs/proposals', '/jobs/detail'],
  },
  { title: 'Jobs', path: '/jobs/user-jobs', children: ['/jobs/user-jobs'] },
  { title: 'Payments', path: '/jobs/job-payment', children: ['/jobs/job-payment'] },
  { title: 'Messages', path: '', children: [] },
];

function JobNavbar(props) {
  const data = useSelector((state) => state.jobsUser?.userInfo);
  const { window } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <JobsLogo />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              sx={{ textAlign: 'center' }}
              onClick={() => {
                navigate(item?.path);
              }}
            >
              <ListItemText primary={item?.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ boxShadow: 'none' }}>
        <Toolbar
          sx={{
            backgroundColor: '#F4F4F4',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingX: { sm: 'auto', md: '100px', lg: '180px' },
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, color: '#000' }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }} p={1}>
            <JobsLogo />
          </Box>
          <Paper component="form" sx={{ p: '2px', mx: '5px', display: 'flex', alignItems: 'center', width: 400 }}>
            <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search Projects..." />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box mr={1}>
              <Chip
                icon={<AccountBalanceWalletIcon sx={{ fill: '#8cb505' }} />}
                label={`PKR ${!data?.paymentPending ? 0 : data?.paymentPending}`}
                variant="outlined"
                sx={{ display: 'flex', alignItems: 'center' }}
              />
            </Box>
            <Badge badgeContent={4} sx={{ cursor: 'pointer' }}>
              <NotificationsIcon color="action" />
            </Badge>
            <Box ml={2}>
              <AccountPopover />
            </Box>
          </Box>
        </Toolbar>
        <Toolbar
          sx={{
            backgroundColor: '#8cb505',
            paddingX: { sm: 'auto', md: '100px', lg: '180px' },
            minHeight: '50px !important',
            display: { xs: 'none', sm: 'flex' },
          }}
        >
          <Box>
            {navItems.map((item, index) => (
              <Button
                key={index}
                sx={{
                  color: '#000',
                  backgroundColor: item.children.some((child) => location.pathname.includes(child))
                    ? '#fff'
                    : 'inherit',
                  border:
                    item.children.some((child) => location.pathname.includes(child)) === true
                      ? '1px solid black'
                      : 'none',
                  borderRadius: '0px',
                }}
                onClick={() => {
                  navigate(item?.path);
                }}
              >
                {item?.title}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ paddingTop: { xs: '10px', sm: '70px' } }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

JobNavbar.propTypes = {
  window: PropTypes.func,
};

export default JobNavbar;
