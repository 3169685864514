import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveNewArrivalsServices } from '../../redux/slice/newArrivalsSlice';
import ProductCard from '../../Components/ProductCard';
import ProgressBar from '../../../components/progress/ProgressBar';

const imagePerRow = 4;
const NewArrivals = () => {
  //
  const dispatch = useDispatch();
  const cachedData = useSelector((state) => state.newArrivals);
  const data = React.useMemo(() => cachedData, [cachedData]);
  const initFetch = React.useCallback(() => {
    dispatch(retrieveNewArrivalsServices());
    // console.log(data);
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  return (
    <Box>
      {data?.loading && <ProgressBar />}
      {data.data === null || data.data === undefined || data.data.length === 0 ? null : (
        <Grid container spacing={2} className="featured-products-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="featured-products-header-container">
            <Typography variant="h6" className="featured-products-header-text">
              New Arrivals
            </Typography>
            <Link to="new-arrivals" className="featured-products-header-link">
              View All&#x276F;
            </Link>
          </Grid>
          {data.data?.slice(0, imagePerRow)?.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
              <ProductCard image={image} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default NewArrivals;
