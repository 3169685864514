import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import { addSliderMethod } from '../../../redux/slice/sliderSlice';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function AddSliderDialog({ openAddForm, handleClickOpenAddForm }) {
  const dispatch = useDispatch();
  const parentCategoryData = useSelector((state) => state.parentCategory);

  const [title, setTitle] = React.useState(null);
  const [subTitle, setSubTitle] = React.useState(null);
  const [productUrl, setProductUrl] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [parentCategory, setParentCategory] = React.useState('Select Parent Category');
  const [file, setFile] = React.useState(null);

  const handleSubmit = () => {
    if (title === null || subTitle === null || productUrl === null || description === null) {
      toast.error('Please fill all the fields');
      return;
    }
    const data = {
      title,
      sliderDescription: description,
      subTitle,
      url: productUrl,
      sliderImage: file,
      parent_Id: parentCategory,
    };
    dispatch(addSliderMethod(data));
  };

  return (
    <div>
      <Dialog fullScreen open={openAddForm} onClose={handleClickOpenAddForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenAddForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Slider
            </Typography>
            {/* <Button
              autoFocus
              sx={{ color: 'white', borderColor: 'white' }}
              onClick={() => {
                handleSubmit();
                handleClickOpenAddForm();
              }}
            >
              Save
            </Button> */}
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Add Slider</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Slider Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Slider Title"
              variant="outlined"
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Slider Sub Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Slider Sub Title"
              variant="outlined"
              onChange={(e) => setSubTitle(e.target.value)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Parent Category
            </Typography>
            <FormControl fullWidth>
              <Select
                value={parentCategory}
                onChange={(e) => setParentCategory(e.target.value)}
                sx={{ textAlign: 'left' }}
              >
                <MenuItem value="Select Parent Category" disabled>
                  Select Parent Category
                </MenuItem>
                {parentCategoryData.data !== null &&
                  parentCategoryData.data !== undefined &&
                  parentCategoryData.data.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Slider Product URL
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Slider Product URL"
              variant="outlined"
              onChange={(e) => setProductUrl(e.target.value)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Slider Product Description
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Slider Product Description"
              multiline
              rows={5}
              variant="outlined"
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Upload Image
            </Typography>
            <br />
            <input
              accept=".png, .jpg, .jpeg"
              type="file"
              id="select-image"
              onChange={(e) => setFile(e.target.files[0])}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={() => {
                handleSubmit();
                handleClickOpenAddForm();
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
