import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { Grid, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import UserServices from '../../redux/api/UserServices';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function UpdateVendorDialog({ openAddForm, handleClickOpenAddForm, emailID }) {
  const navigate = useNavigate();
  const [companyName, setcompanyName] = React.useState(null);
  const [cnic, setcnic] = React.useState(null);
  const [bankname, setbankname] = React.useState(null);
  const [bankaccountnumber, setbankaccountnumber] = React.useState(null);

  const handleSubmit = async () => {
    if (companyName === null || cnic === null || bankname === null || bankaccountnumber === null) {
      toast.error('Please enter all required fields');
      return;
    }
    const data = {
      companyName,
      cnic,
      bank_name: bankname,
      bank_account_number: bankaccountnumber,
    };
    await UserServices.becomeVendor(data)
      .then((res) => {
        toast.success(res.data.message);
        if (res.data.success === true && res.data.verified === false) {
          navigate('/verify-otp', { state: emailID });
          return;
        }
        navigate('/dashboard/app');
        setcompanyName(null);
        setcnic(null);
        setbankname(null);
        setbankaccountnumber(null);
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };
  return (
    <div>
      <Dialog fullScreen open={openAddForm} onClose={handleClickOpenAddForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'fixed', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenAddForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Become a Vendor
            </Typography>
            {/* <Button
              autoFocus
              variant="outlined"
              sx={{ color: 'white', borderColor: 'white' }}
              onClick={() => {
                handleSubmit();
                handleClickOpenAddForm();
              }}
            >
              Submit
            </Button> */}
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '5%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Start Selling! Become a Vendor</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Company Name
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Company Name"
              variant="outlined"
              fullWidth
              onChange={(e) => setcompanyName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              CNIC
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="CNIC"
              variant="outlined"
              fullWidth
              onChange={(e) => setcnic(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Bank Account Name
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Bank Account Name"
              variant="outlined"
              fullWidth
              onChange={(e) => setbankname(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Bank Account IBAN Number
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Bank Account IBAN Number"
              variant="outlined"
              fullWidth
              onChange={(e) => setbankaccountnumber(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              variant="contained"
              onClick={() => {
                handleSubmit();
                handleClickOpenAddForm();
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
