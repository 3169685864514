import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Button, InputAdornment, IconButton, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet-async';
import { vendorLoginSchema } from '../../schemas';
import { socialLogin, userLogin } from '../../redux/slice/userSlice';
import Iconify from '../../../components/iconify/Iconify';

const LoginPages = () => {
  const { userInfo } = useSelector((state) => state.clientuser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: '141583905035-b3s65qca6n62q6kc4d235mp59rjs0sru.apps.googleusercontent.com',
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);
  // redirect authenticated user to profile screen
  useEffect(() => {
    if (userInfo) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  }, [navigate, userInfo]);

  const submitForm = async (e) => {
    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('password', CryptoJS.AES.encrypt(password, 'ppw-153').toString());
    const data = await dispatch(userLogin(formdata));
    if (data?.payload?.success === true) {
      navigate('/profile');
    }
  };
  const { errors, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: vendorLoginSchema,
    onSubmit: (values) => {
      submitForm();
    },
  });
  return (
    <>
      <Helmet>
        <title>Login | PakPrint Wishes </title>
      </Helmet>
      <Grid className="top-container" container>
        <Typography className="login-title">Login</Typography>
        <Grid item className="login-sub-container">
          <Grid item className="login-form-container" xs={10} sm={10} md={4} lg={4} xl={4}>
            <Box className="login-form">
              <TextField
                name="email"
                label="Email address"
                placeholder="Your Email"
                fullWidth
                value={email}
                onChange={(e) => {
                  setFieldValue('email', e.target.value);
                  setEmail(e.target.value);
                }}
                onBlur={handleBlur}
                error={errors.email && touched.email}
                helperText={errors.email && touched.email ? errors.email : null}
              />
              <br />
              <TextField
                id="password"
                name="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => {
                  setFieldValue('password', e.target.value);
                  setPassword(e.target.value);
                }}
                onBlur={handleBlur}
                error={errors.password && touched.password}
                helperText={errors.password && touched.password ? errors.password : null}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <span className="login-forgot-link-span">
                <Link to="/forgot-password" className="login-forgot-link">
                  <Typography className="login-forgot-text">Forgot Password?</Typography>
                </Link>
              </span>
              <Button className="login-btn" onClick={handleSubmit}>
                Login
              </Button>
            </Box>

            <span>
              <Typography className="login-signup-text">
                Don’t have an account? &nbsp;
                <span>
                  <Link to="/signup" className="login-signup-link">
                    Create account
                  </Link>
                </span>
              </Typography>
            </span>
            <span className="login-other-connect-span">
              <Typography className="login-signup-text login-other-text">or connect with</Typography>
            </span>
            <span className="login-google-span">
              <GoogleLogin
                onSuccess={(res) => {
                  // const formdata = new FormData();
                  // formdata.append("email", res.profileObj.email);
                  // formdata.append("password", res.profileObj.googleId);
                  dispatch(socialLogin({ token: res?.tokenId }));
                }}
                onFailure={(err) => {
                  console.log(err);
                }}
                // cookiePolicy={"single_host_origin"}
                // isSignedIn={true}
              />
              {/* <LoginSocialFacebook
                id="fb-root"
                appId="1330694440822436"
                onResolve={(res) => {
                  const formdata = new FormData();
                  formdata.append('email', res.data.email);
                  formdata.append('password', res.data.userID);
                  dispatch(userLogin(formdata));
                }}
                onReject={(err) => {
                  console.log(err);
                }}
              >
                <Button
                  size="small"
                  startIcon={<img className="login-google" src={p2} alt="" fontSize="small" />}
                  sx={{
                    fontSize: '13px',
                    marginLeft: '5px',
                    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;',
                    color: 'gray',
                    textTransform: 'capitalize',
                  }}
                >
                  Login with Facebook
                </Button>
              </LoginSocialFacebook> */}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginPages;
