import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PaymentServices from '../api/PaymentServices';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getPaymentMethod = createAsyncThunk(`/get-payment`, async (datas) => {
  try {
    const { data } = await PaymentServices.get(datas);
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getPaymentMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPaymentMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getPaymentMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default paymentSlice.reducer;
