import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import CustomProductServices from '../../../redux/api/CustomProductServices';
import { IMAGE_BASE_URL } from '../../../redux/api/http-common';
import ProgressBar from "../../progress/ProgressBar";

const CustomProductDetail = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    getDetail();
  }, [id]);
  const getDetail = async () => {
    await CustomProductServices.getbyAuthId(id)
      .then((res) => {
        const responseData = res.data.data;
        setData(responseData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  function camelToSentence(inputStr) {
    // Use regular expression to find camel case
    const words = inputStr.match(/[A-Za-z0-9]+/g);

    // Capitalize the first word and join with spaces
    const result = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    return result;
  }

  return (
    <Box sx={{ width: '100%' }}>
      {loading === true ? (
        <ProgressBar />
      ) : (
        <Grid
          container
          sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '20px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;',
          }}
        >
          <Grid item xs={12} py={2}>
            <Typography variant="h5">Custom Product Details</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Product Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.titles ? 'N/A' : data?.titles}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">PPW No.</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.sku ? 'N/A' : data?.sku}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Custom Parent Category</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.customParentCategoryIds || data?.customParentCategoryIds.length === 0
                ? 'N/A'
                : data?.customParentCategoryIds?.map((u) => u.title).join(', ')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Custom Sub Category</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.customChildCategoryIds || data?.customChildCategoryIds.length === 0
                ? 'N/A'
                : data?.customChildCategoryIds?.map((u) => u.title).join(', ')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Prices</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.prices ? 'N/A' : data?.prices}
            </Typography>
          </Grid>
          <Grid item xs={12} pt={1}>
            <Typography variant="h6">Custom Fields</Typography>
          </Grid>
          {!data || !data?.customFields || Object.keys(data?.customFields).length === 0
            ? null
            : Object.keys(data?.customFields)?.map((key, index) => (
                <Grid item xs={12} sm={12} pt={1} key={index}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1">{camelToSentence(key)}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        {data?.customFields[key]?.fields?.map((item, indexs) => (
                          <Grid item xs={12} key={indexs}>
                            {Object.keys(item).length === 4 ? (
                              <Grid container>
                                <Grid item xs={3}>
                                  {!item?.title ? 'N/A' : `Title : ${item?.title}`}
                                </Grid>
                                <Grid item xs={3}>
                                  {!item?.colorCode ? 'N/A' : `Color Code : ${item?.colorCode}`}
                                </Grid>
                                <Grid item xs={3}>
                                  {!item?.price ? 'N/A' : `Price : ${item?.price}`}
                                </Grid>
                                <Grid item xs={3}>
                                  {!item?.itemStatus ? 'N/A' : `Status : ${item?.itemStatus}`}
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid container>
                                <Grid item xs={4}>
                                  {!item?.title ? 'N/A' : `Title : ${item?.title}`}
                                </Grid>
                                <Grid item xs={4}>
                                  {!item?.price ? 'N/A' : `Price : ${item?.price}`}
                                </Grid>
                                <Grid item xs={4}>
                                  {!item?.itemStatus ? 'N/A' : `Status : ${item?.itemStatus}`}
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          <Grid item xs={12} pt={1}>
            <Typography variant="h6">Boolean Fields</Typography>
          </Grid>
          {!data || !data?.booleanFields || Object.keys(data?.booleanFields).length === 0
            ? null
            : Object.keys(data?.booleanFields)?.map((key, index) => (
                <Grid item xs={12} sm={12} pt={1} key={index}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1">{camelToSentence(key)}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {!data?.booleanFields[key] ? 'N/A' : camelToSentence(data?.booleanFields[key])}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          <Grid item xs={12} pt={1}>
            <Typography variant="h6">String Fields</Typography>
          </Grid>
          {!data || !data?.stringFields || Object.keys(data?.stringFields).length === 0
            ? null
            : Object.keys(data?.stringFields)?.map((key, index) => (
                <Grid item xs={12} sm={12} pt={1} key={index}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1">{camelToSentence(key)}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {!data?.stringFields[key] ||
                      Object.keys(!data?.stringFields[key]).length === 0 ||
                      !data?.stringFields[key]?.value ? (
                        'N/A'
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: data?.stringFields[key]?.value }} />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}

          {!data || !data?.objectFields || Object.keys(data?.objectFields).length === 0
            ? null
            : Object.keys(data?.objectFields)?.map((key, index) => (
                <Grid item xs={12} sm={12} pt={1} key={index}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1">{camelToSentence(key)}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container>
                        <Grid item xs={4}>
                          Start Range
                        </Grid>
                        <Grid item xs={4}>
                          End Range
                        </Grid>
                        <Grid item xs={4}>
                          Difference
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4}>
                          {!data?.objectFields[key]?.fields?.startRange
                            ? 'N/A'
                            : data?.objectFields[key]?.fields?.startRange}
                        </Grid>
                        <Grid item xs={4}>
                          {!data?.objectFields[key]?.fields?.endRange
                            ? 'N/A'
                            : data?.objectFields[key]?.fields?.endRange}
                        </Grid>
                        <Grid item xs={4}>
                          {!data?.objectFields[key]?.fields?.difference
                            ? 'N/A'
                            : data?.objectFields[key]?.fields?.difference}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Status</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.status ? 'N/A' : data?.status === true ? 'Approved' : 'Disapproved'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Descriptions</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.product_description ? (
                'N/A'
              ) : (
                <div dangerouslySetInnerHTML={{ __html: data?.product_description }} />
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} pt={1} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {!data || !data?.images || !data?.images.length === 0
              ? 'N/A'
              : data?.images.map((item, index) => (
                  <img
                    src={`${IMAGE_BASE_URL}${item}`}
                    alt={data?.product_title}
                    key={index}
                    height={'200px'}
                    style={{ padding: '10px' }}
                  />
                ))}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CustomProductDetail;
