import { ImageListItem, ImageListItemBar } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IMAGE_BASE_URL } from '../../redux/api/http-common';

const GalleryCategoryCard = ({ row }) => {
  const navigate = useNavigate();
  return (
    <ImageListItem
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        navigate(`/dashboard/gallery-images/${row?._id}`);
      }}
    >
      <img
        src={`${IMAGE_BASE_URL}${row?.category_image}?w=248&fit=crop&auto=format`}
        srcSet={`${IMAGE_BASE_URL}${row?.category_image}?w=248&fit=crop&auto=format&dpr=2 2x`}
        alt={row?.name}
        loading="lazy"
        style={{ height: '200px', borderRadius: '10px' }}
      />
      <ImageListItemBar style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }} title={row?.name} />
    </ImageListItem>
  );
};

export default GalleryCategoryCard;
