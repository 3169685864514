import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { imageBaseUrl } from '../../client/redux/api/http-common';

const BlogSlider = ({ item }) => {
  const navigate = useNavigate();
  const onClickItem = (index) => {
    console.log(`Clicked on item ${index}`);
    navigate(`/blogs/${item[index].title.replace(/\s+/g, '-').toLowerCase()}/${item[index]?._id}`);
  };

  return (
    <Carousel
      showArrows
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      autoPlay
      infiniteLoop
      interval={3000}
      onClickItem={onClickItem}
    >
      {item &&
        item?.map((res, index) => (
          <div key={index}>
            <img src={`${imageBaseUrl}${res?.image}`} alt={res && res?.title} height="350px" width="100%" />
            <div className="legend">
              <h4>{res && res?.title}</h4>
              <p>{item && moment(item?.createdAt).format('LL')}</p>
            </div>
          </div>
        ))}
    </Carousel>
  );
};
export default BlogSlider;
