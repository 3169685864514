import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import bgImg from "../Assets/header_bg/terms.png";
import WhatsApp from "../Components/WhatsApp";

const TermAndConditionPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid container className="centered top-container">
      <Helmet>
        <title>Our Terms and Conditions | PakPrint Wishes </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <WhatsApp />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
        sx={{ backgroundImage: `url(${bgImg})` }}
      >
        <Grid container justifyContent={"center"}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={9}
            xl={9}
            sx={{ display: "flex" }}
          >
            <div className="breadcrumb-text">
              <h1 className="breadcrumb-text__title">Terms & Condition</h1>
              <ul className="breadcrumb-text__nav">
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li>&nbsp;-&nbsp;</li>
                <li>Terms & Condition</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="featured-products-header-title">
              TERMS AND CONDITIONS
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb" className="breadcrumb-list">
                <Link className="breadcrumb-list" to="/">
                  Home
                </Link>
                <Link className="breadcrumb-list">TERMS AND CONDITIONS</Link>
              </Breadcrumbs>
            </div>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="service-header-container"
      >
        <Typography className="service-header-text">
          Our Terms and Conditions
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="service-paragraph-container centered"
      >
        <Typography className="service-paragraph-text">
          We welcome you to our Online Print Store. Please review these terms
          and conditions carefully, as they contain important information about
          your rights and obligations. By using the Site, you agree to be bound
          by these Terms and Conditions, which also cover all orders made via or
          in connection with the Site. You should not use this Website if you do
          not want to be bound by these terms and conditions.
        </Typography>
      </Grid>
      <Grid item xs={11} sm={11} md={10} lg={10} xl={10}>
        <Grid container pb={5}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
            Proofing & Matching :
            </Typography>
            <Typography className="service-paragraph-text2">
              Before printing begins, customers have an important responsibility
              of reviewing and approving a final proof and layout before
              initiating production. This ensures that design and content is
              accurately matched and final product is according to the client.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Order Approval And Proofing :
            </Typography>
            <Typography className="service-paragraph-text2">
              Order approval and proofing are fundamental steps of printing
              process. Customers should review a proof provided by their printer
              within 48 hours, noting any minor differences that might appear
              due to differences between digital displays (RGB) and printed
              materials (CMYK). It is important to verify the quantity ordered
              as well as any details. Whether a design provided by customer
              themselves or created by PakPrintWishes. careful handling during
              this stage can prevent mistakes while guarantee customer
              satisfaction.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Data Collection :
            </Typography>
            <Typography className="service-paragraph-text2">
              PakPrintWishes adheres to strict data protection compact in order
              to secure user and vendor data, which includes collecting, storing
              and handling personal and professional information in an
              authorized fashion. Compliance with privacy laws as well as
              employing effective security measures protect these sensitive
              files against unwarranted access or misuse by any third-parties.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Acceptance Of Terms :
            </Typography>
            <Typography className="service-paragraph-text2">
              PakPrint Wishes customers and vendors sign contracts upon
              Agreement of terms. Whether online or through paper contracts,
              this step sets the rules governing business relations; mutual
              understanding of expectations as outlined by us is important here.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Order Shipping And Delivery :
            </Typography>
            <Typography className="service-paragraph-text2">
              We guarantees all orders placed within Lahore, Pakistan with 100%
              coverage being insured against potential damages during transit.
              Orders outside Lahore will be fulfilled through courier service
              providers with insurance charges applicable depending upon
              destination. With these measures taken into effect, every step can
              be taken to ensure customers receive their packages while also
              secure them against all damage during shipping and delivery.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Pre Design Size Availability :
            </Typography>
            <Typography className="service-paragraph-text2">
              Our Customers have the choice between selecting existing designs
              or pre designs products available on our website, or browsing
              pre-design sizes. In order to customize the selection process to
              meet individual needs and preferences. This streamlines ordering
              experience while assuring chosen designs meet meaningful purposes
              of usage.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Returns and Refunds :
            </Typography>
            <Typography className="service-paragraph-text2">
              Returns and refunds apply differently for online readymade
              products and for custom-made ones, depending on PakPrintWishes
              terms of return/refund eligibility. Online pre-designs may qualify
              for returns/refunds while custom made ones, designed exclusively
              for an individual, are usually non-returnable/non-refundable due
              to being style specifically towards them and cannot be returned or
              refunded due to being custom items designed just for them.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Customer Submitted Artwork Or Graphics :
            </Typography>
            <Typography className="service-paragraph-text2">
              Customers support ultimate responsibility for submitting
              high-quality designs to PakPrintWishes, because providing artwork
              or graphics suitable for printing is of much importance. By
              meeting required standards when uploading designs to us, customers
              contribute towards improving overall visual and aesthetic
              qualities of the final printed product.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Order Cancellation :
            </Typography>
            <Typography className="service-paragraph-text2">
              Order cancellations can only be accepted within 24 hours before
              printing or order processing has started, though should it have
              already started, customer notification must take place and 50%
              advance payment may be requested from custom orders as per our
              policy to help mitigate cancellation costs while covering initial
              expenses incurred by PakPrintWishes. It helps effectively manage
              cancellations as a whole.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Design Orders :
            </Typography>
            <Typography className="service-paragraph-text2">
              Once a design has been approved, some changes may still be
              possible in soft form before final printing. But,important design
              changes after approval may not be viable and this policy aims to
              maintain efficiency during the design process while minimizing
              hassle due to crucial modifications at later stages.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">Support :</Typography>
            <Typography className="service-paragraph-text2">
              we understand our customers may require assistance at any time--be
              it holidays or regular working days. That is why our 24/7 support
              system ensures your convenience - you can reach us by SMS, phone
              calls, emails or our website chat and you should expect prompt
              replies although due to high levels of demand there may our
              response time could take more time - yet rest assured, our goal
              remains delivering assistance when and how it's required. please
              contact
              <b>
                <b> pakprintwishes@gmail.com</b>
              </b>{" "}
              .
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Disclaimer Of Warranty :
            </Typography>
            <Typography className="service-paragraph-text2">
              At PakPrintWishes, we take great pride in being transparent about
              our warranty policy. Unfortunately, PakPrintWishes does not
              provide warranties on its products and services despite taking
              every measure to ensure quality and accuracy in what it offers.
              Although this disclaimer provides clarity regarding limitations of
              any warranties related to PakPrintWishes offerings.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Design Files :
            </Typography>
            <Typography className="service-paragraph-text2">
              Design files created by our talented designer team at
              PakPrintWishes remain a part of our company and may only be
              reproduced or distributed with special authorization from
              PakPrintWishes. This measure exists to secure their creativity and
              intellectual work of our team members.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Client Submitted Artwork :
            </Typography>
            <Typography className="service-paragraph-text2">
              As part of their projects, our clients may submit artwork that
              adds their personal choice. It's important to keep in mind,
              though, that any such submission should adhere to legal and
              ethical standards. Our team reserves the right to review submitted
              pieces to ensure it satisfies these expectations; in case any
              artwork is not suitable such criteria we reserve our right to
              reject its use altogether.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">Materials :</Typography>
            <Typography className="service-paragraph-text2">
              PakPrintWishes takes great pride in using only premium materials
              when producing our offerings - be it paper or ink - because using
              top-tier materials helps us meet customers' expectations for their
              final products.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Damaged And Lost Packages :
            </Typography>
            <Typography className="service-paragraph-text2">
              If any package becomes damaged or lost during transit, we are
              committed to quickly and effectively addressing it. In such an
              instance, we start an investigation with our courier partner in
              order to know its cause. We will work together with you towards
              finding a solution which ensures customer satisfaction.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Special Offers And Updates :
            </Typography>
            <Typography className="service-paragraph-text2">
              PakPrintWishes believes in keeping our customers and vendors
              updated with special offers, promotions, and updates through
              various communication channels such as our website, SMS messages,
              social media posts, email correspondence and emails to reach our
              audience. you will always know about exciting offers, new products
              or any updates related to PakPrintWishes first!
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Advance Payment Method Policy :
            </Typography>
            <Typography className="service-paragraph-text2">
              Custom orders such as accessories, bottles, gift packs and more
              require a 50% advance payment in order for us to begin designing
              your product. This policy ensures smooth execution of orders to
              avoid any confusion.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Small Business growth opportunity rule :
            </Typography>
            <Typography className="service-paragraph-text2">
              At PakPrintWishes, we are highly motivated in supporting small
              businesses and helping them to expand their business. Our Small
              Business Growth Opportunity Rule details the steps for onboarding
              and verifying vendors looking to sell products through
              PakPrintWishes. This initiative gives vendors a platform for
              reaching a wider audience with their offerings via our platform -
              such as providing samples to our warehouse while sharing images,
              videos and descriptions for effective representation on
              PakPrintWishes website.
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="service-heading-text">
              Contact us :
            </Typography>
            <Typography className="service-paragraph-text2">
              At PakPrintWishes, we value discussion with both customers and
              vendors. If you ever have any inquiries or require assistance with
              anything we encourage you to reach out via our provided contact
              details . Our team is on standby with information, guidance and
              support that can ensure a satisfying and positive experience with
              PakPrintWishes! You can contact us through email or phone number
              and order now.
            </Typography>
            <Typography className="service-paragraph-text2">
              <b>Email:</b>{" "}
              <a href="mailto:info.pak.printwishes@gmail.com">
                info.pak.printwishes@gmail.com
              </a>
            </Typography>
            <Typography className="service-paragraph-text2">
              <b>Phone No:</b> 0300-7605006
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TermAndConditionPage;
