import { callAPi } from './http-common';

const get = () => {
  return callAPi.get('/getAllVendorOrders');
};
const getPending = () => {
  return callAPi.get('/getAllVendorOrdersPending');
};
const getConfirm = () => {
  return callAPi.get('/getAllVendorOrdersConfirm');
};
const getComplete = () => {
  return callAPi.get('/getAllVendorOrdersCompleted');
};

const getDeliver = () => {
  return callAPi.get('/getAllVendorOrdersDeliverd');
};
const getConcelled = () => {
  return callAPi.get('/getAllVendorOrdersCancelled');
};
const updateStatus = (data) => {
  return callAPi.patch(`/changevendorOrderStatus/${data.orderId}`, data);
};
const getbyid = (id) => {
  return callAPi.get(`/getVendorOrderbyId/${id}`);
};
const VendorOrderServices = {
  get,
  updateStatus,
  getPending,
  getConfirm,
  getComplete,
  getConcelled,
  getbyid,
  getDeliver,
};

export default VendorOrderServices;
