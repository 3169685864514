import { callAPi } from './http-common';

const add = (data) => callAPi.post('/createService', data);
const deletes = (id) => {
  return callAPi.delete(`/deleteService/${id}`);
};
const deleteMultiple = (data) => {
  return callAPi.post(`/deleteMultipleServices`, { ids: data });
};
const get = () => {
  return callAPi.get('/getAllServices');
};
const update = (id, data) => {
  return callAPi.patch(`/updateService/${id}`, data);
};
const AllServices = {
  add,
  deletes,
  get,
  update,
  deleteMultiple,
};

export default AllServices;
