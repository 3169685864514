import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import Iconify from '../../../../components/iconify/Iconify';
import { jobsuserLogin } from '../../../redux/slice/jobsUserSlice';
// ----------------------------------------------------------------------

export default function JobsLoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error('Please enter email and password.');
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+\s*$/;
    if (!emailPattern.test(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }
    if (password.length < 6) {
      toast.error('Please enter password greater than 6 characters');
      return;
    }
    try {
      const data = {
        email,
        password,
      };
      dispatch(jobsuserLogin(data)).then((res) => {
        if (res.payload.success === true) {
          localStorage.setItem('job-token-ppw', res?.payload?.accessToken);
          toast.success(res.payload.message);
          navigate('/jobs/find-jobs');
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField id="email" name="email" label="Email address" onChange={(e) => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
        <Link
          style={{ cursor: 'pointer', color: '#70AD47', textDecoration: 'none' }}
          className="test33"
          to="/jobs/forget-password"
        >
          Forget Password
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        className="green-job-btn"
        onClick={handleClick}
      >
        Login
      </LoadingButton>
    </>
  );
}
