import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PublicVendorService from '../api/PublicVendorService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getPublicVendorDetails = createAsyncThunk(`/all-Vendor-details`, async () => {
  try {
    const { data } = await PublicVendorService.getAllUser();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});


const publicVendorSlice = createSlice({
  name: 'publicVendor',
  initialState,
  reducers: {},
  extraReducers: {
    // get all user details
    [getPublicVendorDetails.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPublicVendorDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getPublicVendorDetails.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default publicVendorSlice.reducer;
