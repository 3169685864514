import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SliderDataService from "../api/SliderServices";

const initialState = {
  data: [],
  loading: false,
  error: null,
}


export const retrieveSlider = createAsyncThunk(
  "slider-getall",
  async () => {
    try{
      const res = await SliderDataService.getAll();
    return res.data.data;
    }catch(err){
      console.log(err.message);
      throw err;
    }
  }
);


const sliderSlice = createSlice({
  name: "slider",
  initialState,
  extraReducers: {
    [retrieveSlider.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    }
  },
});

export const selectAllData = (state) => state.slider.data;
export const getStatusMessage = (state) => state.slider.status;
export const getErrorMessage = (state) => state.slider.error;

export default sliderSlice.reducer;