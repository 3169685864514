import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import BlogListCard from '../../components/card/BlogListCard';
import Blog from '../../components/Blog/Blog';
import BlogService from '../../redux/api/BlogService';
import ProgressBar from '../../components/progress/ProgressBar';

const AllBlogPostPage = () => {
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    await BlogService.getPublic()
      .then((item) => {
        setBlogData(item?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Grid container className="centered">
      <Grid py={6} item xs={11} md={10}>
        <Blog />
      </Grid>
      <Grid py={2} item xs={11} md={10}>
        <Typography variant="h6">Latest Blogs</Typography>
        <br />
        <br />
        <Grid container spacing={2}>
          {loading === true ? (
            <ProgressBar />
          ) : blogData && blogData.length > 2 ? (
            blogData.slice(2, blogData.length).map((item, index) => (
              <Grid item xs={12} sm={12} md={6} lg={3} key={index}>
                <BlogListCard item={item} />
              </Grid>
            ))
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AllBlogPostPage;
