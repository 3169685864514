import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomVendorOrdersService from '../api/CustomVendorOrdersService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getCustomVendorOrdersMethod = createAsyncThunk(`/get-all-getCustomVendorOrdersMethod`, async () => {
  try {
    const { data } = await CustomVendorOrdersService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getCustomVendorOrdersPendingMethod = createAsyncThunk(
  `/get-all-getCustomVendorOrdersPendingMethod`,
  async () => {
    try {
      const { data } = await CustomVendorOrdersService.getPending();
      return data.data;
    } catch (error) {
      console.error('-------', error.message);
      throw error;
    }
  }
);
export const getCustomVendorOrdersConfirmMethod = createAsyncThunk(
  `/get-all-getCustomVendorOrdersConfirmMethod`,
  async () => {
    try {
      const { data } = await CustomVendorOrdersService.getConfirm();
      return data.data;
    } catch (error) {
      console.error('-------', error.message);
      throw error;
    }
  }
);
export const getCustomVendorOrdersCompleteMethod = createAsyncThunk(
  `/get-all-getCustomVendorOrdersCompleteMethod`,
  async () => {
    try {
      const { data } = await CustomVendorOrdersService.getComplete();
      return data.data;
    } catch (error) {
      console.error('-------', error.message);
      throw error;
    }
  }
);

export const getCustomVendorOrdersDeliverMethod = createAsyncThunk(
  `/get-all-getCustomVendorOrdersDeliverMethod`,
  async () => {
    try {
      const { data } = await CustomVendorOrdersService.getDeliver();
      return data.data;
    } catch (error) {
      console.error('-------', error.message);
      throw error;
    }
  }
);
export const getCustomVendorOrdersCancelledMethod = createAsyncThunk(
  `/get-all-getCustomVendorOrdersCancelledMethod`,
  async () => {
    try {
      const { data } = await CustomVendorOrdersService.getConcelled();
      return data.data;
    } catch (error) {
      console.error('-------', error.message);
      throw error;
    }
  }
);

const customVendorOrdersSlice = createSlice({
  name: 'customVendorOrders',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getCustomVendorOrdersMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomVendorOrdersMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomVendorOrdersMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get Pending
    [getCustomVendorOrdersPendingMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomVendorOrdersPendingMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomVendorOrdersPendingMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get confirm
    [getCustomVendorOrdersConfirmMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomVendorOrdersConfirmMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomVendorOrdersConfirmMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get complete
    [getCustomVendorOrdersCompleteMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomVendorOrdersCompleteMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomVendorOrdersCompleteMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    [getCustomVendorOrdersDeliverMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomVendorOrdersDeliverMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomVendorOrdersDeliverMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get cancelled
    [getCustomVendorOrdersCancelledMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomVendorOrdersCancelledMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomVendorOrdersCancelledMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default customVendorOrdersSlice.reducer;
