import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import AppliedJobsServices from '../api/AppliedJobsServices';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getAppliedJobsMethod = createAsyncThunk(`/get-appliedJobs`, async (id) => {
  try {
    const { data } = await AppliedJobsServices.get(id);
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteSelectedAppliedJobsMethod = createAsyncThunk(`/delete-appliedJobs`, async (id) => {
  try {
    const { data } = await AppliedJobsServices.deleteMultiple(id);
    console.warn(data);
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

const appliedJobsSlice = createSlice({
  name: 'appliedJobs',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getAppliedJobsMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAppliedJobsMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getAppliedJobsMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete Multiple
    [deleteSelectedAppliedJobsMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedAppliedJobsMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedAppliedJobsMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default appliedJobsSlice.reducer;
