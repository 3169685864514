import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #58a30a',
  boxShadow: 24,
  p: 4,
};
const BecomeVendorPermission = ({ openVendorForm, handleClickOpenVendorForm, handleClickOpenAddForm }) => {
  return (
    <Modal
      open={openVendorForm}
      onClose={handleClickOpenVendorForm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Become a Vendor!
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Your account does not have a vendor profile premissions. If you want, click on the "Become a Vendor" button to
          enter some details and switch to the Vendor Profile.
        </Typography>
        <Button variant="contained" sx={{ marginTop: '20px' }} onClick={handleClickOpenAddForm}>
          Become a Vendor
        </Button>
      </Box>
    </Modal>
  );
};

export default BecomeVendorPermission;
