import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CustomProductCard from '../../components/card/CustomProductCard';
import { getPublicCustomProductMethod } from '../../redux/slice/publicCustomProductSlice';
import ProgressBar from '../../components/progress/ProgressBar';

const AllCustomProductPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.publicCustomProduct);
  // console.log(data);
  useEffect(() => {
    dispatch(getPublicCustomProductMethod());
  }, [dispatch]);
  return (
    <Grid container spacing={2}>
      {data?.loading === true ? (
        <ProgressBar />
      ) : !data?.data || data?.data.length === 0 ? null : (
        data?.data.map((item, i) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={i}>
            <CustomProductCard
              image={item}
              path={
                location.pathname === '/custom-design/all' ? '/custom-design/all/product' : '/custom-design/product'
              }
            />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default AllCustomProductPage;
