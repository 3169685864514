import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { Grid } from '@mui/material';
// components
import { useEffect, useState } from 'react';
import NavBar from '../../client/Pages/NavBar';
import FooterPage from '../../client/Pages/FooterPage';
import '../../utils/index.css';

export default function SimpleLayout() {
  const { pathname } = useLocation();
  const [isTop, setIsTop] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, [pathname, isTop]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <NavBar setIsTop={setIsTop} isTop={isTop} />
      </Grid>
      <Grid item xs={12}>
        <Outlet />
      </Grid>
      <Grid item xs={12} sx={{backgroundColor:'white'}}>
        <FooterPage setIsTop={setIsTop} isTop={isTop} />
      </Grid>
    </Grid>
  );
}
