import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import JobCard from '../components/card/JobCard';
import { getfreelanceClientJobs } from '../redux/slice/freelanceClientJobsSlice';

const FindJobsPages = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.freelanceClientJobs.data);

  useEffect(() => {
    dispatch(getfreelanceClientJobs());
  }, [dispatch]);
  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} py={3} p={1}>
      <Helmet>
        <title>Find Jobs | Pak Print Wishes</title>
      </Helmet>
      <Grid item xs={12} md={10}>
        <Grid container spacing={3}>
          <Grid item xs={12} py={1} mt={2}>
            <Typography variant="h6">Freelance Jobs — {!data || data.length === 0 ? 0 : data.length} found</Typography>
          </Grid>
          {!data || data.length === 0
            ? null
            : data.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <JobCard item={item} />
                </Grid>
              ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FindJobsPages;
