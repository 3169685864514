import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { cityList } from '../../../_mock/cityList';
import { addJobsMethod } from '../../../redux/slice/jobsSlice';
import RichTextEditors from '../../RichText/RichTextEditors';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function AddJobsDialog({ openAddForm, handleClickOpenAddForm }) {
  const dispatch = useDispatch();
  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [jobType, setJobType] = React.useState('Select Job Type');
  const [responsibilities, setResponsibilities] = React.useState(null);
  const [companyName, setCompanyName] = React.useState(null);
  const [city, setCity] = React.useState('Select City');
  const [country, setCountry] = React.useState('Select Country');
  const [skills, setSkills] = React.useState(null);
  const [salary, setSalary] = React.useState(null);
  const [status, setStatus] = React.useState('Select Status');
  const [noJobs, setNoJobs] = React.useState(null);
  const [lastDate, setLastdate] = React.useState(null);
  const handleSubmit = () => {
    if (
      title === null ||
      description === null ||
      jobType === 'Select Job Type' ||
      responsibilities === null ||
      companyName === null ||
      city === 'Select City' ||
      country === 'Select Country' ||
      skills === null ||
      salary === null ||
      status === 'Select Status' ||
      noJobs === null ||
      lastDate === null
    ) {
      toast.error('Please enter all required fields');
      return;
    }
    const data = {
      title,
      responsibilities,
      companyName,
      city,
      country,
      jobType,
      skills,
      salary,
      status,
      noOfPositions: noJobs,
      lastDate,
      description,
    };
    dispatch(addJobsMethod(data));
    setTitle(null);
    setDescription(null);
    setJobType('Select Job Type');
    setResponsibilities(null);
    setCompanyName(null);
    setCity('Select City');
    setCountry('Select Country');
    setSkills(null);
    setSalary(null);
    setStatus('Select Status');
    setNoJobs(null);
    setLastdate(null);
  };
  return (
    <div>
      <Dialog fullScreen open={openAddForm} onClose={handleClickOpenAddForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenAddForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Job
            </Typography>
            {/* <Button
              autoFocus
              variant='outlined' sx={{color:'white',borderColor:'white'}}
              onClick={() => {
                handleSubmit();
                handleClickOpenAddForm();
              }}
            >
              Save
            </Button> */}
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Add Job</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Job Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Job Title"
              variant="outlined"
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Company Name
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Company Name"
              variant="outlined"
              fullWidth
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Job Type
            </Typography>
            <FormControl fullWidth>
              <Select value={jobType} onChange={(e) => setJobType(e.target.value)} sx={{ textAlign: 'left' }}>
                <MenuItem value="Select Job Type" disabled>
                  Select Job Type
                </MenuItem>
                <MenuItem value="Full Time">Full Time</MenuItem>
                <MenuItem value="Part Time">Part Time</MenuItem>
                <MenuItem value="Hybrid">Hybrid</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Skills
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Skills"
              variant="outlined"
              fullWidth
              onChange={(e) => setSkills(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Salary
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Salary"
              variant="outlined"
              fullWidth
              type='number'
              onChange={(e) => setSalary(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              No. of Jobs
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="No. of Jobs"
              variant="outlined"
              fullWidth
              type='number'
              onChange={(e) => setNoJobs(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Last Date
            </Typography>
            <TextField
              id="outlined-basic"
              type="date"
              variant="outlined"
              fullWidth
              onChange={(e) => setLastdate(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              City
            </Typography>
            <FormControl fullWidth>
              <Select value={city} onChange={(e) => setCity(e.target.value)} sx={{ textAlign: 'left' }}>
                <MenuItem value="Select City" disabled>
                  Select City
                </MenuItem>
                {cityList.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Country
            </Typography>
            <FormControl fullWidth>
              <Select value={country} onChange={(e) => setCountry(e.target.value)} sx={{ textAlign: 'left' }}>
                <MenuItem value="Select Country" disabled>
                  Select Country
                </MenuItem>
                <MenuItem value="Pakistan">Pakistan</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Status
            </Typography>
            <FormControl fullWidth>
              <Select value={status} onChange={(e) => setStatus(e.target.value)} sx={{ textAlign: 'left' }}>
                <MenuItem value="Select Status" disabled>
                  Select Status
                </MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Draft">Draft</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Job Responsibilities
            </Typography>
            <RichTextEditors placeholder="Job Responsibilities" setBody={setResponsibilities} />
            {/* <TextField
              id="outlined-basic"
              placeholder="Job Responsibilities"
              multiline
              rows={5}
              variant="outlined"
              fullWidth
              onChange={(e) => setResponsibilities(e.target.value)}
              required
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Job Description
            </Typography>
            <RichTextEditors placeholder="Job Description" setBody={setDescription} />
            {/* <TextField
              id="outlined-basic"
              placeholder="Job Description"
              multiline
              rows={5}
              variant="outlined"
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
              required
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={() => {
                handleSubmit();
                handleClickOpenAddForm();
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
