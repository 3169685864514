import http from "./http-common";

const getAll = () => {
  return http.get("/user/getAllProducts");
};

const GetShopServices = {
  getAll,
};

export default GetShopServices;
