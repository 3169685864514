import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useRef, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Grid,
  Button,
} from '@mui/material';

import { useLocation, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

import { getpaymentHistoryByIDMethod } from '../redux/slice/paymentHistoryByIDSlice';
import Scrollbar from '../components/scrollbar/Scrollbar';
import OrderProductListHead from '../sections/@dashboard/user/OrderProductListHead';
import Iconify from '../components/iconify/Iconify';
import imgLogo from '../components/logo/logo.png';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'TID', label: 'Transaction ID', alignRight: false },
  { id: 'currentBalance', label: 'Current Balance', alignRight: false },
  { id: 'remainingBalance', label: 'Remaining Balance', alignRight: false },
  { id: 'total', label: 'Total Balance', alignRight: false },
  { id: 'payAmount', label: 'Pay Balance', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.product_id.product_title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PaymentHistoryPrint() {
  const componentRef = useRef();

  const dispatch = useDispatch();
  const location = useLocation();
  const { vendorData } = location.state;

  const { id } = useParams();
  const [page] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage] = useState(5);

  const data = useSelector((state) => state.paymentHistoryByID);

  useEffect(() => {
    dispatch(getpaymentHistoryByIDMethod(id));
  }, [dispatch, id]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredUsers = applySortFilter(
    !data?.data || !data?.data || data?.data.length === 0 ? [] : data?.data,
    getComparator(order, orderBy)
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `PakPrint Wishes Order Invoice ${new Date()}`,
    onAfterPrint: () => toast.success('Print Order Invoice Successfully'),
  });

  return (
    <>
      <Helmet>
        <title> Print Payment | PakPrint Wishes </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Print Payment Invoice
              </Typography>
              <Button variant="outlined" startIcon={<Iconify icon="uit:print" />} onClick={handlePrint}>
                Print
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} p={4} ref={componentRef}>
            <Grid container>
              <Grid item xs={4}>
                <img src={imgLogo} alt="Logo" height={'100px'} />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', textTransform: 'uppercase', textAlign: 'center' }}>
                  PakPrint Wishes
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', textTransform: 'uppercase', textAlign: 'center' }}
                >
                  ALL YOU WANT PRINT WISHES
                </Typography>
              </Grid>
              <Grid item xs={12} pt={3}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Customer Info
                </Typography>
              </Grid>
              <Grid item xs={8} p={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle1" gutterBottom>
                    Name
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {!vendorData?.first_name ? null : vendorData?.first_name}{' '}
                    {!vendorData?.last_name ? null : vendorData?.last_name}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle1" gutterBottom>
                    Email
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {!vendorData?.email ? 'N/A' : vendorData?.email}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle1" gutterBottom>
                    Phone No.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {!vendorData?.phone ? 'N/A' : vendorData?.phone}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle1" gutterBottom>
                    Address
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {!vendorData?.address ? null : vendorData?.address} {!vendorData?.city ? null : vendorData?.city}{' '}
                    {!vendorData?.country ? null : vendorData?.country} {!vendorData?.state ? null : vendorData?.state}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Order Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <Container maxWidth="xl" sx={{ marginTop: '1%' }}>
                <Card>
                  {/* <UserListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                  /> */}

                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <OrderProductListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                          {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const { _id } = row;
                            return (
                              <TableRow hover key={_id} tabIndex={-1}>
                                <TableCell component="th" scope="row">
                                  <Typography variant="subtitle2" noWrap>
                                    {row?.TID}
                                  </Typography>
                                </TableCell>

                                <TableCell align="left">{row?.paymentRecieved.toFixed(2)}</TableCell>
                                <TableCell align="left">{row?.paymentPending.toFixed(2)}</TableCell>
                                <TableCell align="left">{row?.paymentTotal.toFixed(2)}</TableCell>
                                <TableCell align="left">{row?.payPrice.toFixed(2)}</TableCell>
                                <TableCell align="left">
                                  {!row.createdAt
                                    ? 'N/A'
                                    : new Date(row.createdAt).toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: '2-digit',
                                      })}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                </Card>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', paddingTop: '80px', textAlign: 'left' }}>
                    Signature: _____________________
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
