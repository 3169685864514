import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ForgetPasswordService from "../api/ForgetPasswordService";

const initialState = [];

export const createForgetPassword = createAsyncThunk(
  "forgetpassword",
  async (email) => {
    const data = {
      email,
    };
    const res = await ForgetPasswordService.create(data);
    return res.data;
  }
);

const forgetPasswordSlice = createSlice({
  name: "forgetpassword",
  initialState,
  extraReducers: {
    [createForgetPassword.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
  },
});

export default forgetPasswordSlice.reducer;
