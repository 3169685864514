import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import CustomProductOrdersService from '../api/CustomProductOrdersService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getCustomProductOrderMethod = createAsyncThunk(`/get-all-customProductOrders`, async () => {
  try {
    const { data } = await CustomProductOrdersService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getCustomProductOrderDeliverMethod = createAsyncThunk(
  `/get-all-getcustomProductOrdersDeliver`,
  async () => {
    try {
      const { data } = await CustomProductOrdersService.getDeliver();
      return data.data;
    } catch (error) {
      console.error('-------', error.message);
      throw error;
    }
  }
);
export const getCustomProductOrderPendingMethod = createAsyncThunk(
  `/get-all-getcustomProductOrdersPending`,
  async () => {
    try {
      const { data } = await CustomProductOrdersService.getPending();
      return data.data;
    } catch (error) {
      console.error('-------', error.message);
      throw error;
    }
  }
);
export const getCustomProductOrderConfirmMethod = createAsyncThunk(
  `/get-all-getcustomProductOrdersConfirm`,
  async () => {
    try {
      const { data } = await CustomProductOrdersService.getConfirm();
      return data.data;
    } catch (error) {
      console.error('-------', error.message);
      throw error;
    }
  }
);
export const getCustomProductOrderCompleteMethod = createAsyncThunk(
  `/get-all-getcustomProductOrdersComplete`,
  async () => {
    try {
      const { data } = await CustomProductOrdersService.getComplete();
      return data.data;
    } catch (error) {
      console.error('-------', error.message);
      throw error;
    }
  }
);
export const getCustomProductOrderCancelledMethod = createAsyncThunk(
  `/get-all-getcustomProductOrdersConcelled`,
  async () => {
    try {
      const { data } = await CustomProductOrdersService.getConcelled();
      return data.data;
    } catch (error) {
      console.error('-------', error.message);
      throw error;
    }
  }
);
export const getDeletedCustomProductOrderMethod = createAsyncThunk(`/get-deleted-customProductOrders`, async (id) => {
  try {
    const { data } = await CustomProductOrdersService.getAllDeleted(id);
    console.warn(data);
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteSelectedPermanentCustomProductOrderMethod = createAsyncThunk(
  `/delete-multiple-customProductOrders`,
  async (id) => {
    try {
      const { data } = await CustomProductOrdersService.deletePermanentMultiple(id);
      console.warn(data);
      return data.data;
    } catch (error) {
      if (!error.response.data.message) {
        toast.error(error.message, { position: 'top-right' });
      } else {
        toast.error(error.response.data.message, { position: 'top-right' });
      }
      throw error;
    }
  }
);

const customProductOrdersSlice = createSlice({
  name: 'customProductOrders',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getCustomProductOrderMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomProductOrderMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomProductOrderMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get Pending
    [getCustomProductOrderPendingMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomProductOrderPendingMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomProductOrderPendingMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get deliver
    [getCustomProductOrderDeliverMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomProductOrderDeliverMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomProductOrderDeliverMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get confirm
    [getCustomProductOrderConfirmMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomProductOrderConfirmMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomProductOrderConfirmMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get complete
    [getCustomProductOrderCompleteMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomProductOrderCompleteMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomProductOrderCompleteMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get cancelled
    [getCustomProductOrderCancelledMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomProductOrderCancelledMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomProductOrderCancelledMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get deleted
    [getDeletedCustomProductOrderMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getDeletedCustomProductOrderMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getDeletedCustomProductOrderMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete permanent Multiple
    [deleteSelectedPermanentCustomProductOrderMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedPermanentCustomProductOrderMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedPermanentCustomProductOrderMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default customProductOrdersSlice.reducer;
