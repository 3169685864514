import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import SubCategoryService from '../api/SubCategoryService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getPublicSubCategoryMethod = createAsyncThunk(`/get-publicSubCategory`, async (id) => {
  try {
    const { data } = await SubCategoryService.publicGet(id);
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const publicSubCategorySlice = createSlice({
  name: 'publicSubCategory',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getPublicSubCategoryMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPublicSubCategoryMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getPublicSubCategoryMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default publicSubCategorySlice.reducer;
