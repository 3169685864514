import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  FormControl,
  Select,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import AddGetQuoteRateDialog from '../components/Dialog/AddFormDialog/AddGetQuoteRateDialog';
import UpdateGetQuoteRateDialog from '../components/Dialog/UpdateFormDialog/UpdateGetQuoteRateDialog';

import { IMAGE_BASE_URL } from '../redux/api/http-common';
import {
  deleteSelectedPermanentMethod,
  getConfirmQuoteMethod,
  getDeletedQuoteMethod,
  getDeliverQuoteMethod,
  getPendingQuoteMethod,
  getQuoteMethod,
} from '../redux/slice/getQuoteSlice';
import GetQuoteServices from '../redux/api/GetQuoteServices';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: ' Customer Name', alignRight: false },
  { id: 'email', label: 'Customer Email', alignRight: false },
  { id: 'phone', label: 'Customer Phone', alignRight: false },
  { id: 'boxType', label: 'Box Type', alignRight: false },
  { id: 'width', label: 'Width', alignRight: false },
  { id: 'height', label: 'Height', alignRight: false },
  { id: 'length', label: 'Length', alignRight: false },
  { id: 'typeCard', label: 'Type Card', alignRight: false },
  { id: 'printingType', label: 'Printing Type', alignRight: false },
  { id: 'colorOption', label: 'Color Options', alignRight: false },
  { id: 'quantity_1', label: 'Quantity 1', alignRight: false },
  { id: 'quantity_2', label: 'Quantity 2', alignRight: false },
  { id: 'quantity_3', label: 'Quantity 3', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'rate', label: 'Rate', alignRight: false },
  { id: 'comment', label: 'Comments', alignRight: false },
  { id: 'attachFile', label: 'Image', alignRight: false },
  { id: 'ChangeStatus', label: 'Change Status', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function GetQuoteRequest() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [quoteId, setQuouteId] = useState(null);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [quoteItem, setQuoteItem] = useState(null);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [requestName, setRequestName] = useState('All');

  const [openAddForm, setOpenAddForm] = useState(false);

  const [openEditForm, setOpenEditForm] = useState(false);

  const [selectStatus] = useState('Change Status');

  const [changeStatusMenu, setChangeStatusMenu] = useState(false);

  const data = useSelector((state) => state.quote);
  useEffect(() => {
    if (requestName === 'All') {
      dispatch(getQuoteMethod());
    } else if (requestName === 'Pending') {
      dispatch(getPendingQuoteMethod());
    } else if (requestName === 'Confirm') {
      dispatch(getConfirmQuoteMethod());
    } else if (requestName === 'Deliver') {
      dispatch(getDeliverQuoteMethod());
    } else {
      dispatch(getDeletedQuoteMethod());
    }
  }, [requestName,dispatch]);

  const handleClickOpenAddForm = () => {
    setOpenAddForm(!openAddForm);
    handleCloseMenu();
  };

  const handleClickOpenEditForm = () => {
    setOpenEditForm(!openEditForm);
    handleCloseMenu();
  };

  const handleOpenChangeStatusMenu = (event, id) => {
    setChangeStatusMenu(event.currentTarget);
    setQuouteId(id);
  };
  const handleCloseChangeStatusMenu = () => {
    setChangeStatusMenu(false);
  };

  const handleOpenMenu = (event, row) => {
    setQuouteId(row._id);
    setQuoteItem(row);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.data !== null && data.data !== undefined && data.data.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    data.data !== null && data.data !== undefined ? data.data : [],
    getComparator(order, orderBy),
    filterName
  );

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleSelectDeleteMethod = async () => {
    if (requestName === 'Deleted') {
      dispatch(deleteSelectedPermanentMethod(selected));
      setSelected([]);
    } else {
      await GetQuoteServices.deleteSoftMultiple(selected, requestName)
        .then((res) => {
          console.warn('--------', res);
          toast.success(res?.data?.message);
          if (requestName === 'All') {
            dispatch(getQuoteMethod());
          } else if (requestName === 'Pending') {
            dispatch(getPendingQuoteMethod());
          } else if (requestName === 'Confirm') {
            dispatch(getConfirmQuoteMethod());
          } else if (requestName === 'Deliver') {
            dispatch(getDeliverQuoteMethod());
          }
          setSelected([]);
        })
        .catch((error) => {
          if (!error.response.data.message) {
            toast.error(error.message, { position: 'top-right' });
          } else {
            toast.error(error.response.data.message, { position: 'top-right' });
          }
        });
      setSelected([]);
    }
  };

  const handleDeleteMethod = async () => {
    if (requestName === 'Deleted') {
      await GetQuoteServices.premanentDelete(quoteId)
        .then((res) => {
          console.warn('--------', res);
          toast.success(res?.data?.message);
          dispatch(getDeletedQuoteMethod());
          setQuouteId(null);
          setOpen(false);
        })
        .catch((error) => {
          if (!error.response.data.message) {
            toast.error(error.message, { position: 'top-right' });
          } else {
            toast.error(error.response.data.message, { position: 'top-right' });
          }
        });
    } else {
      await GetQuoteServices.softDelete(quoteId)
        .then((res) => {
          console.warn('--------', res);
          toast.success(res?.data?.message);
          if (requestName === 'All') {
            dispatch(getQuoteMethod());
          } else if (requestName === 'Pending') {
            dispatch(getPendingQuoteMethod());
          } else if (requestName === 'Confirm') {
            dispatch(getConfirmQuoteMethod());
          } else if (requestName === 'Deliver') {
            dispatch(getDeliverQuoteMethod());
          }
          setQuouteId(null);
          setOpen(false);
        })
        .catch((error) => {
          if (!error.response.data.message) {
            toast.error(error.message, { position: 'top-right' });
          } else {
            toast.error(error.response.data.message, { position: 'top-right' });
          }
        });
    }
  };

  const handeleRestore = async () => {
    await GetQuoteServices.restore(quoteId)
      .then((res) => {
        console.warn('--------', res);
        toast.success(res?.data?.message);
        dispatch(getDeletedQuoteMethod());
        setQuouteId(null);
        setOpen(false);
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };

  const handleChangeStatus = async (e) => {
    const data = {
      quoteId,
      status: e.target.value,
    };
    await GetQuoteServices.updateStatus(data)
      .then((res) => {
        console.warn('--------', res);
        toast.success(res?.data?.message);
        if (requestName === 'All') {
          dispatch(getQuoteMethod());
        } else if (requestName === 'Pending') {
          dispatch(getPendingQuoteMethod());
        } else if (requestName === 'Confirm') {
          dispatch(getConfirmQuoteMethod());
        } else if (requestName === 'Deliver') {
          dispatch(getDeliverQuoteMethod());
        }
        handleCloseChangeStatusMenu();
        setQuouteId(null);
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
        throw error;
      });
  };
  return (
    <>
      <Helmet>
        <title> Get Quote Request | PakPrint Wishes </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Get {requestName} Quote Request
          </Typography>
          <Box>
            <Button
              variant="contained"
              sx={{ margin: '5px' }}
              onClick={() => {
                dispatch(getQuoteMethod());
                setRequestName('All');
              }}
            >
              All Request
            </Button>
            <Button
              sx={{ margin: '5px' }}
              variant="contained"
              onClick={() => {
                dispatch(getPendingQuoteMethod());
                setRequestName('Pending');
              }}
            >
              Pending Request
            </Button>
            <Button
              sx={{ margin: '5px' }}
              variant="contained"
              onClick={() => {
                dispatch(getConfirmQuoteMethod());
                setRequestName('Confirm');
              }}
            >
              Confirm Request
            </Button>
            <Button
              sx={{ margin: '5px' }}
              variant="contained"
              onClick={() => {
                dispatch(getDeliverQuoteMethod());
                setRequestName('Deliver');
              }}
            >
              Deliver Request
            </Button>
            <Button
              sx={{ margin: '5px' }}
              variant="contained"
              onClick={() => {
                dispatch(getDeletedQuoteMethod());
                setRequestName('Deleted');
              }}
            >
              Deleted Request
            </Button>
          </Box>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleSelectDeleteMethod={handleSelectDeleteMethod}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredUsers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      _id,
                      name,
                      email,
                      phone,
                      boxType,
                      height,
                      width,
                      length,
                      typeCard,
                      printingType,
                      colorOption,
                      status,
                      rate,
                      comment,
                      attachFile,
                    } = row;
                    const selectedUser = selected.indexOf(_id) !== -1;
                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {!name ? 'N/A' : name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{!email ? 'N/A' : email}</TableCell>
                        <TableCell align="left">{!phone ? 'N/A' : phone}</TableCell>
                        <TableCell align="left">{!boxType ? 'N/A' : boxType}</TableCell>
                        <TableCell align="left">{!width ? 'N/A' : width}</TableCell>
                        <TableCell align="left">{!height ? 'N/A' : height}</TableCell>
                        <TableCell align="left">{!length ? 'N/A' : length}</TableCell>
                        <TableCell align="left">{!typeCard ? 'N/A' : typeCard}</TableCell>
                        <TableCell align="left">{!printingType ? 'N/A' : printingType}</TableCell>
                        <TableCell align="left">{!colorOption ? 'N/A' : colorOption}</TableCell>
                        <TableCell align="left">{row?.quantity_1}</TableCell>
                        <TableCell align="left">{row?.quantity_2}</TableCell>
                        <TableCell align="left">{row?.quantity_3}</TableCell>
                        <TableCell align="left">{!status ? 'N/A' : status}</TableCell>
                        <TableCell align="left">{rate !== null && rate !== undefined ? rate : 'N/A'}</TableCell>
                        <TableCell align="left">{!comment ? 'N/A' : comment}</TableCell>
                        <TableCell align="left">
                          {!attachFile ? (
                            'N/A'
                          ) : (
                            <a
                              style={{ textDecoration: 'none' }}
                              href={IMAGE_BASE_URL + attachFile}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View
                            </a>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {requestName === 'Deleted' ? (
                            <Button
                              disabled
                              onClick={(e) => {
                                handleOpenChangeStatusMenu(e, _id);
                              }}
                            >
                              Change Status
                            </Button>
                          ) : (
                            <Button
                              onClick={(e) => {
                                handleOpenChangeStatusMenu(e, _id);
                              }}
                            >
                              Change Status
                            </Button>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              handleOpenMenu(e, row);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {requestName === 'Deleted' ||
        (quoteItem !== null && quoteItem.rate !== null && quoteItem.rate !== undefined) ? null : (
          <MenuItem onClick={handleClickOpenAddForm}>
            <Iconify icon={'mdi:plus'} sx={{ mr: 2 }} />
            Add Rate
          </MenuItem>
        )}
        {requestName === 'Deleted' ? null : (
          <MenuItem onClick={handleClickOpenEditForm}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit Rate
          </MenuItem>
        )}
        {requestName === 'Deleted' ? (
          <MenuItem onClick={handeleRestore}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Restore
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => {
            navigate(`/dashboard/get-quote-details/${quoteId}`);
          }}
        >
          <Iconify icon={'ic:outline-remove-red-eye'} sx={{ mr: 2 }} />
          View
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteMethod}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <Popover
        open={Boolean(changeStatusMenu)}
        anchorEl={changeStatusMenu}
        onClose={handleCloseChangeStatusMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <FormControl fullWidth>
          <Select value={selectStatus} onChange={handleChangeStatus} sx={{ textAlign: 'left' }}>
            <MenuItem value="Change Status" disabled>
              Change Status
            </MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Confirm">Confirm</MenuItem>
            <MenuItem value="Deliver">Deliver</MenuItem>
          </Select>
        </FormControl>
      </Popover>
      <AddGetQuoteRateDialog
        openAddForm={openAddForm}
        handleClickOpenAddForm={handleClickOpenAddForm}
        quoteId={quoteId}
        requestName={requestName}
      />
      <UpdateGetQuoteRateDialog
        openEditForm={openEditForm}
        handleClickOpenEditForm={handleClickOpenEditForm}
        quoteItem={quoteItem}
        requestName={requestName}
      />
    </>
  );
}
