import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ChildCategorySEO from '../api/ChildCategorySEO';

const initialState = {
  loading: false,
  data: [],
  error: null,
  success: false,
};

export const getChildCategorySEOMethod = createAsyncThunk(`/get-getChildCategorySEOMethod`, async () => {
  try {
    const { data } = await ChildCategorySEO.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const childCategorySEOSlice = createSlice({
  name: 'childCategorySEO',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getChildCategorySEOMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getChildCategorySEOMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getChildCategorySEOMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default childCategorySEOSlice.reducer;
