import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import JobUserServices from '../api/JobUserServices';

const initialState = {
  loading: false,
  userInfo: null,
  error: null,
  success: false,
};

export const jobsuserRegister = createAsyncThunk(`jobsUser/user-register`, async (datas) => {
  try {
    const { data } = await JobUserServices.register(datas);
    return data;
  } catch (error) {
    console.error(error);
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

export const jobsuserVerifyOtp = createAsyncThunk(`jobsUser/user-VerifyOtp`, async (datas) => {
  try {
    const { data } = await JobUserServices.otp(datas);
    return data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

export const jobsuserLogin = createAsyncThunk(`jobsUser/user-login`, async (datas) => {
  try {
    const { data } = await JobUserServices.login(datas);
    // store user's token in local storage
    return data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }

    throw error;
  }
});
export const jobsforgetPasswordMethod = createAsyncThunk(`jobsUser/user-forgetPassword`, async (datas) => {
  try {
    const { data } = await JobUserServices.forgetPassword(datas);
    return data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
export const jobsforgetPasswordOPTMethod = createAsyncThunk(`jobsUser/user-otpForgetPassword`, async (datas) => {
  try {
    const { data } = await JobUserServices.otpForgetPassword(datas);
    return data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

export const jobsresetPasswordMethod = createAsyncThunk(`jobsUser/user-resetPassword`, async (datas) => {
  try {
    const { data } = await JobUserServices.resetPassword(datas);
    return data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

export const jobsgetUserDetails = createAsyncThunk(`jobsUser/user-details`, async () => {
  try {
    const { data } = await JobUserServices.getUser();
    return data.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
});

const jobsUserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('access_token'); // delete token from storage
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    },
  },
  extraReducers: {
    // login user
    [jobsuserLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [jobsuserLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload.data;
      state.userToken = payload.access_token;
    },
    [jobsuserLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // // register user
    [jobsuserRegister.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [jobsuserRegister.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true; // registration successful
      state.userInfo = payload;
    },
    [jobsuserRegister.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // otp verify
    [jobsuserVerifyOtp.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [jobsuserVerifyOtp.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload;
    },
    [jobsuserVerifyOtp.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // forget password
    [jobsforgetPasswordMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [jobsforgetPasswordMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload;
    },
    [jobsforgetPasswordMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // forget password otp
    [jobsforgetPasswordOPTMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [jobsforgetPasswordOPTMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload;
    },
    [jobsforgetPasswordOPTMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // reset password
    [jobsresetPasswordMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [jobsresetPasswordMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload;
    },
    [jobsresetPasswordMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // get user details
    [jobsgetUserDetails.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [jobsgetUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload;
    },
    [jobsgetUserDetails.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { logout } = jobsUserSlice.actions;

export default jobsUserSlice.reducer;
