import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/createsubCategory', data);
};
const deletes = (id) => {
  return callAPi.delete(`/deleteSubCategory/${id}`);
};
const deleteMultiple = (data) => {
  return callAPi.post(`/deleteMultipleSubCategory`, { ids: data });
};
const get = () => {
  return callAPi.get('/getallSubCategory');
};
const publicGet = (id) => {
  return callAPi.get(`/user/getallSubCategoryByParentId/${id}`);
};
const update = (id, data) => {
  return callAPi.post(`/updateSubCategory/${id}`, data);
};
const status = (id) => {
  return callAPi.post(`/admin/verifySubCategory`,{categoryId:id});
};
const SubCategoryService = {
  add,
  deletes,
  get,
  update,
  deleteMultiple,
  status,
  publicGet
};

export default SubCategoryService;
