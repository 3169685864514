import { Grid, Typography } from '@mui/material';
import moment from 'moment/moment';
import React from 'react';
import { Link } from 'react-router-dom';

const JobCard = (props) => {
  const data = props?.item;
  return (
    <Grid
      container
      spacing={2}
      className="job-card"
      component={Link}
      to={`/jobs/find-jobs/${!data?.jobTitle ? 'N/A' : data?.jobTitle.replace(/\s+/g, '-').toLowerCase()}/${data?._id}`}
    >
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="subtitle1">{!data?.jobTitle ? 'N/A' : data?.jobTitle}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="body2">{!data?.createdAt ? 'N/A' : moment(data?.createdAt).fromNow()}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="body2">Proposals-{!data?.jobProposals ? 0 : data?.jobProposals.length}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <Typography variant="h6">PKR {!data?.priceBeforeAssignJob ? 0 : data?.priceBeforeAssignJob}</Typography>
      </Grid>
    </Grid>
  );
};

export default JobCard;
