import { Grid, Typography } from '@mui/material';
import React from 'react';
import SavedDetailCard from '../components/card/SavedDetailCard';
import JobDetailsPageForProposal from './JobDetailsPageForProposal';

const SavedJobDetailPage = () => {
  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} py={3} p={1}>
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          <Grid item xs={12} py={1}>
            <Typography variant="h6">Saved Jobs — 2,031 found</Typography>
          </Grid>
          <Grid item xs={0} md={4} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SavedDetailCard />
              </Grid>
              <Grid item xs={12}>
                <SavedDetailCard />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <JobDetailsPageForProposal />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SavedJobDetailPage;
