import http from "./http-common";

const getAll = () => {
  return http.get("/user/getAllPartner");
};

const PartnerServices = {
  getAll,
};

export default PartnerServices;
