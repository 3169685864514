import http from "./http-common";

const getAll = () => {
  return http.get("/getAllBuyNow");
};


const BuyNowServices = {
  getAll
};

export default BuyNowServices;