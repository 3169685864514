import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Autocomplete, FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import CustomSubCategoryService from '../../../redux/api/CustomSubCategoryService';
import RichTextEditors from '../../RichText/RichTextEditors';
import { getCustomSubCategoryMethod } from '../../../redux/slice/customSubCategorySlice';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function UpdateCustomSubCategoryDialog({ openEditForm, handleClickOpenEditForm, subCategoryId }) {
  const dispatch = useDispatch();
  const parentCategoryData = useSelector((state) => state.customParentCategory);
  const data = useSelector((state) => state.customSubCategory);

  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [parentCategory, setParentCategory] = React.useState([]);
  const [file, setFile] = React.useState([]);
  const options = React.useMemo(() => {
    if (parentCategoryData?.data && Array.isArray(parentCategoryData?.data)) {
      return parentCategoryData?.data.map((product) => ({
        value: product?._id,
        label: product?.title,
      }));
    }
    return [];
  }, [parentCategoryData?.data]);

  React.useEffect(() => {
    const result = data?.data?.filter((item) => item?._id === subCategoryId);

    if (result?.[0]) {
      const { title, description, customParentCategoryIds } = result[0];
      setTitle(title);
      setDescription(description);

      if (customParentCategoryIds?.length > 0) {
        let a;
        for (a = 0; a < customParentCategoryIds?.length; a += 1) {
          // Set parent category based on the mapped values
          setParentCategory(
            customParentCategoryIds.map((product) => ({
              value: product?._id,
              label: product?.title,
            }))
          );
        }
      }
    }
  }, [subCategoryId, data?.data, parentCategoryData?.data]);

  const handleSubmit = async () => {
    if (!title || !description || parentCategory.length === 0) {
      toast.error('Please fill all fields');
      return;
    }
    const data = {
      title,
      customParentCategoryIds: parentCategory.map((item) => item.value),
      description,
      customChildCategoryImage: file,
    };
    await CustomSubCategoryService.update(subCategoryId, data)
      .then((res) => {
        if (res.data.success === true) {
          console.warn(res);
          dispatch(getCustomSubCategoryMethod());
          handleClickOpenEditForm();
        }
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };
  return (
    <div>
      <Dialog fullScreen open={openEditForm} onClose={handleClickOpenEditForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenEditForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Custom Sub Category
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Edit Custom Sub Category</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Sub Category Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Custom Parent Category
            </Typography>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={options}
              value={parentCategory} // Set the default value here
              onChange={(event, newValue) => setParentCategory(newValue)}
              isOptionEqualToValue={(option, value) => option.value === value}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} placeholder="Select Custom Parent Category" />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Description
            </Typography>
            <RichTextEditors placeholder="Description" body={description} setBody={setDescription} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Upload Image
            </Typography>
            <br />
            <input
              accept="image/*"
              type="file"
              id="select-image"
              onChange={(e) => setFile(e.target.files[0])}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={() => {
                handleSubmit();
                // handleClickOpenEditForm();
              }}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
