import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import JobsService from '../api/JobsService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const addJobsMethod = createAsyncThunk(`/add-jobs`, async (datas) => {
  try {
    const { data } = await JobsService.add(datas);
    console.warn(data);
    toast.success("Job added successfully");
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
export const getJobsMethod = createAsyncThunk(`/get-jobs`, async () => {
  try {
    const { data } = await JobsService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteSelectedJobsMethod = createAsyncThunk(`/delete-jobs`, async (id) => {
  try {
    const { data } = await JobsService.deleteMultiple(id);
    console.warn(data);
    toast.success("Job deleted successfully");
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {},
  extraReducers: {
    // add
    [addJobsMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addJobsMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = [...state.data, payload];
    },
    [addJobsMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get
    [getJobsMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getJobsMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getJobsMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete Multiple
    [deleteSelectedJobsMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedJobsMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedJobsMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default jobsSlice.reducer;
