import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JobDetailCard from '../components/card/JobDetailCard';
import JobDetailsPageForProposal from './JobDetailsPageForProposal';
import { getfreelanceClientJobs } from '../redux/slice/freelanceClientJobsSlice';

const FreelanceJobsPage = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.freelanceClientJobs.data);

  useEffect(() => {
    dispatch(getfreelanceClientJobs());
  }, [dispatch]);
  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} py={3} p={1}>
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          <Grid item xs={12} py={1}>
            <Typography variant="h6">Freelance Jobs — {!data || data.length === 0 ? 0 : data.length} found</Typography>
          </Grid>
          <Grid item xs={0} md={4} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <Grid container spacing={3}>
              {!data || data.length === 0
                ? null
                : data.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <JobDetailCard item={item}/>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <JobDetailsPageForProposal />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FreelanceJobsPage;
