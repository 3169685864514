import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import BlogListCard from '../card/BlogListCard';
import BlogService from '../../redux/api/BlogService';
import BlogSlider from './BlogSlider';

const Blog = () => {
  const [blogData, setBlogData] = useState(null);
  const [blogSliderData, setBlogSliderData] = useState(null);

  const getSliderDetails = async () => {
    await BlogService.getSlider()
      .then((item) => {
        setBlogSliderData(item?.data?.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const getData = async () => {
    await BlogService.getPublic()
      .then((item) => {
        setBlogData(item?.data?.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    getSliderDetails();
    getData();
  }, []);
  return (
    <Grid container spacing={3} my={2} justifyContent={'center'}>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={6}>
            {!blogSliderData || blogSliderData.length === 0 ? null : <BlogSlider item={blogSliderData} />}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2}>
              {blogData && blogData.length >= 1
                ? blogData.slice(0, 2).map((item, index) => (
                    <Grid item xs={12} sm={12} md={6} key={index}>
                      <BlogListCard item={item} />
                    </Grid>
                  ))
                : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Blog;
