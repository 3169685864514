import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PrintingAccessoriesServices from '../api/PrintingAccessoriesServices';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const retrievePrintingAccessoriesServices = createAsyncThunk('printingAccessories-getall', async () => {
  try {
    const res = await PrintingAccessoriesServices.getAll();
    return res.data.data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
});

export const retrievePrintingAccessoriesPageServices = createAsyncThunk('printingAccessories-getall', async (page) => {
  try {
    const res = await PrintingAccessoriesServices.getPageAll(page);
    return res.data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
});
export const retrievePrintingAccessoriesById = createAsyncThunk('printingAccessories-get', async (id) => {
  try {
    const res = await PrintingAccessoriesServices.get(id);
    return res.data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
});

const printingAccessoriesSlice = createSlice({
  name: 'printingAccessories',
  initialState,
  extraReducers: {
    [retrievePrintingAccessoriesServices.pending]: (state) => {
      state.loading = true;
    },
    [retrievePrintingAccessoriesServices.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [retrievePrintingAccessoriesServices.rejected]: (state) => {
      state.loading = false;
    },
    [retrievePrintingAccessoriesPageServices.pending]: (state) => {
      state.loading = true;
    },
    [retrievePrintingAccessoriesPageServices.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [retrievePrintingAccessoriesPageServices.rejected]: (state) => {
      state.loading = false;
    },
    [retrievePrintingAccessoriesById.pending]: (state) => {
      state.loading = false;
    },
    [retrievePrintingAccessoriesById.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [retrievePrintingAccessoriesById.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const selectAllData = (state) => state.printingAccessories.data;
export const getStatusMessage = (state) => state.printingAccessories.status;
export const getErrorMessage = (state) => state.printingAccessories.error;

export default printingAccessoriesSlice.reducer;
