import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import GroupIcon from '@mui/icons-material/Group';
import StyleIcon from '@mui/icons-material/Style';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TungstenIcon from '@mui/icons-material/Tungsten';

const USP = () => (
    <Grid container>
      <Grid item xs={6} sm={4} md={3} lg={2} textAlign={'center'}>
        <Box className="usp-container" p={4}>
          <InventoryIcon sx={{ fontSize: '50px' }} />
          <Typography>Starting from 1 to 2 Boxes</Typography>
        </Box>
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2} textAlign={'center'}>
        <Box className="usp-container" p={4}>
          <LocalPrintshopIcon sx={{ fontSize: '50px' }} />
          <Typography>High Quality Digital Printing</Typography>
        </Box>
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2} textAlign={'center'}>
        <Box className="usp-container" p={4}>
          <GroupIcon sx={{ fontSize: '50px' }} />
          <Typography>Many Printing Vendor</Typography>
        </Box>
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2} textAlign={'center'}>
        <Box className="usp-container" p={4}>
          <StyleIcon sx={{ fontSize: '50px' }} />
          <Typography>Custom Style and Size</Typography>
        </Box>
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2} textAlign={'center'}>
        <Box className="usp-container" p={4}>
          <LocalShippingIcon sx={{ fontSize: '50px' }} />
          <Typography>Timely Delivery</Typography>
        </Box>
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2} textAlign={'center'}>
        <Box className="usp-container" p={4}>
          <TungstenIcon sx={{ fontSize: '50px' }} />
          <Typography>Fully Printing Solution</Typography>
        </Box>
      </Grid>
    </Grid>
  );

export default USP;
