import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BuyNowServices from '../api/BuyNowServices';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const retrieveBuyNowServices = createAsyncThunk('buynow-getAll', async () => {
  try {
    const res = await BuyNowServices.getAll();
    return res.data.data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
});

const buyNowSlice = createSlice({
  name: 'buynow',
  initialState,
  extraReducers: {
    [retrieveBuyNowServices.pending]: (state) => {
      state.loading = true;
    },
    [retrieveBuyNowServices.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [retrieveBuyNowServices.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const selectAllData = (state) => state.printingAccessories.data;
export const getStatusMessage = (state) => state.printingAccessories.status;
export const getErrorMessage = (state) => state.printingAccessories.error;

export default buyNowSlice.reducer;
