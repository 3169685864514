import { useState } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { userVerifyOtp } from '../../../redux/slice/userSlice';
import UserServices from '../../../redux/api/UserServices';

// ----------------------------------------------------------------------

export default function OTPForm() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const handleClick = async () => {
    const data = {
      email: location.state.emailID,
      otp,
    };
    const result = await dispatch(userVerifyOtp(data));
    if (result.payload.success === true) {
      toast.success('OTP Verification Successfully');
      navigate('/admin-login', { replace: true });
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <MuiOtpInput required value={otp} length={6} onChange={(e) => setOtp(e)} />
        <Typography
          variant="body1"
          sx={{ textAlign: 'right', cursor: 'pointer', color: '#2065D1', fontWeight: 'bold' }}
          onClick={async () => {
            await UserServices.resendOTP({ email: location.state.emailID })
              .then((res) => {
                toast.success(res.data.message);
              })
              .catch((error) => {
                if (!error.response.data.message) {
                  toast.error(error.message, { position: 'top-right' });
                } else {
                  toast.error(error.response.data.message, { position: 'top-right' });
                }
              });
          }}
        >
          Resend OTP
        </Typography>
        <LoadingButton
          sx={{ marginTop: '15px' }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleClick}
        >
          Verify OTP
        </LoadingButton>
      </Stack>
    </>
  );
}
