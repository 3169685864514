import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Autocomplete,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { pink } from '@mui/material/colors';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import RichTextEditors from '../../RichText/RichTextEditors';
import { getCustomParentCategoryMethod } from '../../../redux/slice/customParentCategorySlice';
import { getCustomSubCategoryMethod } from '../../../redux/slice/customSubCategorySlice';
import CustomProductServices from '../../../redux/api/CustomProductServices';
import { getCustomProductMethod } from '../../../redux/slice/customProductSlice';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const initialFormValues = {
  customParentCategoryIds: [],
  customChildCategoryIds: [],
  titles: '',
  images: [],
  sku: '',
  prices: '',
  customFields: {
    bagMaterial: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    bagSize: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    colors: { fields: [{ title: '', itemStatus: 'No', colorCode: '', price: '0' }], status: false },
    colorsofRibbon: {
      fields: [{ title: '', itemStatus: 'No', colorCode: '', price: '0' }],
      status: false,
    },
    colorsofTextonRibbon: {
      fields: [{ title: '', itemStatus: 'No', colorCode: '', price: '0' }],
      status: false,
    },
    coverMaterial: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    cutting: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    formats: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    innerCard: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    ledOption: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    mobileBrand: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    mugColor: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    packaging: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    paper: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    paperFinish: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    paperThickness: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    paperType: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    card: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    cardFinish: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    cardThickness: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    cardType: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    pasting: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    printing: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    printingFrontBack: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    printingSide: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    sides: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    sizes: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    stickerType: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    tShirtColor: {
      fields: [{ title: '', itemStatus: 'No', colorCode: '', price: '0' }],
      status: false,
    },
    tShirtCut: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    tShirtSize: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    textInput: { fields: [{ title: '' }], status: false },
    types: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    waxSeal: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
    foilMinimumQTY: { fields: [{ title: '', itemStatus: 'No', price: '0' }], status: false },
  },
  objectFields: {
    qty: { fields: { startRange: 1, endRange: 100, difference: 5 }, status: false },
  },
  stringFields: {
    description: {
      value: '',
      status: false,
    },
    productDescription: {
      value: '',
      status: false,
    },
  },
  booleanFields: {
    disclaimer: false,
    files: false,
    imageCaption: false,
    inquiryForm: false,
    lamination: false,
    mobileModel: false,
    contactUsPlace: false,
    qtyCaption: false,
    tissueFilling: false,
    addToCartBtn: false,
    qrCodeButton: false,
  },
};

export default function AddCustomProductDialog({ openAddForm, handleClickOpenAddForm }) {
  const dialogRef = React.useRef(null);
  const [values, setValues] = React.useState(initialFormValues);
  const [errors, setErrors] = React.useState({});
  const [productDescriptionValue, setProductDescriptionValue] = React.useState(null);
  const [descriptionValue, setDescriptionValue] = React.useState(null);
  const [parentCategory, setParentCategory] = React.useState([]);
  const [subCategory, setsubCategory] = React.useState([]);

  const dispatch = useDispatch();

  const parentCategoryData = useSelector((state) => state.customParentCategory);
  const subCategoryData = useSelector((state) => state.customSubCategory);

  React.useEffect(() => {
    dispatch(getCustomParentCategoryMethod());
    dispatch(getCustomSubCategoryMethod());
  }, [dispatch]);

  // Function to scroll to the top of the dialog content
  const scrollToTop = () => {
    if (dialogRef.current) {
      dialogRef.current.scrollTop = 0;
    }
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      customParentCategoryIds: parentCategory.map((item) => item.value),
      customChildCategoryIds: subCategory.map((item) => item.value),
    }));
  }, [parentCategory, subCategory]);

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      stringFields: {
        ...prevValues.stringFields,
        description: {
          ...prevValues.stringFields?.description,
          value: descriptionValue,
        },
        productDescription: {
          ...prevValues.stringFields?.productDescription,
          value: productDescriptionValue,
        },
      },
    }));
  }, [productDescriptionValue, descriptionValue]);
  // Handle the checkbox handler
  const handleChange = (event) => {
    const { name, checked } = event.target;

    setValues((prevValues) => {
      // Handle boolean fields
      if (name in prevValues.booleanFields) {
        return {
          ...prevValues,
          booleanFields: {
            ...prevValues.booleanFields,
            [name]: checked,
          },
        };
      }

      // Handle customFields
      const updatedCustomFields = { ...prevValues.customFields };
      if (name in updatedCustomFields) {
        updatedCustomFields[name].status = checked;
      }

      // Handle stringFields
      const updatedStringFields = { ...prevValues.stringFields };
      if (name in updatedStringFields) {
        updatedStringFields[name].status = checked;
      }

      // Handle objectFields
      const updatedObjectFields = { ...prevValues.objectFields };
      if (name in updatedObjectFields) {
        updatedObjectFields[name].status = checked;
      }

      return {
        ...prevValues,
        customFields: updatedCustomFields,
        stringFields: updatedStringFields,
        objectFields: updatedObjectFields,
      };
    });
  };

  const handleAddItem = (type, fieldKey, newItem) => {
    setValues((prevValues) => ({
      ...prevValues,
      customFields: {
        ...prevValues.customFields,
        [fieldKey]: {
          ...prevValues.customFields[fieldKey],
          fields: [...prevValues.customFields[fieldKey].fields, newItem],
        },
      },
    }));
  };

  const handleRemoveItem = (fieldKey, index) => {
    setValues((prevValues) => {
      const updatedFields = [...prevValues.customFields[fieldKey].fields];
      updatedFields.splice(index, 1);

      return {
        ...prevValues,
        customFields: {
          ...prevValues.customFields,
          [fieldKey]: {
            ...prevValues.customFields[fieldKey],
            fields: updatedFields,
          },
        },
      };
    });
  };

  const handleItemChange = (fieldType, fieldName, index, key, value) => {
    setValues((prevValues) => {
      const updatedField = {
        ...prevValues[fieldType],
        [fieldName]: {
          ...prevValues[fieldType][fieldName],
          fields: Array.isArray(prevValues[fieldType][fieldName].fields)
            ? prevValues[fieldType][fieldName].fields.map((item, i) => (i === index ? { ...item, [key]: value } : item))
            : { ...prevValues[fieldType][fieldName].fields, [key]: value },
        },
      };

      return {
        ...prevValues,
        [fieldType]: updatedField,
      };
    });
  };

  const handleNumberItemChange = (fieldType, fieldName, index, key, value) => {
    // Ensure the value is a non-negative number or set it to an empty string
    const newValue = /^[+]?\d+(\.\d+)?$/.test(value) ? value : '';

    handleItemChange(fieldType, fieldName, index, key, newValue);
  };

  const handleObjectFieldChange = (fieldType, fieldName, key, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [fieldType]: {
        ...prevValues[fieldType],
        [fieldName]: {
          ...prevValues[fieldType][fieldName],
          fields: {
            ...prevValues[fieldType][fieldName].fields,
            [key]: value,
          },
        },
      },
    }));
    // Perform validation here
    if (fieldName === 'qty') {
      const { startRange, endRange, difference } = values.objectFields.qty.fields;

      if (startRange >= endRange) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          objectFields: 'End range should be greater than start range.',
        }));
      } else if (difference < startRange) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          objectFields: 'Difference should not be less than start range.',
        }));
      } else if (difference > endRange) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          objectFields: 'Difference should not be greater than end range.',
        }));
      } else {
        // Clear the error if validation passes
        setErrors((prevErrors) => ({
          ...prevErrors,
          objectFields: '',
        }));
      }
    }
  };

  const handleObjectNumberFieldChange = (fieldType, fieldName, key, value) => {
    const newValue = /^[+]?\d+(\.\d+)?$/.test(value) ? value : '';
    handleObjectFieldChange(fieldType, fieldName, key, newValue);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validations = (fieldValue = values) => {
    const temp = { ...errors };
    if ('titles' in fieldValue) temp.titles = fieldValue.titles ? '' : 'This field requires';
    if ('prices' in fieldValue) temp.prices = fieldValue.prices ? '' : 'This field requires';
    if ('sku' in fieldValue) temp.sku = fieldValue.sku ? '' : 'This field requires';
    if ('customParentCategoryIds' in fieldValue)
      temp.customParentCategoryIds =
        fieldValue.customParentCategoryIds.length > 0 ? '' : 'Please select at least one parent category';

    // Validate booleanFields
    const { addToCartBtn, contactUsPlace, inquiryForm } = fieldValue.booleanFields;
    if (!(addToCartBtn || contactUsPlace || inquiryForm)) {
      temp.booleanFields = 'Please select at least one of the options (Add to Cart, Please Contact..., Inquire Form)';
    } else {
      temp.booleanFields = '';
    }

    // Validate qty object in objectFields
    if (addToCartBtn === true && !fieldValue?.objectFields?.qty?.status) {
      temp.objectFields = 'Quantity object is required when "Add To Cart Btn" is checked';
    } else {
      temp.objectFields = '';
    }
    // Validate customFields
    // Object.keys(fieldValue.customFields).forEach((key) => {
    //   if (fieldValue.customFields[key].status === true) {
    //     const fieldsArray = fieldValue.customFields[key].fields;
    //     const fieldTitleErrors = fieldsArray.map((field, index) =>
    //       !field.title ? `Title is required for field ${key}` : ''
    //     );
    //     if (fieldTitleErrors.some((error) => error !== '')) {
    //       temp.customFields = fieldTitleErrors;
    //     } else {
    //       temp.customFields = '';
    //     }
    //   }
    // });

    // // Validate stringFields
    // Object.keys(fieldValue.stringFields).forEach((key) => {
    //   if (fieldValue.stringFields[key].status === true && !fieldValue.stringFields[key].value) {
    //     temp.stringFields = `${key} value is required`;
    //   } else {
    //     temp.stringFields = '';
    //   }
    // });
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validations()) {
      await CustomProductServices.add(values)
        .then((res) => {
          toast.success(res?.data?.message);
          setValues(initialFormValues);
          setDescriptionValue(null);
          setProductDescriptionValue(null);
          dispatch(getCustomProductMethod());
          handleClickOpenAddForm();
        })
        .catch((err) => {
          if (!err?.response?.data) {
            toast.error(err.message);
          } else {
            toast.error(err.response.data.message);
          }
        });
    }
  };

  const children = (
    <Grid container sx={{ ml: 3 }}>
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="subtitle1">Custom Fields</Typography>
        <br />
        <FormControlLabel
          label="Bag Material"
          control={
            <Checkbox checked={values?.customFields?.bagMaterial?.status} onChange={handleChange} name="bagMaterial" />
          }
        />
        <FormControlLabel
          label="Bag Size"
          control={<Checkbox checked={values?.customFields?.bagSize?.status} onChange={handleChange} name="bagSize" />}
        />
        <FormControlLabel
          label="Colors"
          control={<Checkbox checked={values?.customFields?.colors?.status} onChange={handleChange} name="colors" />}
        />
        <FormControlLabel
          label="Colors of Ribbon"
          control={
            <Checkbox
              checked={values?.customFields?.colorsofRibbon?.status}
              onChange={handleChange}
              name="colorsofRibbon"
            />
          }
        />
        <FormControlLabel
          label="Colors of Text on Ribbon"
          control={
            <Checkbox
              checked={values?.customFields?.colorsofTextonRibbon?.status}
              onChange={handleChange}
              name="colorsofTextonRibbon"
            />
          }
        />
        <FormControlLabel
          label="Cover Material"
          control={
            <Checkbox
              checked={values?.customFields?.coverMaterial?.status}
              onChange={handleChange}
              name="coverMaterial"
            />
          }
        />
        <FormControlLabel
          label="Cutting"
          control={<Checkbox checked={values?.customFields?.cutting?.status} onChange={handleChange} name="cutting" />}
        />
        <FormControlLabel
          label="Inner Card"
          control={
            <Checkbox checked={values?.customFields?.innerCard?.status} onChange={handleChange} name="innerCard" />
          }
        />
        <FormControlLabel
          label="LED Option"
          control={
            <Checkbox checked={values?.customFields?.ledOption?.status} onChange={handleChange} name="ledOption" />
          }
        />
        <FormControlLabel
          label="Mobile Brand"
          control={
            <Checkbox checked={values?.customFields?.mobileBrand?.status} onChange={handleChange} name="mobileBrand" />
          }
        />
        <FormControlLabel
          label="Mug Color"
          control={
            <Checkbox checked={values?.customFields?.mugColor?.status} onChange={handleChange} name="mugColor" />
          }
        />
        <FormControlLabel
          label="Packaging"
          control={
            <Checkbox checked={values?.customFields?.packaging?.status} onChange={handleChange} name="packaging" />
          }
        />
        <FormControlLabel
          label="Paper"
          control={<Checkbox checked={values?.customFields?.paper?.status} onChange={handleChange} name="paper" />}
        />
        <FormControlLabel
          label="Paper Finish"
          control={
            <Checkbox checked={values?.customFields?.paperFinish?.status} onChange={handleChange} name="paperFinish" />
          }
        />
        <FormControlLabel
          label="Paper Thickness"
          control={
            <Checkbox
              checked={values?.customFields?.paperThickness?.status}
              onChange={handleChange}
              name="paperThickness"
            />
          }
        />
        <FormControlLabel
          label="Paper Type"
          control={
            <Checkbox checked={values?.customFields?.paperType?.status} onChange={handleChange} name="paperType" />
          }
        />
        <FormControlLabel
          label="Card"
          control={<Checkbox checked={values?.customFields?.card?.status} onChange={handleChange} name="card" />}
        />
        <FormControlLabel
          label="Card Finish"
          control={
            <Checkbox checked={values?.customFields?.cardFinish?.status} onChange={handleChange} name="cardFinish" />
          }
        />
        <FormControlLabel
          label="Card Thickness"
          control={
            <Checkbox
              checked={values?.customFields?.cardThickness?.status}
              onChange={handleChange}
              name="cardThickness"
            />
          }
        />
        <FormControlLabel
          label="Card Type"
          control={
            <Checkbox checked={values?.customFields?.cardType?.status} onChange={handleChange} name="cardType" />
          }
        />
        <FormControlLabel
          label="Pasting"
          control={<Checkbox checked={values?.customFields?.pasting?.status} onChange={handleChange} name="pasting" />}
        />
        <FormControlLabel
          label="Printing"
          control={
            <Checkbox checked={values?.customFields?.printing?.status} onChange={handleChange} name="printing" />
          }
        />
        <FormControlLabel
          label="Printing Front-Back"
          control={
            <Checkbox
              checked={values?.customFields?.printingFrontBack?.status}
              onChange={handleChange}
              name="printingFrontBack"
            />
          }
        />
        <FormControlLabel
          label="Printing Side"
          control={
            <Checkbox
              checked={values?.customFields?.printingSide?.status}
              onChange={handleChange}
              name="printingSide"
            />
          }
        />

        <FormControlLabel
          label="Side"
          control={<Checkbox checked={values?.customFields?.sides?.status} onChange={handleChange} name="sides" />}
        />
        <FormControlLabel
          label="Size"
          control={<Checkbox checked={values?.customFields?.sizes?.status} onChange={handleChange} name="sizes" />}
        />
        <FormControlLabel
          label="Sticker Type"
          control={
            <Checkbox checked={values?.customFields?.stickerType?.status} onChange={handleChange} name="stickerType" />
          }
        />
        <FormControlLabel
          label="T Shirt Color"
          control={
            <Checkbox checked={values?.customFields?.tShirtColor?.status} onChange={handleChange} name="tShirtColor" />
          }
        />
        <FormControlLabel
          label="T Shirt Cut"
          control={
            <Checkbox checked={values?.customFields?.tShirtCut?.status} onChange={handleChange} name="tShirtCut" />
          }
        />
        <FormControlLabel
          label="T Shirt Size"
          control={
            <Checkbox checked={values?.customFields?.tShirtSize?.status} onChange={handleChange} name="tShirtSize" />
          }
        />
        <FormControlLabel
          label="Text Input"
          control={
            <Checkbox checked={values?.customFields?.textInput?.status} onChange={handleChange} name="textInput" />
          }
        />
        <FormControlLabel
          label="Types"
          control={<Checkbox checked={values?.customFields?.types?.status} onChange={handleChange} name="types" />}
        />
        <FormControlLabel
          label="Wax Seal"
          control={<Checkbox checked={values?.customFields?.waxSeal?.status} onChange={handleChange} name="waxSeal" />}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="subtitle1">Description Fields</Typography>
        <br />
        <FormControlLabel
          label="Description"
          control={
            <Checkbox checked={values?.stringFields?.description?.status} onChange={handleChange} name="description" />
          }
        />
        <FormControlLabel
          label="Product Discription"
          control={
            <Checkbox
              checked={values?.stringFields?.productDescription?.status}
              onChange={handleChange}
              name="productDescription"
            />
          }
        />
        <br />
        <br />
        <Typography variant="subtitle1">Qty Field for Show Price</Typography>
        <br />
        <FormControlLabel
          label="Qty"
          control={
            <Checkbox
              checked={values?.objectFields?.qty?.status}
              onChange={handleChange}
              name="qty"
              sx={{
                color: pink[800],
                '&.Mui-checked': {
                  color: pink[600],
                },
              }}
            />
          }
        />
        <br />
        {errors?.objectFields && (
          <Typography variant="caption" sx={{ color: 'red' }}>
            {errors?.objectFields}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="subtitle1">Boolean Field</Typography>
        <br />
        <FormControlLabel
          label="Disclaimer"
          control={<Checkbox checked={values?.booleanFields?.disclaimer} onChange={handleChange} name="disclaimer" />}
        />
        <FormControlLabel
          label="File"
          control={<Checkbox checked={values?.stringFields?.files?.status} onChange={handleChange} name="files" />}
        />
        <FormControlLabel
          label="Image Caption"
          control={
            <Checkbox checked={values?.booleanFields?.imageCaption} onChange={handleChange} name="imageCaption" />
          }
        />
        <FormControlLabel
          label="Lamination"
          control={<Checkbox checked={values?.booleanFields?.lamination} onChange={handleChange} name="lamination" />}
        />
        <FormControlLabel
          label="Mobile Model"
          control={<Checkbox checked={values?.booleanFields?.mobileModel} onChange={handleChange} name="mobileModel" />}
        />
        <FormControlLabel
          label="Qty Caption"
          control={<Checkbox checked={values?.booleanFields?.qtyCaption} onChange={handleChange} name="qtyCaption" />}
        />

        <FormControlLabel
          label="Tissue Filling"
          control={
            <Checkbox checked={values?.booleanFields?.tissueFilling} onChange={handleChange} name="tissueFilling" />
          }
        />
        <FormControlLabel
          label="QR Code Generate"
          control={
            <Checkbox checked={values?.booleanFields?.qrCodeButton} onChange={handleChange} name="qrCodeButton" />
          }
        />

        <FormControlLabel
          label="Please Contact us place your order (Whatsapp, Instagram)"
          control={
            <Checkbox
              checked={values?.booleanFields?.contactUsPlace}
              onChange={handleChange}
              name="contactUsPlace"
              sx={{
                color: pink[800],
                '&.Mui-checked': {
                  color: pink[600],
                },
              }}
            />
          }
        />
        <FormControlLabel
          label="Inquiry Form"
          control={
            <Checkbox
              checked={values?.booleanFields?.inquiryForm}
              onChange={handleChange}
              name="inquiryForm"
              sx={{
                color: pink[800],
                '&.Mui-checked': {
                  color: pink[600],
                },
              }}
            />
          }
        />
        <FormControlLabel
          label="Add to cart button"
          control={
            <Checkbox
              checked={values?.booleanFields?.addToCartBtn}
              onChange={handleChange}
              name="addToCartBtn"
              sx={{
                color: pink[800],
                '&.Mui-checked': {
                  color: pink[600],
                },
              }}
            />
          }
        />
        <br />
        {errors?.booleanFields && (
          <Typography variant="caption" sx={{ color: 'red' }}>
            {errors?.booleanFields}
          </Typography>
        )}
      </Grid>
    </Grid>
  );

  const parentOptions = React.useMemo(() => {
    if (parentCategoryData?.data && Array.isArray(parentCategoryData?.data)) {
      return parentCategoryData?.data.map((product) => ({
        value: product?._id,
        label: product?.title,
      }));
    }
    return [];
  }, [parentCategoryData?.data]);

  const childOptions = React.useMemo(() => {
    if (subCategoryData?.data && Array.isArray(subCategoryData?.data)) {
      return subCategoryData?.data.map((product) => ({
        value: product?._id,
        label: product?.title,
      }));
    }
    return [];
  }, [subCategoryData?.data]);

  return (
    <div>
      <Dialog fullScreen open={openAddForm} onClose={handleClickOpenAddForm} TransitionComponent={Transition}>
        <div ref={dialogRef}>
          {' '}
          <DialogContent>
            <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClickOpenAddForm} aria-label="close">
                  <Icon icon="ic:outline-close" />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Add Custom Product
                </Typography>
              </Toolbar>
            </AppBar>
            <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
                <Typography variant="h5">Add Custom Product</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Product Name
                </Typography>
                <TextField
                  id="outlined-basic"
                  name="titles"
                  onChange={handleChangeInput}
                  value={values?.titles}
                  error={errors.titles}
                  helperText={errors.titles}
                  placeholder="Product Name"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  PPW NO.
                </Typography>
                <TextField
                  id="outlined-basic"
                  name="sku"
                  onChange={handleChangeInput}
                  value={values?.sku}
                  error={errors.sku}
                  helperText={errors.sku}
                  placeholder="PPW NO."
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Custom Parent Category
                </Typography>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={parentOptions}
                  value={parentCategory} // Set the default value here
                  onChange={(event, newValue) => setParentCategory(newValue)}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  filterSelectedOptions
                  renderInput={(params) => <TextField {...params} placeholder="Select Custom Parent Category" />}
                />
                {errors?.customParentCategoryIds && (
                  <Typography variant="caption" sx={{ color: 'red' }}>
                    {errors?.customParentCategoryIds}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Custom Sub Category
                </Typography>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={childOptions}
                  value={subCategory} // Set the default value here
                  onChange={(event, newValue) => setsubCategory(newValue)}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  filterSelectedOptions
                  renderInput={(params) => <TextField {...params} placeholder="Select Custom Sub Category" />}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Basic Price
                </Typography>
                <TextField
                  id="outlined-basic"
                  value={values?.prices}
                  onChange={handleChangeInput}
                  placeholder="Prices"
                  variant="outlined"
                  name="prices"
                  type="number"
                  error={errors.prices}
                  helperText={errors.prices}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Upload Images
                </Typography>
                <br />
                <input
                  multiple
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const files = e.target.files;
                    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes
                    const maxFiles = 5;

                    const selectedFiles = [];
                    let errorMessage = '';

                    // Validate the number of selected files
                    if (files.length > maxFiles) {
                      errorMessage = `You can select up to ${maxFiles} images.`;
                    } else {
                      // Validate each selected file
                      for (let i = 0; i < files.length; i += 1) {
                        const file = files[i];
                        // Validate file type
                        if (!file.type.startsWith('image/')) {
                          errorMessage = 'Please select only image files.';
                          break;
                        }
                        // Validate file size
                        if (file.size > maxFileSize) {
                          errorMessage = `File "${file.name}" exceeds the maximum size limit of 5MB.`;
                          break;
                        }
                        // Add the file to the array
                        selectedFiles.push(file);
                      }
                    }

                    // If there's no error, update the state
                    if (errorMessage === '') {
                      setValues((prev) => ({ ...prev, images: selectedFiles }));
                    } else {
                      // Display error message or handle as needed
                      alert(errorMessage);
                      // Clear the file input if needed
                      e.target.value = null;
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Who you want to add in this Product?
                </Typography>
                <br />
                {/* <FormControlLabel
                  label="All"
                  control={<Checkbox checked={Object.values(values).every((value) => value)} />}
                /> */}
                {children}

                {/* <br />
                {errors?.customFields && (
                  <Typography variant="caption" sx={{ color: 'red' }}>
                    {errors?.customFields}
                  </Typography>
                )}
                {errors?.stringFields && (
                  <Typography variant="caption" sx={{ color: 'red' }}>
                    {errors?.stringFields}
                  </Typography>
                )} */}
              </Grid>
              {values?.customFields?.bagMaterial?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Bag Material
                  </Typography>
                  {values?.customFields?.bagMaterial?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`bagMaterial[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'bagMaterial', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`bagMaterial[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'bagMaterial', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`bagMaterial[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'bagMaterial', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('bagMaterial', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'bagMaterial', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.bagSize?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Bag Size
                  </Typography>
                  {values?.customFields?.bagSize?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`bagSize[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'bagSize', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          placeholder="Price"
                          name={`bagSize[${index}].price`}
                          value={item?.price}
                          type="number"
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'bagSize', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`bagSize[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'bagSize', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('bagSize', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'bagSize', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.colors?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Colors
                  </Typography>
                  {values?.customFields?.colors?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Color Name"
                          name={`colors[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'colors', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`colorCode-${index}`}
                          placeholder="Color Code"
                          name={`colors[${index}].colorCode`}
                          value={item?.colorCode}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'colors', index, 'colorCode', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          placeholder="Price"
                          name={`colors[${index}].price`}
                          value={item?.price}
                          type="number"
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'colors', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`colors[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'colors', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('colors', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'colors', {
                        title: '',
                        itemStatus: 'No',
                        colorCode: '',
                        price: '0',
                      })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.colorsofRibbon?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Colors of Ribbon
                  </Typography>
                  {values?.customFields?.colorsofRibbon?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Color Name"
                          name={`colorsofRibbon[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'colorsofRibbon', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`colorCode-${index}`}
                          placeholder="Color Code"
                          name={`colorsofRibbon[${index}].colorCode`}
                          value={item?.colorCode}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'colorsofRibbon', index, 'colorCode', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          placeholder="Price"
                          name={`colorsofRibbon[${index}].price`}
                          value={item?.price}
                          type="number"
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'colorsofRibbon', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`colorsofRibbon[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'colorsofRibbon', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('colorsofRibbon', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'colorsofRibbon', {
                        title: '',
                        itemStatus: 'No',
                        colorCode: '',
                        price: '0',
                      })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.colorsofTextonRibbon?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Colors of Text on Ribbon
                  </Typography>
                  {values?.customFields?.colorsofTextonRibbon?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Color Name"
                          name={`colorsofTextonRibbon[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'colorsofTextonRibbon', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`colorCode-${index}`}
                          placeholder="Color Code"
                          name={`colorsofTextonRibbon[${index}].colorCode`}
                          value={item?.colorCode}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'colorsofTextonRibbon', index, 'colorCode', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          placeholder="Price"
                          name={`colorsofTextonRibbon[${index}].price`}
                          value={item?.price}
                          type="number"
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange(
                              'customFields',
                              'colorsofTextonRibbon',
                              index,
                              'price',
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`colorsofTextonRibbon[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange(
                              'customFields',
                              'colorsofTextonRibbon',
                              index,
                              'itemStatus',
                              e.target.value
                            )
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('colorsofTextonRibbon', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'colorsofTextonRibbon', {
                        title: '',
                        itemStatus: 'No',
                        colorCode: '',
                        price: '0',
                      })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.coverMaterial?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Cover Material
                  </Typography>
                  {values?.customFields?.coverMaterial?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`coverMaterial[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'coverMaterial', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`coverMaterial[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'coverMaterial', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`coverMaterial[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'coverMaterial', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('coverMaterial', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'coverMaterial', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.cutting?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Cuttings
                  </Typography>
                  {values?.customFields?.cutting?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`cutting[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'cutting', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`cutting[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'cutting', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`cutting[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'cutting', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('cutting', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'cutting', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.stringFields?.description?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Description
                  </Typography>
                  <RichTextEditors body={descriptionValue} setBody={setDescriptionValue} placeholder="Description" />
                </Grid>
              )}
              {values?.customFields?.foilMinimumQTY?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Foil Minimum 100 QTY
                  </Typography>
                  {values?.customFields?.foilMinimumQTY?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`foilMinimumQTY[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'foilMinimumQTY', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`foilMinimumQTY[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'foilMinimumQTY', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`foilMinimumQTY[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'foilMinimumQTY', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('foilMinimumQTY', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'foilMinimumQTY', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.formats?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Formats
                  </Typography>
                  {values?.customFields?.formats?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`formats[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'formats', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`formats[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'formats', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`formats[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'formats', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('formats', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'formats', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.innerCard?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Inner Card
                  </Typography>
                  {values?.customFields?.innerCard?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`innerCard[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'innerCard', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`innerCard[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'innerCard', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`innerCard[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'innerCard', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('innerCard', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'innerCard', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.ledOption?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    LED Option
                  </Typography>
                  {values?.customFields?.ledOption?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`ledOption[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'ledOption', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`ledOption[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'ledOption', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`ledOption[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'ledOption', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('ledOption', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'ledOption', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.mobileBrand?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Mobile Brand
                  </Typography>
                  {values?.customFields?.mobileBrand?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`mobileBrand[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'mobileBrand', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`mobileBrand[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'mobileBrand', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`mobileBrand[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'mobileBrand', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('mobileBrand', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'mobileBrand', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.mugColor?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Mug Color
                  </Typography>
                  {values?.customFields?.mugColor?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Color Name"
                          name={`mugColor[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'mugColor', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`colorCode-${index}`}
                          placeholder="Color Code"
                          name={`mugColor[${index}].colorCode`}
                          value={item?.colorCode}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'mugColor', index, 'colorCode', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          placeholder="Price"
                          name={`mugColor[${index}].price`}
                          value={item?.price}
                          type="number"
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'mugColor', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`mugColor[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'mugColor', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('mugColor', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'mugColor', {
                        title: '',
                        itemStatus: 'No',
                        colorCode: '',
                        price: '0',
                      })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.packaging?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Packaging
                  </Typography>
                  {values?.customFields?.packaging?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`packaging[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'packaging', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`packaging[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'packaging', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`packaging[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'packaging', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('packaging', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'packaging', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.paper?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Paper
                  </Typography>
                  {values?.customFields?.paper?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`paper[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'paper', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`paper[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'paper', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`paper[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'paper', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('paper', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => handleAddItem('customFields', 'paper', { title: '', itemStatus: 'No', price: '0' })}
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.paperFinish?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Paper Finish
                  </Typography>
                  {values?.customFields?.paperFinish?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`paperFinish[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'paperFinish', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`paperFinish[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'paperFinish', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`paperFinish[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'paperFinish', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('paperFinish', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'paperFinish', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.paperThickness?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Paper Thickness
                  </Typography>
                  {values?.customFields?.paperThickness?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`paperThickness[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'paperThickness', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`paperThickness[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'paperThickness', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`paperThickness[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'paperThickness', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('paperThickness', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'paperThickness', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.paperType?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Paper Type
                  </Typography>
                  {values?.customFields?.paperType?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`paperType[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'paperType', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`paperType[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'paperType', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`paperType[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'paperType', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('paperType', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'paperType', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.card?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Card
                  </Typography>
                  {values?.customFields?.card?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`card[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'card', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`card[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'card', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`card[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'card', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('card', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => handleAddItem('customFields', 'card', { title: '', itemStatus: 'No', price: '0' })}
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.cardFinish?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Card Finish
                  </Typography>
                  {values?.customFields?.cardFinish?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`cardFinish[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'cardFinish', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`cardFinish[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'cardFinish', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`cardFinish[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'cardFinish', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('cardFinish', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'cardFinish', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.cardThickness?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Card Thickness
                  </Typography>
                  {values?.customFields?.cardThickness?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`cardThickness[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'cardThickness', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`cardThickness[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'cardThickness', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`cardThickness[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'cardThickness', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('cardThickness', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'cardThickness', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.cardType?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Card Type
                  </Typography>
                  {values?.customFields?.cardType?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`cardType[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'cardType', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`cardType[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'cardType', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`cardType[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'cardType', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('cardType', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'cardType', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.pasting?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Pasting
                  </Typography>
                  {values?.customFields?.pasting?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`pasting[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'pasting', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`pasting[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'pasting', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`pasting[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'pasting', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('pasting', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'pasting', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.printing?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Printing
                  </Typography>
                  {values?.customFields?.printing?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`printing[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'printing', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`printing[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'printing', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`printing[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'printing', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('printing', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'printing', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.printingFrontBack?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Printing Front-Back
                  </Typography>
                  {values?.customFields?.printingFrontBack?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`printingFrontBack[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'printingFrontBack', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`printingFrontBack[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'printingFrontBack', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`printingFrontBack[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'printingFrontBack', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('printingFrontBack', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'printingFrontBack', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.printingSide?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Printing Side
                  </Typography>
                  {values?.customFields?.printingSide?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`printingSide[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'printingSide', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`printingSide[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'printingSide', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`printingSide[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'printingSide', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('printingSide', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'printingSide', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.stringFields?.productDescription?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Product Description
                  </Typography>
                  <RichTextEditors
                    body={productDescriptionValue}
                    setBody={setProductDescriptionValue}
                    placeholder="Product Description"
                  />
                </Grid>
              )}
              {values?.objectFields?.qty?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    QTY
                  </Typography>
                  <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        placeholder="Start Range"
                        variant="outlined"
                        value={values?.objectFields?.qty?.fields?.startRange}
                        onChange={(e) =>
                          handleObjectNumberFieldChange('objectFields', 'qty', 'startRange', e.target.value)
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        placeholder="End Range"
                        variant="outlined"
                        fullWidth
                        value={values?.objectFields?.qty?.fields?.endRange}
                        onChange={(e) =>
                          handleObjectNumberFieldChange('objectFields', 'qty', 'endRange', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        placeholder="Difference"
                        variant="outlined"
                        fullWidth
                        value={values?.objectFields?.qty?.fields?.difference}
                        onChange={(e) =>
                          handleObjectNumberFieldChange('objectFields', 'qty', 'difference', e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {values?.customFields?.sides?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Sides
                  </Typography>
                  {values?.customFields?.sides?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`sides[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'sides', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`sides[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'sides', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`sides[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'sides', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('sides', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => handleAddItem('customFields', 'sides', { title: '', itemStatus: 'No', price: '0' })}
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.sizes?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Sizes
                  </Typography>
                  {values?.customFields?.sizes?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`sizes[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'sizes', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`sizes[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'sizes', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`sizes[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'sizes', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('sizes', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => handleAddItem('customFields', 'sizes', { title: '', itemStatus: 'No', price: '0' })}
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.stickerType?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Sticker Type
                  </Typography>
                  {values?.customFields?.stickerType?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`stickerType[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'stickerType', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`stickerType[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'stickerType', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`stickerType[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'stickerType', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('stickerType', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'stickerType', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.tShirtColor?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    T Shirt Color
                  </Typography>
                  {values?.customFields?.tShirtColor?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Color Name"
                          name={`tShirtColor[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'tShirtColor', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`colorCode-${index}`}
                          placeholder="Color Code"
                          name={`tShirtColor[${index}].colorCode`}
                          value={item?.colorCode}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'tShirtColor', index, 'colorCode', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          placeholder="Price"
                          name={`tShirtColor[${index}].price`}
                          value={item?.price}
                          type="number"
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'tShirtColor', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`tShirtColor[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'tShirtColor', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('tShirtColor', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'tShirtColor', {
                        title: '',
                        itemStatus: 'No',
                        colorCode: '',
                        price: '0',
                      })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.tShirtCut?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    T Shirt Cut
                  </Typography>
                  {values?.customFields?.tShirtCut?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`tShirtCut[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'tShirtCut', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`tShirtCut[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'tShirtCut', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`tShirtCut[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'tShirtCut', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('tShirtCut', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'tShirtCut', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.tShirtSize?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    T Shirt Size
                  </Typography>
                  {values?.customFields?.tShirtSize?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`tShirtSize[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'tShirtSize', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`tShirtSize[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'tShirtSize', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`tShirtSize[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'tShirtSize', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('tShirtSize', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'tShirtSize', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.textInput?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Text Input Name
                  </Typography>
                  {values?.customFields?.textInput?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={10}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Name of Field"
                          name={`textInput[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleItemChange('customFields', 'textInput', index, 'title', e.target.value)
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('textInput', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'textInput', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.types?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Types
                  </Typography>
                  {values?.customFields?.types?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`types[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'types', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`types[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'types', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`types[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'types', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('types', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => handleAddItem('customFields', 'types', { title: '', itemStatus: 'No', price: '0' })}
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              {values?.customFields?.waxSeal?.status !== true ? null : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Wax Seal
                  </Typography>
                  {values?.customFields?.waxSeal?.fields.map((item, index) => (
                    <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id={`title-${index}`}
                          placeholder="Title"
                          name={`waxSeal[${index}].title`}
                          value={item?.title}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => handleItemChange('customFields', 'waxSeal', index, 'title', e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id={`price-${index}`}
                          type="number"
                          placeholder="Price"
                          name={`waxSeal[${index}].price`}
                          value={item?.price}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleNumberItemChange('customFields', 'waxSeal', index, 'price', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Select
                          fullWidth
                          name={`waxSeal[${index}].itemStatus`} // Update to 'status'
                          value={item?.itemStatus} // Update to 'status'
                          onChange={(e) =>
                            handleItemChange('customFields', 'waxSeal', index, 'itemStatus', e.target.value)
                          }
                        >
                          <MenuItem value={'Yes'}>Yes</MenuItem>
                          <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={2} textAlign={'center'}>
                        <IconButton color="primary" onClick={() => handleRemoveItem('waxSeal', index)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    sx={{ margin: '10px' }}
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      handleAddItem('customFields', 'waxSeal', { title: '', itemStatus: 'No', price: '0' })
                    }
                  >
                    Add Item
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
                <Button
                  autoFocus
                  variant="contained"
                  sx={{ color: 'white', borderColor: 'white', width: '10%' }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
