import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import VendorOrderServices from '../api/VendorOrderServices';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getVendorOrderMethod = createAsyncThunk(`/get-all-getVendorOrderMethod`, async () => {
  try {
    const { data } = await VendorOrderServices.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getVendorOrderPendingMethod = createAsyncThunk(`/get-all-getVendorOrderPendingMethod`, async () => {
  try {
    const { data } = await VendorOrderServices.getPending();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getVendorOrderConfirmMethod = createAsyncThunk(`/get-all-getVendorOrderConfirmMethod`, async () => {
  try {
    const { data } = await VendorOrderServices.getConfirm();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getVendorOrderCompleteMethod = createAsyncThunk(`/get-all-getVendorOrderCompleteMethod`, async () => {
  try {
    const { data } = await VendorOrderServices.getComplete();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

export const getVendorOrderDeliverMethod = createAsyncThunk(`/get-all-getVendorOrderDeliverMethod`, async () => {
  try {
    const { data } = await VendorOrderServices.getDeliver();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const getVendorOrderCancelledMethod = createAsyncThunk(`/get-all-getVendorOrderCancelledMethod`, async () => {
  try {
    const { data } = await VendorOrderServices.getConcelled();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const vendorOrderSlice = createSlice({
  name: 'vendorOrder',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getVendorOrderMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getVendorOrderMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getVendorOrderMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get Pending
    [getVendorOrderPendingMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getVendorOrderPendingMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getVendorOrderPendingMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get confirm
    [getVendorOrderConfirmMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getVendorOrderConfirmMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getVendorOrderConfirmMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get complete
    [getVendorOrderCompleteMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getVendorOrderCompleteMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getVendorOrderCompleteMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    [getVendorOrderDeliverMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getVendorOrderDeliverMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getVendorOrderDeliverMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get cancelled
    [getVendorOrderCancelledMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getVendorOrderCancelledMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getVendorOrderCancelledMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default vendorOrderSlice.reducer;
