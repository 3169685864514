import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { imageBaseUrl } from '../redux/api/http-common';

const ServiceCard = ({ image, setServiceDetail, handleClickOpen }) => (
  <Card className="featured-card">
    <CardActionArea
      sx={{ height: '63%', cursor: 'auto' }}
      onClick={() => {
        setServiceDetail(image);
        handleClickOpen();
      }}
    >
      <CardMedia
        component="img"
        height="250px"
        width="100%"
        sx={{ objectFit: '100%' }}
        image={imageBaseUrl + image?.image}
        alt={image.product_title}
      />
      <CardContent sx={{ padding: '10px' }}>
        <Typography gutterBottom component="div" className="card-content-title" sx={{ fontWeight: 'bold' }}>
          {image.heading}
        </Typography>
        <Typography
          gutterBottom
          variant="body"
          component="div"
          sx={{
            fontWeight: 'bold',
            fontFamily: 'Poppins',
            color: 'rgba(0, 0, 0, 0.5);',
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: `${image.description.slice(0, 130)} ...` }} />
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

export default ServiceCard;
