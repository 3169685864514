import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import BrandServices from '../api/BrandServices';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const addBrandMethod = createAsyncThunk(`/add-brand`, async (datas) => {
  try {
    const { data } = await BrandServices.add(datas);
    console.warn(data);
    toast.success("Add Brand Successfully");
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
export const getBrandMethod = createAsyncThunk(`/get-brand`, async () => {
  try {
    const { data } = await BrandServices.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteSelectedBrandMethod = createAsyncThunk(`/delete-brand`, async (id) => {
  try {
    const { data } = await BrandServices.deleteMultiple(id);
    console.warn(data);
    toast.success("Delete Brand Successfully");
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
// export const getByIDBrandMethod = createAsyncThunk(`/get-brand-by_id`, async (id) => {
//   try {
//     const { data } = await BrandServices.getById(id);
//     console.warn('==========', data);
//     return data.data.brands;
//   } catch (error) {
//     console.error('-------', error.message);
//     throw error;
//   }
// });

const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {},
  extraReducers: {
    // add
    [addBrandMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addBrandMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = [...state.data, payload];
    },
    [addBrandMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get
    [getBrandMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getBrandMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getBrandMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // // getbyID
    // [getByIDBrandMethod.pending]: (state) => {
    //   state.loading = true;
    //   state.error = null;
    // },
    // [getByIDBrandMethod.fulfilled]: (state, { payload }) => {
    //   state.loading = false;
    //   state.success = true;
    //   state.data = payload;
    // },
    // [getByIDBrandMethod.rejected]: (state, { payload }) => {
    //   state.loading = false;
    //   state.error = payload;
    // },
    // delete Multiple
    [deleteSelectedBrandMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedBrandMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedBrandMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default brandSlice.reducer;
