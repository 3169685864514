import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useSelector } from 'react-redux';
import VendorOrderPages from './VendorOrderPages';
import CustomVendorOrdersPage from './CustomVendorOrdersPage';

export default function VendorOrderTabs() {
  const [value, setValue] = React.useState('1');
  const userData = useSelector((state) => state.user.userInfo);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        {userData?.role && userData?.role === 'admin' && (
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Orders" value="1" />
              <Tab label="Custom Product Orders" value="2" />
            </TabList>
          </Box>
        )}
        <TabPanel value="1">
          <VendorOrderPages />
        </TabPanel>
        <TabPanel value="2">
          <CustomVendorOrdersPage />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
