import * as Yup from 'yup';

export const messageSchema = Yup.object({
  full_name: Yup.string()
    .min(3, 'Name must be atleast 3 characters')
    .max(25, 'Name must be at most 25 characters')
    .required('Please enter your name'),
  email: Yup.string().email('Please enter a valid email address').required('Please Enter your email'),
  message: Yup.string().required('Please Enter Your Message'),
});

export const vendorLoginSchema = Yup.object({
  email: Yup.string().email('Please enter a valid email address').required('Please Enter your email'),
  password: Yup.string().min(6, 'Password should at least 6 characters').required('Please fill the password'),
});

export const vendorSignUpSchema = Yup.object({
  first_name: Yup.string().required('Please Enter your First Name'),
  last_name: Yup.string().required('Please Enter your Last Name'),
  email: Yup.string().email('Please enter a valid email address').required('Please Enter your email'),
  password: Yup.string().min(6, 'Password should at least 6 characters').required('Please fill the password'),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .min(6, 'Password should at least 6 characters'),
  phone: Yup.string()
    .required('Please Enter your Phone Number')
    .min(11, 'The Phone number must be minimum 11 Characters')
    .max(16, 'The Phone Number at most 16 characters'),
  address: Yup.string()
    .required('Please Enter your Address')
    .min(15, 'Address must be minmum 15 Characters')
    .max(50, 'Address should at most 50 Characters'),
  cnic: Yup.string()
    .required('Please Enter your CNIC Number')
    .min(15, 'CNIC should be with dashes(-) and must be minimum 15 Characters')
    .max(15, 'CNIC should be with dashes(-) at most 15 Characters'),
  company: Yup.string()
    .required('Please Enter your Company Name')
    .min(8, 'The Company Name must be at least 8 Characters')
    .max(40, 'The Company Name should at most 40 Characters'),
  bank_name: Yup.string().required('Please Enter your Bank Name'),
  bank_account_number: Yup.string()
    .required('Please Enter your Account Number')
    .min(14, 'The Account Number should at least 14 characters')
    .max(16, 'The Account Number Should at most 16 characters'),
});

export const getQuoteSchema = Yup.object({
  name: Yup.string().required('Please Enter your Name').min(3, 'Name must be at least 3 characters long'),
  email: Yup.string().email('Please enter a valid email address').required('Please Enter your email'),
  phone: Yup.string()
    .required('Please Enter Phone Number')
    .matches(/^\d{11}$/, 'Phone number must be exactly 11 digits'),
  companyName: Yup.string().required('Please Enter Company name'),
  type: Yup.string().required('Type is required'),
  width: Yup.string().required('Width is required'),
  height: Yup.string().required('Height is required'),
  length: Yup.string().required('Length is required'),
  typeCard: Yup.string().required('Card Type is required'),
  printingType: Yup.string().required('Please select Printing type'),
  colorOption: Yup.string().required('Please select color option'),
  quantity1: Yup.number().min(1, 'Quantity 1 must be at least 1').required('Quantity 1 is required'),
  quantity2: Yup.number().min(1, 'Quantity 2 must be at least 1').required('Quantity 2 is required'),
  quantity3: Yup.number().min(1, 'Quantity 3 must be at least 1').required('Quantity 3 is required'),
  comments: Yup.string().required('Comments is required'),
  attachFile: Yup.mixed().required('Image is required'),
});

export const newPasswordSchema = Yup.object({
  password: Yup.string().min(6, 'Password should at least 6 characters').required('Please fill the password'),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .min(6, 'Password should at least 6 characters'),
});

export const resetPasswordSchema = Yup.object({
  email: Yup.string().email('Please enter a valid email address').required('Please Enter your email'),
});

export const changePasswordSchema = Yup.object({
  current_password: Yup.string().min(6, 'Password should at least 6 characters').required('Please fill the password'),
  new_password: Yup.string().min(6, 'Password should at least 6 characters').required('Please fill the password'),
  confirm_new_password: Yup.string()
    .required()
    .oneOf([Yup.ref('new_password'), null], 'Passwords do not match')
    .min(6, 'Password should at least 6 characters'),
});

export const userSignUpSchema = Yup.object({
  fname: Yup.string().required('Please Enter your First Name'),
  lname: Yup.string().required('Please Enter your Last Name'),
  email: Yup.string().email('Please enter a valid email address').required('Please Enter your email'),
  phoneno: Yup.string()
    .required('Please Enter your Phone Number')
    .min(11, 'The Phone number must be minimum 11 Characters')
    .max(16, 'The Phone Number at most 16 characters'),
  country: Yup.string().required('Please Enter your Country Name'),
  state: Yup.string().required('Please Enter your State Name'),
  city: Yup.string().required('Please Enter your City Name'),
  address: Yup.string().required('Please Enter your Address'),
  password: Yup.string().min(6, 'Password should at least 6 characters').required('Please fill the password'),
  cpassword: Yup.string()
    .required('Re-type Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords do not match')
    .min(6, 'Password should at least 6 characters'),
});

export const applyCarrerSchema = Yup.object({
  fname: Yup.string().required('Please Enter your name'),
  email: Yup.string().email('Please enter a valid email').required('Please Enter your email'),
  phoneno: Yup.string()
    .required('Please Enter Phone Number')
    .min(11, 'The Phone number must be minimum 11 Characters')
    .max(15, 'The Phone Number at most 16 characters'),
  file: Yup.mixed().required('File is required'),
  position: Yup.string().required('Please enter your position'),
});

export const citySearchSchema = Yup.object({
  city: Yup.string()
    .required('Please type city name here')
    .min(3, 'Please enter minmum 3 characters')
    .max(24, 'You can enter at most 24 characters'),
});
export const EditProfilechema = Yup.object({
  first_name: Yup.string().required('This is required'),
  last_name: Yup.string().required('This is required'),
  phone: Yup.string().required('This is required'),
  address: Yup.string().required('This is required'),
  city: Yup.string().required('This is required'),
  country: Yup.string().required('This is required'),
  state: Yup.string().required('This is required'),
});
