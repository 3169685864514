import { callAPi } from './http-common';

const softDelete = (id) => {
  return callAPi.delete(`/softDelete/${id}`);
};
const premanentDelete = (id) => {
  return callAPi.delete(`/permanentDeleteOrder/${id}`);
};
const restore = (id) => {
  return callAPi.patch(`/restoreSoftDeleted/${id}`);
};
const deletePermanentMultiple = (data) => {
  return callAPi.post(`/permanentMultipleDelete`, { ids: data });
};
const deleteSoftMultiple = (data) => {
  const results = {
    ids: data,
  };
  return callAPi.post(`/MultipleSoftDelete`, results);
};
const get = () => {
  return callAPi.get('/getAllOrders');
};
const getPending = () => {
  return callAPi.get('/getPendingOrders');
};
const getDeliver = () => {
  return callAPi.get('/getDeliveredOrders');
};
const getConfirm = () => {
  return callAPi.get('/getConfirmedOrders');
};
const getComplete = () => {
  return callAPi.get('/getCompletedOrders');
};
const getConcelled = () => {
  return callAPi.get('/getCancelledOrders');
};

const getAllDeleted = () => {
  return callAPi.get('/getAllSoftDeletedOrder');
};
const assignCompete = (data) => {
  return callAPi.post('/assignCompeleteOrder',data);
};
const assignOneItem = (data) => {
  return callAPi.post('/assignOrderedProduct',data);
};
const updateStatus = (id,data) => {
  return callAPi.patch(`/changeStatus/${id}`,data);
};
const OrderService = {
  softDelete,
  get,
  deletePermanentMultiple,
  deleteSoftMultiple,
  getAllDeleted,
  restore,
  premanentDelete,
  assignCompete,
  assignOneItem,
  updateStatus,
  getPending,
  getDeliver,
  getConfirm,
  getComplete,
  getConcelled,
};

export default OrderService;
