import { Button, ImageListItem } from '@mui/material';
import React, { useState } from 'react';
import { IMAGE_BASE_URL} from '../../redux/api/http-common';

const GalleyImageCard = ({ row, onDelete }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <ImageListItem>
      <img
        src={`${IMAGE_BASE_URL}${row?.imgUrl}?w=248&fit=crop&auto=format`}
        srcSet={`${IMAGE_BASE_URL}${row?.imgUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
        alt={row?._id}
        loading="lazy"
        onMouseMoveCapture={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={{ height: '200px', borderRadius: '10px', position: 'relative', cursor: 'pointer' }}
      />
      {isHover && (
        <Button
          onMouseMoveCapture={() => setIsHover(true)}
          onClick={() => onDelete(row._id)}
          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          variant="contained"
        >
          Delete
        </Button>
      )}
      {/* <ImageListItemBar
        actionIcon={
          <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }} aria-label={`info about ${row?._id}`}>
            <Iconify icon="pajamas:remove" />
          </IconButton>
        }
      /> */}
    </ImageListItem>
  );
};

export default GalleyImageCard;
