import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

const ContactUsPlace = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={6} md={4}>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => {
            window.open('https://wa.me/+923007605006');
          }}
        >
          <img src="/assets/images/whatsapp-icon.png" alt="whatsapp" width={'50px'} />
          <Typography variant="h6" ml={2}>
            +92 300 7605006
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContactUsPlace;
