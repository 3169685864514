import { Helmet } from 'react-helmet-async';
import { Link} from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography} from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// sections
import { LoginForm } from '../sections/auth/login';
import { imageMissing } from '../redux/api/http-common';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  return (
    <>
      <Helmet>
        <title> Login | PakPrint Wishes </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'absolute',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={`${imageMissing}/assets/illustrations/illustration_login.png`} alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="xl">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in to PakPrint Wishes
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Are you <strong>Vendor</strong> & Don’t have an account? {''}
              <Link
                style={{ cursor: 'pointer', color: '#70AD47', textDecoration: 'none', fontWeight: 'bold' }}
                to="/register"
              >
                Get Started
              </Link>
            </Typography>
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
