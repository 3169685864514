import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import JobUserServices from '../../redux/api/JobUserServices';
import { jobsgetUserDetails } from '../../redux/slice/jobsUserSlice';

const initialValues = {
  title: '',
  companyName: '',
  companyAddress: '',
  experienceLevel: '',
  isCurrentStatus: false,
  experienceStartDate: '',
  experienceEndDate: '',
};
const AddExperinceDialog = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState(initialValues);
  const [errors, setErrors] = React.useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validations()) {
        await JobUserServices.addExperience({ experience: formData })
          .then((response) => {
            toast.success(response?.data?.message);
            dispatch(jobsgetUserDetails());
            setFormData(initialValues);
            handleClose();
          })
          .catch((error) => {
            if (!error.response.data.message) {
              toast.error(error.message, { position: 'top-right' });
            } else {
              toast.error(error.response.data.message, { position: 'top-right' });
            }
          });
      }
    } catch (error) {
      if (!error.response.data.message) {
        toast.error(error.message, { position: 'top-right' });
      } else {
        toast.error(error.response.data.message, { position: 'top-right' });
      }
    }
  };
  const validations = (fieldValue = formData) => {
    const temp = { ...errors };
    if ('title' in fieldValue) temp.title = fieldValue.title ? '' : 'This field requires';
    if ('companyName' in fieldValue) temp.companyName = fieldValue.companyName ? '' : 'This field requires';
    if ('companyAddress' in fieldValue) temp.companyAddress = fieldValue.companyAddress ? '' : 'This field requires';
    if ('experienceLevel' in fieldValue) temp.experienceLevel = fieldValue.experienceLevel ? '' : 'This field requires';
    if ('experienceStartDate' in fieldValue)
      temp.experienceStartDate = fieldValue.experienceStartDate ? '' : 'This field requires';
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validations({ [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
    validations({ [name]: checked });
  };
  const handleDateChange = (fieldName, newValue) => {
    const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
    setFormData({
      ...formData,
      [fieldName]: formattedDate,
    });
    validations({ [fieldName]: formattedDate });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Add Experience'}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: { xs: '200px', sm: '300px', md: '400px' } }} py={1}>
          <Typography variant="subtitle2">Title</Typography>
          <TextField
            placeholder="Title"
            variant="outlined"
            fullWidth
            name="title"
            value={formData.introduction}
            onChange={handleChange}
            error={errors.introduction}
          />
        </Box>
        <Box sx={{ width: { xs: '200px', sm: '300px', md: '400px' } }} py={1}>
          <Typography variant="subtitle2">Company Name</Typography>
          <TextField
            placeholder="Company Name"
            variant="outlined"
            fullWidth
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            error={errors.companyName}
          />
        </Box>
        <Box sx={{ width: { xs: '200px', sm: '300px', md: '400px' } }} py={1}>
          <Typography variant="subtitle2">Company Address</Typography>
          <TextField
            placeholder="Company Address"
            variant="outlined"
            fullWidth
            name="companyAddress"
            value={formData.companyAddress}
            onChange={handleChange}
            error={errors.companyAddress}
          />
        </Box>
        <Box sx={{ width: { xs: '200px', sm: '300px', md: '400px' } }} py={1}>
          <Typography variant="subtitle2">Experience Level</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            fullWidth
            name="experienceLevel"
            value={formData.experienceLevel}
            onChange={handleChange}
            error={errors.experienceLevel}
          >
            <MenuItem value={''} disabled>
              Select Experience Level
            </MenuItem>
            <MenuItem value={'Basic'}>Basic</MenuItem>
            <MenuItem value={'Intermediate'}>Intermediate</MenuItem>
            <MenuItem value={'Expert'}>Expert</MenuItem>
          </Select>
        </Box>
        <Box sx={{ width: { xs: '200px', sm: '300px', md: '400px' } }} py={1}>
          <Typography variant="subtitle2">Start Date</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker
                value={dayjs(formData.experienceStartDate)}
                onChange={(newValue) => {
                  handleDateChange('experienceStartDate', newValue);
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
        {formData?.isCurrentStatus === true ? null : (
          <Box sx={{ width: { xs: '200px', sm: '300px', md: '400px' } }} py={1}>
            <Typography variant="subtitle2">End Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                  value={dayjs(formData.experienceEndDate)}
                  onChange={(newValue) => {
                    handleDateChange('experienceEndDate', newValue);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        )}
        <Box sx={{ width: { xs: '200px', sm: '300px', md: '400px' } }} py={1}>
          <FormControlLabel
            control={
              <Checkbox
                color="success"
                checked={formData.isCurrentStatus}
                onChange={handleCheckboxChange}
                name="isCurrentStatus"
              />
            }
            label="I am currently working in this role"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddExperinceDialog;
