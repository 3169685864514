import http from './http-common';

const create = (data) => http.post('/addReview', data);
const get = (id) => http.get(`/getReviewByProduct/${id}`);
const updateReview = (id, data) => http.patch(`/updateReview-Review/${id}`, data);

const ReviewServices = {
  create,
  get,
  updateReview,
};

export default ReviewServices;
