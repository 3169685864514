import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { imageBaseUrl } from "../redux/api/http-common";

const CategoryCard = ({ image }) => {
  return (
    <Card className="category-card-container">
      <CardActionArea
        component={Link}
        to={`/category/${image?.name.replace(/\s+/g, "-").toLowerCase()}/${
          image?._id
        }`}
        state={{ id: image, name: image.name }}
        sx={{ height: "90%" }}
      >
        <CardMedia
          component="img"
          height="250px"
          width="100%"
          sx={{ objectFit: "100%" }}
          image={imageBaseUrl + image?.category_image}
          alt={image?.name}
        />
        <CardContent sx={{ padding: "10px" }}>
          <Typography
            gutterBottom
            component="div"
            sx={{fontWeight:'bold '}}
            className="card-content-title"
          >
            {image?.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CategoryCard;
