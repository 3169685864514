import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PartnerDataService from '../api/PartnerServices';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const retrievePartner = createAsyncThunk('partner-getAll', async () => {
  const res = await PartnerDataService.getAll();
  return res.data.data;
});

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  extraReducers: {
    [retrievePartner.pending]: (state) => {
      state.loading = true;
    },
    [retrievePartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [retrievePartner.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default partnerSlice.reducer;
