import http from "./http-common";

const getAll = () => {
  return http.get("/user/getAllJobs");
};
const get=id=>{
  return http.get(`/user/getJob/${id}`);
}
const create = (data) => {
  return http.post("/user/applyForJob", data);
};
const getToSearch = (city) => {
  return http.get(`/user/searchJobs?city=${city}`);
};
const CareersServices = {
  getAll,
  get,
  create,
  getToSearch
};

export default CareersServices;
