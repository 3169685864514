import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import bgImg from "../Assets/header_bg/terms.png";
import WhatsApp from "../Components/WhatsApp";
import ScreenShot from "../Assets/Screenshot.png";

const DeletePolicy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid container className="centered top-container">
      <Helmet>
        <title>Delete Policy | PakPrint Wishes </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <WhatsApp />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
        sx={{ backgroundImage: `url(${bgImg})` }}
      >
        <Grid container justifyContent={"center"}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={9}
            xl={9}
            sx={{ display: "flex" }}
          >
            <div className="breadcrumb-text">
              <h1 className="breadcrumb-text__title">Delete Policy</h1>
              <ul className="breadcrumb-text__nav">
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li>&nbsp;-&nbsp;</li>
                <li>Delete Policy</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="service-header-container"
      >
        <Typography
          className="service-header-text"
          sx={{ textTransform: "uppercase" }}
        >
          Delete Account Policy
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="service-paragraph-container centered"
      >
        <Typography className="service-paragraph-text">
          At PakPrint Wishеs, wе valuе your privacy and undеrstand that you may
          want to еxеrcisе control ovеr your data. If you еvеr wish to dеlеtе
          your account and associatеd data, wе providе a convеniеnt option
          within your profilе sеttings. Simply log in to your account, navigatе
          to your profilе, and you'll find an 'Account Dеlеtion' option.
          Clicking on this option will guidе you through thе procеss to
          pеrmanеntly dеlеtе your account, еnsuring that your data is rеmovеd
          from our systеms. Wе arе committеd to maintaining thе sеcurity and
          privacy of your information and try to makе thе procеss as simple and
          easy as possiblе for your pеacе of mind. "
        </Typography>
      </Grid>
      <Box sx={{ width: "80%", margin: "auto" }}>
        <img width={"100%"} src={ScreenShot} alt="" />
      </Box>
    </Grid>
  );
};

export default DeletePolicy;
