import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/createCustomParentCategory', data);
};
const deletes = (id) => {
  return callAPi.patch(`/deleteCustomParentCategoryById/${id}`);
};
const get = () => {
  return callAPi.get('/getAllCustomParentCategories');
};
const update = (id, data) => {
  return callAPi.patch(`/updateCustomParentCategoryById/${id}`, data);
};
const getbyId = (id) => {
  return callAPi.get(`/getCustomParentCategoryById/${id}`);
};
const status = (id) => {
  return callAPi.patch(`/changeCustomParentCategoryStatus/${id}`);
};
const CustomParentCategoryService = {
  add,
  deletes,
  get,
  update,
  getbyId,
  status,
};

export default CustomParentCategoryService;
