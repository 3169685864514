import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { Checkbox, FormControlLabel, FormHelperText, Grid, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import RichTextEditors from '../../RichText/RichTextEditors';
import BlogService from '../../../redux/api/BlogService';
import { getBlogMethod } from '../../../redux/slice/blogSlice';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const initialValues = {
  title: '',
  introduction: '',
  image: '',
  isSlider: false,
};

export default function AddBlogDialog({ openAddForm, handleClickOpenAddForm }) {
  const dispatch = useDispatch();
  const [body, setBody] = React.useState(null);
  const [formData, setFormData] = React.useState(initialValues);
  const [errors, setErrors] = React.useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validations()) {
        await BlogService.add({ ...formData, description: body })
          .then((response) => {
            toast.success(response?.data?.message);
            dispatch(getBlogMethod());
            setFormData(initialValues);
            handleClickOpenAddForm();
          })
          .catch((error) => {
            if (!error.response.data.message) {
              toast.error(error.message, { position: 'top-right' });
            } else {
              toast.error(error.response.data.message, { position: 'top-right' });
            }
          });
      }
    } catch (error) {
      if (!error.response.data.message) {
        toast.error(error.message, { position: 'top-right' });
      } else {
        toast.error(error.response.data.message, { position: 'top-right' });
      }
    }
  };

  const validations = (fieldValue = formData) => {
    const temp = { ...errors };
    if ('title' in fieldValue) temp.title = fieldValue.title ? '' : 'This field requires';
    if ('introduction' in fieldValue) temp.introduction = fieldValue.introduction ? '' : 'This field requires';
    if ('password' in fieldValue) temp.password = fieldValue.password ? '' : 'This field requires';
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validations({ [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
    validations({ [name]: checked });
  };

  const handleProfileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
  };

  return (
    <div>
      <Dialog fullScreen open={openAddForm} onClose={handleClickOpenAddForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenAddForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Blog Post
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Add Blog Post</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Title
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Title"
              variant="outlined"
              name="title"
              value={formData.title}
              onChange={handleChange}
              error={errors.title}
              fullWidth
            />
            {/* {errors.title && (
            <FormHelperText error={!!errors}>{errors.title}</FormHelperText>
          )} */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Introduction
            </Typography>
            <TextField
              id="outlined-basic"
              multiline
              rows={5}
              placeholder="Enter Introduction"
              fullWidth
              name="introduction"
              value={formData.introduction}
              onChange={handleChange}
              error={errors.introduction}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Description
            </Typography>
            <RichTextEditors placeholder="Enter Description" setBody={setBody} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Slider
            </Typography>
            <br />
            <FormControlLabel
              label="Is Slider"
              control={<Checkbox checked={formData.isSlider} onChange={handleCheckboxChange} name="isSlider" />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Upload Image
            </Typography>
            <br />
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              placeholder="Choose an image"
              name="image"
              onChange={handleProfileChange}
            />
            {errors.image && <FormHelperText error={!!errors}>{errors.image}</FormHelperText>}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
