import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/createJob', data);
};
const deletes = (id) => {
  return callAPi.delete(`/deleteJob/${id}`);
};
const deleteMultiple = (data) => {
  return callAPi.post(`/deleteMultipleJobs`, { ids: data });
};
const get = () => {
  return callAPi.get('/getAllJobs');
};
const update = (id, data) => {
  return callAPi.patch(`/updateJob/${id}`, data);
};
const JobsService = {
  add,
  deletes,
  get,
  update,
  deleteMultiple,
};

export default JobsService;
