import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {
  Grid,
  Divider,
  Typography,
  Button,
  Box,
  Pagination,
  PaginationItem,
  Rating,
  TextField,
  Checkbox,
  Breadcrumbs,
  IconButton,
} from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatIcon from '@mui/icons-material/Chat';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createReview } from '../../redux/slice/reviewSlice';
import FeaturedProductServices from '../../redux/api/FeaturedProductServices';
import { findFeaturedProductServicesById } from '../../redux/slice/featuredProductSlice';
import SameStoreServices from '../../redux/api/SameStoreServices';
import { addToCart } from '../../redux/slice/cartSlice';
import ReviewServices from '../../redux/api/ReviewServices';
import { addFavourite, deleteFavourite } from '../../redux/slice/favouriteSlice';
import { getUserDetails } from '../../redux/slice/userSlice';
import ChatDialog from '../Chat/ChatDialog';
import FavouriteService from '../../redux/api/FavouriteService';
import { imageBaseUrl } from '../../redux/api/http-common';
import AccessoriesProductCard from '../../Components/AccessoriesProductCard';
import { BlueButton, GreenButton } from '../../Components/Components';
import ClientProductSEOService from '../../redux/api/ClientProductSEOService';
import ProgressBar from '../../../components/progress/ProgressBar';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ProductAccessoriesDetails = () => {
  const { id } = useParams();
  // const id = location.state.id;
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(1);
  const [reviewIcon, setReviewIcon] = useState(<ChevronRightIcon />);
  const [isShown, setIsShown] = useState(false);
  const navigate = useNavigate();
  const [fetchProduct, setFetchProduct] = useState([]);
  const [imageProduct, setImageProduct] = useState([]);
  const [fetchSameStore, setSameStore] = useState([]);
  const [getReview, setGetReview] = useState([]);
  const [checkeds, setChecked] = React.useState(false);
  const [imgUrls, setImageUrl] = useState(null);
  const [seoDat, setSeoData] = useState(null);
  const [loading, setLoading] = React.useState(true);
  // Pagenations
  const [currentPage, setCurrentPage] = useState(1);
  const [productPerPage] = useState(6);
  // Chat
  const [openChat, setOpenChat] = useState(false);

  const handleChat = () => {
    setOpenChat(!openChat);
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, [navigate]);

  // Favourite
  const userToken = useSelector((state) => state.clientuser.token);
  const userInfo = useSelector((state) => state.clientuser.userInfo);

  // automatically authenticate user if token is found
  useEffect(() => {
    if (userToken) {
      dispatch(getUserDetails());
    }
  }, [userToken, dispatch]);

  useEffect(() => {
    FavouriteService.get(id)
      .then((res) => {
        setChecked(res.data.status);
      })
      .catch((error) => {
        console.log(error.message);
        setChecked(false);
      });
  }, [userInfo]);

  const totalProducts = getReview?.length; // Total number of products
  const totalPages = Math.ceil(totalProducts / productPerPage); // Calculate total pages
  const indexOfLastPost = currentPage * productPerPage;
  const indexOfFirstPost = indexOfLastPost - productPerPage;
  const currentPosts = getReview?.slice(indexOfFirstPost, indexOfLastPost);

  // Product Detail
  const getDetails = async (id) => {
    await FeaturedProductServices.get(id)
      .then((res) => {
        setFetchProduct(res.data.data);
        setImageProduct(res.data.data.images);
        // setChecked(Boolean(res.data.data.product_favourite));
        setImageUrl(res.data.data.images[0]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // Same Store

  const samestore = async (id) => {
    await SameStoreServices.get(id).then((res) => {
      setSameStore(res.data.data);
    });
  };

  const getReviews = async (id) => {
    await ReviewServices.get(id).then((res) => {
      setGetReview(res.data.data);
    });
  };

  // Product Detail
  useEffect(() => {
    getSEO();
    getDetails(id);
    samestore(id);
    getReviews(id);
  }, [id]);

  //  Post Review
  const [fname, setFName] = useState('');
  const [comments, setcomments] = useState('');
  const [feedbackImages, setfeedbackImages] = useState(null);
  const [ratingValue, setRatingValue] = useState(0);

  const saveReview = async () => {
    const formdata = new FormData();
    formdata.append('name', fname);
    formdata.append('comment', comments);
    formdata.append('rating', ratingValue);
    formdata.append('feedbackImage', feedbackImages);
    formdata.append('productId', id);
    await dispatch(createReview(formdata))
      .unwrap()
      .then((res) => {
        toast.success('Your Review Submit Successfully', {
          position: 'bottom-left',
        });
        getReviews(id);
        setFName('');
        setcomments('');
        setfeedbackImages(null);
        setRatingValue(0);
        getReviews(id);
        setIsShown(false);
      })
      .catch((e) => {
        toast.success(e.response.data.message, {
          position: 'bottom-left',
        });
      });
  };

  const initFetch = useCallback(() => {
    dispatch(findFeaturedProductServicesById({ id }));
    getDetails(id);
    samestore(id);
    getReviews(id);
  }, [dispatch]);

  useEffect(() => {
    dispatch(findFeaturedProductServicesById({ id }));
    getDetails(id);
    samestore(id);
    getReviews(id);
  }, [dispatch, id]);

  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown(!isShown);
    // isShown === true ? setReviewIcon(<ExpandMoreIcon />) : setReviewIcon(<ExpandMoreIcon />);
    setReviewIcon(isShown ? <ExpandMoreIcon /> : <ExpandMoreIcon />);
  };

  const increment = () => {
    setCounter(counter + 1);
  };

  const decrement = () => {
    if (counter > 1) setCounter(counter - 1);
  };

  const handleChangeCheckBox = (event) => {
    setChecked(event.target.checked);
    if (checkeds === false) {
      const payload = {
        productId: fetchProduct._id,
        status: true,
      };
      dispatch(addFavourite(payload));
      getDetails(id);
    } else {
      const payload = {
        productId: fetchProduct._id,
        status: false,
      };
      dispatch(deleteFavourite(payload));
      getDetails(id);
    }
  };

  // Add to cart
  const handleAddToCart = (product) => {
    const products = { products: product, cartQty: counter };
    dispatch(addToCart(products));
    navigate('/cart');
  };
  // Add to cart
  const handleBuyNow = (product) => {
    const products = { products: product, cartQty: counter };
    dispatch(addToCart(products));
    navigate('/checkout');
  };

  const handleCounterChange = (e) => {
    const inputValue = e.target.value;

    // Remove leading zeros and non-numeric characters
    const sanitizedValue = inputValue.replace(/^0+/, '').replace(/\D/g, '');

    // Make sure the sanitized value is not empty
    if (sanitizedValue !== '') {
      setCounter(Number(sanitizedValue));
    } else {
      setCounter(1); // Reset to 0 if input is empty
    }
  };
  const getSEO = async () => {
    await ClientProductSEOService.getbyid(id)
      .then((res) => {
        setSeoData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Helmet>
        <title>{!seoDat || Object.keys(seoDat).length === 0 || !seoDat?.web_title ? '' : seoDat?.web_title}</title>
        <meta
          name="description"
          content={!seoDat || Object.keys(seoDat).length === 0 || !seoDat?.web_title ? '' : seoDat?.web_description}
        />
        <meta
          property="og:title"
          content={!seoDat || Object.keys(seoDat).length === 0 || !seoDat?.web_title ? '' : seoDat?.web_title}
        />
        <meta
          property="og:description"
          content={!seoDat || Object.keys(seoDat).length === 0 || !seoDat?.web_title ? '' : seoDat?.web_description}
        />
        <meta property="og:image" content={!imageProduct ? null : imageBaseUrl + imgUrls} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {loading === true ? (
        <ProgressBar />
      ) : (
        <Box>
          {fetchProduct !== null || fetchProduct !== undefined || fetchProduct.length !== 0 ? (
            <Grid container className="top-container centered" spacing={5}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    to="/"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    Home
                  </Link>
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    to="/printing-accessories"
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    Machine & Accessories
                  </Link>
                  <Typography key="3" color="text.primary">
                    {!fetchProduct?.product_title ? null : fetchProduct?.product_title}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {fetchProduct !== null || fetchProduct !== undefined ? (
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={10} md={5} lg={4} xl={4}>
                      <h1 className="single-prod-title">{fetchProduct?.product_title}</h1>
                      {imageProduct === null || imageProduct === undefined ? null : (
                        <img src={imageBaseUrl + imgUrls} alt="pic" className="prod-image-main" />
                      )}
                      <Divider className="prod-divider" />
                      <Grid container className="prod-image-small-container">
                        {imageProduct !== null || imageProduct !== undefined || imageProduct.length > 0
                          ? imageProduct.map((res, index) => (
                              <Grid item key={index}>
                                <IconButton
                                  sx={{ padding: '5px !important' }}
                                  onClick={() => {
                                    setImageUrl(res);
                                  }}
                                >
                                  <img src={imageBaseUrl + res} alt="pic" className="prod-image-small pointers" />
                                </IconButton>
                              </Grid>
                            ))
                          : null}
                      </Grid>
                    </Grid>
                    <Grid item xs={10} sm={10} md={5} lg={6} xl={6} sx={{ marginLeft: '20px' }}>
                      {!fetchProduct?.accessories_condition ? null : (
                        <Box className="row-flex" py={1}>
                          <Typography className="prod-page-subtitle">Condition</Typography>
                          <Typography className="prodpage-text-body">
                            &nbsp;&nbsp;{fetchProduct?.accessories_condition}
                          </Typography>
                          <br />
                        </Box>
                      )}
                      {!fetchProduct?.accessories_type ? null : (
                        <Box className="row-flex">
                          <Typography className="prod-page-subtitle">Type</Typography>
                          <Typography className="prodpage-text-body">
                            &nbsp;&nbsp;{fetchProduct?.accessories_type}
                          </Typography>
                          <br />
                        </Box>
                      )}
                      {!fetchProduct?.accessories_Location ? null : (
                        <Box className="row-flex" py={1}>
                          <Typography className="prod-page-subtitle">Location</Typography>
                          <Typography className="prodpage-text-body">
                            &nbsp;&nbsp;{fetchProduct?.accessories_Location}
                          </Typography>
                          <br />
                        </Box>
                      )}
                      {!fetchProduct?.product_description ? null : (
                        <Box py={1}>
                          <Typography className="prod-page-subtitle">Description</Typography>
                          <Typography className="prodpage-text-body">
                            {/* {fetchProduct?.product_description} */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: fetchProduct?.product_description,
                              }}
                            />
                          </Typography>
                        </Box>
                      )}
                      {/* {fetchProduct.product_features ? (
                    <Box>
                      <br />
                      <Typography className="prodpage-text-body">
                        {fetchProduct.product_features}
                      </Typography>
                    </Box>
                  ) : null} */}
                      {fetchProduct?.product_brand_id?.name ? (
                        <Box>
                          <Typography className="prod-page-subtitle">Brand Name</Typography>
                          <Typography className="prodpage-text-body">{fetchProduct?.product_brand_id.name}</Typography>
                        </Box>
                      ) : null}
                    </Grid>
                    <Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
                      {/* <Typography variant="h3" className="single-prod-title">
                    {fetchProduct?.product_title}
                  </Typography> */}
                      {/* <span>
                  <Rating
                    name="read-only"
                    value={parseInt(fetchProduct.rating)}
                    size="small"
                    sx={{ color: "#BECC03;", paddingTop: "20px" }}
                    readOnly
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      color: "rgba(0, 0, 0, 0.5);",
                    }}
                  >
                    {`(${fetchProduct.rating})`}
                  </Typography>
                </span> */}
                      <Box className="space-between-container">
                        <Typography className="single-pro-price">
                          RS.{Number(fetchProduct.new_price).toFixed(2)}
                        </Typography>
                        <Checkbox
                          {...label}
                          checked={checkeds}
                          onChange={handleChangeCheckBox}
                          icon={<FavoriteBorderIcon />}
                          checkedIcon={<FavoriteIcon sx={{ color: 'red' }} />}
                        />
                      </Box>
                      {!fetchProduct?.discount ||
                      fetchProduct?.discount === 0 ||
                      fetchProduct?.discount === '0' ? null : (
                        <Typography
                          pt={1}
                          gutterBottom
                          variant="body"
                          component="div"
                          sx={{
                            fontWeight: '400',
                            fontFamily: 'Poppins',
                            fontSize: '22px',
                            color: 'rgba(0, 0, 0, 0.5);',
                          }}
                        >
                          <del>RS. {fetchProduct?.old_price}</del>&nbsp;&nbsp;
                          <span style={{ color: 'red' }}> {fetchProduct?.discount}%off</span>
                        </Typography>
                      )}
                      <Typography
                        gutterBottom
                        variant="body"
                        component="div"
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Poppins',
                          color: 'rgba(0, 0, 0, 0.5);',
                          marginTop: '30px',
                          marginBottom: '30px',
                        }}
                        className="desc"
                      >
                        Quantity: &nbsp;&nbsp;&nbsp;
                        <span className="desc">
                          <Button
                            variant={counter === 1 ? 'disabled' : 'contained'}
                            className="acc-btn"
                            onClick={decrement}
                            size="small"
                          >
                            -
                          </Button>
                          <TextField
                            value={counter}
                            inputProps={{ style: { padding: '5px' } }}
                            sx={{ width: '100px', paddingX: '5px' }}
                            type="number"
                            onChange={handleCounterChange}
                          />
                          <Button variant="contained" className="acc-btn" onClick={increment} size="small">
                            +
                          </Button>
                        </span>
                      </Typography>
                      <Grid container className="row-container-wrap" spacing={2}>
                        <Grid item>
                          <Button
                            onClick={() =>
                              !userInfo || Object.keys(userInfo).length === 0 ? navigate('/login') : handleChat()
                            }
                          >
                            <ChatIcon fontSize="medium" sx={{ color: '#4E9F0B' }} />
                            <Typography className="prodChatBtnText ">Chat</Typography>
                          </Button>
                        </Grid>
                        <Grid item>
                          <BlueButton onClick={() => handleBuyNow(fetchProduct)} sx={{ fontSize: '10px' }}>
                            Buy Now
                          </BlueButton>
                        </Grid>
                        <Grid item>
                          <GreenButton onClick={() => handleAddToCart(fetchProduct)} sx={{ fontSize: '10px' }}>
                            Add to Cart
                          </GreenButton>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} alignItems={'center'} py={2}>
                        <Grid item xs={12} sm={10}>
                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            Product Code
                          </Typography>
                          <TextField
                            placeholder="Product Code"
                            disabled
                            value={!fetchProduct?.productCode ? 'N/A' : fetchProduct?.productCode}
                            // InputLabelProps={{
                            //   style: { fontSize: 18,fontWeight: "bold" },
                            //   shrink: true,
                            // }}
                            inputProps={{
                              style: { fontSize: 12, fontWeight: 'bold' },
                            }}
                            fullWidth
                            InputProps={{
                              style: {
                                borderRadius: '50px',
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <BlueButton
                            sx={{ fontSize: '14px', marginTop: '25px' }}
                            onClick={() => {
                              const productCode = fetchProduct?.productCode || 'N/A';

                              // Create a temporary input element
                              const tempInput = document.createElement('input');
                              tempInput.setAttribute('type', 'text');
                              tempInput.setAttribute('value', productCode);
                              document.body.appendChild(tempInput);

                              // Select the text in the input element
                              tempInput.select();

                              // Execute the copy command
                              const success = document.execCommand('copy');

                              // Remove the temporary input element from the DOM
                              document.body.removeChild(tempInput);

                              if (success) {
                                toast.success('Copied Product Code', {
                                  position: 'bottom-left',
                                });
                              } else {
                                console.error('Copying to clipboard failed.');
                                // Handle the case when the copy command fails
                              }
                            }}
                          >
                            Copy
                          </BlueButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box sx={{ display: openChat === false ? 'none' : 'block' }} className="bottomright">
                      <ChatDialog
                        productid={fetchProduct && fetchProduct?.id}
                        handleChat={handleChat}
                        openChat={openChat}
                      />
                    </Box>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
              {/* <Grid> */}
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Typography className="prodPageSubtitle2 prodReviewTitle prodPageText" align="center">
                  From The Same Store
                </Typography>
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Grid container spacing={2}>
                  {fetchSameStore !== null || fetchSameStore !== undefined || fetchSameStore.length > 0
                    ? fetchSameStore.map((image, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} pt={2} key={index}>
                          <AccessoriesProductCard image={image} />
                        </Grid>
                      ))
                    : null}
                </Grid>
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Typography gutterBottom className="prodReviewTitle prodPageText">
                  Products Reviews
                </Typography>
                <Divider className="prodDivider prodReviewDivider" />
              </Grid>

              {/* <Grid sx={{ display: "flex" }}> */}
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container>
                      {currentPosts
                        ? currentPosts?.map((obj, index) => {
                            return (
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Grid item>
                                    <Typography
                                      gutterBottom
                                      variant="body"
                                      component="div"
                                      sx={{
                                        fontWeight: '400',
                                        fontFamily: 'Poppins',
                                        fontSize: '14px',
                                      }}
                                    >
                                      {obj?.name} &nbsp;
                                      <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                                        - {moment(obj?.createdAt).fromNow()}
                                      </span>
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <span>
                                      <Rating
                                        name="read-only"
                                        value={obj?.rating}
                                        size="small"
                                        sx={{ color: '#BECC03;' }}
                                        readOnly
                                      />
                                    </span>
                                  </Grid>
                                </Box>
                                <Typography variant="body" className="prodComnt">
                                  {obj?.comment}
                                </Typography>
                                <Grid className="prodComntImgContainer" container>
                                  <Grid item>
                                    <img
                                      src={imageBaseUrl + obj?.feedbackImage}
                                      alt="pic"
                                      className="prodImageSmall comntImg"
                                    />
                                  </Grid>
                                </Grid>
                                {/* <Box sx={{ display: "flex", flexDirection: "row" }}>
                            {obj.like_count === 0 ? (
                              <ThumbUpOffAltIcon />
                            ) : (
                              <ThumbUpIcon />
                            )}
                            <Typography
                              variant="caption"
                              sx={{
                                fontWeight: "bold",
                                fontFamily: "Poppins",
                                color: "rgba(0, 0, 0, 0.5);",
                              }}
                            >
                              ({obj.like_count})
                            </Typography>
                          </Box> */}
                                <Divider className="prodDivider prodReviewDivider" />
                              </Grid>
                            );
                          })
                        : []}
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="pagination-containerProd" pt={2}>
                        <Pagination
                          count={totalPages} // Show total pages instead of current page
                          page={currentPage} // Set the current page
                          renderItem={(item) => (
                            <PaginationItem
                              components={{
                                previous: ArrowBackIcon,
                                next: ArrowForwardIcon,
                              }}
                              {...item}
                            />
                          )}
                          variant="outlined"
                          shape="rounded"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid className="writeReviewContainer" item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          variant="contained"
                          endIcon={reviewIcon}
                          className="writeReviewBtn"
                          onClick={() => {
                            handleClick();
                          }}
                        >
                          Write a Review
                        </Button>
                      </Grid>
                      {isShown && (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography className="review-form-title">Your Rating</Typography>
                          <Rating
                            value={ratingValue}
                            name="read-only"
                            size="small"
                            sx={{ color: '#BECC03;', paddingRight: '10px' }}
                            onChange={(event, newValue) => {
                              setRatingValue(newValue);
                            }}
                          />
                          <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                            className="login-form"
                            sx={{ width: '100%' }}
                            spacing={4}
                          >
                            <TextField
                              name="name"
                              id="name"
                              label="Your Name"
                              value={fname}
                              onChange={(e) => setFName(e.target.value)}
                              variant="outlined"
                              style={{
                                width: '100%',
                                marginBottom: '30px',
                                fontFamily: 'Poppins',
                              }}
                            />
                            <TextField
                              name="message"
                              id="outlined-multiline-static"
                              label="Your Feedback"
                              value={comments}
                              onChange={(e) => setcomments(e.target.value)}
                              multiline
                              rows={4}
                              style={{ width: '100%', fontFamily: 'Poppins' }}
                            />
                            <Grid sx={{ width: '100%' }}>
                              <Typography className="prodPageText uploadImagTitle">
                                Images: &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                <Button className="reviewUploadBtn" variant="contained" component="label">
                                  Choose File
                                  <input
                                    hidden
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) => setfeedbackImages(e.target.files[0])}
                                    // onChange={onFileChange}
                                    // multiple
                                    type="file"
                                  />
                                </Button>
                              </Typography>
                            </Grid>
                            <Grid sx={{ width: '100%' }} pt={4}>
                              <BlueButton onClick={saveReview}>Send</BlueButton>
                            </Grid>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Box className="top-container centered">
              <Typography className="prodPageSubtitle2 prodReviewTitle prodPageText" align="center">
                Loading
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default ProductAccessoriesDetails;
