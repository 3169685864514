import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { retrieveNewArrivalsServices } from '../redux/slice/newArrivalsSlice';
import ProductCard from '../Components/ProductCard';
import bgImg from '../Assets/header_bg/shop_bg.png';
import WhatsApp from '../Components/WhatsApp';
import ProgressBar from '../../components/progress/ProgressBar';

const NewArrivalsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [productPerPage] = useState(12);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, [currentPage]);
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const dispatch = useDispatch();
  const cachedData = useSelector((state) => state.newArrivals);
  const data = React.useMemo(() => cachedData, [cachedData]);
  const initFetch = React.useCallback(() => {
    dispatch(retrieveNewArrivalsServices());
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const totalProducts = data?.data?.length; // Total number of products
  const totalPages = Math.ceil(totalProducts / productPerPage); // Calculate total pages
  const indexOfLastPost = currentPage * productPerPage;
  const indexOfFirstPost = indexOfLastPost - productPerPage;
  const currentPosts =
    !data?.data || data?.data.length === 0 ? [] : data?.data?.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <Grid container className="main-top-container" justifyContent={'center'}>
      <Helmet>
        <title>New Arrivals in High-Quality Printing Services | Pak Print Wishes</title>
        <meta
          name="description"
          content="Explore the latest arrivals in high-quality printing services by Pak Print Wishes. From business cards to banners, Get Delivery all over Pakistan."
        />
        <meta property="og:title" content="New Arrivals in High-Quality Printing Services | Pak Print Wishes" />
        <meta
          property="og:description"
          content="Explore the latest arrivals in high-quality printing services by Pak Print Wishes. From business cards to banners, Get Delivery all over Pakistan."
        />
        <meta property="og:image" content={bgImg} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <WhatsApp />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
        sx={{ backgroundImage: `url(${bgImg})` }}
      >
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9} sx={{ display: 'flex' }}>
            <div className="breadcrumb-text">
              <h1 className="breadcrumb-text__title">New Arrivals Products</h1>
              <ul className="breadcrumb-text__nav">
                <li>
                  <Link to={'/'}>Home</Link>
                </li>
                <li>&nbsp;-&nbsp;</li>
                <li>New Arrivals Products</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={11} xl={11}>
        {data?.loading && <ProgressBar />}
        <Grid container spacing={2} className="featured-products-container">
          {currentPosts?.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
              <ProductCard image={image} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="pagination-container">
        <Pagination
          count={totalPages} // Show total pages instead of current page
          page={currentPage} // Set the current page
          renderItem={(item) => (
            <PaginationItem components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />
          )}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

export default NewArrivalsPage;
