import { Helmet } from 'react-helmet-async';
import { filter, toLower, includes } from 'lodash';
import { useEffect, useRef, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListToolbar } from '../sections/@dashboard/user';
// mock
import { IMAGE_BASE_URL } from '../redux/api/http-common';
import AddCustomProductDialog from '../components/Dialog/AddFormDialog/AddCustomProductDialog';
import CustomProductServices from '../redux/api/CustomProductServices';
import OrderProductListHead from '../sections/@dashboard/user/OrderProductListHead';
import { getCustomProductMethod } from '../redux/slice/customProductSlice';
import UpdateCustomProductDialog from '../components/Dialog/UpdateFormDialog/UpdateCustomProductDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'titles', label: ' Product Name', alignRight: false },
  { id: 'productCode', label: 'PPW No.', alignRight: false },
  { id: 'product_category_id.name', label: 'Custom Parent Category', alignRight: false },
  { id: 'product_child_category_id.name', label: 'Custom Sub Category', alignRight: false },
  { id: 'prices', label: 'Basic Price', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'images', label: 'Image', alignRight: false },
  // { id: 'view', label: 'View', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function CustomProductsPage() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [productId, setProductId] = useState(null);

  const [productItem, setProductItem] = useState(null);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openAddForm, setOpenAddForm] = useState(false);

  const [openEditForm, setOpenEditForm] = useState(false);

  const data = useSelector((state) => state.customProduct);
  const userData = useSelector((state) => state.user.userInfo);

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = Array.isArray(array) && array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => {
        const lowerQuery = toLower(query);
        return (
          includes(toLower(!_user?.titles ? '' : _user?.titles), lowerQuery) ||
          includes(toLower(!_user?.prices ? '' : _user?.prices), lowerQuery) ||
          includes(toLower(!_user?.sku ? '' : _user?.sku), lowerQuery)
        );
      });
    }
    return stabilizedThis.map((el) => el[0]);
  }

  useEffect(() => {
    dispatch(getCustomProductMethod());
  }, [dispatch]);

  const handleClickOpenAddForm = () => {
    setOpenAddForm(!openAddForm);
    handleCloseMenu();
  };

  const handleClickOpenEditForm = () => {
    setOpenEditForm(!openEditForm);
    handleCloseMenu();
  };

  const handleOpenMenu = (event, item) => {
    setProductId(item._id);
    setProductItem(item);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    data.data !== null && data.data !== undefined ? data.data : [],
    getComparator(order, orderBy),
    filterName
  );

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const isNotFound = !filteredUsers.length && !!filterName;

  const dialogRef = useRef(null);

  // Function to scroll to the top of the dialog content
  const scrollToTop = () => {
    if (dialogRef.current) {
      dialogRef.current.scrollTo(0, 0);
    }
  };

  // Use the useEffect hook to scroll to the top when the component is mounted
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Helmet>
        <title>Custom Products | PakPrint Wishes </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Custom Products
          </Typography>
          <Button sx={{ margin: '5px' }} variant="contained" onClick={handleClickOpenAddForm}>
            Add Custom Product
          </Button>
        </Stack>
        <Card>
          <UserListToolbar numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrderProductListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id } = row;
                    return (
                      <TableRow hover key={_id}>
                        <TableCell component="th" scope="row">
                          <Typography variant="subtitle2" noWrap>
                            {!row.titles ? 'N/A' : row.titles}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            navigator.clipboard.writeText(!row.sku ? 'N/A' : row.sku);
                            toast.success('Copied Custom Product PPW No.');
                          }}
                        >
                          {!row.sku ? 'N/A' : row.sku}
                        </TableCell>
                        <TableCell align="left">
                          {!row?.customParentCategoryIds || row?.customParentCategoryIds.length === 0
                            ? 'N/A'
                            : row?.customParentCategoryIds.map((items, index) => (
                                <Typography key={index}>
                                  {index + 1} {!items?.title ? 'N/A' : items?.title}
                                </Typography>
                              ))}
                        </TableCell>
                        <TableCell align="left">
                          {!row?.customChildCategoryIds || row?.customChildCategoryIds.length === 0
                            ? 'N/A'
                            : row?.customChildCategoryIds.map((items, index) => (
                                <Typography key={index}>
                                  {index + 1} {!items?.title ? 'N/A' : items?.title}
                                </Typography>
                              ))}
                        </TableCell>
                        <TableCell align="left">{!row.prices ? 'N/A' : row.prices}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {row?.status && row?.status === true ? 'Approved' : 'Disapproved'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <img src={IMAGE_BASE_URL + row?.images[0]} alt={row.titles} style={{ width: '100px' }} />
                        </TableCell>
                        <TableCell align="right" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              handleOpenMenu(e, row);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {!userData || Object.keys(userData).length === 0 || userData?.role !== 'admin' ? null : (
          <MenuItem
            sx={{ color: 'error.main' }}
            onClick={async () => {
              await CustomProductServices.status(productId)
                .then(() => {
                  if (productItem?.status === false) {
                    toast.success('Custom Product Approved successfully');
                  } else {
                    toast.success('Custom Product Disapproved successfully');
                  }
                  dispatch(getCustomProductMethod());
                  setProductId(null);
                  setOpen(false);
                })
                .catch((error) => {
                  if (!error.response.data.message) {
                    toast.error(error.message, { position: 'top-right' });
                  } else {
                    toast.error(error.response.data.message, { position: 'top-right' });
                  }
                });
            }}
          >
            <Iconify
              icon={
                'streamline:interface-id-iris-scan-check-identification-retina-security-approved-success-iris-scan-eye-login'
              }
              sx={{ mr: 2 }}
            />
            {productItem?.status === false ? 'Approved' : 'Disapproved'}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            navigate(`/dashboard/custom-product/custom-product-detail/${productId}`);
          }}
        >
          <Iconify icon={'carbon:view'} sx={{ mr: 2 }} />
          View
        </MenuItem>
        <MenuItem onClick={handleClickOpenEditForm}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={async () => {
            await CustomProductServices.deletes(productId)
              .then((res) => {
                console.warn('--------', res);
                toast.success('Custom Product deleted successfully');
                dispatch(getCustomProductMethod());
                setProductId(null);
                setOpen(false);
              })
              .catch((error) => {
                if (!error.response.data.message) {
                  toast.error(error.message, { position: 'top-right' });
                } else {
                  toast.error(error.response.data.message, { position: 'top-right' });
                }
              });
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      {openAddForm && (
        <AddCustomProductDialog openAddForm={openAddForm} handleClickOpenAddForm={handleClickOpenAddForm} />
      )}

      <UpdateCustomProductDialog
        openAddForm={openEditForm}
        handleClickOpenAddForm={handleClickOpenEditForm}
        jobId={productId}
      />
    </>
  );
}
