import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomParentCategorySEOService from '../api/CustomParentCategorySEOService';

const initialState = {
  loading: false,
  data: [],
  error: null,
  success: false,
};

export const getCustomParentCategorySEOMethod = createAsyncThunk(`/get-getCustomParentCategorySEOMethod`, async () => {
  try {
    const { data } = await CustomParentCategorySEOService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const customParentCategorySEOSlice = createSlice({
  name: 'customParentCategorySEO',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getCustomParentCategorySEOMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomParentCategorySEOMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomParentCategorySEOMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default customParentCategorySEOSlice.reducer;
