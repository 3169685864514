import http from "./http-common";

const get = (data) => {
  return http.post(`/search/product`,data);
};


const ProductSearch = {
  get
};

export default ProductSearch;