import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import SliderServices from '../api/SliderServices';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const addSliderMethod = createAsyncThunk(`/add-slider`, async (datas) => {
  try {
    const { data } = await SliderServices.add(datas);
    console.warn(data);
    toast.success("Slider added successfully");
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
export const getSliderMethod = createAsyncThunk(`/get-slider`, async () => {
  try {
    const { data } = await SliderServices.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});
export const deleteSelectedSliderMethod = createAsyncThunk(`/delete-slider`, async (id) => {
  try {
    const { data } = await SliderServices.deleteMultiple(id);
    console.warn(data);
    toast.success("Slider deleted successfully");
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

const sliderSlice = createSlice({
  name: 'slider',
  initialState,
  reducers: {},
  extraReducers: {
    // add
    [addSliderMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addSliderMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = [...state.data, payload];
    },
    [addSliderMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get
    [getSliderMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSliderMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getSliderMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete Multiple
    [deleteSelectedSliderMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedSliderMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedSliderMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default sliderSlice.reducer;
