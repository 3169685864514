import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { getNotificationMethod } from '../../../redux/slice/notificationSlice';
import NotificationService from '../../../redux/api/NotificationService';
import { imageMissing } from '../../../redux/api/http-common';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { data } = useSelector((state) => state.notification);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotificationMethod());
  }, [dispatch]);

  const totalUnRead = data !== null ? (data.length !== 0 ? data.filter((item) => item.isRead === false).length : 0) : 0;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async () => {
    await NotificationService.UpdateMultiple()
      .then(() => {
        dispatch(getNotificationMethod());
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        {data !== null ? (
          data.length > 0 ? (
            <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
              <List
                disablePadding
                subheader={
                  <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    New
                  </ListSubheader>
                }
              >
                {data.filter((item) => item.isRead === false).length !== 0 ? (
                  data
                    .filter((item) => item.isRead === false)
                    .map((notification) => (
                      <NotificationItem key={notification._id} notification={notification} handleClose={handleClose} />
                    ))
                ) : (
                  <Typography variant="body2" p={2} sx={{ color: 'text.secondary' }}>
                    Empty Notification
                  </Typography>
                )}
              </List>

              <List
                disablePadding
                subheader={
                  <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    Before that
                  </ListSubheader>
                }
              >
                {data.filter((item) => item.isRead === true).length !== 0 ? (
                  data
                    .filter((item) => item.isRead === true)
                    .map((notification) => (
                      <NotificationItem key={notification._id} notification={notification} handleClose={handleClose} />
                    ))
                ) : (
                  <Typography variant="body2" p={2} sx={{ color: 'text.secondary' }}>
                    Empty Notification
                  </Typography>
                )}
              </List>
            </Scrollbar>
          ) : (
            <Typography variant="body2" p={2} sx={{ color: 'text.secondary' }}>
              Empty Notification
            </Typography>
          )
        ) : (
          <Typography variant="body2" p={2} sx={{ color: 'text.secondary' }}>
            Loading Notification
          </Typography>
        )}
        {/* <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, handleClose }) {
  // console.log(notification);
  const { avatar, title } = renderContent(notification);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isRead === false && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={async () => {
        await NotificationService.update(notification?._id)
          .then(() => {
            dispatch(getNotificationMethod());
            if (notification?.type === 'quote') {
              navigate(`/dashboard/get-quote-details/${notification?.id}`);
              handleClose();
            } else if (notification.type === 'job') {
              navigate(`/dashboard/jobs/applied-jobs/${notification?.id}`);
              handleClose();
            } else if (notification.type === 'userOrder') {
              navigate(`/dashboard/orders/order-products/${notification?.id}`);
              handleClose();
            } else if (notification.type === 'order') {
              navigate(`/dashboard/vendor-order-details/${notification?.id}`);
              handleClose();
            } else if (notification.type === 'chat') {
              navigate(`/dashboard/chat/messages/${notification?.userId}?product_id=${notification?.id}`);
              handleClose();
            } else if (notification.type === 'amount') {
              navigate(`/dashboard/payments/history/${notification?.id}`);
              handleClose();
            } else if (notification.type === 'vendorRegister') {
              navigate(`/dashboard/vendor-detail/${notification?.userId}`);
              handleClose();
            } else if (notification.type === 'newProduct') {
              navigate(`/dashboard/products/product-detail/${notification?.id}`);
              handleClose();
            } else if (notification.type === 'updateProduct') {
              navigate(`/dashboard/products/product-detail/${notification?.id}`);
              handleClose();
            } else if (notification.type === 'parentCategory') {
              navigate(`/dashboard/parent-category`);
              handleClose();
            }
          })

          .catch((error) => {
            if (!error.response.data.message) {
              toast.error(error.message, { position: 'top-right' });
            } else {
              toast.error(error.response.data.message, { position: 'top-right' });
            }
          });
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.body}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order') {
    return {
      avatar: <img alt={notification.title} src={`${imageMissing}/assets/icons/ic_notification_package.svg`} />,
      title,
    };
  }
  if (notification.type === 'userOrder') {
    return {
      avatar: <img alt={notification.title} src={`${imageMissing}/assets/icons/ic_notification_package.svg`} />,
      title,
    };
  }
  if (notification.type === 'quote') {
    return {
      avatar: <img alt={notification.title} src={`${imageMissing}/assets/icons/ic_notification_shipping.svg`} />,
      title,
    };
  }
  if (notification.type === 'amount') {
    return {
      avatar: <img alt={notification.title} src={`${imageMissing}/assets/icons/navbar/payment-ic.svg`} />,
      title,
    };
  }
  if (notification.type === 'job') {
    return {
      avatar: <img alt={notification.title} src={`${imageMissing}/assets/icons/navbar/job-family-ic.svg`} />,
      title,
    };
  }
  if (notification.type === 'chat') {
    return {
      avatar: <img alt={notification.title} src={`${imageMissing}/assets/icons/navbar/chat-message-ic.svg`} />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
