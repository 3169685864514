import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomParentCategoryService from '../api/CustomParentCategoryService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getCustomParentCategoryMethod = createAsyncThunk(`/get-parentCustomCategory`, async () => {
  try {
    const { data } = await CustomParentCategoryService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const customParentCategorySlice = createSlice({
  name: 'customParentCategory',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getCustomParentCategoryMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCustomParentCategoryMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getCustomParentCategoryMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default customParentCategorySlice.reducer;
