import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// @mui
import { Stack, Container, Typography, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import UserServices from '../redux/api/UserServices';
import { getUserDetails } from '../redux/slice/userSlice';
// components

export default function ProfilePage() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const userData = useSelector((state) => state.user.userInfo);
  const [file, setFile] = useState(null);

  const [fName, setFName] = useState(
    userData !== null && userData !== undefined && userData.first_name ? userData.first_name : null
  );
  const [LastName, setLastName] = useState(
    userData !== null && userData !== undefined && userData.last_name ? userData.last_name : null
  );
  const [Phone, setPhone] = useState(
    userData !== null && userData !== undefined && userData.phone ? userData.phone : null
  );
  const [email, setEmail] = useState(
    userData !== null && userData !== undefined && userData.email ? userData.email : null
  );
  const [CompanyName, setCompanyName] = useState(
    userData !== null && userData !== undefined && userData.companyName ? userData.companyName : null
  );
  const [CNIC, setCNIC] = useState(userData !== null && userData !== undefined && userData.cnic ? userData.cnic : null);
  const [BankAccountName, setBankAccountName] = useState(
    userData !== null && userData !== undefined && userData.bank_name ? userData.bank_name : null
  );
  const [BankAccountNumber, setBankAccountNumber] = useState(
    userData !== null && userData !== undefined && userData.bank_account_number ? userData.bank_account_number : null
  );
  const [Address, setAddress] = useState(
    userData !== null && userData !== undefined && userData.address ? userData.address : null
  );

  const handleClick = async () => {
    if (!fName || !LastName || !Phone || !CompanyName || !CNIC || !BankAccountName || !BankAccountNumber || !Address) {
      toast.error('Please enter all fields');
      return;
    }

    if (CNIC.length < 13) {
      toast.error('Please enter 13 numbers for the cnic.');
      return;
    }
    const numberPattern = /^\d+$/;
    if (!numberPattern.test(CNIC)) {
      toast.error('Please enter a valid number for the CNIC.');
      return;
    }
    if (!numberPattern.test(Phone)) {
      toast.error('Please enter a valid number for the Phone.');
      return;
    }
    if (Phone.length < 11) {
      toast.error('Please enter 11 numbers for the Phone.');
      return;
    }
    if (BankAccountNumber.length < 24) {
      toast.error('Please enter 24 numbers for the Bank Account IBAN Number.');
      return;
    }
    const specialSymbolPattern = /^[a-zA-Z0-9]*$/;
    if (!specialSymbolPattern.test(BankAccountNumber)) {
      toast.error('Please enter a valid bank account IBAN number');
      return;
    }
    const data = {
      first_name: fName,
      last_name: LastName,
      phone: Phone,
      companyName: CompanyName,
      bank_name: BankAccountName,
      bank_account_number: BankAccountNumber,
      cnic: CNIC,
      address: Address,
      profileImg:file
    };
    await UserServices.update(data)
      .then((res) => {
        console.warn('-----------', res);
        dispatch(getUserDetails());
        navigate('/dashboard');
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };

  return (
    <>
      <Helmet>
        <title> Message | PakPrint Wishes </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Profile Update
          </Typography>
        </Stack>
        <Stack className="product-order-box" p={5}>
          <Stack spacing={3}>
            <TextField label="First Name" required value={fName} onChange={(e) => setFName(e.target.value)} />
            <TextField label="Last Name" required value={LastName} onChange={(e) => setLastName(e.target.value)} />
            <TextField label="Phone" type="number" value={Phone} required onChange={(e) => setPhone(e.target.value)} />
            <TextField label="Email" type="text" value={email} disabled onChange={(e) => setEmail(e.target.value)} />

            <TextField
              label="Company Name"
              required
              value={CompanyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <TextField label="CNIC" required value={CNIC} onChange={(e) => setCNIC(e.target.value)} />
            <TextField
              label="Bank Account Name"
              required
              value={BankAccountName}
              onChange={(e) => setBankAccountName(e.target.value)}
            />
            <TextField
              label="Bank Account Number"
              required
              value={BankAccountNumber}
              onChange={(e) => setBankAccountNumber(e.target.value)}
            />
            <TextField
              label="Address"
              multiline
              rows={5}
              value={Address}
              required
              onChange={(e) => setAddress(e.target.value)}
            />

            <input
              accept=".png, .jpg, .jpeg"
              type="file"
              id="select-image"
              onChange={(e) => setFile(e.target.files[0])}
              required
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ marginTop: '15px' }}
            onClick={handleClick}
          >
            Update
          </LoadingButton>
        </Stack>
      </Container>
    </>
  );
}
