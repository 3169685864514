import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProductSEOService from '../api/ProductSEOService';

const initialState = {
  loading: false,
  data: [],
  error: null,
  success: false,
};

export const getProductSEOMethod = createAsyncThunk(`/get-getProductSEOMethod`, async () => {
  try {
    const { data } = await ProductSEOService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const productSEOSlice = createSlice({
  name: 'productSEO',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getProductSEOMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProductSEOMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getProductSEOMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default productSEOSlice.reducer;
