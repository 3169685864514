import { Link } from 'react-router-dom';
import { Grid, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet-async';
import { retrieveSerives } from '../../redux/slice/careersSlice';
import CareersServices from '../../redux/api/CareersServices';
import { applyCarrerSchema } from '../../schemas';
import bgImg from '../../Assets/header_bg/careers.png';
import { BlueButton, CareerListItemButton, RoundedInputField } from '../../Components/Components';

const CareersPage = () => {
  const dispatch = useDispatch();
  const cachedData = useSelector((state) => state.careers.data);
  const data = React.useMemo(() => cachedData, [cachedData]);
  const [open, setOpen] = React.useState(false);
  const [fname, setFname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneno, setPhoneno] = useState('');
  const [position, setPostion] = useState('');
  const [file, setFile] = useState('');
  const [jid, setJid] = useState(0);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(retrieveSerives());
  }, [dispatch]);

  const handleClickOpen = (id) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilter = () => {
    if (search.trim() === '') {
      // If search input is empty, show all data
      setFilteredData([]);
      setIsFiltering(false);
    } else {
      // Filter the data based on the search input
      const filtered = data.filter(
        (res) =>
          res.title.toLowerCase().includes(search.toLowerCase()) ||
          res.city.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredData(filtered);
      setIsFiltering(true);
    }
  };

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append('jobId', jid);
    formData.append('fullName', fname);
    formData.append('email', email);
    formData.append('phone', phoneno);
    formData.append('applyPosition', position);
    formData.append('file', file);
    try {
      const res = await CareersServices.create(formData);
      if (res.data) {
        toast.success(res.data.message, {
          position: 'bottom-left',
        });
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        position: 'bottom-left',
      });
    }
  };
  const { values, errors, handleChange, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: {
      fname: '',
      email: '',
      phoneno: '',
      position: '',
      file: null,
    },
    validationSchema: applyCarrerSchema,
    onSubmit: (values) => {
      onSubmit();
      setEmail('');
      setFname('');
      setPostion('');
      setPhoneno('');
      values.email = '';
      values.fname = '';
      values.position = '';
      values.phoneno = '';
      handleClose();
    },
  });

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleFilter();
    }
  };

  return (
    <Grid pb={6} container className="top-container centered">
      <Helmet>
        <title>Careers | PakPrint Wishes</title>
        <meta
          name="description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:title" content="Careers | PakPrint Wishes" />
        <meta
          property="og:description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:image" content={bgImg} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
        sx={{ backgroundImage: `url(${bgImg})` }}
      >
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9} sx={{ display: 'flex' }}>
            <div className="breadcrumb-text">
              <h2 className="breadcrumb-text__title">Careers</h2>
              <ul className="breadcrumb-text__nav">
                <li>
                  <Link to={'/'}>Home</Link>
                </li>
                <li>&nbsp;-&nbsp;</li>
                <li>Careers</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10} pt={5} sx={{ textAlign: 'center' }} className="centered">
        <Grid container spacing={3} className="centered">
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10} pb={2}>
            <Typography className="careers-header-titile">Open Vacanicies</Typography>
          </Grid>
          <Grid item xs={10} sm={10} md={4} lg={4} xl={4}>
            <RoundedInputField
              id="outlined-basic"
              placeholder="Search"
              variant="outlined"
              inputProps={{ style: { fontSize: 10 } }}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyPress={handleKeyPress}
              fullWidth
            />
          </Grid>
          <Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
            <BlueButton onClick={handleFilter}>Search</BlueButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10} sm={10} md={9.5} lg={9.5} xl={9.5} pt={3} mt={3} className="careers-top-boder">
        {isFiltering === true ? (
          filteredData.length > 0 ? (
            filteredData.map((res, index) => (
              <Grid container key={index} pt={3}>
                <Grid item xs={12} sm={12} md={3.2} lg={3.2} xl={3.2}>
                  <Typography className="careers-job-title" component={Link} to={`careers-details/${res._id}`}>
                    {res?.title}
                  </Typography>
                  <Typography className="careers-job-date" pt={1}>
                    {res?.city}
                  </Typography>
                  <Typography className="careers-job-date">{res?.lastDate}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <Typography className="careers-job-descr">Description</Typography>
                  <div dangerouslySetInnerHTML={{ __html: res?.description }} />
                </Grid>
                <Grid item xs={12} sm={12} md={3.8} lg={3.8} xl={3.8} pt={3}>
                  <CareerListItemButton
                    className="career-btn"
                    onClick={() => {
                      handleClickOpen();
                      setJid(res._id);
                    }}
                  >
                    <ListItemText key={index}>Apply Now</ListItemText>
                    <ListItemIcon>
                      <ArrowRightAltIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                  </CareerListItemButton>
                </Grid>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle
                    sx={{
                      fontSize: '25px',
                      color: '#173470',
                    }}
                  >
                    Apply Now
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Your Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      id="fname"
                      name="fname"
                      value={fname}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue('fname', e.target.value);
                        setFname(e.target.value);
                      }}
                      error={errors.fname && touched.fname}
                      helperText={errors.fname && touched.fname ? errors.fname : null}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Email Address"
                      type="email"
                      fullWidth
                      variant="outlined"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setFieldValue('email', e.target.value);
                        setEmail(e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={errors.email && touched.email}
                      helperText={errors.email && touched.email ? errors.email : null}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Phone Number"
                      type="Number"
                      fullWidth
                      variant="outlined"
                      id="phoneno"
                      name="phoneno"
                      value={phoneno}
                      onChange={(e) => {
                        setFieldValue('phoneno', e.target.value);
                        setPhoneno(e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={errors.phoneno && touched.phoneno}
                      helperText={errors.phoneno && touched.phoneno ? errors.phoneno : null}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Postion Applied For"
                      type="text"
                      fullWidth
                      variant="outlined"
                      id="position"
                      name="position"
                      value={position}
                      onChange={(e) => {
                        setFieldValue('position', e.target.value);
                        setPostion(e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={errors.position && touched.position}
                      helperText={errors.position && touched.position ? errors.position : null}
                    />
                    <TextField
                      type="file"
                      variant="outlined"
                      autoFocus
                      margin="dense"
                      fullWidth
                      id="file"
                      name="file"
                      onChange={(e) => {
                        setFieldValue('file', e.target.files[0]);
                        setFile(e.target.files[0]);
                      }}
                      onBlur={handleBlur}
                      error={errors.file && touched.file}
                      helperText={errors.file && touched.file ? errors.file : null}
                    />
                    <BlueButton sx={{ marginTop: '10px' }} onClick={handleSubmit}>
                      Submit
                    </BlueButton>
                  </DialogContent>
                </Dialog>
              </Grid>
            ))
          ) : (
            <Typography className="careers-job-date" pt={1}>
              No jobs found matching the search criteria.
            </Typography>
          )
        ) : data ? (
          data.map((res, index) => (
            <Grid container key={index} pt={3}>
              <Grid item xs={12} sm={12} md={3.2} lg={3.2} xl={3.2}>
                <Typography className="careers-job-title" component={Link} to={`careers-details/${res._id}`}>
                  {res?.title}
                </Typography>
                <Typography className="careers-job-date" pt={1}>
                  {res?.city}
                </Typography>
                <Typography className="careers-job-date">{res?.lastDate}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <Typography className="careers-job-descr">Description</Typography>
                <Typography className="careers-job-date" pt={1}>
                  <div dangerouslySetInnerHTML={{ __html: res?.description }} />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3.8} lg={3.8} xl={3.8} pt={3}>
                {/* <Link className="career-btn">Apply Now</Link> */}
                <CareerListItemButton
                  className="career-btn"
                  onClick={() => {
                    handleClickOpen();
                    setJid(res._id);
                  }}
                >
                  <ListItemText key={index}>Apply Now</ListItemText>
                  <ListItemIcon>
                    <ArrowRightAltIcon sx={{ color: 'white' }} />
                  </ListItemIcon>
                </CareerListItemButton>
              </Grid>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle
                  sx={{
                    fontSize: '25px',
                    color: '#173470',
                  }}
                >
                  Apply Now
                </DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Your Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    id="fname"
                    name="fname"
                    value={fname}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue('fname', e.target.value);
                      setFname(e.target.value);
                    }}
                    error={errors.fname && touched.fname}
                    helperText={errors.fname && touched.fname ? errors.fname : null}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="outlined"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setFieldValue('email', e.target.value);
                      setEmail(e.target.value);
                    }}
                    onBlur={handleBlur}
                    error={errors.email && touched.email}
                    helperText={errors.email && touched.email ? errors.email : null}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Phone Number"
                    type="Number"
                    fullWidth
                    variant="outlined"
                    id="phoneno"
                    name="phoneno"
                    value={phoneno}
                    onChange={(e) => {
                      setFieldValue('phoneno', e.target.value);
                      setPhoneno(e.target.value);
                    }}
                    onBlur={handleBlur}
                    error={errors.phoneno && touched.phoneno}
                    helperText={errors.phoneno && touched.phoneno ? errors.phoneno : null}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Postion Applied For"
                    type="text"
                    fullWidth
                    variant="outlined"
                    id="position"
                    name="position"
                    value={position}
                    onChange={(e) => {
                      setFieldValue('position', e.target.value);
                      setPostion(e.target.value);
                    }}
                    onBlur={handleBlur}
                    error={errors.position && touched.position}
                    helperText={errors.position && touched.position ? errors.position : null}
                  />
                  <TextField
                    type="file"
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    fullWidth
                    id="file"
                    name="file"
                    onChange={(e) => {
                      setFieldValue('file', e.target.files[0]);
                      setFile(e.target.files[0]);
                    }}
                    onBlur={handleBlur}
                    error={errors.file && touched.file}
                    helperText={errors.file && touched.file ? errors.file : null}
                  />
                  <BlueButton sx={{ marginTop: '10px' }} onClick={handleSubmit}>
                    Submit
                  </BlueButton>
                </DialogContent>
              </Dialog>
            </Grid>
          ))
        ) : (
          <Typography className="careers-job-date" pt={1}>
            Not Found Job
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default CareersPage;
