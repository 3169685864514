import { configureStore } from '@reduxjs/toolkit';
import partnerReducer from './slice/partner';
import sliderReducer from './slice/slider';
import servicesReducer from './slice/serviceSlice';
import printingAccessoriesReducer from './slice/printingAccessoriesSlice';
import buyNowReducer from './slice/buyNowSlice';
import featuredProductReducer from './slice/featuredProductSlice';
import newArrivalsReducer from './slice/newArrivalsSlice';
import reviewReducer from './slice/reviewSlice';
import careersReducer from './slice/careersSlice';
import getShopReducer from './slice/getShopSlice';
import cartReducer from './slice/cartSlice';
import userReducer from './slice/userSlice';
import registerUserSlice from './slice/registerUserSlice';
import categorySlice from './slice/categorySlice';
import wishlistSlice from './slice/wishlistSlice';
import favouriteSlice from './slice/favouriteSlice';
import forgetPasswordSlice from './slice/forgetPasswordSlice';
import subCategorySlice from './slice/subCategorySlice';
import categoryProductSlice from './slice/categoryProductSlice';
import orderSlice from './slice/orderSlice';

export const clientReducer = {
  clientpartner: partnerReducer,
  clientslider: sliderReducer,
  services: servicesReducer,
  printingAccessories: printingAccessoriesReducer,
  buyNow: buyNowReducer,
  featuredProduct: featuredProductReducer,
  newArrivals: newArrivalsReducer,
  review: reviewReducer,
  careers: careersReducer,
  shop: getShopReducer,
  cart: cartReducer,
  clientorder: orderSlice,
  clientuser: userReducer,
  register: registerUserSlice,
  category: categorySlice,
  wishlist: wishlistSlice,
  favourite: favouriteSlice,
  forgetpassword: forgetPasswordSlice,
  clientsubcategory: subCategorySlice,
  categoryproduct: categoryProductSlice,
};
