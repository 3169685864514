import { callAPi } from './http-common';

const get = () => {
  return callAPi.get('/getAllVendorCustomOrders');
};
const getPending = () => {
  return callAPi.get('/getAllPendingVendorCustomOrders');
};
const getConfirm = () => {
  return callAPi.get('/getAllConfirmVendorCustomOrders');
};
const getComplete = () => {
  return callAPi.get('/getAllCompletedVendorCustomOrders');
};
const getDeliver = () => {
  return callAPi.get('/getAllDeliveredVendorCustomOrders');
};
const getConcelled = () => {
  return callAPi.get('/getAllCancelledVendorCustomOrders');
};
const updateStatus = (data) => {
  return callAPi.patch(`/changeStatusVendorCustomOrderById/${data.orderId}`, data);
};
const getbyid = (id) => {
  return callAPi.get(`/getVendorOrderbyId/${id}`);
};
const CustomVendorOrdersService = {
  get,
  updateStatus,
  getPending,
  getConfirm,
  getComplete,
  getConcelled,
  getbyid,
  getDeliver,
};

export default CustomVendorOrdersService;
