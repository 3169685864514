import http from "./http-common";

const create = data => {
  return http.post("/addMessage", data);
};


const ContactUsServices = {
  create
};

export default ContactUsServices;