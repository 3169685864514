import { Box, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ProductSearch from '../redux/api/ProductSearch';
import { imageBaseUrl } from '../redux/api/http-common';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 4,
  pb: 3,
};
const SearchDialog = ({ handleSearch, openSearch }) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const handleChange = async (search) => {
    if (!search) {
      setData([]);
    } else {
      await ProductSearch.get({ search })
        .then((res) => {
          setData(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <Modal
      open={openSearch}
      onClose={() => {
        setData([]);
        handleSearch();
      }}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style, width: '50%', height: { xs: '550px', sm: '500px', md: '450px' } }}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container alignItems={'center'}>
              <Grid item xs={6}>
                <h2 id="child-modal-title">Search Product</h2>
              </Grid>
              <Grid item xs={6}>
                <IconButton
                  sx={{ float: 'right' }}
                  onClick={() => {
                    setData([]);
                    handleSearch();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              fullWidth
              label="Search"
            />
          </Grid>
          <Grid
            item
            xs={12}
            py={2}
            sx={{
              overflowY: 'scroll',
              height: { xs: '370px', sm: '320px', md: '300px' },
              overflowX: 'hidden',
              width: '100%',
              objectFit: '100%',
            }}
            mt={1}
          >
            {!data || data.length === 0 ? (
              <Typography variant="subtitle1">Not Found Product</Typography>
            ) : (
              data.map((item, index) => (
                <Grid
                  container
                  key={index}
                  spacing={2}
                  className="search-card-product"
                  onClick={() => {
                    if (item?.product_type === 'Product') {
                      navigate(`/products/${item?.product_title.replace(/\s+/g, '-').toLowerCase()}/${item?._id}`);
                      setData([]);
                      handleSearch();
                    } else {
                      navigate(
                        `/product-accessories/${item?.product_title.replace(/\s+/g, '-').toLowerCase()}/${item?._id}`
                      );
                      setData([]);
                      handleSearch();
                    }
                  }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="subtitle1">{!item?.product_title ? 'N/A' : item?.product_title}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Typography variant="subtitle1">{`Total Price(${item?.old_price}) Discount(${item?.discount}%)  New Price(${item?.new_price}%)`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} px={1}>
                    {!item?.images || item?.images.length === 0 ? (
                      'N/A'
                    ) : (
                      <img
                        src={imageBaseUrl + item?.images[0]}
                        alt={!item?.product_title ? 'N/A' : item?.product_title}
                        width={'100%'}
                      />
                    )}
                  </Grid>
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default SearchDialog;
