import { Link } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveSerives } from '../redux/slice/serviceSlice';
import ServiceCard from '../Components/ServiceCard';
import bgImg from '../Assets/header_bg/service_bg.png';
import WhatsApp from '../Components/WhatsApp';
import ServiceDialog from '../Components/ServiceDialog';

const ServicesPages = () => {
  const dispatch = useDispatch();
  const cachedData = useSelector((state) => state.services);
  const data = React.useMemo(() => cachedData, [cachedData]);
  const [open, setOpen] = React.useState(false);
  const [serviceDetail, setServiceDetail] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  const initFetch = React.useCallback(() => {
    dispatch(retrieveSerives());
  }, [dispatch]);

  React.useEffect(() => {
    initFetch();
  }, [initFetch]);

  return (
    <Grid container className="centered top-container">
      <Helmet>
        <title>Our Services | PakPrint Wishes</title>
        <meta
          name="description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:title" content="Our Services | PakPrint Wishes" />
        <meta
          property="og:description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:image" content={bgImg} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <WhatsApp />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
        sx={{ backgroundImage: `url(${bgImg})` }}
      >
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9} sx={{ display: 'flex' }}>
            <div className="breadcrumb-text">
              <h1 className="breadcrumb-text__title">OUR SERVICES</h1>
              <ul className="breadcrumb-text__nav">
                <li>
                  <Link to={'/'}>Home</Link>
                </li>
                <li>&nbsp;-&nbsp;</li>
                <li>SERVICES</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid pt={6} item xs={12} sm={12} md={12} lg={12} xl={12} className="service-header-container">
        <Typography className="service-header-text">Our Services</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="service-paragraph-container centered">
        <Typography className="service-paragraph-text">
          Welcome to <b>Pakprint Wishes! </b>We are proud to offer comprehensive services for all your printing needs.
          Whether you need business cards, flyers, postcards, brochures, posters, or any other printed material, we help
          you create the perfect product to meet your unique needs.
        </Typography>
      </Grid>
      <Grid pb={6} item xs={12} sm={12} md={12} lg={11} xl={11}>
        <Grid container spacing={2} className="featured-products-container">
          {data.data
            ? data.data?.map((image, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                    <Paper sx={{ height: '100%', backgroundColor: 'transparent' }}>
                      <ServiceCard image={image} setServiceDetail={setServiceDetail} handleClickOpen={handleClickOpen}/>
                    </Paper>
                  </Grid>
                );
              })
            : null}
        </Grid>
      </Grid>
      <ServiceDialog open={open} handleClose={handleClose} serviceDetail={serviceDetail}/>
    </Grid>
  );
};

export default ServicesPages;
