import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NotificationService from '../api/NotificationService';

const initialState = {
    loading: false,
    data: null,
    error: null,
    success: false,
};

export const getNotificationMethod = createAsyncThunk(`/get-notification`, async () => {
    try {
        const { data } = await NotificationService.get();
        return data.data;
    } catch (error) {
        console.error('-------', error.message);
        throw error;
    }
});

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {},
    extraReducers: {
        // get
        [getNotificationMethod.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [getNotificationMethod.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.success = true;
            state.data = payload;
        },
        [getNotificationMethod.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export default notificationSlice.reducer;
