import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useRef, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Grid,
  Button,
} from '@mui/material';

import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
// components
import { getOrderMethod } from '../redux/slice/orderSlice';
import OrderProductListHead from '../sections/@dashboard/user/OrderProductListHead';
import { getPublicVendorDetails } from '../redux/slice/publicVendorSlice';
import Iconify from '../components/iconify/Iconify';
import imgLogo from '../components/logo/logo.png';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'productCode', label: 'Product Code', alignRight: false },
  { id: 'product_title', label: 'Product Name', alignRight: false },
  { id: 'product_type', label: 'Product Type', alignRight: false },
  { id: 'quantity', label: 'Quantity', alignRight: false },
  { id: 'old_price', label: 'Total Price', alignRight: false },
  { id: 'discount', label: 'Discount', alignRight: false },
  { id: 'new_price', label: 'New Price', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.product_id.product_title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PrintOrderPage() {
  const componentRef = useRef();

  const dispatch = useDispatch();

  const { id } = useParams();

  const [data, setDatas] = useState(null);

  const orderList = useSelector((state) => state.order.data);

  useEffect(() => {
    dispatch(getPublicVendorDetails());
    dispatch(getOrderMethod());
  }, [dispatch]);

  useEffect(() => {
    const result = !orderList ? null : orderList?.filter((item) => item?._id === id);
    setDatas(!result ? null : result[0]);
  }, [id, orderList]);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredUsers = applySortFilter(
    !data || !data.products || data.products.length === 0 ? [] : data.products,
    getComparator(order, orderBy)
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `PakPrint Wishes Order Invoice ${new Date()}`,
    onAfterPrint: () => toast.success('Print Order Invoice Successfully'),
  });

  return (
    <>
      <Helmet>
        <title> Print Order | PakPrint Wishes </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Print Order Invoice
              </Typography>
              <Button variant="outlined" startIcon={<Iconify icon="uit:print" />} onClick={handlePrint}>
                Print
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} p={4} ref={componentRef}>
            <Grid container>
              <Grid item xs={4}>
                <img src={imgLogo} alt="Logo" height={'100px'} />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', textTransform: 'uppercase', textAlign: 'center' }}>
                  PakPrint Wishes
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', textTransform: 'uppercase', textAlign: 'center' }}
                >
                  ALL YOU WANT PRINT WISHES
                </Typography>
              </Grid>
              <Grid item xs={12} pt={3}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Customer Info
                </Typography>
              </Grid>
              <Grid item xs={8} p={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle1" gutterBottom>
                    Name
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {!data?.user_id?.first_name ? null : data?.user_id?.first_name}{' '}
                    {!data?.user_id?.last_name ? null : data?.user_id?.last_name}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle1" gutterBottom>
                    Email
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {!data?.user_id?.email ? 'N/A' : data?.user_id?.email}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle1" gutterBottom>
                    Phone No.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {!data?.user_id?.phone ? 'N/A' : data?.user_id?.phone}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle1" gutterBottom>
                    Address
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {!data?.user_id?.address ? null : data?.user_id?.address}{' '}
                    {!data?.user_id?.city ? null : data?.user_id?.city}{' '}
                    {!data?.user_id?.country ? null : data?.user_id?.country}{' '}
                    {!data?.user_id?.state ? null : data?.user_id?.state}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Order Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <Container maxWidth="xl" sx={{ marginTop: '1%' }}>
                <Card>
                  <TableContainer sx={{ minWidth: '100%' }}>
                    <Table>
                      <OrderProductListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {filteredUsers.map((row) => (
                          <TableRow hover key={row._id} tabIndex={-1}>
                            <TableCell align="left">
                              {!row.product_id.productCode ? 'N/A' : row.product_id.productCode}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography variant="subtitle2">
                                {row?.product_id?.product_title ? row.product_id.product_title : 'N/A'}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              {row.product_id.product_type ? row.product_id.product_type : 'N/A'}
                            </TableCell>
                            <TableCell align="left">{row.qusantity ? row.qusantity : 'N/A'}</TableCell>
                            <TableCell align="left">{row.old_price ? row.old_price : 'N/A'}</TableCell>
                            <TableCell align="left">{row.discount ? row.discount : 'N/A'}</TableCell>
                            <TableCell align="left">{row.new_price ? row.new_price.toFixed(2) : 'N/A'}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} mt={1} ml={2}>
                <Grid item md={6}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle1" gutterBottom>
                      Total Amount
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {data?.sub_total.toFixed(2)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle1" gutterBottom>
                      Total Shiping Fee
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {data?.total_shipping_fee}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle1" gutterBottom>
                      Grand Total
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {data?.all_totals.toFixed(2)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle1" gutterBottom>
                      Delivery Address
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {data?.delivery_address}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle1" gutterBottom>
                      Payment Method
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {data?.payment_method}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', paddingTop: '20px', textAlign: 'right' }}>
                    Signature: _____________________
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
