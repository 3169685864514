import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Button, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet-async';
import { createForgetPassword } from '../../redux/slice/forgetPasswordSlice';
import { resetPasswordSchema } from '../../schemas';

const ForgotPasswordPages = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  const { errors, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      forgetPasswordSubmit();
    },
  });

  const dispatch = useDispatch();

  const forgetPasswordSubmit = () => {
    dispatch(createForgetPassword(email))
      .unwrap()
      .then((res) => {
        toast.success(res.message, {
          position: 'bottom-left',
        });
        navigate('/reset-password', { state: { email } });
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>Forget Password | PakPrint Wishes </title>
      </Helmet>
      <Grid className="top-container" container>
        <Typography className="login-title">Forget Password</Typography>
        <span className="forgot-subtitle-text-span">
          <Typography className="login-title forgot-password-text">
            Enter the email associted with your account and we’ll send an email with instructions to resert your
            password.{' '}
          </Typography>
        </span>
        <Grid item style={{ padding: '0' }} className="login-sub-container">
          <Grid item className="login-form-container" xs={10} sm={10} md={4} lg={4} xl={4}>
            <Box className="login-form">
              <TextField
                id="email"
                name="email"
                type="email"
                value={email}
                placeholder="Email Address"
                fullWidth
                onChange={(e) => {
                  setFieldValue('email', e.target.value);
                  setEmail(e.target.value);
                }}
                onBlur={handleBlur}
                error={errors.email && touched.email}
                helperText={errors.email && touched.email ? errors.email : null}
              /><br />
              {/* <span className="login-forgot-link-span"></span> */}
              <Button
                style={{ marginTop: '150px !important' }}
                variant="contained"
                className="login-btn "
                onClick={handleSubmit}
              >
                Send
              </Button>
            </Box>
            <span className="centered">
              <Typography className="login-signup-text">
                Go to back &nbsp;
                <span>
                  <Link to="/login" className="login-signup-link">
                    Login
                  </Link>
                </span>
              </Typography>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPasswordPages;
