import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import AllVendorServices from '../../../redux/api/AllVendorServices';
import VendorProductCard from '../../card/VendorProductCard';
import ProgressBar from '../../progress/ProgressBar';

const VendorDetails = () => {
  const [data, setData] = useState(null);
  const [productsData, setProductsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    getDetail();
    getProductsDetail();
  }, [id]);
  const getDetail = async () => {
    await AllVendorServices.getVendorbyId(id)
      .then((res) => {
        setData(res?.data?.data);
        setLoading(false);
        
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };
  const getProductsDetail = async () => {
    await AllVendorServices.getVendorProductbyId(id)
      .then((res) => {
        setProductsData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <Box sx={{ width: '100%' }}>
      {loading === true ? (
        <ProgressBar />
      ) : (
        <Grid
          container
          sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '20px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;',
          }}
        >
          <Grid item xs={12} py={2}>
            <Typography variant="h5">Vendor Detail</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.first_name ? 'N/A' : `${data?.first_name} ${data?.last_name}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Email</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.email ? 'N/A' : data?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Phone</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.phone ? 'N/A' : data?.phone}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Address</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.address ? 'N/A' : data?.address}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">City</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.city ? 'N/A' : data?.city}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Country</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.country ? 'N/A' : data?.country}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Bank Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.bank_name ? 'N/A' : data?.bank_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Bank Account Number</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.bank_account_number ? 'N/A' : data?.bank_account_number}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Company Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.companyName ? 'N/A' : data?.companyName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Status</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.blocked ? 'Unblock' : 'Blocked'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} pt={2}>
            <Typography variant="h5">Products</Typography>
          </Grid>
          <Grid item xs={12} p={1}>
            <Grid container spacing={2}>
              {!productsData || productsData.length === 0
                ? null
                : productsData.map((image, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                      <VendorProductCard image={image} />
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default VendorDetails;
