import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveBuyNowServices } from '../../redux/slice/buyNowSlice';
import ProductCard from '../../Components/ProductCard';
import ProgressBar from '../../../components/progress/ProgressBar';

const imagePerRow = 8;
const BuyNow = () => {
  const [next, setNext] = useState(imagePerRow);
  const dispatch = useDispatch();

  const cachedData = useSelector((state) => state.buyNow);
  const data = React.useMemo(() => cachedData, [cachedData]);
  const initFetch = React.useCallback(() => {
    dispatch(retrieveBuyNowServices());
  }, [dispatch]);

  React.useEffect(() => {
    initFetch();
  }, [initFetch]);

  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };

  return (
    <Box>
      {data?.loading && <ProgressBar />}
      {data?.data === null || data?.data === undefined || data?.data.length === 0 ? null : (
        <Grid container spacing={2} className="featured-products-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="featured-products-header-container">
            <Typography variant="h6" className="featured-products-header-text">
              Buy Now
            </Typography>
          </Grid>
          {data?.data
            ? data?.data?.slice(0, next)?.map((image, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                  <ProductCard image={image} />
                </Grid>
              ))
            : ''}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            {data?.data.length > imagePerRow
              ? next < data?.data?.length && (
                  <a
                    onClick={handleMoreImage}
                    className="featured-products-header-link pointers"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === 'Space') {
                        handleMoreImage();
                      }
                    }}
                    role="button" // Set the role to "button" to indicate it's an interactive element
                    tabIndex={0} // Make the element focusable
                  >
                    Load More&#x276F;
                  </a>
                )
              : ''}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default BuyNow;
