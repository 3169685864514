import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import AllServices from '../api/AllServices';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const addServicesMethod = createAsyncThunk(`/add-services`, async (datas) => {
  try {
    const { data } = await AllServices.add(datas);
    console.warn(data);
    toast.success("Services added successfully");
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});
export const getServicesMethod = createAsyncThunk(`/get-services`, async () => {
  try {
    const { data } = await AllServices.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.response.data.message);
    throw error;
  }
});
export const deleteSelectedServicesMethod = createAsyncThunk(`/delete-services`, async (id) => {
  try {
    const { data } = await AllServices.deleteMultiple(id);
    console.warn(data);
    toast.success("Services deleted successfully");
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      toast.error(error.message, { position: 'top-right' });
    } else {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
    throw error;
  }
});

const allServicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {},
  extraReducers: {
    // add
    [addServicesMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addServicesMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = [...state.data, payload];
    },
    [addServicesMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get
    [getServicesMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getServicesMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getServicesMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete Multiple
    [deleteSelectedServicesMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSelectedServicesMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [deleteSelectedServicesMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default allServicesSlice.reducer;
