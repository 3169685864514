import { callAPi } from './http-common';

const getAllUser = () => {
  return callAPi.get('/getAllVendorsName');
};

const PublicVendorService = {
  getAllUser,
};

export default PublicVendorService;
