import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListToolbar } from '../sections/@dashboard/user';
// mock
import AddCustomParentCategoryDialog from '../components/Dialog/AddFormDialog/AddCustomParentCategoryDialog';
import UpdateCustomParentCategoryDialog from '../components/Dialog/UpdateFormDialog/UpdateCustomParentCategoryDialog';
import { IMAGE_BASE_URL } from '../redux/api/http-common';
import CustomParentCategoryService from '../redux/api/CustomParentCategoryService';
import { getCustomParentCategoryMethod } from '../redux/slice/customParentCategorySlice';
import OrderProductListHead from '../sections/@dashboard/user/OrderProductListHead';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'customParentCategoryImage', label: 'Image', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomParentCategoryPage() {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.userInfo);
  const data = useSelector((state) => state.customParentCategory);
  useEffect(() => {
    dispatch(getCustomParentCategoryMethod());
  }, [dispatch]);

  const [open, setOpen] = useState(null);

  const [parentCategoryId, setParentCategoryId] = useState(null);

  const [parentCategoryStatus, setParentCategoryStatus] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openAddForm, setOpenAddForm] = useState(false);

  const [openEditForm, setOpenEditForm] = useState(false);

  const handleClickOpenAddForm = () => {
    setOpenAddForm(!openAddForm);
  };

  const handleClickOpenEditForm = () => {
    setOpenEditForm(!openEditForm);
    setOpen(false);
  };

  const handleOpenMenu = (event, id) => {
    setParentCategoryId(id);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    data.data !== null && data.data !== undefined ? data.data : [],
    getComparator(order, orderBy),
    filterName
  );
console.log(filteredUsers)
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const isNotFound = !filteredUsers.length && !!filterName;
  return (
    <>
      <Helmet>
        <title>Custom Parent Category | PakPrint Wishes </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Custom Parent Category
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpenAddForm}>
            Add Custom Parent Category
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrderProductListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, title, status } = row;
                    return (
                      <TableRow hover key={_id}>
                        <TableCell component="th" scope="row">
                          <Typography variant="subtitle2" noWrap>
                            {!title ? 'N/A' : title}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" sx={{fontSize:'10px'}}>
                          {!row?.description ? 'N/A' : <div dangerouslySetInnerHTML={{ __html: row?.description.slice(0, 100) }} />}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {status && status === true ? 'Approved' : 'Disapproved'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {!row?.customParentCategoryImage ? (
                            'N/A'
                          ) : (
                            <img
                              src={IMAGE_BASE_URL + row?.customParentCategoryImage}
                              alt={title}
                              style={{ width: '100px' }}
                            />
                          )}
                        </TableCell>

                        <TableCell align="right">
                          <IconButton
                            disabled={row?.createdBy !== userData?._id && userData?.role !== 'admin'}
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              setParentCategoryStatus(status);
                              handleOpenMenu(e, _id);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {!userData || Object.keys(userData).length === 0 || userData?.role !== 'admin' ? null : (
          <MenuItem
            sx={{ color: 'error.main' }}
            onClick={async () => {
              await CustomParentCategoryService.status(parentCategoryId)
                .then(() => {
                  dispatch(getCustomParentCategoryMethod());
                  if (parentCategoryStatus === false) {
                    toast.success('Category Approved successfully');
                  } else {
                    toast.success('Category Disapproved successfully');
                  }
                  setParentCategoryStatus(false);
                  setParentCategoryId(null);
                  setOpen(false);
                })
                .catch((error) => {
                  if (!error.response.data.message) {
                    toast.error(error.message, { position: 'top-right' });
                  } else {
                    toast.error(error.response.data.message, { position: 'top-right' });
                  }
                });
            }}
          >
            <Iconify
              icon={
                'streamline:interface-id-iris-scan-check-identification-retina-security-approved-success-iris-scan-eye-login'
              }
              sx={{ mr: 2 }}
            />
            {parentCategoryStatus === false ? 'Approved' : 'Disapproved'}
          </MenuItem>
        )}
        <MenuItem onClick={handleClickOpenEditForm}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={async () => {
            await CustomParentCategoryService.deletes(parentCategoryId)
              .then(() => {
                dispatch(getCustomParentCategoryMethod());
                setParentCategoryId(null);
                setOpen(false);
              })
              .catch((error) => {
                if (!error.response.data.message) {
                  toast.error(error.message, { position: 'top-right' });
                } else {
                  toast.error(error.response.data.message, { position: 'top-right' });
                }
              });
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <AddCustomParentCategoryDialog openAddForm={openAddForm} handleClickOpenAddForm={handleClickOpenAddForm} />
      <UpdateCustomParentCategoryDialog
        openEditForm={openEditForm}
        parentCategoryId={parentCategoryId}
        handleClickOpenEditForm={handleClickOpenEditForm}
      />
    </>
  );
}
