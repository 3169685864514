import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { getUserDetails } from '../../redux/slice/userSlice';
import { BlueButton } from '../../Components/Components';
import OrderServices from '../../redux/api/OrderServices';
import { imageBaseUrl } from '../../redux/api/http-common';

const ConfirmOrderPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const userToken = useSelector((state) => state.clientuser.token);
  const userInfo = useSelector((state) => state.clientuser.userInfo);
  const navigate = useNavigate();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    OrderServices.getOrderDetail(id)
      .then((order) => {
        setData(order.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    if (userToken) {
      dispatch(getUserDetails());
    }
  }, [userToken, dispatch, id]);
  return (
    <Grid container className="top-container centered">
      <Helmet>
        <title>Order Detail | PakPrint Wishes </title>
      </Helmet>
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10} pt={2}>
        <Box className="row-flex-container confirm-order-header-text centered">
          <AccessTimeFilledIcon className="co-header-text-font" />
          <Typography className="co-header-text-font">Thanks you for your purchase!</Typography>
        </Box>
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
        <br />
        <Typography className="co-header-text-font2 centered">Your order number is {id}</Typography>
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
        <br />

        <Typography className="co-header-text-font2 centered">
          Please have this amount ready on delivery day.
        </Typography>
        <br />
        <Typography className="co-header-text-font3 centered">
          Rs.{' '}
          {(
            (!data.products || data.products.length === 0 ? 0 : Number(data?.all_totals)) +
            (!data.customProduct || data.customProduct.length === 0 ? 0 : Number(data?.customTotal))
          ).toFixed(2)}
        </Typography>
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
        <Typography className="co-header-text-font4">Your Delivery Dates</Typography>
        {data.products
          ? data.products.map((res) => (
              <Grid container className="co-box-border" key={res._id}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="row-space-between-container co-content-bottom-border"
                >
                  <img
                    src={imageBaseUrl + res.product_id.images[0]}
                    alt="image2"
                    className="co-box-img"
                    key={res._id}
                  />
                  <Typography className="co-header-text-font5" pr={5}>
                    Qty.{res.quantity}
                  </Typography>
                </Grid>
              </Grid>
            ))
          : ''}
        {data.customProduct
          ? data.customProduct.map((res) => (
              <Grid container className="co-box-border" key={res._id}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="row-space-between-container co-content-bottom-border"
                >
                  <img
                    src={imageBaseUrl + res?.customProductId?.images[0]}
                    alt="image2"
                    className="co-box-img"
                    key={res?._id}
                  />
                  <Typography className="co-header-text-font5" pr={5}>
                    Qty.{res?.cartQuantity}
                  </Typography>
                </Grid>
              </Grid>
            ))
          : ''}
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10} className="co-box-border" mt={5}>
        <List>
          <ListItem>
            <ListItemIcon>
              <MailOutlineOutlinedIcon sx={{ color: 'black' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography className="co-des-text">
                We’ve sent a confirmation email to <strong style={{ color: '#4e9f0b' }}>{userInfo?.email}</strong> with
                the order details.
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10} mt={3}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="co-header-text-font4" p={4}>
              Order Summary
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!data.products || data.products.length === 0 ? null : (
              <Box className="row-space-between-container" p={4}>
                <Typography className="co-header-text-font6">
                  Total Product Items ({`${!data.products ? '0' : data.products.length} items`})
                </Typography>
                <Typography className="co-header-text-font2">Rs. {data.sub_total}</Typography>
              </Box>
            )}
            {!data.customProduct || data.customProduct.length === 0 ? null : (
              <Box className="row-space-between-container" p={4}>
                <Typography className="co-header-text-font6">
                  Total Custom Product Items ({`${!data.customProduct ? '0' : data.customProduct.length} items`})
                </Typography>
                <Typography className="co-header-text-font2">Rs. {data?.customTotal}</Typography>
              </Box>
            )}
            <Box className="row-space-between-container" p={4}>
              <Typography className="co-header-text-font6" sx={{ color: 'red !important' }}>
                Note: Ship Fee Calculate on the base of Distance and Weight.
              </Typography>
              {/* <Typography className="co-header-text-font6">Ship Fee</Typography>
              <Typography className="co-header-text-font2">
                Rs. {!data?.total_shipping_fee ? '0' : data?.total_shipping_fee}
              </Typography> */}
            </Box>
            <Box className="row-space-between-container" p={4} sx={{ borderTop: '1px solid black' }}>
              <Typography className="co-header-text-font6">Total:</Typography>
              <Typography className="co-header-text-font2" sx={{ color: '#58A30A' }}>
                Rs.{' '}
                {(
                  (!data.products || data.products.length === 0 ? 0 : Number(data?.all_totals)) +
                  (!data.customProduct || data.customProduct.length === 0 ? 0 : Number(data?.customTotal))
                ).toFixed(2)}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10} mt={3} className="centered">
        <BlueButton onClick={() => navigate('/')}>Continue Shopping</BlueButton>
      </Grid>
    </Grid>
  );
};

export default ConfirmOrderPage;
