import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnRefresh = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top when the component is mounted or when the location changes
    window.scrollTo(0, 0);

    // Override the browser's scroll position restoration behavior
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
      window.scrollTo(0, 0);
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [pathname]);

  return null; // This component doesn't render anything
};

export default ScrollToTopOnRefresh;
