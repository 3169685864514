import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BlogService from '../api/BlogService';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getBlogMethod = createAsyncThunk(`/get-blog`, async () => {
  try {
    const { data } = await BlogService.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getBlogMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getBlogMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getBlogMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default blogSlice.reducer;
