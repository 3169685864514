import { useState } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Divider, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../../components/iconify';
import { jobsforgetPasswordOPTMethod, jobsresetPasswordMethod } from '../../../redux/slice/jobsUserSlice';
import JobUserServices from '../../../redux/api/JobUserServices';

// ----------------------------------------------------------------------

export default function JobsResetPasswordForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [disableComponent, setDisableComponent] = useState(true);
  const [disableEmailComponent, setDisableEmailComponent] = useState(false);
  const [forgotPasswordOtp, setForgotPasswordOtp] = useState('');
  const [password, setPassword] = useState(null);
  const [confirmPassword, setconfirmPassword] = useState(null);
  const handleClick = async () => {
    const data = {
      email: location.state.emailID,
      forgotPasswordOtp,
    };
    const result = await dispatch(jobsforgetPasswordOPTMethod(data));
    if (result.payload.success === true) {
      toast.success(result.payload.message);
      setDisableComponent(false);
      setDisableEmailComponent(true);
    }
  };
  const handleResetPasswordClick = async () => {
    const data = {
      email: location.state.emailID,
      password,
      confirmPassword,
    };
    const result = await dispatch(jobsresetPasswordMethod(data));
    if (result.payload.success === true) {
      setDisableComponent(true);
      setDisableEmailComponent(true);
      toast.success(result.payload.message);
      navigate('/jobs/login', { replace: true });
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <MuiOtpInput
          required
          length={6}
          value={forgotPasswordOtp}
          onChange={(e) => setForgotPasswordOtp(e)}
          TextFieldsProps={{ disabled: disableEmailComponent }}
        />
        <Typography
          variant="body1"
          sx={{ textAlign: 'right', cursor: 'pointer', color: '#8cb505', fontWeight: 'bold' }}
          onClick={async () => {
            await JobUserServices.forgetPassword({ email: location.state.emailID })
              .then((res) => {
                toast.success(res.data.message);
              })
              .catch((error) => {
                if (!error.response.data.message) {
                  toast.error(error.message, { position: 'top-right' });
                } else {
                  toast.error(error.response.data.message, { position: 'top-right' });
                }
              });
          }}
        >
          Resend OTP
        </Typography>
        <LoadingButton
          disabled={disableEmailComponent}
          sx={{ marginTop: '15px' }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          className="green-job-btn"
          onClick={handleClick}
        >
          Verify OTP
        </LoadingButton>
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          AND
        </Typography>
      </Divider>

      <Stack spacing={3} sx={{ marginTop: '15px' }}>
        <TextField
          disabled={disableComponent}
          name="password"
          label="Password"
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          disabled={disableComponent}
          name="confirmPassword"
          label="Confirm Password"
          onChange={(e) => setconfirmPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <LoadingButton
        disabled={disableComponent}
        sx={{ marginTop: '15px' }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        className="green-job-btn"
        onClick={handleResetPasswordClick}
      >
        Reset Password
      </LoadingButton>
    </>
  );
}
