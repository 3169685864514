import { Box, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { retrievePartner } from '../../redux/slice/partner';
import { imageBaseUrl } from '../../redux/api/http-common';
import ProgressBar from '../../../components/progress/ProgressBar';

const ClientsCompanies = () => {
  const dispatch = useDispatch();
  const cachedData = useSelector((state) => state.clientpartner);
  const data = React.useMemo(() => cachedData, [cachedData]);
  const initFetch = useCallback(() => {
    dispatch(retrievePartner());
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const responsive = {
    0: { items: 2 },
    568: { items: 3 },
    1024: { items: 5 },
  };
  return (
    <Box>
      {data?.loading && <ProgressBar />}
      {data.data === null || data.data === undefined || data.data.length === 0 ? null : (
        <Grid container className="client-company-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h6" className="client-company-text">
              Our Clients
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pt={3}>
            <AliceCarousel
              mouseTracking
              infinite
              autoPlayInterval={1000}
              animationDuration={1500}
              disableButtonsControls
              autoPlay
              disableDotsControls
              responsive={responsive}
            >
              {data.data?.map((res) => (
                <Box key={res._id} className="sliderimg-container">
                  <img src={imageBaseUrl + res.logo} alt={res.name} className="sliderimg" />
                </Box>
              ))}
            </AliceCarousel>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ClientsCompanies;
