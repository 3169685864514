import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ParentCategorySEO from '../api/ParentCategorySEO';

const initialState = {
  loading: false,
  data: [],
  error: null,
  success: false,
};

export const getParentCategorySEOMethod = createAsyncThunk(`/get-getParentCategorySEOMethod`, async () => {
  try {
    const { data } = await ParentCategorySEO.get();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const parentCategorySEOSlice = createSlice({
  name: 'parentCategorySEO',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getParentCategorySEOMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getParentCategorySEOMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getParentCategorySEOMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default parentCategorySEOSlice.reducer;
