import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import { useLocation, useNavigate } from 'react-router-dom';

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
const navItems = [
  { title: 'Find Job', path: '/jobs/find-jobs' },
  { title: 'Saved Jobs', path: '/jobs/saved-jobs' },
  { title: 'Proposals', path: '/jobs/proposals' },
];

function ChildNavbar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar position="static" sx={{ boxShadow: 'none', borderBottom: '1px solid rgba(170, 170, 170, 1)' }}>
          <Toolbar
            sx={{
              backgroundColor: '#F4F4F4',
              paddingX: { sm: 'auto', md: '100px', lg: '180px' },
              minHeight: '40px !important',
              display: { xs: 'none', sm: 'flex' },
              justifyContent: 'center',
            }}
          >
            {/* ,borderBottom:'2px solid rgba(170, 170, 170, 1)'  */}
            <Box>
              {navItems.map((item, index) => (
                <Button
                  key={index}
                  onClick={() => {
                    navigate(item?.path);
                  }}
                  sx={{
                    color: '#000',
                    borderRadius: '0px',
                    borderBottom: item.path && location.pathname.includes(item.path) ? '2px solid black' : 'none',
                  }}
                >
                  {item?.title}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </Box>
  );
}

ChildNavbar.propTypes = {
  window: PropTypes.func,
};

export default ChildNavbar;
