import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Iconify from '../../iconify/Iconify';
import { vendorRegister } from '../../../redux/slice/userSlice';
import { getAllVendorDetails } from '../../../redux/slice/allVendorSlice';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function AddVendorForm({ openAddForm, handleClickOpenAddForm }) {
  const dispatch = useDispatch();
  const [fName, setFName] = useState(null);
  const [LastName, setLastName] = useState(null);
  const [email, setemail] = useState(null);
  const [Phone, setPhone] = useState(null);
  const [CompanyName, setCompanyName] = useState(null);
  const [CNIC, setCNIC] = useState(null);
  const [BankAccountName, setBankAccountName] = useState(null);
  const [BankAccountNumber, setBankAccountNumber] = useState('');
  const [password, setpassword] = useState(null);
  const [Address, setAddress] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = async () => {
    if (
      !email ||
      !password ||
      !fName ||
      !LastName ||
      !Phone ||
      !CompanyName ||
      !CNIC ||
      !BankAccountName ||
      !BankAccountNumber ||
      !password ||
      !Address
    ) {
      toast.error('Please enter all fields');
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }
    if (password.length < 6) {
      toast.error('Please enter password greater than 6 characters');
      return;
    }
    if (CNIC.length < 13) {
      toast.error('Please enter 13 numbers for the cnic.');
      return;
    }
    const numberPattern = /^\d+$/;
    if (!numberPattern.test(CNIC)) {
      toast.error('Please enter a valid number for the CNIC.');
      return;
    }
    if (!numberPattern.test(Phone)) {
      toast.error('Please enter a valid number for the Phone.');
      return;
    }
    if (Phone.length < 11) {
      toast.error('Please enter 11 numbers for the Phone.');
      return;
    }
    if (BankAccountNumber.length < 24) {
      toast.error('Please enter 24 numbers for the Bank Account IBAN Number.');
      return;
    }
    const specialSymbolPattern = /^[a-zA-Z0-9]*$/;
    if (!specialSymbolPattern.test(BankAccountNumber)) {
      toast.error('Please enter a valid bank account IBAN number');
      return;
    }
    const data = {
      first_name: fName,
      last_name: LastName,
      phone: Phone,
      companyName: CompanyName,
      bank_name: BankAccountName,
      bank_account_number: BankAccountNumber,
      cnic: CNIC,
      address: Address,
      email,
      password,
      confirmpassword: password,
    };
    const result = await dispatch(vendorRegister(data));
    if (result.payload.success === true) {
      toast.success('Vendor Create Successfully');
      dispatch(getAllVendorDetails());
      handleClickOpenAddForm();
    }
  };
  return (
    <div>
      <Dialog fullScreen open={openAddForm} onClose={handleClickOpenAddForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenAddForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Vendor
            </Typography>
            {/* <Button autoFocus variant="outlined" sx={{ color: 'white', borderColor: 'white' }} onClick={handleClick}>
              Save
            </Button> */}
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12}>
            <TextField label="First Name" required onChange={(e) => setFName(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Last Name" required onChange={(e) => setLastName(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              label="Email address"
              required
              onChange={(e) => setemail(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Phone" type="number" required onChange={(e) => setPhone(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Company Name" required onChange={(e) => setCompanyName(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField label="CNIC" required onChange={(e) => setCNIC(e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Bank Account Name"
              required
              onChange={(e) => setBankAccountName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={`Bank Account IBAN Number without dash (${BankAccountNumber.length})`}
              required
              onChange={(e) => setBankAccountNumber(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setpassword(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Address"
              multiline
              rows={5}
              required
              onChange={(e) => setAddress(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={() => {
                handleClick();
                // handleClickOpenAddForm();
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
