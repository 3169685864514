import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import { Grid, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandMethod } from '../../../redux/slice/brandSlice';
import BrandServices from '../../../redux/api/BrandServices';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function UpdateParentCategoryDialog({ openEditForm, handleClickOpenEditForm, brandId }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.brand);
  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [slug, setSlug] = React.useState(null);
  const [file, setFile] = React.useState(null);

  React.useEffect(() => {
    const result = data.data && data.data.filter((item) => item._id === brandId);
    if (result && result.length !== 0) {
      setTitle(result[0]?.name);
      setSlug(result[0]?.slug);
      setDescription(result[0]?.description);
    }
  }, [brandId]);

  const handleSubmit = async () => {
    if (title === null || description === null || slug === null) {
      toast.error('Please fill all the fields');
      return;
    }
    const data = {
      name: title,
      slug,
      description,
      image: file,
    };
    await BrandServices.update(brandId, data)
      .then(() => {
        toast.success('Brand updated successfully');
        dispatch(getBrandMethod());
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Dialog fullScreen open={openEditForm} onClose={handleClickOpenEditForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenEditForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Brand
            </Typography>
            {/* <Button
              autoFocus
              variant="outlined"
              sx={{ color: 'white', borderColor: 'white' }}
              onClick={() => {
                handleSubmit();
                handleClickOpenEditForm();
              }}
            >
              Edit
            </Button> */}
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Edit Brand</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Brand Name
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Brand Name"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Brand Slug
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Brand Slug"
              variant="outlined"
              fullWidth
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Brand Description
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Brand Description"
              multiline
              rows={5}
              variant="outlined"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Upload Image
            </Typography>
            <br />
            <input
              accept=".png, .jpg, .jpeg"
              type="file"
              id="select-image"
              onChange={(e) => setFile(e.target.files[0])}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={() => {
                handleSubmit();
                handleClickOpenEditForm();
              }}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
