/* eslint-disable object-shorthand */
import { Helmet } from 'react-helmet-async';
import { filter, includes, toLower } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// components
import { useLocation } from 'react-router-dom';
import AllVendorServices from '../redux/api/AllVendorServices';
import CustomPopup from '../client/Components/CustomPopup';
import Label from '../components/label';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import {
  deleteSelectedVendorMethod,
  getVendorAllApprovedOrders,
} from '../redux/slice/allVendorSlice';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'First Name', alignRight: false },
  // { id: 'last_name', label: 'Last Name', alignRight: false },
  { id: 'orderId', label: 'Order Id', alignRight: false },
  { id: 'payment_method', label: 'Payment Method', alignRight: false },
  { id: 'delivery_address', label: 'Delivery Address', alignRight: false },
  { id: 'total_shipping_fee', label: 'Total Shipping Fee', alignRight: false },
  { id: 'sub_total', label: 'Sub total', alignRight: false },
  { id: 'all_totals', label: 'All Totals', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },

  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = Array.isArray(array) && array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => {
      const lowerQuery = toLower(query);

      return (
        includes(toLower(_user.first_name), lowerQuery) ||
        includes(toLower(_user.last_name), lowerQuery) ||
        includes(toLower(_user.email), lowerQuery) ||
        includes(toLower(_user.phone), lowerQuery) ||
        includes(toLower(_user.cnic), lowerQuery) ||
        includes(toLower(_user.bank_account_number), lowerQuery) ||
        includes(toLower(_user.bank_name), lowerQuery)
      );
    });
  }

  return stabilizedThis.map((el) => el[0]);
}

const initialValue = {
  TID: '',
  payPrice: '',
};

export default function VendorPayment() {
  const data = useSelector((state) => state.vendors);
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    dispatch(getVendorAllApprovedOrders(location.state.id));
  }, [dispatch, location.state.id]);

  const [values, setValues] = useState(initialValue);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isPay, setIsPay] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds =
        data.vendorApprovedOrders !== null && data.vendorApprovedOrders !== undefined
          ? data.vendorApprovedOrders.map((n) => n._id)
          : [];
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    data.vendorApprovedOrders !== null && data.vendorApprovedOrders !== undefined ? data.vendorApprovedOrders : [],
    getComparator(order, orderBy),
    filterName
  );

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleSelectDeleteMethod = () => {
    dispatch(deleteSelectedVendorMethod(selected));
    setSelected([]);
  };

  const handlePayOpen = () => {
    setIsPay(true);
  };

  const filteredData = data?.vendorApprovedOrders?.filter((item) => selected.includes(item._id));
  const allTotalsSum = filteredData.reduce((sum, item) => sum + item.order.all_totals, 0);

  const handlePayToVendor = async () => {
    if (values.tid === '') {
      toast.error('transaction id is required');
      return;
    }

    await AllVendorServices.cratePaymentToVendor(location.state.id, {
      payPrice: allTotalsSum,
      ids: selected,
      TID: values.TID,
    })
      .then((results) => {
        if (results.data.success === true) {
          dispatch(getVendorAllApprovedOrders(location.state.id));
          setIsPay(false);
        }
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };


  return (
    <>
      <Helmet>
        <title> Vendors Payments | PakPrint Wishes </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Vendors Aprroved Orders ({data?.vendorApprovedOrders?.length})
          </Typography>
          {selected.length !== 0 && (
            <Button sx={{ margin: '5px' }} variant="contained" onClick={handlePayOpen}>
              Pay Now
            </Button>
          )}
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleSelectDeleteMethod={handleSelectDeleteMethod}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredUsers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, avatarUrl} = row;
                    const selectedUser = selected.indexOf(_id) !== -1;

                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={row.first_name} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {!row?.order?.name ? 'N/A' : row?.order?.name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{!row?.order?.orderId ? 'N/A' : row?.order?.orderId}</TableCell>

                        <TableCell align="left">
                          {!row?.order?.payment_method ? 'N/A' : row?.order?.payment_method}
                        </TableCell>
                        <TableCell align="left">
                          {!row?.order?.delivery_address ? 'N/A' : row?.order?.delivery_address}
                        </TableCell>
                        <TableCell align="left">
                          {!row?.order?.total_shipping_fee ? 'N/A' : row?.order?.total_shipping_fee}
                        </TableCell>
                        <TableCell align="left">{!row?.order?.sub_total ? 'N/A' : row?.order?.sub_total}</TableCell>
                        <TableCell align="left">{!row?.order?.all_totals ? 'N/A' : row?.order?.all_totals}</TableCell>

                        <TableCell align="left">
                          <Label
                            color={
                              row.status === 'Pending'
                                ? 'warning'
                                : row.status === 'Approved' && !row.blocked
                                ? 'success'
                                : 'error'
                            }
                          >
                            {/* {blocked === true ? 'Block' : 'Active'} */}
                            {row.status}
                          </Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <CustomPopup open={isPay} setOpen={setIsPay}>
        <Stack spacing={2} padding={4} sx={{ width: '700px' }}>
          <>
            <Typography>Fill the payments details</Typography>
            <TextField
              name="TID"
              label="Transaction Id"
              type="text"
              required
              value={values.tid}
              onChange={handleChange}
            />
            <TextField
              name="payPrice"
              label="Pay Price"
              type="number"
              required
              disabled
              value={allTotalsSum}
              onChange={handleChange}
            />
          </>

          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={() => handlePayToVendor()}>
              Submit
            </Button>
          </Box>
        </Stack>
      </CustomPopup>
    </>
  );
}
