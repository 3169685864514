import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomProductServices from '../api/CustomProductServices';

const initialState = {
  loading: false,
  data: null,
  error: null,
  success: false,
};

export const getPublicCustomProductMethod = createAsyncThunk(`/get-publicCustomProduct`, async () => {
  try {
    const { data } = await CustomProductServices.publicGetAll();
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

export const getPublicCustomProductParentIdMethod = createAsyncThunk(
  `/getbyParentId-publicCustomProduct`,
  async (id) => {
    try {
      const { data } = await CustomProductServices.getbyParentId(id);
      return data.data;
    } catch (error) {
      console.error('-------', error.message);
      throw error;
    }
  }
);

export const getPublicCustomProductChildIdMethod = createAsyncThunk(`/getbyChildId-publicCustomProduct`, async (id) => {
  try {
    const { data } = await CustomProductServices.getbyChildId(id);
    return data.data;
  } catch (error) {
    console.error('-------', error.message);
    throw error;
  }
});

const publicCustomProductSlice = createSlice({
  name: 'publicCustomProduct',
  initialState,
  reducers: {},
  extraReducers: {
    // get
    [getPublicCustomProductMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPublicCustomProductMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getPublicCustomProductMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // getbyParentId
    [getPublicCustomProductParentIdMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPublicCustomProductParentIdMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getPublicCustomProductParentIdMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // getbyChildId
    [getPublicCustomProductChildIdMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPublicCustomProductChildIdMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.data = payload;
    },
    [getPublicCustomProductChildIdMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default publicCustomProductSlice.reducer;
