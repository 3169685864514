import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ContactUsServices from "../api/ContactUsServices";

const initialState = [];


export const createContactUs = createAsyncThunk(
    "contact-us",
    async (data) => {
      const res = await ContactUsServices.create(data);
      return res.data;
    }
  );


const contactUsSlice = createSlice({
  name: "contact-us",
  initialState,
  extraReducers: {
    [createContactUs.fulfilled]: (state, action) => {
        state.push(action.payload);
      },
  },
});

export const { contactAdded } = contactUsSlice.actions
export default contactUsSlice.reducer;