import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  Grid,
  Box,
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// components
import CustomPopup from '../client/Components/CustomPopup';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListToolbar } from '../sections/@dashboard/user';
// mock
import { IMAGE_BASE_URL } from '../redux/api/http-common';
import { getOrderMethod } from '../redux/slice/orderSlice';
import OrderProductListHead from '../sections/@dashboard/user/OrderProductListHead';
import { getPublicVendorDetails } from '../redux/slice/publicVendorSlice';
import Iconify from '../components/iconify/Iconify';
import CustomProductOrdersService from '../redux/api/CustomProductOrdersService';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'titles', label: ' Product Name', alignRight: false },
  { id: 'sku', label: 'PPW NO.', alignRight: false },
  { id: 'prices', label: 'Basic Price', alignRight: false },
  { id: 'cartQuantity', label: 'Quantity', alignRight: false },
  { id: 'totalPrice', label: 'Total Price', alignRight: false },
  { id: 'customFields', label: 'Custom Fields', alignRight: false },
  { id: 'lamination', label: 'Lamination', alignRight: false },
  { id: 'mobileModel', label: 'Mobile Model', alignRight: false },
  { id: 'tissueFilling', label: 'Tissue Filling', alignRight: false },
  { id: 'files', label: 'File', alignRight: false },
  { id: 'images', label: 'Image', alignRight: false },
  { id: 'AssignOrder', label: 'Assign Order', alignRight: false },
  { id: '', label: 'Product Detail', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user?.customProductId?.titles.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomProductOrderDetailPage() {
  const navigates = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  const [data, setDatas] = useState(null);

  const venderList = useSelector((state) => state.publicVendor);

  const user = useSelector((state) => state?.user?.userInfo);

  useEffect(() => {
    dispatch(getPublicVendorDetails());
  }, [dispatch]);

  const getOrderDetails = async () => {
    await CustomProductOrdersService.getbyId(id)
      .then((res) => {
        const result = res?.data?.data;
        setDatas(!result ? null : result);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getOrderDetails();
  }, [id]);

  const [selectVendor, setSelectVendor] = useState('Select Vendor');

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isReAsign, setIsReAsign] = useState(false);

  const [reAsignObject, setReAsignObject] = useState({});

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    !data || !data.customProduct || data.customProduct.length === 0 ? [] : data.customProduct,
    getComparator(order, orderBy),
    filterName
  );

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleCompleteOrder = async () => {
    const result = {
      vendorId: selectVendor,
      customProductOrderId: data._id,
    };
    await CustomProductOrdersService.assignCompete(result)
      .then((res) => {
        console.warn('--------', res);
        toast.success(res?.data?.message);
        dispatch(getPublicVendorDetails());
        dispatch(getOrderMethod());
        getOrderDetails();
        setIsReAsign(false);
        setSelectVendor('Select Vendor');
        // navigates('/dashboard/orders');
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };

  const handleAssignOrder = async (e, productId, isAssign) => {
    if (isAssign) {
      setIsReAsign(true);
      setReAsignObject({
        vendorId: e.target.value,
        customProductOrderId: id,
        customProductId: productId,
      });
      return;
    }
    const result = {
      vendorId: e.target.value,
      customProductOrderId: id,
      customProductId: productId,
    };
    await CustomProductOrdersService.assignOneItem(result)
      .then((res) => {
        console.warn('--------', res);
        toast.success(res?.data?.message);
        if (res.data.success) {
          dispatch(getPublicVendorDetails());
          dispatch(getOrderMethod());
          getOrderDetails();
        }
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };
  const handleReAssignProduct = async () => {
    await CustomProductOrdersService.assignOneItem(reAsignObject)
      .then((res) => {
        console.warn('--------', res);
        toast.success(res?.data?.message);
        if (res.data.success) {
          setIsReAsign(false);
          dispatch(getPublicVendorDetails());
          dispatch(getOrderMethod());
          getOrderDetails();
        }
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };
  function camelToSentence(inputStr) {
    // Use regular expression to find camel case
    const words = inputStr.match(/[A-Za-z0-9]+/g);

    // Capitalize the first word and join with spaces
    const result = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    return result;
  }
  return (
    <>
      <Helmet>
        <title>Custom Product Order Detail | PakPrint Wishes </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Customer Information
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Custom Product Order Detail
              </Typography>
              {/* <Button
                variant="contained"
                startIcon={<Iconify icon="uit:print" />}
                onClick={() => {
                  navigates(`/dashboard/orders/print-order/${id}`);
                }}
              >
                Print
              </Button> */}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} p={2} className="product-order-box">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Customer Name
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data && data?.createdBy && data?.createdBy?.first_name}{' '}
                {data && data?.createdBy && data?.createdBy?.last_name}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Customer Email
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data && data?.createdBy && data?.createdBy?.email}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Customer Phone No.
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data && data?.createdBy && data?.createdBy?.phone}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Customer Address
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data && data?.createdBy && data?.createdBy?.address} {data && data?.createdBy && data?.createdBy?.city}
                , {data && data?.createdBy && data?.createdBy?.state},{' '}
                {data && data?.createdBy && data?.createdBy?.country}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} p={2} className="product-order-box">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Custom Order#
              </Typography>
              <Typography variant="body1" gutterBottom>
                {!data?.customOrderCode ? 'N/A' : data?.customOrderCode}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Total Amount
              </Typography>
              <Typography variant="body1" gutterBottom>
                {!data?.customTotal ? '0' : Number(data?.customTotal).toFixed(2)}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Total Shiping Fee
              </Typography>
              <Typography variant="body1" gutterBottom>
                {!data?.total_shipping_fee ? '0' : data?.total_shipping_fee}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Grand Total
              </Typography>
              <Typography variant="body1" gutterBottom>
                {(
                  (!data?.customTotal ? 0 : Number(data?.customTotal)) +
                  (!data?.total_shipping_fee ? 0 : Number(data?.total_shipping_fee))
                ).toFixed(2)}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Delivery Address
              </Typography>
              <Typography variant="body1" gutterBottom>
                {!data?.total_shipping_fee ? 'N/A' : data?.delivery_address}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Payment Method
              </Typography>
              <Typography variant="body1" gutterBottom>
                {!data?.payment_method ? 'N/A' : data?.payment_method}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Assign Complete Order
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="center" justifyContent="center">
              <FormControl fullWidth>
                <Select
                  value={data?.assignedVendor}
                  onChange={(e) => setSelectVendor(e.target.value)}
                  sx={{ textAlign: 'left' }}
                >
                  {venderList.data !== null &&
                    venderList.data !== undefined && [
                      <MenuItem
                        key="assign-to-admin"
                        sx={{
                          backgroundColor: '#becc03',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#103996',
                            // Add any other styles for the hover state here
                          },
                        }}
                        value={user?._id}
                      >
                        Assign To Admin
                      </MenuItem>,
                      ...venderList.data.map((item, index) => (
                        <MenuItem key={index} value={item._id}>
                          {item.first_name} {item.last_name}
                        </MenuItem>
                      )),
                    ]}
                </Select>
              </FormControl>
              <Button
                // disabled={data && data.completeAsign === true}
                variant="contained"
                sx={{ marginTop: '10px' }}
                onClick={handleCompleteOrder}
              >
                Assign Complete Order
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" sx={{ marginTop: '3%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Order Products
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrderProductListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow hover key={row._id} tabIndex={-1}>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle2" noWrap>
                          {!row?.customProductId?.titles ? 'N/A' : row?.customProductId?.titles}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {!row?.customProductId?.sku ? 'N/A' : row?.customProductId?.sku}
                      </TableCell>
                      <TableCell align="left">{!row?.prices ? 'N/A' : row?.prices}</TableCell>
                      <TableCell align="left">{!row?.cartQuantity ? 'N/A' : row?.cartQuantity}</TableCell>
                      <TableCell align="left">{!row?.totalPrice ? 'N/A' : row?.totalPrice}</TableCell>
                      <TableCell align="left">
                        {!row?.customFields || Object.keys(row?.customFields).length === 0
                          ? 'N/A'
                          : Object.keys(row?.customFields)?.map((key, index) => (
                              <Typography variant="body2" key={index}>
                                {camelToSentence(key)}: {row?.customFields[key]?.title}
                              </Typography>
                            ))}
                      </TableCell>

                      <TableCell align="left">
                        {!row?.booleanFields ||
                        Object.keys(row?.booleanFields).length === 0 ||
                        !row?.booleanFields?.lamination
                          ? 'N/A'
                          : row?.booleanFields?.lamination}
                      </TableCell>
                      <TableCell align="left">
                        {!row?.booleanFields ||
                        Object.keys(row?.booleanFields).length === 0 ||
                        !row?.booleanFields?.mobileModel
                          ? 'N/A'
                          : row?.booleanFields?.mobileModel}
                      </TableCell>
                      <TableCell align="left">
                        {!row?.booleanFields ||
                        Object.keys(row?.booleanFields).length === 0 ||
                        !row?.booleanFields?.tissueFilling
                          ? 'N/A'
                          : row?.booleanFields?.tissueFilling}
                      </TableCell>
                      <TableCell align="left">
                        {!row?.booleanFields ||
                        Object.keys(row?.booleanFields).length === 0 ||
                        !row?.booleanFields?.files ? (
                          'N/A'
                        ) : (
                          <a href={row?.booleanFields?.files} target="_blank" rel="noreferrer">
                            View
                          </a>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {!row?.customProductId?.titles ? (
                          'N/A'
                        ) : (
                          <img
                            src={IMAGE_BASE_URL + row?.customProductId?.images[0]}
                            alt={row?.customProductId?.titles}
                            style={{ width: '100px' }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <FormControl sx={{ width: 200 }}>
                          <Select
                            value={row?.assignedVendor}
                            size="small"
                            onChange={(e) => {
                              handleAssignOrder(e, row?.customProductId?._id, row.assigned);
                            }}
                            sx={{ textAlign: 'left' }}
                          >
                            {venderList.data !== null &&
                              venderList.data !== undefined && [
                                <MenuItem
                                  key="assign-to-admin"
                                  sx={{
                                    backgroundColor: '#becc03',
                                    color: 'white',
                                    '&:hover': {
                                      backgroundColor: '#103996',
                                      // Add any other styles for the hover state here
                                    },
                                  }}
                                  value={user?._id}
                                >
                                  Assign To Admin
                                </MenuItem>,
                                ...venderList.data.map((item, index) => (
                                  <MenuItem key={index} value={item._id}>
                                    {item.first_name} {item.last_name}
                                  </MenuItem>
                                )),
                              ]}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="left">
                        <MenuItem
                          onClick={() => {
                            navigates(`/dashboard/custom-product/custom-product-detail/${row?.customProductId?._id}`);
                          }}
                        >
                          <Iconify icon={'carbon:view'} sx={{ mr: 2 }} />
                        </MenuItem>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <CustomPopup open={isReAsign} setOpen={setIsReAsign}>
        <Stack p={4} spacing={2}>
          <Typography>Are You Sure You Want to Re Assign this Order</Typography>
          <Box display="flex" justifyContent="flex-end">
            <Box display="flex" gap={2}>
              <Button variant="contained" onClick={handleReAssignProduct}>
                Yes
              </Button>
              <Button variant="contained" onClick={() => setIsReAsign(false)}>
                No
              </Button>
            </Box>
          </Box>
        </Stack>
      </CustomPopup>
    </>
  );
}
