import { Outlet } from 'react-router-dom';
// @mui
import { Grid } from '@mui/material';
import ChildNavbar from '../../jobs/components/navbar/ChildNavbar';

export default function JobPortalLayout() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <ChildNavbar />
      </Grid>
      <Grid item xs={12}>
        <Outlet />
      </Grid>
    </Grid>
  );
}
