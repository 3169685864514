import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GetShopServices from "../api/GetShopServices";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const retrieveGetShopServices = createAsyncThunk("shop-getAll", async () => {
  try {
    const res = await GetShopServices.getAll();
    return res.data.data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
});

const getShopSlice = createSlice({
  name: "shop",
  initialState,
  extraReducers: {
    [retrieveGetShopServices.pending]: (state) => {
      state.loading = true;
    },
    [retrieveGetShopServices.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [retrieveGetShopServices.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const selectAllData = (state) => state.getShop.data;
export const getStatusMessage = (state) => state.getShop.status;
export const getErrorMessage = (state) => state.getShop.error;

export default getShopSlice.reducer;
