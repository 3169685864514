import http, { api } from './http-common';

const create = (data) => {
  return http.post('/createOrder', data);
};

const deleteOrder = (data) => {
  return http.delete(`/softDeleteByUser/${data}`);
};

const allDeleteOrder = () => {
  return http.delete('/allSoftDeleteByUser');
};

const getOrderDetail = (id) => {
  return http.get(`/getOrderDetailsByOrderCode/${id}`);
};

const getOrderHistorey = () => {
  return http.get('/getOrderHistory');
};
const orderCancel = (id, data) => {
  return http.patch(`/changeStatusByUser/${id}`, data);
};

const OrderServices = {
  create,
  getOrderDetail,
  getOrderHistorey,
  deleteOrder,
  allDeleteOrder,
  orderCancel,
};

export default OrderServices;
