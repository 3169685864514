import { callAPi } from './http-common';

const add = (data) => {
  return callAPi.post('/createCustomChildSeoCategory', data);
};
const get = () => {
  return callAPi.get('/getAllCustomChildSeoCategories');
};
const getbyid = (id) => {
  return callAPi.get(`/getCustomChildSeoCategoryById/${id}`);
};
const update = (id, data) => {
  return callAPi.patch(`/updateCustomChildSeoCategoryById/${id}`, data);
};
const deletes = (id) => {
  return callAPi.patch(`/deleteCustomChildSeoCategoryById/${id}`);
};
const CustomSubCategorySEOService = {
  add,
  get,
  update,
  deletes,
  getbyid,
};

export default CustomSubCategorySEOService;
