import { Box, Grid, List, ListItemButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Link, useNavigate } from 'react-router-dom';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet-async';
import { messageSchema } from '../schemas';
import bgImg from '../Assets/header_bg/contact_bg.png';
import WhatsApp from '../Components/WhatsApp';
import { createContactUs } from '../redux/slice/contactUsSlice';
import { BlueButton } from '../Components/Components';

const ContactPages = () => {
  const [fname, setFName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const saveContact = () => {
    const data = {
      email,
      message,
      name: fname,
    };

    dispatch(createContactUs(data))
      .unwrap()
      .then((data) => {
        toast.success(data.message, {
          position: 'bottom-left',
        });
        // navigate("/success");
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };
  const { values, errors, handleChange, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      message: '',
    },
    validationSchema: messageSchema,
    onSubmit: (values) => {
      saveContact();
      setFName('');
      setEmail('');
      setMessage('');
      values.email = '';
      values.fname = '';
      values.message = '';
    },
  });
  return (
    <Grid container className="centered top-container">
      <Helmet>
        <title>Contact Us | PakPrint Wishes</title>
        <meta
          name="description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:title" content="Contact Us | PakPrint Wishes" />
        <meta
          property="og:description"
          content="We are an online printing company in Pakistan that provides all type of printing services. Print with Pak Print Wishes and make your brand shine!"
        />
        <meta property="og:image" content={bgImg} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <WhatsApp />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="featured-products-header"
        sx={{ backgroundImage: `url(${bgImg})` }}
      >
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9} sx={{ display: 'flex' }}>
            <div className="breadcrumb-text">
              <h1 className="breadcrumb-text__title">Contact us</h1>
              <ul className="breadcrumb-text__nav">
                <li>
                  <Link to={'/'}>Home</Link>
                </li>
                <li>&nbsp;-&nbsp;</li>
                <li>contact</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid display={'flex'} alignItems={'flex-start'} width={'90%'} margin={'auto'} py={6} container>
        <Grid item xs={10} sm={10} md={6} lg={6} xl={6}>
          <Typography className="contact-header-title">Get in touch with us</Typography>
          <Typography className="contact-title">Ask for your query</Typography>
          <List>
            <ListItemButton
              sx={{
                paddingLeft: 0,
              }}
              onClick={() => {
                window.open('https://wa.me/+923007605006');
              }}
              className="row-justify-center-flex contact-list-item"
            >
              <LocalPhoneIcon className="list-item-icon" />
              &nbsp; &nbsp; <Typography className="contact-font">+92 300 7605006</Typography>
            </ListItemButton>
            <ListItemButton
              sx={{
                paddingLeft: 0,
              }}
              onClick={() => {
                window.open('mailto:info.pak.printwishes@gmail.com');
              }}
              className="row-justify-center-flex contact-list-item"
            >
              <EmailOutlinedIcon className="list-item-icon" />
              &nbsp; &nbsp;
              {/* <h5 className="contact-font1">
              info.pak.printwishes@gmail.com
            </h5> */}
              <Typography className="contact-font">info.pak.printwishes@gmail.com</Typography>
            </ListItemButton>
            <ListItemButton
              sx={{
                paddingLeft: 0,
              }}
              className="row-justify-center-flex contact-list-item"
            >
              <LocationOnIcon className="list-item-icon" />
              &nbsp; &nbsp;
              <Typography className="contact-font">
                14, Block A Abdalians Cooperative Housing Society, Lahore, Punjab 54000
              </Typography>
            </ListItemButton>
            <ListItemButton
              sx={{
                paddingLeft: 0,
              }}
              className="row-justify-center-flex contact-list-item"
            >
              <LocationOnIcon className="list-item-icon" />
              &nbsp; &nbsp;{' '}
              <Typography className="contact-font">6 Nisbat Rd, Gawalmandi, Lahore, Punjab 54000</Typography>
            </ListItemButton>
          </List>
        </Grid>
        <Grid item xs={10} sm={10} md={6} lg={6} xl={6}>
          <Box className="text-field">
            <TextField
              id="outlined-basic"
              name="full_name"
              fullWidth
              placeholder="Your Name"
              variant="outlined"
              value={fname}
              onChange={(e) => {
                setFieldValue('full_name', e.target.value);
                setFName(e.target.value);
              }}
              onBlur={handleBlur}
              error={errors.full_name && touched.full_name ? errors.full_name : false}
              helperText={errors.full_name && touched.full_name ? errors.full_name : null}
            />
          </Box>
          <Box className="text-field">
            <TextField
              id="email"
              name="email"
              fullWidth
              placeholder="Your Email"
              variant="outlined"
              value={email}
              onChange={(e) => {
                setFieldValue('email', e.target.value);
                setEmail(e.target.value);
              }}
              onBlur={handleBlur}
              error={errors.email && touched.email ? errors.email : false}
              helperText={errors.email && touched.email ? errors.email : null}
            />
          </Box>
          <Box className="text-field">
            <TextField
              id="outlined-multiline-static"
              name="message"
              placeholder="Write a Message"
              multiline
              fullWidth
              rows={4}
              value={message}
              onChange={(e) => {
                setFieldValue('message', e.target.value);
                setMessage(e.target.value);
              }}
              onBlur={handleBlur}
              error={errors.message && touched.message ? errors.message : false}
              helperText={errors.message && touched.message ? errors.message : null}
            />
          </Box>
          <Box py={2}>
            <BlueButton onClick={handleSubmit} sx={{ marginLeft: '5px' }}>
              Send A Message
            </BlueButton>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3399.3525766849084!2d74.31905861499725!3d31.569377981353327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39191b71993876fb%3A0xbc3291e7e8397926!2sPakPrintWishes%20(Printing%20services%2C%20printing%20machines%2C%20printing%20vendors%20supplier)!5e0!3m2!1sen!2s!4v1665387463451!5m2!1sen!2s"
          width="100%"
          height="500"
          loading="lazy"
          title="myFrame"
          style={{ border: 'none' }}
          // referrerpolicy="no-referrer-when-downgrade"
        />
      </Grid>
    </Grid>
  );
};

export default ContactPages;
