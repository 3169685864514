import http from "./http-common";

const getAll = () => {
  return http.get("/getSlider");
};



const SliderServices = {
  getAll
};

export default SliderServices;