import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomProductCard from '../../components/card/CustomProductCard';
import { getPublicCustomProductChildIdMethod } from '../../redux/slice/publicCustomProductSlice';
import ProgressBar from '../../components/progress/ProgressBar';

const CustomChildProductPage = () => {
  const location = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.publicCustomProduct);
  useEffect(() => {
    dispatch(getPublicCustomProductChildIdMethod(location?.id));
  }, [dispatch, location?.id]);
  return (
    <Grid container spacing={2}>
      {data?.loading === true ? (
        <ProgressBar />
      ) : !data?.data || data?.data.length === 0 ? null : (
        data?.data.map((item, i) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={i}>
            <CustomProductCard
              image={item}
              path={`/custom-design/${location?.parentTitle}/${location?.title}/product`}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default CustomChildProductPage;
