import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import http, { api } from "../api/http-common";

const initialState = {
  data: [],
};
export const registerUser = createAsyncThunk(
  "user/createAPI",
  async (payload) => {
    const response = await http.post(`/user/register`, payload);
    return response.data;
  }
);

const registerUserSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    [registerUser.fulfilled]: (state, action) => {
      state.data.push(action.payload);
    },
  },
});

export default registerUserSlice.reducer;
