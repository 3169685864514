import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Swal from 'sweetalert2';
import UserServices from '../api/UserServices';

const initialState = {
  loading: false,
  userInfo: null,
  error: null,
  success: false,
};

export const userRegister = createAsyncThunk(`/user-register`, async (datas) => {
  try {
    const { data } = await UserServices.register(datas);
    Swal.fire({
      title: 'Success',
      text: 'Vendor Created Successfully and Please Check your email to verfiy OTP',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    });
    return data;
  } catch (error) {
    console.error(error);
    if (!error.response.data.message) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: error.response.data.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    throw error;
  }
});

export const vendorRegister = createAsyncThunk(`/vendorRegister`, async (datas) => {
  try {
    const { data } = await UserServices.vendorregister(datas);
    Swal.fire({
      title: 'Success',
      text: 'Vendor Created Successfully and Admin Approved then you can access this panel',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    });
    return data;
  } catch (error) {
    console.error(error);
    if (!error.response.data.message) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: error.response.data.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    throw error;
  }
});
export const userVerifyOtp = createAsyncThunk(`/user-VerifyOtp`, async (datas) => {
  try {
    const { data } = await UserServices.otp(datas);
    return data;
  } catch (error) {
    if (!error.response.data.message) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: error.response.data.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    throw error;
  }
});

export const userLogin = createAsyncThunk(`/user-login`, async (datas) => {
  try {
    const { data } = await UserServices.login(datas);
    // store user's token in local storage
    return data;
  } catch (error) {
    if (!error.response.data.message) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: error.response.data.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    throw error;
  }
});
export const forgetPasswordMethod = createAsyncThunk(`/user-forgetPassword`, async (datas) => {
  try {
    const { data } = await UserServices.forgetPassword(datas);
    return data;
  } catch (error) {
    if (!error.response.data.message) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: error.response.data.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    throw error;
  }
});
export const forgetPasswordOPTMethod = createAsyncThunk(`/user-otpForgetPassword`, async (datas) => {
  try {
    const { data } = await UserServices.otpForgetPassword(datas);
    return data;
  } catch (error) {
    if (!error.response.data.message) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: error.response.data.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    throw error;
  }
});

export const resetPasswordMethod = createAsyncThunk(`/user-resetPassword`, async (datas) => {
  try {
    const { data } = await UserServices.resetPassword(datas);
    return data;
  } catch (error) {
    if (!error.response.data.message) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: error.response.data.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    throw error;
  }
});

export const getUserDetails = createAsyncThunk(`/user-details`, async () => {
  try {
    const { data } = await UserServices.getUser();
    return data.data;
  } catch (error) {
    if (!error.response.data.message) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: error.response.data.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    throw error;
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('access_token'); // delete token from storage
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    },
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload.data;
      state.userToken = payload.access_token;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // // register user
    [userRegister.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userRegister.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true; // registration successful
      state.userInfo = payload;
    },
    [userRegister.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // // register venodr
    [vendorRegister.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [vendorRegister.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true; // registration successful
      // state.userInfo = payload;
    },
    [vendorRegister.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // otp verify
    [userVerifyOtp.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userVerifyOtp.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload;
    },
    [userVerifyOtp.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // forget password
    [forgetPasswordMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [forgetPasswordMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload;
    },
    [forgetPasswordMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // forget password otp
    [forgetPasswordOPTMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [forgetPasswordOPTMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload;
    },
    [forgetPasswordOPTMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // reset password
    [resetPasswordMethod.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [resetPasswordMethod.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload;
    },
    [resetPasswordMethod.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // get user details
    [getUserDetails.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.userInfo = payload;
    },
    [getUserDetails.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
